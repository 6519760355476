/** 기타 관리 > 공지사항 > 공지사항 클릭(상세 화면) > 수정 */

import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import styled, { css } from 'styled-components';

import { Button, Div, Input, Modal, ModalContext, SelectBox } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';

import PATHS from '@router/paths';
import { useDispatch, useSelector } from 'react-redux';
// import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import FileUpload from '@modules/FTA/components/FileUpload';
import { HealthcareRegistAPI } from '@redux/action/etcAction';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const DivSection = styled(Div)`
  display: flex;
  width: 400px;
  gap: 10px;
`;

// TODO: 유효성관련 메세지 파일 만들기
// const ERROR_MESSAGE = {
//   inputText: '내용을 입력해주세요.',
//   pwdLength: '비밀번호는 8자 이상 10자 이상이어야 합니다.',
// };

const HealthCareDBEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showModal, closeModal } = useContext(ModalContext);
  const [serarchParams] = useSearchParams();
  const healthId = serarchParams.get('healthId');
  // eslint-disable-next-line no-unneeded-ternary
  const isEdit = healthId ? true : false;
  const healthcare = useSelector((state) => state.etc.healthcareDetail);
  const enumAll = useSelector((state) => state.common.list);

  const formData = useMemo(() => new FormData(), []);

  const methods = useForm();
  const { handleSubmit, reset } = methods;

  const ADMIN_OPTIONS = {
    guide: 'DB 종류 선택',
    list: enumAll.HealthCareMainType.map((el) => ({ id: el.code, title: el.value })),
  };

  const ADMIN_SUB_OPTIONS = {
    guide: '2차 선택',
    list: enumAll.HealthCareSubType.map((el) => ({ id: el.code, title: el.value })),
  };

  // FIXME[CS] 실제 option으로 수정

  const COUNTRY_OPTIONS = {
    guide: '국가 선택',
    list: [
      { id: 'KR', title: '대한민국' },
      // NOTE: 국가 권역 리스트 API가 없고, 현재 다국어 진행이 되어있지 않으므로 일단 주석처리
      // { id: 'JP', title: '일본' },
      // { id: 'CN', title: '중국' },
      // { id: 'TW', title: '대만' },
    ],
  };

  const LANGUAGE_OPTIONS = {
    guide: '언어 선택',
    list: [
      { id: 'KR', title: '대한민국' },
      // NOTE: 국가 권역 리스트 API가 없고, 현재 다국어 진행이 되어있지 않으므로 일단 주석처리
      // { id: 'JP', title: '일본' },
      // { id: 'CN', title: '중국' },
      // { id: 'TW', title: '대만' },
    ],
  };

  const handleNavigate = useCallback(
    (path) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  const handleFileChange = useCallback((file) => {
    formData.append('file', file);
  }, []);

  const handleSaveClick = ({ content, country, deleteYn, languageType, mainCategoryType, subCategoryType, useYn }) => {
    const params = {
      healthCareInfoId: healthId,
      content,
      country,
      deleteYn,
      languageType,
      mainCategoryType,
      subCategoryType,
      useYn,
    };

    const jsonString = JSON.stringify(params);

    formData.append('healthCareInfoDTO', jsonString);

    HealthcareRegistAPI(dispatch, formData);

    handleNavigate(`/${PATHS.HEALTHCAREDB}`);
    closeModal();
  };

  const handleSave = useCallback(() => {
    showModal(
      <Modal
        body='입력한 정보를 저장하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={handleSubmit(handleSaveClick)}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const handleCancel = useCallback(() => {
    showModal(
      <Modal
        body='정보 저장을 취소하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                handleNavigate(`/${PATHS.HEALTHCAREDB}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const HEALTHDB_DATA = useMemo(() => {
    return [
      {
        title: 'DB 종류 선택',
        content: [
          <DivSection>
            <SelectBox name='mainCategoryType' options={ADMIN_OPTIONS} />
            <SelectBox name='subCategoryType' options={ADMIN_SUB_OPTIONS} />
          </DivSection>,
        ],
      },
      {
        title: '국가/ 언어',
        content: [
          <DivSection>
            <SelectBox name='country' options={COUNTRY_OPTIONS} />
            <SelectBox name='languageType' options={LANGUAGE_OPTIONS} />
          </DivSection>,
        ],
      },
      {
        title: '업데이트 사항',
        content: [
          <DivSection>
            <Input name='content' placeholder='업데이트 사항을 입력해주세요' />
          </DivSection>,
        ],
      },
      // FIXME - 파일 업로드 수정
      {
        title: 'DB 파일 업로드',
        content: [
          <div>
            <FileUpload name='healthCareFile' placeHolder='DB 파일을 선택해주세요.' onFileChange={handleFileChange} />
          </div>,
        ],
      },
    ];
  }, [healthcare, isEdit]);

  useEffect(() => {
    if (Object.values(healthcare).length > 0 && isEdit) {
      reset({
        healthCareInfoId: healthcare.healthCareInfoId,
        content: healthcare.content,
        country: healthcare.country,
        deleteYn: healthcare.deleteYn,
        languageType: healthcare.languageType,
        mainCategoryType: healthcare.mainCategoryType,
        subCategoryType: healthcare.subCategoryType,
        useYn: healthcare.useYn,
      });
    } else {
      reset();
    }
  }, [healthcare, isEdit]);

  return (
    <Container>
      <PageTitle title='헬스케어 DB' />
      <FormProvider {...methods}>
        <GridLayout data={HEALTHDB_DATA} />
        <ButtonSection>
          <Button onClick={handleSave} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
            저장
          </Button>
          <Button onClick={handleCancel} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            취소
          </Button>
        </ButtonSection>
      </FormProvider>
    </Container>
  );
};

HealthCareDBEdit.propTypes = {};

export default HealthCareDBEdit;
