/** 기타 관리 > 헬스케어 DB > 단건 조회 */

import { useCallback, useContext, useEffect, useMemo } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { Button, Div, Modal, ModalContext, Text } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import paths from '@router/paths';
import { getCSDetailAPI } from '@redux/action/etcAction';
import { useDispatch, useSelector } from 'react-redux';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import { FormProvider, useForm } from 'react-hook-form';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled(Div)`
  border-top: 3px solid black;
  word-break: break-all;
  margin-top: 10px;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
`;

const ExtendedText = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.gray07};
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const CsResponseDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { showModal, closeModal } = useContext(ModalContext);
  const getcsRequestInfoId = searchParams.get('csRequestInfoId');
  const csDetail = useSelector((state) => state.etc.csDetail);
  const methods = useForm();

  const CS_DATA = useMemo(
    () => [
      {
        title: '번호',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='num'>{1}</ExtendedText>
          </div>,
        ],
      },
      {
        title: '등록 날짜',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='num'>{csDetail.registDate}</ExtendedText>
          </div>,
        ],
      },
      {
        title: '상태',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='num'>{csDetail.csStatus}</ExtendedText>
          </div>,
        ],
      },
      {
        title: '아이디',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='num'>{csDetail.userId}</ExtendedText>
          </div>,
        ],
      },
      {
        title: '국가',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='num'>{csDetail.country}</ExtendedText>
          </div>,
        ],
      },
      {
        title: 'TV 사양',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='num'>값 없음</ExtendedText>
          </div>,
        ],
      },
      {
        title: '카테고리',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='num'>{csDetail.category}</ExtendedText>
          </div>,
        ],
      },
      {
        title: '제목',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='num'>{csDetail.title}</ExtendedText>
          </div>,
        ],
      },
      {
        title: '내용',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='num'>{csDetail.content}</ExtendedText>
          </div>,
        ],
      },
    ],
    [csDetail]
  );

  const CS_ANSWER_DATA = useMemo(
    () => [
      {
        title: '제목',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='num'>{csDetail?.csResponseList?.[0]?.title}</ExtendedText>
          </div>,
        ],
      },
      {
        title: '내용',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='num'>{csDetail?.csResponseList?.[0]?.content}</ExtendedText>
          </div>,
        ],
      },
    ],
    [csDetail]
  );

  const gotoList = useCallback(() => {
    const uuid = searchParams.get('uuid');
    const search = createSearchParams({ uuid }).toString();

    showModal(
      <Modal
        body='목록으로 이동하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                navigate({ pathname: `/${paths.CS_RESPONSE}`, search });
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  useEffect(() => {
    const params = {
      csRequestInfoId: getcsRequestInfoId,
    };
    getCSDetailAPI(dispatch, params);
  }, []);

  return (
    <Container>
      <PageTitle title='CS 대응' />
      <ContentContainer>
        <FormProvider {...methods}>
          <GridLayout data={CS_DATA} />
          {/* FIXME:   답변 내용 어디에, 내용은 어떤식으로 조회되는 지 확인 필요 */}
          {csDetail?.csResponseList?.length > 0 && (
            <>
              <TitleWithDivider title='답변' />
              <GridLayout data={CS_ANSWER_DATA} />
            </>
          )}
        </FormProvider>
        <ButtonContainer>
          {/* FIXME:   답변 내용 어디에, 내용은 어떤식으로 조회되는 지 확인 필요 */}
          {csDetail?.csResponseList?.length <= 0 && (
            <Button
              onClick={() => {
                const search = createSearchParams({ getcsRequestInfoId }).toString();
                navigate({ pathname: `/${paths.CS_RESPONSE_EDIT}`, search });
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              답변
            </Button>
          )}
          <Button onClick={gotoList} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            목록
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

export default CsResponseDetail;
