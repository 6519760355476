import { useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Button, Span } from '@components/index';

import { PlusIcon, CloseDarkIcon } from '@FTA/assets';

const Container = styled(Div)`
  width: 100%;
`;

const InputContainer = styled(Div)`
  --default-input-height: 50px;

  width: 400px;
  position: relative;
`;

const PlainInput = styled.input`
  padding: 16px 22px;
  border: 1px solid ${({ theme }) => theme.gray04};
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  width: 400px;
`;

const PlusButton = styled(Button)`
  position: absolute;
  top: calc(var(--default-input-height) / 2);
  right: 0;
  transform: translateY(-50%);
`;

const FilteredChipContainer = styled(Div)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
`;

const FilteredChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 8px 14px;
  border-radius: 56px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.38px;
  background-color: ${({ theme }) => theme.green01};
  color: ${({ theme }) => theme.green02};
`;

const FilterValue = styled(Span)``;

const InputChip = ({ name, maxContent, maxName, placeholder, ...rest }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });
  const maxWatch = useWatch({ name: maxName || '', control });

  const plainInputRef = useRef(null);

  const addChip = () => {
    if (!plainInputRef.current) return;

    const currentFeature = plainInputRef.current.value;

    if (!currentFeature) {
      alert('값을 입력하세요.');
      return;
    }

    if (maxContent === fields.length) {
      alert(`${maxContent}개를 초과하여 추가할 수 없습니다.`);
      return;
    }

    if (maxName && maxName !== '') {
      if (maxWatch === fields.length) {
        alert(`${maxWatch}개를 초과하여 추가할 수 없습니다.`);
        return;
      }
    }

    const isDuplicate = fields.some((field) => field.value === currentFeature);
    if (isDuplicate) {
      alert('이미 존재하는 값입니다.');
      return;
    }

    append({ value: currentFeature });
    plainInputRef.current.value = '';
  };

  const removeChip = (index) => {
    remove(index);
  };

  return (
    <Container>
      <InputContainer>
        <PlainInput
          ref={plainInputRef}
          type='text'
          placeholder={`${placeholder}${maxContent ? ` (최대 ${maxContent}개)` : ''}`}
          {...rest}
        />
        <PlusButton startIcon={PlusIcon} $imgVariant='close' onClick={addChip} />
      </InputContainer>
      <FilteredChipContainer>
        {fields?.map((field, index) => (
          <FilteredChip key={field.id}>
            <FilterValue>{field.value}</FilterValue>
            <Button startIcon={CloseDarkIcon} $imgVariant='close' onClick={() => removeChip(index)} />
          </FilteredChip>
        ))}
      </FilteredChipContainer>
    </Container>
  );
};

InputChip.propTypes = {
  /** HandleSubmit에서 사용되는 고유 key 값 */
  name: PropTypes.string.isRequired,
  /** Chip 최대 개수 */
  maxContent: PropTypes.number,
  /** Chip placeholder */
  placeholder: PropTypes.string,
  maxName: PropTypes.any,
};

export default InputChip;
