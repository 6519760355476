import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // CP List
  cpList: [],
};

/* eslint-disable no-param-reassign */
const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // NOTE: 회원가입 가능한 CP 리스트
    setCPList: (state, { payload }) => {
      state.cpList = payload;
    },
    // 등록,수정
    setAdminNote: (state) => {
      state.adminNote.exam = [
        {
          adminId: '',
          category: '',
          deleteYn: '',
          contents: '',
        },
      ];
    },
    // 리스트
    setAdminNoteList: (state, action) => {
      state.adminNote.list = action.payload;
    },
  },
});
/* eslint-enable no-param-reassign */

export const { setCPList, setAdminNoteList } = admin.actions;
export default admin.reducer;
