const themes = {
  light: {
    // Primary
    brown01: '#f2ebe0',
    brown02: '#bba283',
    brown03: '#847258',
    brown04: '#605039',
    brown05: '#423626',
    // Secondary
    green01: '#e5f7f1',
    green02: '#44655b', // NOTE[CS] graphy02와 색이 동일
    // Gray
    gray01: '#ffffff',
    gray02: '#fafafa',
    gray03: '#f2f2f2',
    gray04: '#e5e5e5',
    gray05: '#cccccc',
    gray06: '#999999',
    gray07: '#666666',
    gray08: '#4c4c4c',
    gray09: '#303030',
    gray10: '#202020', // NOTE[CS] button과 색이 동일
    // Semantic
    red: '#f44b4a',
    yellow: '#f9b811',
    green: '#44a280',
    // Graphy
    graphy01: '#b8a283',
    graphy02: '#44655b',
    graphy03: '#3089da',
    // hover color
    hover01: '#f0f0f0', // rgba(0, 0, 0, 0.05)
  },
  // NOTE[CS] 만약에 theme이 생기면 여기에 dark 색상을 추가하면 됩니다
  dark: {},
};

export default Object.freeze(themes);

/**
 * NOTE[CS] 사용은 되고 있지만 정의되어 있지 않는 색
 *
 * - #343c47
 * - #00a0b6
 * - #262626
 * - #7f7f7f
 * - #006eb9
 * - #eeeeee
 * - #222222
 * - #e1e1e1
 * - #e9e9e9
 * - #dbdbdb
 * - #7f7f7f
 * - #e0e0e0
 * - #dddddd
 * - #767676
 * - #d9d9d9
 * - #111111
 * - #dbd7d4
 * - #e9e9ed
 * - #f5f6fa
 * - #e4e4e4
 * - #b4b4b4
 */
