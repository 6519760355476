import { Div } from '@components/index';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled(Div)`
  display: grid;
  grid-template-columns: 1fr;
  /* gap: 16px; */
  /* padding: 16px; */
  & > :last-child {
    border-bottom: none;
    padding-bottom: 60px;
  }
`;

const GridItem = styled(Div)`
  display: grid;
  grid-template-columns: 140px 15fr;
  gap: 20px;
  padding-top: 24px;
  padding-bottom: 24px;

  ${({ hasBottomBorderLine }) => hasBottomBorderLine && 'border-bottom: 1px solid #f2f2f2;'}
`;

const Title = styled(Div)`
  font-weight: bold;
  padding-right: 8px;
  width: 100%;
  word-break: keep-all;
  display: flex;
  align-items: center;
  ${({ isRequired }) =>
    isRequired &&
    `
      &:after {
        content: '*';
        color: #f44b4a;
        font-size: 13px;
      }
    `}
`;

const Content = styled(Div)`
  /* padding-left: 8px; */
`;

const FullLineTitleContainer = styled(Div)`
  display: flex;
  align-items: center;
  width: 80vw;
  height: 59px;
  background: rgba(250, 250, 250, 1);
  border-top: 1px solid rgba(229, 229, 229, 1);
  border-bottom: 1px solid rgba(229, 229, 229, 1);
`;

const FullLineTitle = styled(Div)`
  font-size: 16px;
  font-weight: 700;
  padding-left: 5px;
`;

const ContentContainer = styled(Div)`
  min-height: 50px;
  display: flex;
  align-items: center;
`;

const GridLayout = ({ data, hasBottomBorderLine = false }) => {
  return (
    <Container>
      {data.map((item) => {
        if (!item) {
          return null;
        }
        const { title, content, sessionTitle, isRequired, dynamicItems } = item;
        return (
          <GridItem hasBottomBorderLine={hasBottomBorderLine}>
            {sessionTitle && (
              <FullLineTitleContainer>
                <FullLineTitle>{sessionTitle}</FullLineTitle>
              </FullLineTitleContainer>
            )}
            <Title isRequired={isRequired}>{title}</Title>
            {dynamicItems
              ? dynamicItems.map((dynamicItem) => (
                  <ContentContainer>
                    <Title>{dynamicItem.title}</Title>
                    <ContentContainer>{dynamicItem.content}</ContentContainer>
                  </ContentContainer>
                ))
              : Array.isArray(content) &&
                content.map((contentItem) => (
                  <ContentContainer>
                    <Content>{contentItem}</Content>
                  </ContentContainer>
                ))}
          </GridItem>
        );
      })}
    </Container>
  );
};
GridLayout.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fullLineTitle: PropTypes.string,
      title: PropTypes.string.isRequired,
      isRequired: PropTypes.bool,
      content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
      dynamicItems: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          content: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  hasBottomBorderLine: PropTypes.bool,
};

export default GridLayout;
