/** 컨텐츠 관리 > CP 컨텐츠 관리 / 자사 컨텐츠 */

import { useCallback, useEffect, useMemo } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { contentAPIs } from '@redux/action/contentsAction';

import styled, { css } from 'styled-components';
import { createColumnHelper } from '@tanstack/react-table';

import { Button, Table, Div } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';
import DetailButton from '@FTA/components/DetailButton';
import ApprovalStatusChip from '@FTA/components/ApprovalStatusChip';

import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';
import { convertDateToString } from '@utils';
import PATHS from '@router/paths';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';

const columnHelper = createColumnHelper();

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: end;
  margin-top: 30px;
`;

const FooterButtonStyle = css`
  padding: 8px 4px;
`;

const TABLE_KEYS = Object.freeze({
  UUID: 'uuid',
  NO: 'no',
  CONTENT_NAME: 'contentsName',
  CP_NAME: 'cpName',
  FIRST_CONTENT_CLASSIFICATION: 'mainCategory',
  SECOND_CONTENT_CLASSIFICATION: 'subCategory',
  CATEGORY: 'categoryType',
  RECOMMENDED_AGE_GROUP: 'recommendAge',
  DIFFICULTY: 'level',
  EXERCISE_INTENSITY: 'exerciseIntensity',
  APPROVAL_STATUS: 'routineApprovalStatus',
  REGISTRATION_DATE: 'registDate',
  QUICK_REGISTRATION: '-',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, name: 'no', checked: false },
  { code: TABLE_KEYS.CONTENT_NAME, name: '컨텐츠명', checked: false },
  { code: TABLE_KEYS.CP_NAME, name: 'CP명', checked: false },
];

const MAIN_CATEGORY_NAME = 'selectSingle';

const ContentsCP = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const contentUrl = location.pathname.split('/').pop();

  const { searchParams, getSearchParamsObject } = useSearchParams();
  const contentList = useSelector((state) => state.contents.list[contentUrl]);
  const contentPage = useSelector((state) => state.contents.paging);
  const enumAll = useSelector((state) => state.common.list);
  const { convertEnum } = useEnumSearch();

  const methods = useForm();
  const { control, setValue } = methods;
  const MainCaterotySelect = useWatch({ name: MAIN_CATEGORY_NAME, control });
  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.NO, {
        id: TABLE_KEYS.NO,
        header: () => <span>No</span>,
        cell: ({ row: { index } }) => <span>{contentList && contentList.length - index}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.CONTENT_NAME, {
        id: TABLE_KEYS.CONTENT_NAME,
        header: () => <span>컨텐츠명</span>,
        cell: ({ getValue, row: { original } }) => {
          const { contentsInfoId = '', mainCategory = '' } = original;
          const content = contentUrl;
          return (
            <DetailButton
              pathname={contentUrl === 'cp' ? PATHS.CONTENTS_CP_DETAIL : PATHS.CONTENTS_LG_DETAIL}
              searchParams={{ contentsInfoId, mainCategory, content }}
            >
              {getValue()}
            </DetailButton>
          );
        },
      }),
      columnHelper.accessor(TABLE_KEYS.CP_NAME, {
        id: TABLE_KEYS.CP_NAME,
        header: () => <span>CP명</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.FIRST_CONTENT_CLASSIFICATION, {
        id: TABLE_KEYS.FIRST_CONTENT_CLASSIFICATION,
        header: () => <span>컨텐츠 분류 1</span>,
        cell: ({ getValue }) => <span>{convertEnum(getValue())}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.SECOND_CONTENT_CLASSIFICATION, {
        id: TABLE_KEYS.SECOND_CONTENT_CLASSIFICATION,
        header: () => <span>컨텐츠 분류 2</span>,
        cell: ({ getValue }) => <span>{convertEnum(getValue())}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.CATEGORY, {
        id: TABLE_KEYS.CATEGORY,
        header: () => <span>카테고리</span>,
        cell: ({ getValue }) => <span>{convertEnum(getValue())}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.RECOMMENDED_AGE_GROUP, {
        id: TABLE_KEYS.RECOMMENDED_AGE_GROUP,
        header: () => <span>권장 연령대</span>,
        cell: ({ getValue }) => <span>{convertEnum(getValue())}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.DIFFICULTY, {
        id: TABLE_KEYS.DIFFICULTY,
        header: () => <span>난이도</span>,
        cell: ({ getValue }) => <span>{convertEnum(getValue())}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.EXERCISE_INTENSITY, {
        id: TABLE_KEYS.EXERCISE_INTENSITY,
        header: () => <span>운동강도</span>,
        cell: ({ getValue }) => <span>{convertEnum(getValue())}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.APPROVAL_STATUS, {
        id: TABLE_KEYS.APPROVAL_STATUS,
        header: () => <span>승인상태</span>,
        cell: ({ getValue }) => <ApprovalStatusChip code={getValue()} />,
      }),
      columnHelper.accessor(TABLE_KEYS.REGISTRATION_DATE, {
        id: TABLE_KEYS.REGISTRATION_DATE,
        header: () => <span>등록일</span>,
        cell: ({ getValue }) => <span>{convertDateToString(new Date(getValue()), '.')}</span>,
      }),
    ],
    [contentList]
  );

  const CONTENTS_OPTIONS = useMemo(() => {
    const baseOptions =
      enumAll.MainCategoryType?.map((item) => ({
        name: item.code,
        title: item.value,
        checked: false,
      })) || [];

    let filteredOptions = [];

    if (contentUrl !== 'lg') {
      const order = ['MCT000', 'MCT001', 'MCT002'];
      filteredOptions = baseOptions.filter((option) => order.includes(option.name));
      return filteredOptions.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
    }
    const order = ['MCT003', 'MCT001', 'MCT002'];
    filteredOptions = baseOptions.filter((option) => order.includes(option.name));
    return filteredOptions.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
  }, [enumAll, contentUrl]);

  const handleSearchClick = useCallback((data) => {
    console.log('Search data:', data);
  }, []);

  const gotoAdd = useCallback(() => {
    const getContent = contentUrl;
    const search = createSearchParams({ adminType: getContent }).toString();
    navigate({ pathname: `/${PATHS.CONTENTS_CP_WRITE}`, search });
  }, []);

  useEffect(() => {
    setValue(MAIN_CATEGORY_NAME, CONTENTS_OPTIONS[0]);
  }, [CONTENTS_OPTIONS]);

  useEffect(() => {
    if (!contentUrl || !MainCaterotySelect?.name) return;

    const param = getSearchParamsObject();
    contentAPIs[contentUrl][MainCaterotySelect.name].list(dispatch, param);
  }, [MainCaterotySelect, contentUrl, searchParams]);

  return (
    <>
      <PageTitle title={contentUrl === 'lg' ? '자사 컨텐츠' : 'CP 컨텐츠 관리'} />
      {contentList?.length ? (
        <>
          <FormProvider {...methods}>
            <SearchContainer
              options={TABLE_OPTIONS}
              contentOption={CONTENTS_OPTIONS}
              contentName={MAIN_CATEGORY_NAME}
              labelTitle='컨텐츠 선택'
              hasChip
              hasColumnContentsFilter
              hasInputSelectBox
              hasDateFilter
              onClick={handleSearchClick}
            />
          </FormProvider>
          <Table
            data={contentList}
            columns={COLUMNS_DATA}
            paging={contentPage}
            hasChip
            hasColumnContentsFilter
            hasInputSelectBox
          />
        </>
      ) : (
        <EmptyData text='등록된 컨텐츠가 없습니다.' />
      )}
      <ButtonContainer>
        <Button
          classes={{ Button: FooterButtonStyle }}
          $layout='square'
          $variantColor='primary'
          width={51}
          height={36}
          fontSize={14}
          onClick={() => {
            gotoAdd();
          }}
        >
          등록
        </Button>
      </ButtonContainer>
    </>
  );
};

ContentsCP.propTypes = {};

export default ContentsCP;
