/** 외부 센터프로그램 관리 > 프로그램 작성 > 추가 */

import { useCallback, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import styled from 'styled-components';

import { Div, RadioButton } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import ContentsSection from '@FTA/components/ContentsSection';
import InputComponent from '@FTA/components/DataGrid/InputComponent';

const columnHelper = createColumnHelper();

const periodSelectBox = {
  guide: '운동 횟수',
  list: [
    { id: '1', title: '1' },
    { id: '2', title: '2' },
  ],
};

const applyAllFirst = {
  guide: '적용방법 선택',
  list: [
    { id: '1', title: '1' },
    { id: '2', title: '2' },
  ],
};

const applyAllSecond = {
  guide: '주 선택',
  list: [
    { id: '1', title: '1' },
    { id: '2', title: '2' },
  ],
};

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const LinkText = styled.span`
  text-decoration: underline;
  text-underline-offset: 2px;
`;

const POPUP_MOCK = [
  {
    contentName: '1111 ',
    cpName: 'J&S 바디 필로우',
    content1: '아웃도어 액티비티',
    content2: '산책/조깅',
    programCount: 3,
    contentCount: '100개',
    fco: '유료',
    subscriber: 2000,
    grade: '4.5',
    status: 'pending',
    date: '2023.12.12',
    routineSubmit: '빠른 루틴 등록',
  },
  {
    contentName: '222 ',
    cpName: 'J&S 바디 필로우',
    content1: '아웃도어 액티비티',
    content2: '산책/조깅',
    programCount: 3,
    contentCount: '100개',
    fco: '유료',
    subscriber: 2000,
    grade: '2.5',
    status: 'approval',
    date: '2023.12.12',
    routineSubmit: '빠른 루틴 등록',
  },
  {
    contentName: '333 ',
    cpName: 'J&S 바디 필로우',
    content1: '아웃도어 액티비티',
    content2: '산책/조깅',
    programCount: 3,
    contentCount: '100개',
    fco: '유료',
    subscriber: 2000,
    grade: '3.5',
    status: 'reject',
    date: '2023.12.12',
    routineSubmit: '빠른 루틴 등록',
  },
  {
    programCount: 3,
    routineSubmit: '빠른 루틴 등록',
    status: 'reject',
  },
  {
    programCount: 3,
    routineSubmit: '빠른 루틴 등록',
    status: 'reject',
  },
  {
    programCount: 3,
    routineSubmit: '빠른 루틴 등록',
    status: 'reject',
  },
  {
    programCount: 3,
    routineSubmit: '빠른 루틴 등록',
    status: 'reject',
  },
  {
    programCount: 3,
    routineSubmit: '빠른 루틴 등록',
    status: 'reject',
  },
  {
    programCount: 3,
    routineSubmit: '빠른 루틴 등록',
    status: 'reject',
  },
  {
    programCount: 3,
    routineSubmit: '빠른 루틴 등록',
    status: 'reject',
  },
  {
    programCount: 3,
    routineSubmit: '빠른 루틴 등록',
    status: 'reject',
  },
  {
    programCount: 3,
    routineSubmit: '빠른 루틴 등록',
    status: 'reject',
  },
];

// TODO: 유효성관련 메세지 파일 만들기
const ERROR_MESSAGE = {
  inputText: '내용을 입력해주세요.',
  pwdLength: '비밀번호는 8자 이상 10자 이상이어야 합니다.',
};

const ExternalCenterProgramCreationEdit = () => {
  const schema = yup.object().shape({
    '컨텐츠명-text-0': yup.string().required(ERROR_MESSAGE.inputText),
    'CP명-text-0': yup.string().required(ERROR_MESSAGE.inputText),
    '컨텐츠 분류 1-radio-0': yup.string().required(ERROR_MESSAGE.inputText),
    '컨텐츠 분류 2-radio-0': yup.string().required(ERROR_MESSAGE.inputText),
    '컨텐츠 저장 위치-radio-0': yup.string().required(ERROR_MESSAGE.inputText),
  });
  const inputForm = useForm({ resolver: yupResolver(schema), mode: 'onBlur' });

  const {
    formState: { errors },
    setValue,
  } = inputForm;

  const handleContentTitle = useCallback((key, newValue) => {
    setValue(key, newValue);
  }, []);

  const [selectedValue, setSelectedValue] = useState(null);

  const radioButotnClick = (val) => {
    setSelectedValue(val);
  };

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor('no', {
        id: 'choose',
        cell: (info) => {
          return (
            <RadioButton
              name='radio_choose'
              value={info.row.id}
              required
              onClick={() => radioButotnClick(info.row.getValue('contentName'))}
            />
          );
        },
        header: () => <span>선택</span>,
        enableSorting: false,
      }),
      // columnHelper.accessor('no', {
      //   id: 'no',
      //   cell: ({ row: { index } }) => <span>{POPUP_DATA && POPUP_DATA.length - index}</span>,
      //   header: () => <span>번호</span>,
      //   enableColumnFilter: false,
      // }),
      columnHelper.accessor('contentName', {
        id: 'contentName',
        cell: (info) => <LinkText>{info.getValue()}</LinkText>,
        header: () => <span>콘텐츠명</span>,
      }),
      columnHelper.accessor('content1', {
        id: 'content1',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>컨텐츠 분류 1</span>,
      }),
      columnHelper.accessor('content2', {
        id: 'content2',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>컨텐츠 분류 2</span>,
      }),
      columnHelper.accessor('catetory', {
        id: 'catetory',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>카테고리</span>,
        size: 110,
      }),
      columnHelper.accessor('recommendedAge', {
        id: 'recommendedAge',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>권장 연령대</span>,
        size: 120,
      }),
      columnHelper.accessor('level', {
        id: 'level',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>난이도</span>,
        size: 110,
      }),
      columnHelper.accessor('intensity', {
        id: 'intensity',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>운동강도</span>,
        size: 110,
      }),
      columnHelper.accessor('date', {
        id: 'date',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>등록일</span>,
      }),
    ],
    []
  );

  const MOCK_SESSION_FIRST = [
    {
      name: '설정 방법',
      required: true,
      data: [{ type: 'radio', content: ['수동 설정', '자동 추천', '미설정'] }],
    },
  ];

  const MOCK_EXCERCISE_FIRST = [
    {
      name: '컨텐츠 선택',
      required: true,
      data: [{ type: 'choose-content-button', initialPlaceholder: '컨텐츠를 선택해주세요.', name: 'choose-content_1' }],
    },
    {
      name: '운동 횟수',
      required: true,
      data: [{ type: 'selectbox', name: 'programPeriod', options: periodSelectBox }],
    },
    {
      name: '일괄 적용',
      multiline: true,
      data: [
        {
          type: 'string',
          content: '시작 지점부터 코스까지의 거리를 입력해주세요.',
        },
        {
          type: 'dual-selectbox',
          name: ['applyAll_first', 'applyAll_second'],
          options: [applyAllFirst, applyAllSecond],
        },
      ],
    },
  ];

  const MOCK_EXCERCISE_SECOND = [
    {
      name: '컨텐츠 선택',
      required: true,
      data: [
        {
          type: 'choose-content-button',
          name: 'choose-content_2',
        },
      ],
    },
  ];

  const MOCK_SESSION_SECOND = [
    {
      name: '설정 방법',
      required: true,
      data: [{ type: 'radio', content: ['수동 설정', '자동 추천', '미설정'] }],
    },
  ];

  const MOCK_SESSION_THIRD = [
    {
      name: '설정 방법',
      required: true,
      data: [{ type: 'radio', content: ['수동 설정', '자동 추천', '미설정'] }],
    },
  ];

  // section 정보
  const MOCK_SECTION_MAP = [
    { title: '세션1', data: MOCK_SESSION_FIRST },
    { title: '운동1', data: MOCK_EXCERCISE_FIRST },
    { title: '운동2', data: MOCK_EXCERCISE_SECOND },
    { title: '세션2', data: MOCK_SESSION_SECOND },
    { title: '세션3', data: MOCK_SESSION_THIRD },
  ];

  return (
    <Container>
      <PageTitle title='프로그램 작성' />
      <FormProvider {...inputForm}>
        {MOCK_SECTION_MAP.map((mock, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <ContentsSection key={index} title={mock.title}>
              <InputComponent
                data={mock.data}
                errors={errors}
                popupElements={{
                  handleContentTitle,
                  popupColumns: COLUMNS_DATA,
                  popupData: POPUP_MOCK,
                  selectedValue,
                }}
              />
            </ContentsSection>
          );
        })}
      </FormProvider>
    </Container>
  );
};

export default ExternalCenterProgramCreationEdit;
