import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Div } from '@components/index';
import { useCallback, useRef } from 'react';
import TShakaPlayer from './ShakaPlayer/TShakaPlayer';

const Container = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;
`;
// const Video = styled.video``;

const VideoModal = ({ src }) => {
  const playerRef = useRef(null);
  const reftestRef = useRef(null);

  // const [isFocused, setFocused] = useState(false);

  const onVideoProgress = useCallback((ev) => {
    console.log('ShakaPlayerTest onVideoProgress');
    const current = ev.toFixed(1);
    console.log('ShakaPlayerTest onVideoProgress current paused ', current, playerRef.current.paused());
  }, []);

  const onVideoEnd = useCallback(() => {
    console.log('ShakaPlayerTest onVideoEnd');
    // setVideoIndex((videoIndex + 1) % VIDEO_SOURCE.length);
  }, []);

  // const onPlay = useCallback(()=>{
  //   playerRef.current.play();
  // }, []);

  // const nextStep = useCallback(()=>{
  // 	setVideoIndex((videoIndex+1)%VIDEO_SOURCE.length);
  // },[videoIndex]);

  return (
    <Container>
      {/* <Video src={src} /> */}
      <TShakaPlayer
        playerRef={playerRef}
        // className={classNames(
        //   css["shaka-player"],
        //   isFocused ? css.focused : css.unfocused
        // )}
        src={src}
        onProgress={onVideoProgress}
        onEnded={onVideoEnd}
        reftest={reftestRef}
        // poster={videoIndex === 0? chairStandThumb : bodyScanThumb}
      />
    </Container>
  );
};

VideoModal.propTypes = {
  src: PropTypes.string,
};

export default VideoModal;
