/* eslint-disable react/no-array-index-key */
import { Div } from '@components/index';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled(Div)`
  display: grid;
  grid-template-columns: 1fr;
  /* gap: 16px; */
  /* padding: 16px; */
`;

const GridItem = styled(Div)`
  display: grid;
  grid-template-columns: 140px 15fr;
  gap: 20px;
  padding: 16px;
`;

const Title = styled(Div)`
  font-weight: bold;
  padding-right: 8px;
  width: 100%;
  word-break: keep-all;

  ${({ isRequired }) =>
    isRequired &&
    `
      &:after {
        content: '*';
        color: #f44b4a;
        font-size: 13px;
      }
    `}
`;

const ButtonContainer = styled(Div)`
  z-index: 9999999;
  display: flex;
`;

const ButtonSubContainer = styled(Div)`
  display: flex;
  justify-content: center;
`;

const FullLineTitleContainer = styled(Div)`
  display: flex;
  align-items: center;
  width: 80vw;
  height: 59px;
  background: rgba(250, 250, 250, 1);
  border-top: 1px solid rgba(229, 229, 229, 1);
  border-bottom: 1px solid rgba(229, 229, 229, 1);
  justify-content: space-between;
`;

const FullLineSubTitleContainer = styled(Div)`
  display: flex;
  align-items: center;
  width: 80vw;
  height: 59px;
  background: rgba(250, 250, 250, 1);
  border-top: 1px solid rgba(229, 229, 229, 1);
  border-bottom: 1px solid rgba(229, 229, 229, 0.5);
  justify-content: space-between;
`;

const FullLineTitle = styled(Div)`
  font-size: 16px;
  font-weight: 700;
  padding-left: 5px;
`;

const ContentContainer = styled(Div)``;

const DefaultTitle = { title: '세션', addBtn: '세션' };

const GridSubSessionLayout = ({ data, buttonGroup, buttonSubAdd, buttonSubDelete, section = DefaultTitle }) => {
  return (
    <Container>
      {data?.map((item, index) => {
        if (!item) {
          return null;
        }
        const { dynamicItems, subList, isRequired, title } = item;
        return (
          <div key={index}>
            <GridItem>
              {buttonGroup && (
                <FullLineTitleContainer>
                  <FullLineTitle>
                    {section?.title}
                    {[index + 1]}
                  </FullLineTitle>
                  {buttonGroup && <ButtonContainer>{buttonGroup(index)}</ButtonContainer>}
                </FullLineTitleContainer>
              )}
              <Title isRequired={isRequired}>{title}</Title>
              {dynamicItems?.map((dynamicItem) => (
                <>
                  <Title>{dynamicItem.title}</Title>
                  <ContentContainer>{dynamicItem.content}</ContentContainer>
                </>
              ))}
            </GridItem>
            {subList &&
              subList?.map((subItem, subIndex) => (
                <GridItem>
                  <FullLineSubTitleContainer>
                    <FullLineTitle>
                      {section?.subTitle}
                      {[subIndex + 1]}
                    </FullLineTitle>
                    <ButtonContainer>{buttonSubDelete(index, subIndex)}</ButtonContainer>
                  </FullLineSubTitleContainer>
                  <Title isRequired={isRequired}>{title}</Title>
                  {subItem?.dynamicItems?.map((subDynamicItem) => (
                    <>
                      <Title>{subDynamicItem?.title}</Title>
                      <ContentContainer>{subDynamicItem.content}</ContentContainer>
                    </>
                  ))}
                </GridItem>
              ))}
            <ButtonSubContainer>{buttonSubAdd(index)}</ButtonSubContainer>
          </div>
        );
      })}
    </Container>
  );
};
GridSubSessionLayout.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fullLineTitle: PropTypes.string,
      title: PropTypes.string.isRequired,
      isRequired: PropTypes.bool,
      content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
      dynamicItems: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          content: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  buttonGroup: PropTypes.node,
  buttonSubAdd: PropTypes.node,
  buttonSubDelete: PropTypes.node,
  section: PropTypes.any,
};

export default GridSubSessionLayout;
