import { memo } from 'react';
import PropTypes from 'prop-types';
import { Div } from '@components/Atoms/Atoms';
import styled, { css } from 'styled-components';

import { InfoIcon } from '@images/';

const InfoCss = css`
  &::before {
    position: absolute;
    display: block;
    content: "";
    width: ${({ w }) => (w ? `${w}px` : '14px')};
    height: ${({ h }) => (h ? `${h}px` : '14px')};
    top: ${({ h }) => `calc(50% - ${h / 2}px);`}
    left: 0;
    background: url(${InfoIcon}) no-repeat center/cover;
  }
`;

const InfoDiv = styled(Div)`
  position: relative;
  padding-left: ${({ w }) => (w ? `${w + 4}px` : '18px')};
  ${InfoCss}
`;

const InfoBox = memo(({ className, children, w, h, isLoading }) => (
  <InfoDiv className={className} w={w} h={h} isLoading={isLoading}>
    {children}
  </InfoDiv>
));

InfoBox.propTypes = {
  /** ClassName */
  className: PropTypes.string,
  /** Children */
  children: PropTypes.node,
  /** Loading 화면 표시 */
  isLoading: PropTypes.bool,
  /** InfoDiv의 높이 */
  h: PropTypes.number,
  /** InfoDiv의 폭 */
  w: PropTypes.number,
};

InfoBox.defaultProps = {
  h: 14,
  w: 14,
};

export default InfoBox;
