import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';

import { getCpManagementAPI } from '@redux/action/cpAction';

const useCPInfo = () => {
  const dispatch = useDispatch();
  const { searchParams, getSearchParamsObject } = useSearchParams();
  const cpList = useSelector((state) => state.cp.list);
  const cpPaging = useSelector((state) => state.cp.paging);
  const { convertEnum } = useEnumSearch();

  useEffect(() => {
    const param = getSearchParamsObject();
    getCpManagementAPI(dispatch, param);
  }, [searchParams]);

  return {
    cpList,
    cpPaging,
    convertEnum,
  };
};

export default useCPInfo;
