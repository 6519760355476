/** CP 관리 > CP 정보 > 등록 */
/** CP 관리 > CP 정보 > 컨텐츠 클릭(상세 화면) > 수정 */
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CpManagementRegistAPI, getCpManagementDetailAPI } from '@redux/action/cpAction';
import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useContentMode from '@modules/FTA/hooks/useContentMode';

import styled, { css } from 'styled-components';

import { Button, Div, FormProviderWithDevTools, Modal, ModalContext } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import CPDefaultInfoForm from './modules/form/CPDefaultInfoForm';
import CPMainCategoryInfoForm from './modules/form/CPMainCategoryInfoForm';

import PATHS from '@router/paths';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const FORM_NAMES = Object.freeze({
  CP_NAME: 'cpName',
  MAIN_CATEGORY: 'mainCategory',
  SUB_CATEGORY: 'subCategory',
  NATIONAL: 'national',
  LANGUAGE: 'language',
  LOGO_IMAGE: 'logoImage',
  INTRODUCTION_IMAGE: 'introductionImage',
  CENTER_INTRODUCTION_IMAGE: 'centerIntroductionImage',
  INTRODUCTION: 'introduction',
  FEATURES: 'features',
  EXPOSE_YN: 'exposeYn',
});

const CPInfoForm = () => {
  const dispatch = useDispatch();
  const CPDetailInfo = useSelector((state) => state.cp.detail);

  const navigate = useNavigate();
  const { isWriteMode, targetId: cpManagementId } = useContentMode('cpManagementId');

  const { showModal, closeModal } = useContext(ModalContext);

  const hookFormInstance = useForm({
    defaultValues: {
      [FORM_NAMES.FEATURES]: [],
    },
  });
  const { handleSubmit, reset } = hookFormInstance;

  const onSubmit = ({
    mainCategory,
    subCategory,
    national,
    language,
    exposeYn,
    logoImage,
    introductionImage,
    centerIntroductionImage,
    cpName,
    features,
    introduction,
  }) => {
    console.log('###features', features);

    const params = {
      mainCategory,
      subCategory: [subCategory],
      national,
      language,
      exposeYn: !!parseInt(exposeYn, 10),
      logoImage,
      introductionImage,
      centerIntroductionImage,
      // FIXME: 현재 언어별 입력란이 없어서 아래와 같이 한글 입력 값을 영어에도 적용하도록 처리
      cpManageDetailList: [
        {
          cpName,
          introduction,
          features: features.map((feature) => feature.value),
          languageType: 'KR',
        },
        // {
        //   cpName,
        //   introduction,
        //   features: features.map((feature) => feature.value),
        //   languageType: 'LANGUAGE_SET_EN',
        // },
      ],
    };

    if (isWriteMode) {
      CpManagementRegistAPI(dispatch, params);
    } else {
      const originalCopy = { ...CPDetailInfo };
      // eslint-disable-next-line
      const test = Object.assign(originalCopy, params);
      CpManagementRegistAPI(dispatch, test);
    }
    navigate(`/${PATHS.CP_INFO}`);
    closeModal();
  };

  const handleApproval = useCallback(() => {
    showModal(
      <Modal
        body='입력한 정보를 저장하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={handleSubmit(onSubmit)}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              저장
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, [handleSubmit]);

  const handleReject = useCallback(() => {
    showModal(
      <Modal
        body='정보 저장을 취소하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                navigate(`/${PATHS.CP_INFO}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  useEffect(() => {
    if (!isWriteMode) {
      getCpManagementDetailAPI(dispatch, { cpManagementId });
    }
  }, []);

  useEffect(() => {
    if (!isWriteMode) {
      reset({
        [FORM_NAMES.CP_NAME]: CPDetailInfo?.cpManageDetailList?.[0][FORM_NAMES.CP_NAME],
        [FORM_NAMES.MAIN_CATEGORY]: CPDetailInfo[FORM_NAMES.MAIN_CATEGORY],
        [FORM_NAMES.SUB_CATEGORY]: CPDetailInfo[FORM_NAMES.SUB_CATEGORY]?.[0], // NOTE: 디자인 상에서 단일 선택이고, 논리적으로도 단일 선택이 맞는 것 같아 radio button으로 구현
        [FORM_NAMES.NATIONAL]: CPDetailInfo[FORM_NAMES.NATIONAL],
        [FORM_NAMES.LANGUAGE]: CPDetailInfo[FORM_NAMES.LANGUAGE],
        [FORM_NAMES.LOGO_IMAGE]: CPDetailInfo[FORM_NAMES.LOGO_IMAGE],
        [FORM_NAMES.INTRODUCTION_IMAGE]: CPDetailInfo[FORM_NAMES.INTRODUCTION_IMAGE],
        [FORM_NAMES.CENTER_INTRODUCTION_IMAGE]: CPDetailInfo[FORM_NAMES.CENTER_INTRODUCTION_IMAGE],
        [FORM_NAMES.INTRODUCTION]: CPDetailInfo?.cpManageDetailList?.[0][FORM_NAMES.INTRODUCTION],
        [FORM_NAMES.FEATURES]: CPDetailInfo?.cpManageDetailList?.[0][FORM_NAMES.FEATURES]?.map((feature) => ({
          value: feature,
        })),
        [FORM_NAMES.EXPOSE_YN]: CPDetailInfo[FORM_NAMES.EXPOSE_YN] ? '1' : '0',
      });
    }
  }, [CPDetailInfo]);

  return (
    <Container>
      <PageTitle />
      <FormProviderWithDevTools {...hookFormInstance}>
        <CPDefaultInfoForm formNames={FORM_NAMES} />
        <CPMainCategoryInfoForm formNames={FORM_NAMES} />
      </FormProviderWithDevTools>
      <ButtonSection>
        <Button onClick={handleApproval} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
          저장
        </Button>
        <Button onClick={handleReject} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
          취소
        </Button>
      </ButtonSection>
    </Container>
  );
};

CPInfoForm.propTypes = {};

export default CPInfoForm;
