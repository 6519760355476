import Cell from '@FTA/components/Cell';
import { Grid } from '@components/index';
import { css } from 'styled-components';

const GridStyle = css`
  margin-bottom: 40px;
`;

const ContentManagement = () => {
  return (
    <>
      <Grid title='프로그램' column={4} classes={{ Grid: GridStyle }}>
        <Cell title='프로그램 승인 요청 수 (Total)' value='1250' />
        <Cell title='프로그램 승인 수 (Total) ' value='3800' />
        <Cell title='프로그램 승인 요청 수 (O2O 정규프로그램)' value='34:33' />
        <Cell title='프로그램 승인 수 (O2O 정규프로그램)' value='34:33' />

        <Cell title='프로그램 승 인요청 수 (운동추천 프로그램)' value='1250' />
        <Cell title='프로그램 승인 수 (운동추천 프로그램)' value='3800' />
        <Cell title='프로그램 승인 요청 수 (산책/조깅/하이킹프로그램)' value='34:33' />
        <Cell title='프로그램 승인 요청 수 (산책/조깅/하이킹프로그램)' value='34:33' />

        <Cell title='프로그램 승인 요청 수 (트레이닝 프로그램)' value='1250' />
        <Cell title='프로그램 승인 수 (트레이닝 프로그램)' value='3800' />
        <Cell title='프로그램 승인 요청 수 (골프 프로그램)' value='34:33' />
        <Cell title='프로그램 승인 수 (골프 프로그램)' value='34:33' />
      </Grid>
      <Grid title='개별 컨텐츠' column={4} classes={{ Grid: GridStyle }}>
        <Cell title='컨텐츠 승인 요청 수 (Total)' value='1250' />
        <Cell title='컨텐츠 승인수 (Total) ' value='3800' />
        <Cell title='컨텐츠 승인 요청 수 (O2O 정규프로그램)' value='34:33' />
        <Cell title='컨텐츠 승인 수 (O2O 정규프로그램)' value='34:33' />

        <Cell title='컨텐츠 승인 요청 수 (O2O 정규프로그램_메타정보有)' value='1250' />
        <Cell title='컨텐츠 승인 수 (O2O 정규프로그램_메타정보有)' value='3800' />
        <Cell title='컨텐츠 승인 요청 수 (O2O 정규프로그램_운동코칭 가능)' value='34:33' />
        <Cell title='컨텐츠 승인 수 (O2O 정규프로그램_운동코칭 가능)' value='34:33' />

        <Cell title='컨텐츠 승인 요청 수 (운동추천프로그램)' value='1250' />
        <Cell title='컨텐츠 승인 수 (운동추천프로그램)' value='3800' />
        <Cell title='컨텐츠 승인 요청 수 (산책/조깅/하이킹프로그램)' value='34:33' />
        <Cell title='컨텐츠 승인 요청 수 (산책/조깅/하이킹프로그램)' value='34:33' />

        <Cell title='컨텐츠 승인 요청 수 (트레이닝 프로그램)' value='1250' />
        <Cell title='컨텐츠 승인 수 (트레이닝 프로그램)' value='3800' />
        <Cell title='컨텐츠 승인 요청 수 (골프 프로그램)' value='34:33' />
        <Cell title='컨텐츠 승인 수 (골프 프로그램)' value='34:33' />
      </Grid>
    </>
  );
};

export default ContentManagement;
