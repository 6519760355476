import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@components/Input/Button/Button';
import { Div } from '@components/Atoms/Atoms';
import { PaginationArrow, PaginationArrowLast } from '@images/index';
import { useCallback } from 'react';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';

const Container = styled(Div)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 20px;
`;

const PaginationBox = styled(Div)`
  display: flex;
  gap: 20px;
`;

const PaginationButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: ${({ theme }) => theme.gray08};
  width: 28px;
  height: 28px;
  border-radius: 20px;
  background-color: ${(props) => props.selected && props.theme.brown01};

  &:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const PaginationNext = styled(Button)`
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${PaginationArrow});
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }
`;

const PaginationLast = styled(PaginationNext)`
  background-image: url(${PaginationArrowLast});
`;

const PaginationPrev = styled(PaginationNext)`
  transform: rotate(180deg);
`;

const PaginationFirst = styled(PaginationPrev)`
  background-image: url(${PaginationArrowLast});
`;

const TablePagination = ({ paging }) => {
  /* eslint-disable */
  const { searchParams, setParam, deleteParam, getParam } = useSearchParams();
  console.log('###paging', paging);

  const firstPage = useCallback(() => {
    setParam('page', 1);
  }, [paging]);

  const previousPage = useCallback(() => {
    if (paging.number !== 0) {
      setParam('page', paging?.number);
    }
  }, [paging]);

  const gotoPage = useCallback(
    (number) => {
      if (paging?.number + 1 !== number) {
        setParam('page', number);
      }
    },
    [paging]
  );

  const nextPage = useCallback(() => {
    if (paging?.totalPages > 1 && paging?.number + 1 !== paging?.totalPages) {
      setParam('page', paging?.number + 2);
    }
  }, [paging]);

  const lastPage = useCallback(() => {
    setParam('page', paging?.totalPages);
  }, [paging]);
  // 페이지 번호를 계산하는 로직
  const getPageNumbers = () => {
    const currentPage = paging?.number + 1; // 현재 페이지 (0 기반 인덱스)
    const totalPages = paging?.totalPages;
    const pageNumbers = [];

    let startPage = Math.max(1, currentPage - 2); // 2페이지 뒤부터 시작
    let endPage = Math.min(totalPages, currentPage + 2); // 2페이지 앞으로 끝

    if (currentPage <= 3) {
      endPage = Math.min(5, totalPages); // 처음 3페이지에서는 최대 5페이지까지
    }
    if (currentPage > totalPages - 3) {
      startPage = Math.max(1, totalPages - 4); // 마지막 3페이지에서는 마지막 5페이지
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <Container>
      <PaginationFirst onClick={firstPage} disabled={paging?.first} />
      <PaginationPrev onClick={previousPage} disabled={paging?.first} />
      <PaginationBox>
        {pageNumbers.map((number) => (
          <div key={`pagination-${number}`}>
            <PaginationButton onClick={() => gotoPage(number)} selected={paging?.number === number - 1}>
              {number}
            </PaginationButton>
          </div>
        ))}
      </PaginationBox>
      <PaginationNext onClick={nextPage} disabled={paging?.last} />

      <PaginationLast onClick={lastPage} disabled={paging?.last} />
    </Container>
  );
};

TablePagination.propTypes = {
  /** React-Table에서 사용되는 데이터 객체 */
  table: PropTypes.object.isRequired,
  /** 서버에서 주는 page 정보 */
  paging: PropTypes.object,
};

export default TablePagination;
