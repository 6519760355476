import { useState, useEffect, useMemo } from 'react';

import styled from 'styled-components';

import TabMenu from '@components/TabMenu/TabMenu';

import { Div } from '@components/index';
import { FormProvider, useForm } from 'react-hook-form';
import SearchContainer from '@modules/FTA/components/SearchContainer';
import TableMultiSelectBox from '@components/DataDisplay/Table/modules/TableMultiSelectBox';
import CPManagement from './modules/CPManagement';
import ContentManagement from './ContentManagement';

const Container = styled(Div)``;

const SelectBoxContainer = styled(Div)`
  display: flex;
  gap: 15px;
  margin-bottom: 40px;
`;

const ChipDateContainer = styled(Div)`
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
`;

const CP_OPTIONS = [
  { code: '서초 지점', title: '서초 지점', checked: false },
  { code: '학동 지점', title: '학동 지점', checked: false },
  { code: '교대 지점', title: '교대 지점', checked: false },
];

const REGION_OPTIONS = [{ code: '대한민국', value: '대한민국' }];

const PLATFORM_OPTIONS = [
  { code: 'WebOS 4.0', value: 'WebOS 4.0' },
  { code: 'WebOS 4.5', value: 'WebOS 4.5' },
  { code: 'WebOS 5.0', value: 'WebOS 5.0' },
  { code: 'WebOS 6.0', value: 'WebOS 6.0' },
  { code: 'WebOS 22', value: 'WebOS 22' },
  { code: 'WebOS 23', value: 'WebOS 23' },
];

const AGE_GROUP_OPTIONS = [
  { code: '20대 미만', value: '20대 미만' },
  { code: '20대', value: '20대' },
  { code: '30대', value: '30대' },
  { code: '40대', value: '40대' },
  { code: '50대', value: '50대' },
  { code: '60대', value: '60대' },
  { code: '70대', value: '70대' },
  { code: '80대 이상', value: '80대 이상' },
];

const PRIMARY_MENU_OPTIONS = [
  { code: '바디 체크업', value: '바디 체크업' },
  { code: '홈케어 프로그램', value: '홈케어 프로그램' },
  { code: '아웃도어 액티비티', value: '아웃도어 액티비티' },
];

const DAY_OPTIONS = [
  { code: '일', value: '일' },
  { code: '월', value: '월' },
  { code: '화', value: '화' },
  { code: '수', value: '수' },
  { code: '목', value: '목' },
  { code: '금', value: '금' },
  { code: '토', value: '토' },
];

const SECONDARY_MENU_OPTIONS = [
  [
    { code: '체형분석', value: '체형분석' },
    { code: '관절가동범위', value: '관절가동범위' },
    { code: '신체 균형', value: '신체 균형' },
    { code: '신체 능력', value: '신체 능력' },
  ],
  [
    { code: '프로그램', value: '프로그램' },
    { code: 'AI 추천 프로그램', value: 'AI 추천 프로그램' },
  ],
  [
    { code: '산책/조깅', value: '산책/조깅' },
    { code: '하이킹', value: '하이킹' },
    { code: '골프 코칭', value: '골프 코칭' },
    { code: '트레이닝', value: '트레이닝' },
  ],
];

const tabData = [
  { id: 'tab1', title: 'CP사 관리', content: <CPManagement /> },
  { id: 'tab2', title: '컨텐츠 관리', content: <ContentManagement /> },
];

const ReportCp = () => {
  const inputForm = useForm();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [primaryMenuSelectedIndex, setPrimaryMenuSelectedIndex] = useState(null);
  const [secondaryMenuOptions, setSecondaryMenuOptions] = useState([]);

  const TIME_OPTIONS = useMemo(() => {
    const options = [];
    for (let hour = 0; hour <= 23; hour += 1) {
      const timeString = `${hour.toString().padStart(2, '0')}:00`;
      options.push({ code: timeString, value: timeString });
    }
    return options;
  }, []);

  useEffect(() => {
    if (primaryMenuSelectedIndex != null) {
      setSecondaryMenuOptions(SECONDARY_MENU_OPTIONS[primaryMenuSelectedIndex]);
    }
  }, [primaryMenuSelectedIndex]);

  return (
    <Container>
      <TabMenu tabData={tabData} currentTabIndex={currentTabIndex} setCurrentTabIndex={setCurrentTabIndex}>
        <FormProvider {...inputForm}>
          <ChipDateContainer>
            <SearchContainer
              options={CP_OPTIONS}
              labelTitle='CP 선택'
              hasChip
              hasColumnFilter
              hasDateFilter
              hasSearchInput={false}
            />
          </ChipDateContainer>
          <SelectBoxContainer>
            <TableMultiSelectBox options={REGION_OPTIONS} initialLabel='권역 선택' />
            <TableMultiSelectBox options={PLATFORM_OPTIONS} initialLabel='플랫폼 선택' />
            <TableMultiSelectBox options={AGE_GROUP_OPTIONS} initialLabel='연령대 선택' />
            <TableMultiSelectBox
              options={PRIMARY_MENU_OPTIONS}
              initialLabel='1차 메뉴 선택'
              singleSelect
              setCheckedIndex={setPrimaryMenuSelectedIndex}
            />
            <TableMultiSelectBox
              options={secondaryMenuOptions}
              initialLabel='2차 메뉴 선택'
              disabled={primaryMenuSelectedIndex == null}
            />
            <TableMultiSelectBox options={DAY_OPTIONS} initialLabel='요일 선택' />
            <TableMultiSelectBox options={TIME_OPTIONS} initialLabel='시간 선택' />
          </SelectBoxContainer>
        </FormProvider>
      </TabMenu>
    </Container>
  );
};

ReportCp.propTypes = {};

export default ReportCp;
