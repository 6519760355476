import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { Div } from '@components/Atoms/Atoms';
import { useFormContext, useWatch } from 'react-hook-form';

const Container = styled(Div)`
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ classes }) => classes && classes.ContainerStyle}
`;

const DisabledLabel = css`
  cursor: not-allowed;
  color: ${({ theme }) => theme.gray05};
`;

const DisabledInput = css`
  opacity: 0.5;
  cursor: not-allowed;
`;

const Input = styled.input`
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.gray06};
  cursor: pointer;
  ${({ disabled }) => disabled && DisabledInput}
  &:checked {
    background: ${({ theme }) => theme.brown04};
    border: 2px solid ${({ theme }) => theme.gray05};
  }
  ${({ classes }) => classes && classes.InputStyle}
`;

const Label = styled.label`
  margin: 0 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.72px;
  color: ${({ theme }) => theme.gray08};
  cursor: pointer;
  ${({ disabled }) => disabled && DisabledLabel}
  ${({ classes }) => classes && classes.LabelStyle}
`;

const RadioButton = ({ id, name, item, disabled, required, hasLabel = true, classes, onClick }) => {
  const { register, setValue } = useFormContext();
  const selectedValue = useWatch({ name });
  const isChecked = selectedValue === item?.code;

  const handleClick = () => {
    if (disabled) return;

    setValue(name, item?.code);
    onClick && onClick();
  };
  return (
    <Container classes={classes}>
      <Input
        id={id}
        type='radio'
        {...register(name, { disabled, required })}
        value={item?.code}
        classes={classes}
        checked={isChecked}
        onChange={handleClick}
      />
      {hasLabel && (
        <Label htmlFor={id} value={item?.value} classes={classes} disabled={disabled}>
          {item?.value}
        </Label>
      )}
    </Container>
  );
};

RadioButton.propTypes = {
  /** 라디오 버튼 id */
  id: PropTypes.string.isRequired,
  /** 라디오 버튼 name */
  name: PropTypes.string.isRequired,
  /** 라디오 버튼 { code, value } */
  item: PropTypes.shape({
    /** 라디오 버튼 param code 값 */
    code: PropTypes.string,
    /** 라디오 버튼 Label 값 */
    value: PropTypes.string,
  }).isRequired,
  /** 라디오 버튼에서 disabled 하고싶은 버튼의 index ex.[0,1,2] */
  disabled: PropTypes.bool,
  /** 라디오 버튼 필수 선택여부 */
  required: PropTypes.bool,
  /** Label 존재 여부 */
  hasLabel: PropTypes.bool,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  onClick: PropTypes.node,
};

export default React.memo(RadioButton);
