import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { updateTokenExpireTimeAPI } from '@redux/action/authAction';

import styled from 'styled-components';

import { Div, Modal, Button, ModalContext } from '@components/index';
import Cookies from 'js-cookie';

const BoxContainer = styled(Div)`
  display: flex;
  gap: 10px;
  margin: 20px;
`;

const SessionRefreshModal = () => {
  const dispatch = useDispatch();
  const { closeModal } = useContext(ModalContext);

  const refreshToken = Cookies.get('refreshToken');

  const handleConfirm = () => {
    const params = {
      refreshToken,
    }; // FIXME: 실제 API 호출 시 필요한 파라미터 추가

    updateTokenExpireTimeAPI(dispatch, params);
    closeModal();
  };

  const handleClose = () => {
    closeModal();
  };
  return (
    <Modal
      body='세션을 연장 하시겠습니까?'
      btns={
        <BoxContainer>
          <Button onClick={handleClose} $layout='square' $variantColor='line' width={160} height={48} fontSize={16}>
            취소
          </Button>
          <Button
            onClick={handleConfirm}
            $layout='square'
            $variantColor='primary'
            width={160}
            height={48}
            fontSize={16}
          >
            확인
          </Button>
        </BoxContainer>
      }
    />
  );
};

SessionRefreshModal.propTypes = {};

export default SessionRefreshModal;
