import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Div } from '@components/Atoms/Atoms';

const STATUS_TYPE = Object.freeze({
  approval: 'approval',
  reject: 'reject',
  pending: 'pending',
});

const Type = {
  approval: css`
    background-color: ${({ theme }) => theme.green}80;
    color: ${({ theme }) => theme.green};
  `,
  reject: css`
    background-color: ${({ theme }) => theme.red}80;
    color: ${({ theme }) => theme.red};
  `,
  pending: css`
    background-color: ${({ theme }) => theme.yellow}80;
    color: ${({ theme }) => theme.yellow};
  `,
};

const getStyles = ({ classes }) => classes && classes.Variant;
const getType = ({ type }) => type && Type[type];

const styles = css`
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  ${getStyles};
  ${getType};
`;

const Container = styled(Div)`
  ${styles}
`;

const ApprovalStatusChip = ({ code, ...props }) => {
  const { convertEnum } = useEnumSearch();

  const statusType = {
    // NOTE: 프로그램
    SAS000: STATUS_TYPE.approval,
    SAS001: STATUS_TYPE.reject,
    SAS002: STATUS_TYPE.pending,
    // NOTE: 컨텐츠
    RAS001: STATUS_TYPE.approval,
    RAS002: STATUS_TYPE.reject,
    RAS003: STATUS_TYPE.pending,
    // NOTE: 관리자 계정
    AAS999: STATUS_TYPE.pending,
    AAS000: STATUS_TYPE.pending,
    AAS001: STATUS_TYPE.approval,
    AAS002: STATUS_TYPE.reject,
  };

  return (
    <Container type={statusType[code]} {...props}>
      {convertEnum(code)}
    </Container>
  );
};

ApprovalStatusChip.propTypes = {
  /** 승인 상태에 따른 스타일 타입 */
  code: PropTypes.oneOf([
    // NOTE: 프로그램
    'SAS000', // NOTE: 승인
    'SAS001', // NOTE: 반려
    'SAS002', // NOTE: 심사중
    // NOTE: 컨텐츠
    'RAS001', // NOTE: 승인
    'RAS002', // NOTE: 반려
    'RAS003', // NOTE: 심사 중
    // NOTE: 관리자 계정
    'AAS999', // NOTE: 심사대기
    'AAS000', // NOTE: 심사중
    'AAS001', // NOTE: 승인
    'AAS002', // NOTE: 반려
  ]).isRequired,
  /** 버튼 커스텀 스타일 오브젝트 */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ApprovalStatusChip;
