import { useCallback, useRef } from 'react';

import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Button, Divider } from '@components/index';
import renderCustomHeader from './modules/CalendarHeader';
import RangeCalendarDropdown from './modules/RangeCalendarDropdown';
import { CalendarButtonStyle, CalendarDefaultStyles, CalendarDivider } from './modules/CalendarStyles';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';
import { convertDateToString } from '@utils';
import SERVICE_CONST from '@modules/FTA/data/service';

const CalendarWrapper = styled.div`
  ${CalendarDefaultStyles}

  display: flex;
  column-gap: 10px;
  align-items: center;

  .react-datepicker-popper {
    z-index: 2;
  }
`;

function formatDate(dateStr) {
  const year = dateStr.slice(0, 4);
  const month = dateStr.slice(4, 6);
  const day = dateStr.slice(6, 8);

  return `${year}-${month}-${day}`;
}

const StyledDatePicker = styled(DatePicker)`
  // NOTE[CS] datepicker input에 대한 style
  font-size: 16px;
  font-weight: 600;
  border: 0;
  text-align: center;
  ${({ classes }) => classes && classes.DatePickerStyle}
`;

const DATE_NOW = Date.now();
const DEFAULT_VALUE = { start: new Date(SERVICE_CONST.START_DATE), end: new Date(DATE_NOW) };

const RangeCalendar = ({ name, required, disabled, hasDropdown = true, dateFormat = 'yyyy.MM.dd', classes }) => {
  const calendarRef = useRef(null);
  const { setParam, getParams } = useSearchParams();

  // NOTE[CS]
  // 만약 취소 버튼을 눌렀을 때 예전에 선택된 값으로 되돌리는 동작을 구현해야 한다면
  // previous value를 저장하도록 추가 구현이 필요함
  const handleCancelClick = useCallback(() => {
    calendarRef.current.setOpen && calendarRef.current.setOpen(false);
  }, []);

  const { startDate, endDate } = getParams();

  const defaultValue =
    startDate && endDate
      ? { start: new Date(formatDate(startDate)), end: new Date(formatDate(endDate)) }
      : DEFAULT_VALUE;

  const { control } = useFormContext();
  const { field } = useController({
    name,
    defaultValue,
    control,
    rules: {
      required,
      disabled,
    },
  });

  const handleDateRangeChange = useCallback(
    ([start, end]) => {
      field.onChange({ start, end });
      setParam('startDate', convertDateToString(start));
      setParam('endDate', convertDateToString(end));
    },
    [field]
  );

  const handleDropDownDateRangeParams = useCallback((item) => {
    setParam('startDate', convertDateToString(item.startDate));
    setParam('endDate', convertDateToString(item.endDate));
  }, []);

  return (
    <CalendarWrapper>
      {hasDropdown ? (
        <>
          <RangeCalendarDropdown field={field} handleDateRangeParams={handleDropDownDateRangeParams} />
          <Divider classes={{ Divider: CalendarDivider }} />
        </>
      ) : null}
      <StyledDatePicker
        ref={calendarRef}
        renderCustomHeader={(props) => renderCustomHeader({ ...props, setOpen: calendarRef.current.setOpen })}
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
        selected={field.value.start}
        startDate={field.value.start}
        endDate={field.value.end}
        onChange={handleDateRangeChange}
        dateFormat={dateFormat}
        classes={classes}
        selectsRange
      >
        <Button
          onClick={handleCancelClick}
          $layout='square'
          $variantColor='primary'
          classes={{ Button: CalendarButtonStyle }}
        >
          취소
        </Button>
      </StyledDatePicker>
    </CalendarWrapper>
  );
};

RangeCalendar.propTypes = {
  /** Calendar를 구분하기 위한 name */
  name: PropTypes.string.isRequired,
  /** 값 선택 필수 유무 */
  required: PropTypes.bool,
  /** Disabled 여부 */
  disabled: PropTypes.bool,
  /** Calendar에 Dropdown 여부 */
  hasDropdown: PropTypes.bool,
  /** RangeCalendar format 형식 */
  dateFormat: PropTypes.string,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.shape({
    RangeCalendar: PropTypes.objectOf(PropTypes.string),
  }),
};

export default RangeCalendar;
