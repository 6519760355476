/** 외부 센터프로그램 관리 > 프로그램 구성 > 프로그램 클릭(상세 화면) > 수정 */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import styled, { css } from 'styled-components';
import * as yup from 'yup';

import { Button, Checkbox, Div, Input, Modal, ModalContext, SelectBox, Span } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';

import { CheckBoxChecked } from '@modules/FTA/assets';
import ExternalCenterProgramConfigurationEditFirst from './module/ExternalCenterProgramConfigurationEditFirst';
import ExternalCenterProgramConfigurationEditSecond from './module/ExternalCenterProgramConfigurationEditSecond';
import ExternalCenterProgramCreation from '../ExternalCenterProgramCreation';
import { yupResolver } from '@hookform/resolvers/yup';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const buttonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const SelectBoxContainer = styled(Div)`
  width: 155px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.gray06};
  font-weight: 600;
`;

const CheckBoxContainer = styled(Div)`
  display: flex;
  gap: 10px;
  margin-top: 25px;
`;

const TextSpan = styled(Span)`
  margin-left: 15px;
  width: 15px;
`;

// 기본 input ui 안보이게 함
const checkboxInputStyle = css`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

// unChecked 상태 ui
const checkboxCustomUIStyle = css`
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
  border: 1px solid ${({ theme }) => theme.gray05};
  border-radius: 5px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
`;

// checked 상태 ui
const checkboxT = css`
  &:after {
    content: '';
    display: block;
    background-image: url(${CheckBoxChecked});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }
`;

const UnusedContainer = styled(Div)`
  display: none;
`;

const WEEKDAYS = [
  { code: '6', value: '일요일' },
  { code: '0', value: '월요일' },
  { code: '1', value: '화요일' },
  { code: '2', value: '수요일' },
  { code: '3', value: '목요일' },
  { code: '4', value: '금요일' },
  { code: '5', value: '토요일' },
];

const periodOptions = {
  guide: '기간 선택',
  list: Array.from({ length: 12 }, (v, i) => ({ id: i + 1, title: i + 1 })),
};

const FORM_KEYS = Object.freeze({
  KR: 'kr',
  EN: 'en',
  PROGRAM: 'program',
  INTRODUCE_TEXT: 'introduceText',
  PRICE_YN: 'priceYn',
  PROGRAM_IMAGE: 'programImage',
  SESSIONS: 'sessions',
  SESSION_NAME: 'sessionName',
  SESSION_DESCRIPTION: 'sessionDescription',
});

const ExternalCenterProgramConfigurationEdit = () => {
  const programListDetail = useSelector((state) => state.program.programList);
  const [serarchParams] = useSearchParams();
  const [sessionOrder, setSessionOrder] = useState([0]);
  const [step, setStep] = useState(1);

  // FIXME:기획 or api변경에 따라 필수값 변경해야 할수도있음
  // 현재는 현재 기획(0.85) 기준
  const [schema, setSchema] = useState(
    yup.object().shape({
      [FORM_KEYS.KR]: yup.object().shape({
        [FORM_KEYS.PROGRAM]: yup.string().required(),
        [FORM_KEYS.INTRODUCE_TEXT]: yup.string().required(),
      }),
      [FORM_KEYS.PROGRAM_IMAGE]: yup.mixed(),
    })
  );

  const isEdit = serarchParams.get('searchId');

  const onClickNext = useCallback(() => {
    setStep((prev) => prev + 1);
  }, []);

  const onClickPrev = useCallback(() => {
    setStep((prev) => prev - 1);
  }, [step]);

  const defaultValues =
    isEdit !== null && isEdit
      ? {
          program:
            programListDetail?.programDetailList?.length > 0
              ? programListDetail?.programDetailList[0]?.programName
              : '',
          introduceText:
            programListDetail?.programDetailList?.length > 0
              ? programListDetail?.programDetailList[0]?.programDescription
              : '',
          priceYn: programListDetail?.priceYn ? 'priceY' : 'priceN',
          exposeYn: programListDetail?.exposeYn ? 'exposeY' : 'exposeN',
        }
      : {};

  const inputForm = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const {
    formState: { isValid },
    getValues,
    handleSubmit,
    watch,
  } = inputForm;
  const { showModal, closeModal } = useContext(ModalContext);

  const initialSession = useCallback((id) => {
    const session = {
      dynamicItems: [
        {
          title: '세션명',
          content: (
            <Input
              key={`sessions.${id}.sessionName`}
              name={`sessions.${id}.sessionName`}
              type='text'
              placeholder='세션명을 입력해주세요.'
              maxLength='100'
              // defaultValue={isEdit && '세션명'}
            />
          ),
        },
        {
          title: '설명',
          content: (
            <Input
              key={`sessions.${id}.sessionDescription`}
              name={`sessions.${id}.sessionDescription`}
              type='text'
              placeholder='설명을 입력해주세요.'
              maxLength='100'
              // defaultValue={isEdit && '설명'}
            />
          ),
        },
        {
          title: '세션 기간 및 요일선택',
          content: (
            <Div key={`sessions.${id}.sessionPeriodWeekdays`}>
              <SelectBoxContainer>
                <SelectBox name={`sessions.${id}.period`} options={periodOptions} /> <TextSpan>주</TextSpan>
              </SelectBoxContainer>
              <CheckBoxContainer>
                {WEEKDAYS.map((item) => (
                  <Checkbox
                    id={`sessions.${id}.${item.code}`}
                    name={`sessions.${id}.${item.code}`}
                    item={item}
                    classes={{
                      Input: checkboxInputStyle,
                      Box: checkboxCustomUIStyle,
                      CheckboxT: checkboxT,
                    }}
                  />
                ))}
              </CheckBoxContainer>
            </Div>
          ),
        },
      ],
    };
    return session;
  }, []);

  const [exerciseInfo, setExerciseInfo] = useState([initialSession(0)]);

  const getProgramSessionList = useCallback(
    (sessions) => {
      // 빈 객체를 제거
      const nonEmptySessions = sessions.filter((session) => session && Object.keys(session).length > 0);

      // sessionOrder에 따라 세션을 재정렬
      const reorderedSessions = sessionOrder
        .map((item) => {
          return nonEmptySessions[item];
        })
        .filter((session) => session !== undefined);

      return reorderedSessions
        .map((session, index) => {
          if (!session) return null;

          const dayListOfWeek = Object.keys(session)
            .filter((key) => {
              const num = parseInt(key, 10);
              return !Number.isNaN(num) && session[key] !== false;
            })
            .map((key) => parseInt(key, 10));

          return {
            dayListOfWeek,
            period: session.period,
            programSessionOrder: index + 1,
            sessionDetailList: [
              {
                languageType: 'kr',
                sessionDescription: session.sessionDescription,
                sessionName: session.sessionName,
              },
            ],
          };
        })
        .filter((session) => session !== null);
    },
    [sessionOrder, getValues('sessions')]
  );

  const onSuccess = useCallback(
    (data) => {
      console.log('success data 확인요', data);
      console.log('formData success', watch());

      const params = {
        ageGroup: getValues('ageGroup'),
        approvalStatus: 'SAS000',
        category: getValues('category'),
        exerciseIntensity: getValues('exerciseIntensity'),
        exerciseLevel: getValues('exerciseLevel'),
        exerciseTime: 1,
        exposeYn: getValues('exposeYn'),
        file: {
          fileId: getValues('programImage').fileId,
          useYn: getValues('programImage').useYn,
        },
        priceYn: getValues('priceYn'),
        programDetailList: [
          {
            languageType: 'kr',
            programDescription: getValues('kr.introduceText'),
            programName: getValues('kr.program'),
          },
          {
            languageType: 'en',
            programDescription: getValues('en.introduceText'),
            programName: getValues('en.program'),
          },
        ],
        programDuration: '10',
        programSessionList: getProgramSessionList(getValues('sessions')),
        scheduleYn: 1,
        sessionCount: exerciseInfo.length,
      };

      console.log('params', params);

      // programRegisterAPI(dispatch, params);
      closeModal();
    },
    [exerciseInfo]
  );

  const onError = (data) => {
    console.log('error data 확인요', data);
    console.log('formData onError', watch());
    closeModal();
  };

  const onClickSave = useCallback(() => {
    showModal(
      <Modal
        body='저장하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={handleSubmit(onSuccess, onError)}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: buttonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: buttonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, [handleSubmit]);

  const handleValidation = useCallback(() => {
    if (step === 1) {
      // FIXME:기획 or api변경에 따라 필수값 변경해야 할수도있음
      // 현재는 현재 기획(0.85) 기준
      setSchema(
        yup.object().shape({
          [FORM_KEYS.KR]: yup.object().shape({
            [FORM_KEYS.PROGRAM]: yup.string().required(),
            [FORM_KEYS.INTRODUCE_TEXT]: yup.string().required(),
          }),
          [FORM_KEYS.PROGRAM_IMAGE]: yup.mixed(),
        })
      );
    } else if (step === 2) {
      setSchema(
        yup.object().shape({
          [FORM_KEYS.SESSIONS]: yup.array().of(
            yup.object().shape({
              [FORM_KEYS.SESSION_NAME]: yup.string().required(),
              [FORM_KEYS.SESSION_DESCRIPTION]: yup.string().required(),
            })
          ),
        })
      );
    }
  }, [step]);

  useEffect(() => {
    handleValidation();
  }, [step]);

  const content = useMemo(() => {
    if (step === 1) {
      return (
        <>
          <ExternalCenterProgramConfigurationEditFirst />
          <UnusedContainer>
            <ExternalCenterProgramConfigurationEditSecond
              initialSession={initialSession}
              exerciseInfo={exerciseInfo}
              setExerciseInfo={setExerciseInfo}
              sessionOrder={sessionOrder}
              setSessionOrder={setSessionOrder}
            />
          </UnusedContainer>
        </>
      );
    }
    if (step === 2) {
      return (
        <>
          <UnusedContainer>
            <ExternalCenterProgramConfigurationEditFirst />
          </UnusedContainer>
          <ExternalCenterProgramConfigurationEditSecond
            initialSession={initialSession}
            exerciseInfo={exerciseInfo}
            setExerciseInfo={setExerciseInfo}
            sessionOrder={sessionOrder}
            setSessionOrder={setSessionOrder}
          />
        </>
      );
    }
    return <ExternalCenterProgramCreation />;
  }, [step, initialSession, exerciseInfo, sessionOrder, setExerciseInfo, setSessionOrder]);

  return (
    <Container>
      <PageTitle title='프로그램 구성' />
      <FormProvider {...inputForm}>
        <div>{content}</div>
      </FormProvider>

      <ButtonContainer>
        {/* <Button $layout='square' $variantColor='line' classes={{ Button: buttonCss }} onClick={onClickSave}>
          저장
        </Button> */}
        {step !== 1 && (
          <Button $layout='square' $variantColor='primary' classes={{ Button: buttonCss }} onClick={onClickPrev}>
            이전
          </Button>
        )}

        {step < 3 ? (
          <Button
            $layout='square'
            $variantColor='line'
            disabled={!isValid}
            classes={{ Button: buttonCss }}
            onClick={onClickNext}
          >
            다음
          </Button>
        ) : (
          <Button $layout='square' $variantColor='line' classes={{ Button: buttonCss }} onClick={onClickSave}>
            저장
          </Button>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default ExternalCenterProgramConfigurationEdit;
