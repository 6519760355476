/** 외부 센터프로그램 관리 > 프로그램 목록 */
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styled, { css } from 'styled-components';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import { Button, Div, Table } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';
import DetailButton from '@FTA/components/DetailButton';
import ApprovalStatusChip from '@FTA/components/ApprovalStatusChip';

import { convertDateToString } from '@utils';
import PATHS from '@router/paths';
import useExternalCenterProgramManagement from '@modules/FTA/hooks/externalCenterProgramManagement/useExternalCenterProgramManagement';

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const FooterButtonStyle = css`
  padding: 8px 4px;
`;

const buttonCss = css`
  font-size: 12px;
  text-decoration: none;
`;

const columnHelper = createColumnHelper();

const TABLE_KEYS = Object.freeze({
  NO: 'no',
  PROGRAM_NAME: 'programName',
  CP_NAME: 'cpName',
  PRICE_YN: 'priceYn',
  PROGRAM_DURATION: 'programDuration', //
  SESSION_COUNT: 'sessionCount',
  SCHEDULE_YN: 'scheduleYn',
  PROGRAM_COMPLETION_STATUS: 'programCompletionStatus', //
  EXPOSE_YN: 'exposeYn', //
  APPROVAL_STATUS: 'approvalStatus',
  REGIST_DATE: 'registDate',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, title: 'no', checked: false },
  { code: TABLE_KEYS.PROGRAM_NAME, title: '컨텐츠명', checked: false },
  { code: TABLE_KEYS.CP_NAME, title: 'CP명', checked: false },
];

const ExternalCenterProgramConfiguration = () => {
  const navigate = useNavigate();
  const methods = useForm();
  const { programList, programPaging } = useExternalCenterProgramManagement();

  const handleNavigate = useCallback((path) => {
    if (path) {
      navigate(path);
    }
  }, []);

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.NO, {
        id: TABLE_KEYS.NO,
        header: () => <span>No</span>,
        cell: ({ row: { index } }) => <span>{programList && programList.length - index}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.PROGRAM_NAME, {
        id: TABLE_KEYS.PROGRAM_NAME,
        header: () => <span>컨텐츠명</span>,
        cell: ({ getValue, row: { original } }) => {
          const programInfoId = original?.programInfoId;
          return (
            <DetailButton pathname={PATHS.ECP_CONFIGURATION_DETAIL} searchParams={{ programInfoId }}>
              {getValue()}
            </DetailButton>
          );
        },
      }),
      columnHelper.accessor(TABLE_KEYS.CP_NAME, {
        id: TABLE_KEYS.CP_NAME,
        header: () => <span>CP명</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.PRICE_YN, {
        id: TABLE_KEYS.PRICE_YN,
        header: () => <span>유/무료</span>,
        cell: ({ getValue }) => <span>{getValue() ? '유료' : '무료'}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.PROGRAM_DURATION, {
        id: TABLE_KEYS.PROGRAM_DURATION,
        header: () => <span>프로그램 기간</span>,
        cell: ({ getValue }) => <span>{getValue()}주</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.SESSION_COUNT, {
        id: TABLE_KEYS.SESSION_COUNT,
        header: () => <span>세션 수</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.SCHEDULE_YN, {
        id: TABLE_KEYS.SCHEDULE_YN,
        header: () => <span>스케쥴링 여부</span>,
        cell: ({ getValue }) => <span>{getValue() ? '완료' : '진행중'}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.PROGRAM_COMPLETION_STATUS, {
        id: TABLE_KEYS.PROGRAM_COMPLETION_STATUS,
        header: () => <span>프로그램 작성 여부</span>,
        cell: ({ getValue }) => (
          <Button classes={{ Button: buttonCss }} onClick={() => handleNavigate(`/${PATHS.ECP_CREATION}`)}>
            {getValue() === 'Y' ? '완료' : '진행중'}{' '}
          </Button>
        ),
      }),
      columnHelper.accessor(TABLE_KEYS.EXPOSE_YN, {
        id: TABLE_KEYS.EXPOSE_YN,
        header: () => <span>노출 여부</span>,
        cell: ({ getValue }) => <span>{getValue() ? '노출' : '미노출'}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.APPROVAL_STATUS, {
        id: TABLE_KEYS.APPROVAL_STATUS,
        header: () => <span>승인상태</span>,
        cell: ({ getValue }) => <ApprovalStatusChip code={getValue()} />,
      }),
      columnHelper.accessor(TABLE_KEYS.REGIST_DATE, {
        id: TABLE_KEYS.REGIST_DATE,
        header: () => <span>등록일</span>,
        cell: ({ getValue }) => <span>{convertDateToString(new Date(getValue()), '.')}</span>,
      }),
    ],
    [programList]
  );
  /* eslint-enable react/no-unstable-nested-components */

  // FIXME[CS] 추후에 실제 데이터의 길이로 변경
  if (programList?.length === 0) return <EmptyData text='등록된 컨텐츠가 없습니다.' />;

  return (
    <>
      <PageTitle title='외부 센터프로그램 관리' />
      <FormProvider {...methods}>
        <SearchContainer options={TABLE_OPTIONS} hasChip hasColumnFilter hasInputSelectBox hasDateFilter />
      </FormProvider>
      <Table
        data={programList}
        columns={COLUMNS_DATA}
        paging={programPaging}
        hasChip
        hasColumnFilter
        hasInputSelectBox
        buttonList={
          <ButtonContainer>
            <Button
              classes={{ Button: FooterButtonStyle }}
              $layout='square'
              $variantColor='primary'
              width={51}
              height={36}
              fontSize={14}
              onClick={() => {
                handleNavigate(`/${PATHS.ECP_CONFIGURATION_EDIT}`);
              }}
            >
              등록
            </Button>
          </ButtonContainer>
        }
      />
    </>
  );
};

ExternalCenterProgramConfiguration.propTypes = {};

export default ExternalCenterProgramConfiguration;
