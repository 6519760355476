/** 기타 관리 > 헬스케어 DB > 단건 조회 */

import { useCallback, useContext, useEffect, useMemo } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { Button, Div, Modal, ModalContext, Text } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import paths from '@router/paths';
import { FormProvider, useForm } from 'react-hook-form';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import { HealthcareDeleteAPI, HealthcareDetailAPI } from '@redux/action/etcAction';
import { useDispatch, useSelector } from 'react-redux';
import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled(Div)`
  border-top: 3px solid black;
  word-break: break-all;
  margin-top: 10px;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const ExtendedText = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.gray07};
`;

const HealthCareDBDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { showModal, closeModal } = useContext(ModalContext);
  const { convertEnum } = useEnumSearch();
  const healthId = searchParams.get('healthCareInfoId');
  const healthcare = useSelector((state) => state.etc.healthcareDetail);
  const inputForm = useForm();

  const DB_INFO = useMemo(() => {
    return [
      {
        title: 'DB 종류 선택',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='cpName'>{convertEnum(healthcare?.mainCategoryType)}</ExtendedText>
          </div>,
        ],
      },
      {
        title: '업데이트 사항',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='mainCategory'>{healthcare?.content}</ExtendedText>
          </div>,
        ],
      },
      {
        title: '국가/ 언어',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='subCategory'>{healthcare.country}</ExtendedText>
          </div>,
        ],
      },
      {
        title: 'DB 파일',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='national'>{healthcare?.healthCareFile?.originalFileName || ''}</ExtendedText>
          </div>,
        ],
      },
      {
        title: '버전 정보',
        content: [
          <div style={{ width: '400px' }}>
            <ExtendedText name='national'>{healthcare?.versionInfo || ''}</ExtendedText>
          </div>,
        ],
      },
    ];
  }, [healthcare]);

  const onClickEdit = useCallback(() => {
    const search = createSearchParams({ healthId }).toString();

    showModal(
      <Modal
        body='등록된 정보를 수정하시겠습니까?
        헬스케어 DB를 수정할 경우 헬스케어 DB 작성 정보가 변경되거나 삭제될 수 있으니 주의해주세요.
        '
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                navigate({ pathname: `/${paths.HEALTHCAREDB_EDIT}`, search });
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const onClickDelete = useCallback(() => {
    showModal(
      <Modal
        body='등록된 정보를 삭제하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                const params = { healthCareInfoId: healthId };
                HealthcareDeleteAPI(dispatch, params);
                navigate({ pathname: `/${paths.HEALTHCAREDB}` });
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const gotoList = useCallback(() => {
    const uuid = searchParams.get('uuid');
    const search = createSearchParams({ uuid }).toString();

    showModal(
      <Modal
        body='목록으로 이동하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                navigate({ pathname: `/${paths.HEALTHCAREDB}`, search });
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  useEffect(() => {
    const params = { healthCareInfoId: healthId };
    HealthcareDetailAPI(dispatch, params);
  }, []);

  return (
    <Container>
      <PageTitle title='헬스케어 DB' />
      <ContentContainer>
        <FormProvider {...inputForm}>
          <GridLayout data={DB_INFO} hasBottomBorderLine />
        </FormProvider>

        <ButtonContainer>
          <Button onClick={onClickEdit} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
            수정
          </Button>
          <Button onClick={onClickDelete} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            삭제
          </Button>
          <Button onClick={gotoList} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            목록
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

export default HealthCareDBDetail;
