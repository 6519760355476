import Axios from '@Axios';
import { HEADER, METHODS } from '@AxiosConfig';
import {
  setContentPendingList,
  setContentPendingPaging,
  setCpPendingList,
  setCpPendingPaing,
  setProgramPendingList,
  setProgramPendingPaging,
} from '@redux/feature/slices/approvalPendingSlice';
import { omit } from '@utils';

const BASE_URL = 'v1/api/approval';

/**
 * 승인대기 프로그램 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const approvalProgramAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/getApprovalProgram`;

  const onSuccess = (res) => {
    console.log('%c✅ approvalProgramAPI RESOPONSE : ', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setProgramPendingList(res.data));
    const paging = omit(res.data, ['content']);
    dispatch(setProgramPendingPaging(paging));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 승인대기 cp 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const approvalCpAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/getApprovalCp`;

  const onSuccess = (res) => {
    console.log('%c✅ approvalCpAPI RESOPONSE : ', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setCpPendingList(res.data));
    const paging = omit(res.data, ['content']);
    dispatch(setCpPendingPaing(paging));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 승인대기 컨텐츠 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const approvalContentAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/getApprovalContents`;

  const onSuccess = (res) => {
    console.log('%c✅ approvalContentAPI RESOPONSE : ', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setContentPendingList(res.data));
    const paging = omit(res.data, ['content']);
    dispatch(setContentPendingPaging(paging));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

// eslint-disable-next-line import/prefer-default-export
export { approvalProgramAPI, approvalCpAPI, approvalContentAPI };
