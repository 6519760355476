import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';
import { approvalCpAPI } from '@redux/action/approvalPendingAction';

const useApprovalPendingCp = () => {
  const dispatch = useDispatch();
  const { searchParams, getSearchParamsObject } = useSearchParams();
  const { convertEnum } = useEnumSearch();

  const cpPendingList = useSelector((state) => state.approvalPending.cpPendingList.content);
  const cpPendingPaing = useSelector((state) => state.approvalPending.cpPendingPaing);

  useEffect(() => {
    const param = getSearchParamsObject();
    approvalCpAPI(dispatch, param);
  }, [searchParams]);

  return {
    cpPendingList,
    cpPendingPaing,
    convertEnum,
  };
};

export default useApprovalPendingCp;
