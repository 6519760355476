class ArrayToObjectByKey {
  constructor(array) {
    this.array = array;
  }

  mapByKey(key) {
    return this.array.reduce((acc, obj) => {
      acc[obj[key]] = obj;
      return acc;
    }, {});
  }
}
export default ArrayToObjectByKey;
