import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InquiryHistoryNavi } from '@images/';
// import { htmlDecode } from "@libs/Utils";

const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
  display: none;

  &:checked + label {
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
  }

  &:checked + label::after {
    transform: rotate(180deg);
  }

  &:checked + label {
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
    &::after {
      transform: rotate(180deg);
    }
  }
`;

const CommonLabel = styled.label`
  position: relative;
  padding-top: 30px;
  display: block;
  font-size: 15px;
  font: -apple-system-body;
  color: #111;
  cursor: pointer;
  margin: 0 24px;
  padding-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 34px;
    height: 25px;
    background: url(${InquiryHistoryNavi}) no-repeat right -3px;
    background-size: 34px auto;
    text-align: center;
    -webkit-transition: all 0.35s;
    -o-transition: all 0.35s;
    transition: all 0.35s;
  }
`;

const Span = styled.span`
  font-size: 17px;
  font: -apple-system-body;
  color: #111;
  font-weight: bold;
`;

const Expand = ({ id, onChangeEvent }) => {
  const onChange = (e) => {
    onChangeEvent && onChangeEvent(e.target.checked);
  };
  return (
    <>
      <Input
        id={id}
        type='checkbox'
        onChange={(e) => {
          onChange(e);
        }}
      />
      <CommonLabel htmlFor={id}>
        <Span>type</Span>
        {
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: 'htmlDecode(text)',
              // htmlDecode(text),
            }}
          />
        }
      </CommonLabel>
    </>
  );
};

Expand.propTypes = {
  id: PropTypes.string,
  onChangeEvent: PropTypes.func,
};

export default Expand;
