/** 관리자 계정 관리 > 관리자 계정 > 계정 선택 > 수정 */

import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountDetailAPI, getAccountsRoleAPI, updateAccountAPI } from '@redux/action/accountsAction';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import styled, { css } from 'styled-components';

import { Button, Div, Modal, ModalContext, Text, FormProviderWithDevTools, Img, RadioButton } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';

import PATHS from '@router/paths';
import { ERRORS } from '@FTA/data';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const PageDesc = styled(Text)`
  color: ${({ theme }) => theme.gray06};
  span {
    color: red;
  }
`;

const ExtendedText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.gray08};
`;

const ExtendedImg = styled(Img)`
  width: 150px;
  height: 150px;
  border-radius: 8px;
`;

const ClassNameContainer = styled(Div)``;

const FORM_NAMES = Object.freeze({
  ADMIN_CLASSINFO_ID: 'adminClassInfoId',
});

const schema = yup.object().shape({
  [FORM_NAMES.ADMIN_CLASSINFO_ID]: yup.string().required(ERRORS.EMPTY_INPUT),
});

const SEARCH_PARAMS_KEY = 'adminInfoId';

const AccountForm = () => {
  const dispatch = useDispatch();
  const accountDetail = useSelector((state) => state.accounts.accountDetail);
  const accountsRoleList = useSelector((state) => state.accounts.accountsRoleList);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const adminInfoId = searchParams.get(SEARCH_PARAMS_KEY);

  const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur' });
  const { handleSubmit, resetField } = methods;

  const { showModal, closeModal } = useContext(ModalContext);

  const accountGridData = useMemo(
    () => [
      {
        title: 'ID',
        content: [<ExtendedText>{accountDetail?.adminId}</ExtendedText>],
      },
      {
        title: '이름',
        content: [<ExtendedText>{accountDetail?.adminName}</ExtendedText>],
      },
      {
        title: '닉네임',
        content: [<ExtendedText>{accountDetail?.adminNickname}</ExtendedText>],
      },
      {
        title: '프로필 이미지',
        content: [<ExtendedImg src={accountDetail?.adminImageFile?.fileUrl} />],
      },
      {
        title: '국적',
        content: [<ExtendedText>{accountDetail?.adminCountry}</ExtendedText>],
      },
      {
        title: '관리자 등급',
        content: [
          <ClassNameContainer>
            {accountsRoleList.map(({ adminClassInfoId, className }) => (
              <RadioButton
                key={adminClassInfoId}
                id={adminClassInfoId}
                name={FORM_NAMES.ADMIN_CLASSINFO_ID}
                item={{ code: adminClassInfoId, value: className }}
              />
            ))}
          </ClassNameContainer>,
        ],
      },
    ],
    [accountDetail, accountsRoleList]
  );

  const handleNavigate = useCallback(
    (path) => {
      path && navigate(path);
    },
    [navigate]
  );

  const handleSaveClick = ({ adminClassInfoId }) => {
    const params = {
      adminInfoId,
      adminClassInfoId,
    };

    updateAccountAPI(dispatch, params);
    closeModal();
    handleNavigate(`/${PATHS.ACCOUNT}`);
  };

  const handleSave = useCallback(() => {
    showModal(
      <Modal
        body='입력한 정보를 저장하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={handleSubmit(handleSaveClick)}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              저장
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, [handleSubmit]);

  const handleCancel = useCallback(() => {
    showModal(
      <Modal
        body='목록으로 이동하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                handleNavigate(`/${PATHS.ACCOUNT}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  useEffect(() => {
    getAccountDetailAPI(dispatch, { adminInfoId });
    getAccountsRoleAPI(dispatch);
  }, []);

  useEffect(() => {
    if (accountDetail?.className && accountsRoleList.length !== 0) {
      const defaultValue = accountsRoleList.find(
        (elem) => elem.className === accountDetail.className
      )?.adminClassInfoId;
      resetField(FORM_NAMES.ADMIN_CLASSINFO_ID, { defaultValue });
    }
  }, [accountDetail, accountsRoleList]);

  return (
    <Container>
      <PageTitle title='관리자 계정' />
      <PageDesc>
        별표(<span>*</span>)가 표시된 항목은 필수입력 항목입니다.
      </PageDesc>
      <FormProviderWithDevTools {...methods}>
        <TitleWithDivider title='기본 정보' />
        <GridLayout data={accountGridData} />
      </FormProviderWithDevTools>
      <ButtonSection>
        <Button onClick={handleSave} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
          저장
        </Button>
        <Button onClick={handleCancel} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
          취소
        </Button>
      </ButtonSection>
    </Container>
  );
};

export default AccountForm;
