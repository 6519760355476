import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contentPendingList: {},
  contentPendingPaging: {},
  programPendingList: {},
  programPendingPaging: {},
  cpPendingList: {},
  cpPendingPaing: {},
};

/* eslint-disable no-param-reassign */
const approvalPending = createSlice({
  name: 'approvalPending',
  initialState,
  reducers: {
    setContentPendingList: (state, { payload }) => {
      state.contentPendingList = payload;
    },
    setContentPendingPaging: (state, { payload }) => {
      state.contentPendingPaging = payload;
    },
    setProgramPendingList: (state, { payload }) => {
      state.programPendingList = payload;
    },
    setProgramPendingPaging: (state, { payload }) => {
      state.programPendingPaging = payload;
    },
    setCpPendingList: (state, { payload }) => {
      state.cpPendingList = payload;
    },
    setCpPendingPaing: (state, { payload }) => {
      state.cpPendingPaing = payload;
    },
  },
});

/* eslint-enable no-param-reassign */

export const {
  setContentPendingList,
  setContentPendingPaging,
  setProgramPendingList,
  setProgramPendingPaging,
  setCpPendingList,
  setCpPendingPaing,
} = approvalPending.actions;
export default approvalPending.reducer;
