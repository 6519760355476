/* eslint-disable */

/** 컨텐츠 관리 > 컨텐츠 관리 단건 조회 (아웃도어) > */

import { useCallback, useEffect, useMemo, useState, useContext, useRef } from 'react';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import { Div, Text, Img, Button, ModalContext } from '@components/index';

// import ContentsSection from '@modules/FTA/components/ContentsSection';
// import FieldComponent from '@modules/FTA/components/DataGrid/FieldComponent';
import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';
// import { changeCodeToValueEnum } from '@utils';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import VideoModal from '@FTA/components/VideoModal';
import { PlayIcon } from '@FTA/assets';
import Themes from '@styles/Themes';
import { useSearchParams } from 'react-router-dom';
import { convertDateToString } from '@utils';
import testAudio from '../../../assets/B2-B-4.mp3';

const ExtendedText = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.gray07};
`;

const ExtendedImg = styled(Img)`
  width: 150px;
  height: 150px;
  border-radius: 8px;
`;

const ItemValueVideoContainer = styled(Div)`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const ItemValueText = styled(Text)``;

const ContensDetailOutdoor = ({ title }) => {
  // const [joggingDetail, setJoggingDetail] = useState([]);
  const { convertEnum } = useEnumSearch();
  // const enumAll = useSelector((state) => state.common.list);
  const detailContent = useSelector((state) => state.contents.detail);
  const [searchParams] = useSearchParams();
  const contentType = searchParams.get('content');
  const cpInfo = useSelector((state) => state.auth.loggedUserInfo.cpManagementList?.[0]);
  const [currentAudio, setCurrentAudio] = useState(null);
  // const languageType = 'LANGUAGE_SET_KR';
  const [isPlayingBgm, setIsPlayingBgm] = useState(false);

  const { showModal } = useContext(ModalContext);

  const audioRef = useRef(null);

  const handleVideoModal = useCallback((src) => {
    showModal(<VideoModal src={src} />);
  }, []);

  const handleAudio = useCallback(() => {
    if (audioRef.current) {
      if (isPlayingBgm) {
        console.log('###wtf', isPlayingBgm);
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setIsPlayingBgm(false);
      } else {
        console.log('###wtf?????', isPlayingBgm);

        audioRef.current.play();
        setIsPlayingBgm(true);
      }
    }
  }, [isPlayingBgm]);

  const getLanguageChange = useCallback((list, language) => {
    return list?.filter((item) => item?.languageType === language);
  }, []);

  console.log('###컨텐츠 아웃도어 data???', detailContent);

  const defaultInfo = useMemo(() => {
    return [
      {
        title: '컨텐츠명',
        content: [<ExtendedText>{detailContent?.contentsInfo?.contentsDetailList?.[0]?.contentsName}</ExtendedText>],
      },
      {
        title: 'CP/자사 구분',
        content: [<ExtendedText>{contentType === 'lg' ? '자사' : 'cp'}</ExtendedText>],
      },
      {
        title: 'CP명',
        content: [<ExtendedText>{cpInfo.cpManageDetailList?.[0]?.cpName}</ExtendedText>],
      },
      {
        title: '컨텐츠 분류 1',
        content: [<ExtendedText>{convertEnum(detailContent?.contentsInfo?.mainCategoryType)}</ExtendedText>],
      },
      {
        title: '컨텐츠 분류 2',
        content: [<ExtendedText>{convertEnum(detailContent?.contentsInfo?.subCategoryType[0])}</ExtendedText>],
      },
      {
        title: '컨텐츠 저장 위치',
        content: [<ExtendedText>{convertEnum(detailContent?.contentsInfo?.contentsStorageLocation)}</ExtendedText>],
      },
    ];
  }, [detailContent]);

  const outdoorInfo = useMemo(() => {
    return [
      {
        title: '관련 국가',
        content: [<ExtendedText>{detailContent?.walkingJoggingInfo?.associatedCountry}</ExtendedText>],
      },
      {
        title: '테마',
        content: [<ExtendedText>{convertEnum(detailContent?.walkingJoggingInfo?.themeType)}</ExtendedText>],
      },
      {
        title: '액티비티 설명',
        content: [
          <ExtendedText>{detailContent?.walkingJoggingDetailList?.[0]?.walkingJoggingDescription}</ExtendedText>,
        ],
      },
      {
        title: '트랙 유형',
        content: [<ExtendedText>{convertEnum(detailContent?.walkingJoggingInfo?.trackType)}</ExtendedText>],
      },
      {
        title: '거리',
        content: [<ExtendedText>{detailContent?.walkingJoggingInfo?.trackDistance}</ExtendedText>],
      },
      {
        title: '높이',
        content: [<ExtendedText>{detailContent?.walkingJoggingInfo?.height}</ExtendedText>],
      },
      {
        title: '소모 칼로리',
        content: [<ExtendedText>{detailContent?.walkingJoggingInfo?.trackCal}</ExtendedText>],
      },
      {
        title: '스텝 수',
        content: [<ExtendedText>{detailContent?.walkingJoggingInfo?.steps}</ExtendedText>],
      },
      {
        title: '권장 속도',
        content: [<ExtendedText>{detailContent?.walkingJoggingInfo?.recommendSpeed}</ExtendedText>],
      },
      {
        title: '컨텐츠 재생속도',
        content: [<ExtendedText>{convertEnum(detailContent?.walkingJoggingInfo?.contentsPlaySpeed)}</ExtendedText>],
      },
      {
        title: '영상 썸네일 이미지',
        content: [<ExtendedImg src={detailContent?.walkingJoggingInfo?.walkingJoggingThumbnail?.fileUrl} />],
      },
      {
        title: '운동 시간',
        // content: [<ExtendedText>{detailContent?.walkingJoggingInfo?.playTime}</ExtendedText>],
        content: [<ExtendedText>-</ExtendedText>],
      },
      {
        title: '영상 파일',
        content: [
          <ItemValueVideoContainer>
            {/* <ExtendedText inner={detailContent?.walkingJoggingInfo?.walkingJoggingFile?.originalFileName} />
            <Button
              onClick={() => handleVideoModal(detailContent?.walkingJoggingInfo?.walkingJoggingFile?.fileUrl)}
              $layout='round'
              $variantColor='gray'
              startIcon={PlayIcon}
              $imgVariant='play'
            >
              재생
            </Button> */}
            {detailContent?.walkingJoggingInfo?.walkingJoggingFileList &&
              Object.keys(detailContent?.walkingJoggingInfo?.walkingJoggingFileList).map((videokey) => {
                return (
                  <>
                    <ExtendedText inner={videokey} />
                    <Button
                      onClick={() =>
                        handleVideoModal(detailContent?.walkingJoggingInfo?.walkingJoggingFileList[videokey])
                      }
                      $layout='round'
                      $variantColor='gray'
                      startIcon={PlayIcon}
                      $imgVariant='play'
                    >
                      재생
                    </Button>
                  </>
                );
              })}
          </ItemValueVideoContainer>,
        ],
      },
      {
        title: '효과음 파일',
        content: [
          <ItemValueVideoContainer>
            <ExtendedText inner={detailContent?.walkingJoggingInfo?.bgmFile.originalFileName} />
            <Button
              onClick={handleAudio}
              $layout='round'
              $variantColor='gray'
              startIcon={!isPlayingBgm ? PlayIcon : undefined}
              $imgVariant='play'
            >
              {isPlayingBgm ? '정지' : '재생'}
            </Button>
          </ItemValueVideoContainer>,
        ],
      },
      {
        title: '화면 비율',
        content: [<ExtendedText>-</ExtendedText>],
      },
      {
        title: '등록일',
        content: [
          <ExtendedText>
            {convertDateToString(new Date(detailContent?.walkingJoggingInfo?.registDate), '.')}
          </ExtendedText>,
        ],
      },
      {
        title: '코스 정보',
        content: [
          <ExtendedText>{detailContent?.walkingJoggingCourseInfoList?.length}개</ExtendedText>,
          <ExtendedText>
            {detailContent?.walkingJoggingCourseInfoList?.map((item, index) => {
              return (
                <Div>
                  {index + 1 + '.'} {item.courseDistance}km
                </Div>
              );
            })}
          </ExtendedText>,
        ],
      },
      {
        title: '코스별 영상 파일',
        content: [
          <ExtendedText>
            {detailContent?.walkingJoggingCourseInfoList?.map((item, index) => (
              <ItemValueVideoContainer key={item?.courseMediaFile?.fileId}>
                {Object.keys(item.courseMediaFileList).map((videoKey) => {
                  return (
                    <>
                      <ItemValueText
                        fontSize={16}
                        fontWeight={600}
                        color={Themes.light.gray08}
                        inner={`${index + 1}코스 ${videoKey}`}
                      />
                      <Button
                        onClick={() => handleVideoModal(item.courseMediaFileList[videoKey])}
                        $layout='round'
                        $variantColor='gray'
                        startIcon={PlayIcon}
                        $imgVariant='play'
                      >
                        재생
                      </Button>
                    </>
                  );
                })}
              </ItemValueVideoContainer>
            ))}
          </ExtendedText>,
        ],
      },
      {
        title: 'LG 주최 마라톤 개최 여부',
        content: [<ExtendedText>-</ExtendedText>],
      },
      {
        title: '일시 정보',
        content: [<ExtendedText>-</ExtendedText>],
      },
      {
        title: 'LG 주최 마라톤 참가 설명 문구',
        content: [<ExtendedText>-</ExtendedText>],
      },
      {
        title: '대회 입상 시 경품 정보',
        content: [<ExtendedText>-</ExtendedText>],
      },
    ];
  }, [detailContent, isPlayingBgm]);

  useEffect(() => {
    if (detailContent) {
      // const filteredItemsBasic = getLanguageChange(detailContent?.contentsInfo?.contentsDetailList || [], languageType);
      // const filteredItemsInfo = getLanguageChange(detailContent?.walkingJoggingDetailList || [], languageType);

      setCurrentAudio(detailContent?.walkingJoggingInfo?.bgmFile?.fileUrl);
    }
  }, [detailContent]);

  return (
    <>
      <TitleWithDivider title='기본 정보' />
      <GridLayout data={defaultInfo} hasBottomBorderLine />
      <TitleWithDivider title={title} />
      <GridLayout data={outdoorInfo} hasBottomBorderLine />
      <audio ref={audioRef} src={currentAudio} type='audio/mpeg' />
    </>
  );
};

ContensDetailOutdoor.propTypes = {
  title: PropTypes.string,
};

export default ContensDetailOutdoor;
