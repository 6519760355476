import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';
import { approvalProgramAPI } from '@redux/action/approvalPendingAction';

const useApprovalPendingProgram = () => {
  const dispatch = useDispatch();
  const { searchParams, getSearchParamsObject } = useSearchParams();

  const pendingProgramList = useSelector((state) => state.approvalPending.programPendingList.content);
  const pendingProgramPaging = useSelector((state) => state.approvalPending.programPendingPaging);

  useEffect(() => {
    const param = getSearchParamsObject();
    approvalProgramAPI(dispatch, param);
  }, [searchParams]);

  return {
    pendingProgramList,
    pendingProgramPaging,
  };
};

export default useApprovalPendingProgram;
