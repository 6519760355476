const enumType = Object.freeze({
  StfType: 'StfType',
  BodyPartType: 'BodyPartType',
  SubCategoryType: 'SubCategoryType',
  LevelType: 'LevelType',
  LogicType: 'LogicType',
  FrontBackType: 'FrontBackType',
  RepeatCycleType: 'RepeatCycleType',
  ExceptionBodyPartType: 'ExceptionBodyPartType',
  CategoryType: 'CategoryType',
  RoutineApprovalStatusType: 'RoutineApprovalStatusType',
  EquipmentType: 'EquipmentType',
  LanguageType: 'LanguageType',
  ExceptionDiseaseType: 'ExceptionDiseaseType',
  RepeatStandardType: 'RepeatStandardType',
  FeedbackType: 'FeedbackType',
  PartTypeForRom: 'PartTypeForRom',
  ExerciseDivisionType: 'ExerciseDivisionType',
  ApprovalStatusType: 'ApprovalStatusType',
  ContentsStorageLocationType: 'ContentsStorageLocationType',
  BodyAlignmentType: 'BodyAlignmentType',
  RomType: 'RomType',
  SideType: 'SideType',
  ProgramType: 'ProgramType',
  AccessibleMenuType: 'AccessibleMenuType',
  ExerciseIntensityType: 'ExerciseIntensityType',
  LogicTypeForRom: 'LogicTypeForRom',
  BodyCheckupLevelType: 'BodyCheckupLevelType',
  CoachingType: 'CoachingType',
  AdminApproveStatusType: 'AdminApproveStatusType',
  FeedbackPartType: 'FeedbackPartType',
  LevelTypeForAlignment: 'LevelTypeForAlignment',
  AdminUseStatusType: 'AdminUseStatusType',
  MainCategoryType: 'MainCategoryType',
  SideTypeForRom: 'SideTypeForRom',
  ExerciseType: 'ExerciseType',
  PositionType: 'PositionType',
  SectionType: 'SectionType',
  NoteCategoryType: 'NoteCategoryType',
  RecommendAgeType: 'RecommendAgeType',
  ExercisePurposeType: 'ExercisePurposeType',
  CoachmarkType: 'CoachmarkType',
  UserType: 'UserType',
  ContentsType: 'ContentsType',
});

export default enumType;
