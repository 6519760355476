import { fakerKO as faker } from '@faker-js/faker';

const RANDOM_LENGTH = 20 + Math.floor(Math.random() * 30);
const DUMMY_DATA_LENGTH = RANDOM_LENGTH > 23 ? RANDOM_LENGTH : 0;
const programList = new Array(DUMMY_DATA_LENGTH).fill(0).map(() => {
  return {
    programInfoId: faker.number.int(),
    programName: faker.commerce.product(),
    cpName: faker.commerce.product(),
    priceYn: faker.helpers.arrayElement([true, false]),
    programDuration: faker.string.numeric(),
    // week: faker.number.int({ max: 16 }),
    sessionCount: faker.number.int(),
    scheduleYn: faker.helpers.arrayElement([true, false]),
    programCompletionStatus: faker.helpers.arrayElement(['Y', 'N']),
    exposeYn: faker.helpers.arrayElement([true, false]),
    approvalStatus: faker.helpers.arrayElement(['approval', 'reject', 'pending']),
    registDate: faker.date.anytime(),
  };
});

const MockProgramsInstance = {
  programList,
  pageable: {
    sort: {
      unsorted: true,
      sorted: false,
      empty: true,
    },
    pageSize: 10,
    pageNumber: 0,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  totalPages: 1,
  totalElements: 9,
  last: true,
  numberOfElements: 9,
  first: true,
  size: 10,
  sort: {
    unsorted: true,
    sorted: false,
    empty: true,
  },
  number: 0,
  empty: false,
};

class MockPrograms {
  constructor() {
    this.programs = MockProgramsInstance;
  }

  getPrograms() {
    return this.programs;
  }

  getProgram(programInfoId) {
    const target = this.programs.programList.find((program) => +program.programInfoId === +programInfoId);

    return {
      ...target,
      programInfoId,
      programType: {
        code: '000',
        value: target.programName,
      },
      cpInfo: {
        cpId: 1,
        cpName: target.cpName,
      },
      programImageFile: {
        file: [
          {
            fileId: 1,
            fileType: '파일타입?',
            fileLocation: '파일 저장 위치',
            fileUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWIFQS6slTLTJ1nKJmimsf-RxtzZ3mYrPKzg&s',
            originalFileName: 'profile-image.jpg',
            realFileName: '12312312.jpg',
            playTime: '영상 시간',
            fileSize: '파일 사이즈',
            useYn: 1,
            registDate: '등록 일자',
            registUser: '등록자',
            registUtc: '등록 UTC',
          },
        ],
      },
      priceYn: true,
      template: 'A',
      exposeYn: true,
      programDetail: [
        {
          languageType: 'kr',
          programInfoId: 1,
          programName: '스트레칭으로 바른체형 만들기',
          programDescription:
            '지리산과 북한의 금강산과 함께 한반도의 3대 영산 중 하나로, 높이 해발 1,950m로 남한에 서 가장 높은 자연의 미를 만나는 영혼을 찾아, 한라산 오르기의 감동을 느껴보세요!',
        },
        {
          languageType: 'en',
          programName: 'streching.....',
          programDescription: 'english.......',
        },
      ],
      sessionInfo: [
        {
          languageType: 'kr',
          sessionList: [
            {
              programSessionOrder: 1,
              sessionName: '유연성을 기르기 위한 특별 운동 1탄',
              sessionDescription:
                '다양한 동작과 스트레칭을 결합하여 몸의 유연성을 향상시키고 동시에 체지방을 감소시킵니다.',
              sessionImageFile: {
                fileId: 1,
                fileType: '파일타입?',
                fileLocation: '파일 저장 위치',
                fileUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWIFQS6slTLTJ1nKJmimsf-RxtzZ3mYrPKzg&s',
                originalFileName: 'profile-image.jpg',
                realFileName: '12312312.jpg)',
                playTime: '영상 시간',
                fileSize: '파일 사이즈',
                useYn: 1,
                registDate: '등록 일자',
                registUser: '등록자',
                registUtc: '등록 UTC',
              },
            },
            {
              programSessionOrder: 2,
              sessionName: '유연성을 기르기 위한 특별 운동 2탄',
              sessionDescription:
                '다양한 동작과 스트레칭을 결합하여 몸의 유연성을 향상시키고 동시에 체지방을 감소시킵니다.',
              sessionImageFile: {
                fileId: 1,
                fileType: '파일타입?',
                fileLocation: '파일 저장 위치',
                fileUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWIFQS6slTLTJ1nKJmimsf-RxtzZ3mYrPKzg&s',
                originalFileName: 'profile-image.jpg',
                realFileName: '12312312.jpg)',
                playTime: '영상 시간',
                fileSize: '파일 사이즈',
                useYn: 1,
                registDate: '등록 일자',
                registUser: '등록자',
                registUtc: '등록 UTC',
              },
            },
          ],
        },
        {
          languageType: 'en',
          sessionList: [
            {
              programSessionOrder: 1,
              sessionName: '유연성을 기르기 위한 특별 운동 1탄',
              sessionDescription:
                '다양한 동작과 스트레칭을 결합하여 몸의 유연성을 향상시키고 동시에 체지방을 감소시킵니다.',
              sessionImageFile: {
                fileId: 1,
                fileType: '파일타입?',
                fileLocation: '파일 저장 위치',
                fileUrl: '파일 URL',
                originalFileName: 'profile-image.jpg',
                realFileName: '12312312.jpg)',
                playTime: '영상 시간',
                fileSize: '파일 사이즈',
                useYn: 1,
                registDate: '등록 일자',
                registUser: '등록자',
                registUtc: '등록 UTC',
              },
            },
            {
              programSessionOrder: 2,
              sessionName: '유연성을 기르기 위한 특별 운동 2탄',
              sessionDescription:
                '다양한 동작과 스트레칭을 결합하여 몸의 유연성을 향상시키고 동시에 체지방을 감소시킵니다.',
              file: {
                fileId: 1,
                fileType: '파일타입?',
                fileLocation: '파일 저장 위치',
                fileUrl: '파일 URL',
                originalFileName: 'profile-image.jpg',
                realFileName: '12312312.jpg)',
                playTime: '영상 시간',
                fileSize: '파일 사이즈',
                useYn: 1,
                registDate: '등록 일자',
                registUser: '등록자',
                registUtc: '등록 UTC',
              },
            },
          ],
        },
      ],
      approvalStatus: '승인',
      registDate: '2024-05-07T12:00:00Z',
      registUser: '관리자',
      registUtc: '',
    };
  }

  getApprovalStatusProgram(status) {
    const getStatusProgram = this.programs.programList.filter((con) => con.approvalStatus === status);
    return getStatusProgram;
  }
}

// const getPrograms = () => {
//   return PROGRAM.programList;
// };

// const getApprovalStatusProgram = (status) => {
//   const getStatusProgram = PROGRAM.programList.filter((con) => con.approvalStatus === status);
//   return getStatusProgram;
// };

// const getProgram = (searchId) => {
//   const getSameIdData = PROGRAM.programList.find((pro) => toString(pro.programInfoId) === toString(searchId));
//   const getProgramOne = {
//     programInfoId: searchId,
//     programType: {
//       code: '000',
//       value: getSameIdData.programName,
//     },
//     cpInfo: {
//       cpId: 1,
//       cpName: getSameIdData.cpName,
//     },
//     programImageFile: {
//       file: [
//         {
//           fileId: 1,
//           fileType: '파일타입?',
//           fileLocation: '파일 저장 위치',
//           fileUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWIFQS6slTLTJ1nKJmimsf-RxtzZ3mYrPKzg&s',
//           originalFileName: 'profile-image.jpg',
//           realFileName: '12312312.jpg',
//           playTime: '영상 시간',
//           fileSize: '파일 사이즈',
//           useYn: 1,
//           registDate: '등록 일자',
//           registUser: '등록자',
//           registUtc: '등록 UTC',
//         },
//         {
//           fileId: 2,
//           fileType: '파일타입2222222?',
//           fileLocation: '파일 저장 위치',
//           fileUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWIFQS6slTLTJ1nKJmimsf-RxtzZ3mYrPKzg&s',
//           originalFileName: 'document.pdf',
//           realFileName: '45645645.pdf',
//           playTime: '영상 시간',
//           fileSize: '파일 사이즈',
//           useYn: 1,
//           registDate: '등록 일자',
//           registUser: '등록자',
//           registUtc: '등록 UTC',
//         },
//       ],
//     },
//     priceYn: true,
//     template: 'A',
//     exposeYn: true,
//     programDetail: [
//       {
//         languageType: 'kr',
//         programInfoId: 1,
//         programName: '스트레칭으로 바른체형 만들기',
//         programDescription:
//           '지리산과 북한의 금강산과 함께 한반도의 3대 영산 중 하나로, 높이 해발 1,950m로 남한에 서 가장 높은 자연의 미를 만나는 영혼을 찾아, 한라산 오르기의 감동을 느껴보세요!',
//       },
//       {
//         languageType: 'en',
//         programName: 'streching.....',
//         programDescription: 'english.......',
//       },
//     ],
//     sessionInfo: [
//       {
//         languageType: 'kr',
//         sessionList: [
//           {
//             programSessionOrder: 1,
//             sessionName: '유연성을 기르기 위한 특별 운동 1탄',
//             sessionDescription:
//               '다양한 동작과 스트레칭을 결합하여 몸의 유연성을 향상시키고 동시에 체지방을 감소시킵니다.',
//             sessionImageFile: {
//               fileId: 1,
//               fileType: '파일타입?',
//               fileLocation: '파일 저장 위치',
//               fileUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWIFQS6slTLTJ1nKJmimsf-RxtzZ3mYrPKzg&s',
//               originalFileName: 'profile-image.jpg',
//               realFileName: '12312312.jpg)',
//               playTime: '영상 시간',
//               fileSize: '파일 사이즈',
//               useYn: 1,
//               registDate: '등록 일자',
//               registUser: '등록자',
//               registUtc: '등록 UTC',
//             },
//           },
//           {
//             programSessionOrder: 2,
//             sessionName: '유연성을 기르기 위한 특별 운동 2탄',
//             sessionDescription:
//               '다양한 동작과 스트레칭을 결합하여 몸의 유연성을 향상시키고 동시에 체지방을 감소시킵니다.',
//             sessionImageFile: {
//               fileId: 1,
//               fileType: '파일타입?',
//               fileLocation: '파일 저장 위치',
//               fileUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWIFQS6slTLTJ1nKJmimsf-RxtzZ3mYrPKzg&s',
//               originalFileName: 'profile-image.jpg',
//               realFileName: '12312312.jpg)',
//               playTime: '영상 시간',
//               fileSize: '파일 사이즈',
//               useYn: 1,
//               registDate: '등록 일자',
//               registUser: '등록자',
//               registUtc: '등록 UTC',
//             },
//           },
//         ],
//       },
//       {
//         languageType: 'en',
//         sessionList: [
//           {
//             programSessionOrder: 1,
//             sessionName: '유연성을 기르기 위한 특별 운동 1탄',
//             sessionDescription:
//               '다양한 동작과 스트레칭을 결합하여 몸의 유연성을 향상시키고 동시에 체지방을 감소시킵니다.',
//             sessionImageFile: {
//               fileId: 1,
//               fileType: '파일타입?',
//               fileLocation: '파일 저장 위치',
//               fileUrl: '파일 URL',
//               originalFileName: 'profile-image.jpg',
//               realFileName: '12312312.jpg)',
//               playTime: '영상 시간',
//               fileSize: '파일 사이즈',
//               useYn: 1,
//               registDate: '등록 일자',
//               registUser: '등록자',
//               registUtc: '등록 UTC',
//             },
//           },
//           {
//             programSessionOrder: 2,
//             sessionName: '유연성을 기르기 위한 특별 운동 2탄',
//             sessionDescription:
//               '다양한 동작과 스트레칭을 결합하여 몸의 유연성을 향상시키고 동시에 체지방을 감소시킵니다.',
//             file: {
//               fileId: 1,
//               fileType: '파일타입?',
//               fileLocation: '파일 저장 위치',
//               fileUrl: '파일 URL',
//               originalFileName: 'profile-image.jpg',
//               realFileName: '12312312.jpg)',
//               playTime: '영상 시간',
//               fileSize: '파일 사이즈',
//               useYn: 1,
//               registDate: '등록 일자',
//               registUser: '등록자',
//               registUtc: '등록 UTC',
//             },
//           },
//         ],
//       },
//     ],
//     approvalStatus: '승인',
//     registDate: '2024-05-07T12:00:00Z',
//     registUser: '관리자',
//     registUtc: '',
//   };

//   return getProgramOne;
// };

// export { getPrograms, getProgram, getApprovalStatusProgram };
export default new MockPrograms();
