import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import session from 'redux-persist/lib/storage/session';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import {
  accountsSlice,
  adminSlice,
  authSlice,
  contentsSlice,
  programSlice,
  commonSlice,
  etcSlice,
  cpSlice,
  approvalPendingSlice,
} from '../feature';

const authPersistConfig = {
  key: 'auth',
  storage: session,
};

const commonPersistConfig = {
  key: 'common',
  storage: session,
};

const authPersistedReducer = persistReducer(authPersistConfig, authSlice);
const commonPersistedReducer = persistReducer(commonPersistConfig, commonSlice);

const reducers = combineReducers({
  admin: adminSlice,
  auth: authPersistedReducer,
  accounts: accountsSlice,
  contents: contentsSlice,
  program: programSlice,
  common: commonPersistedReducer,
  etc: etcSlice,
  cp: cpSlice,
  approvalPending: approvalPendingSlice,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistor = persistStore(store);

export default { store, persistor };
