/** 관리자 계정 관리 > 관리자 등급설정 */

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountsRoleAPI } from '@redux/action/accountsAction';
import { useForm } from 'react-hook-form';

import { createColumnHelper } from '@tanstack/react-table';

import { FormProviderWithDevTools, Table } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';
import DetailButton from '@FTA/components/DetailButton';

import { convertDateToString } from '@utils';
import PATHS from '@router/paths';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';

const columnHelper = createColumnHelper();

const TABLE_KEYS = Object.freeze({
  NO: 'no',
  CLASS_NAME: 'className',
  APPROVAL_AUTHORITY: 'approvalAuthority',
  REGIST_DATE: 'registDate',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, title: '번호', checked: false },
  { code: TABLE_KEYS.CLASS_NAME, title: '관리자 등급', checked: false },
];

const AccountRoleList = () => {
  const dispatch = useDispatch();
  const { searchParams, getSearchParamsObject } = useSearchParams();

  const accountsRoleList = useSelector((state) => state.accounts.accountsRoleList);
  const accountsRolePaging = useSelector((state) => state.accounts.accountsRolePaging);
  const methods = useForm();

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.NO, {
        size: 60,
        id: TABLE_KEYS.NO,
        header: () => <span>번호</span>,
        cell: ({ row: { index } }) => <span>{accountsRoleList && accountsRoleList.length - index}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.CLASS_NAME, {
        id: TABLE_KEYS.CLASS_NAME,
        header: () => <span>관리자 등급</span>,
        cell: ({ getValue, row: { original } }) => {
          const { adminClassInfoId = '' } = original;
          return (
            <DetailButton pathname={PATHS.ACCOUNT_ROLE_DETAIL} searchParams={{ adminClassInfoId }}>
              {getValue()}
            </DetailButton>
          );
        },
      }),
      columnHelper.accessor(TABLE_KEYS.APPROVAL_AUTHORITY, {
        id: TABLE_KEYS.APPROVAL_AUTHORITY,
        header: () => <span>승인 권한</span>,
        cell: ({ getValue }) => <span>{getValue() ? '가능' : '불가'}</span>, // NOTE: 왜 enum이 없을까요?
      }),
      columnHelper.accessor(TABLE_KEYS.REGIST_DATE, {
        id: TABLE_KEYS.REGIST_DATE,
        header: () => <span>등록일</span>,
        cell: ({ getValue }) => <span>{convertDateToString(new Date(getValue()), '.')}</span>,
      }),
    ],
    [accountsRoleList]
  );
  /* eslint-enable react/no-unstable-nested-components */

  useEffect(() => {
    const param = getSearchParamsObject();

    getAccountsRoleAPI(dispatch, param);
  }, [searchParams]);

  if (accountsRoleList?.length === 0) return <EmptyData text='등록된 관리자 계정이 없습니다.' />;

  return (
    <>
      <PageTitle title='관리자 등급설정' />
      <FormProviderWithDevTools {...methods}>
        <SearchContainer options={TABLE_OPTIONS} hasChip hasColumnFilter hasInputSelectBox hasDateFilter />
      </FormProviderWithDevTools>
      <Table
        data={accountsRoleList}
        columns={COLUMNS_DATA}
        paging={accountsRolePaging}
        hasChip
        hasColumnFilter
        hasInputSelectBox
      />
    </>
  );
};

AccountRoleList.propTypes = {};

export default AccountRoleList;
