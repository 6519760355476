import { useState } from 'react';

import ApexCharts from 'react-apexcharts';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Text } from '@components/index';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
  --padding: 32px;
  padding: var(--padding);
`;

const Title = styled(Text)`
  font-size: 20px;
  line-height: 1.17;
  font-weight: 700;
`;

const ChartWrapper = styled(Div)`
  margin-top: 32px;
  display: flex;
  align-items: center;
  column-gap: 120px;
`;

const ReactApexChart = styled(ApexCharts)``;

const Legends = styled(Div)`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  width: 362px;
  height: 152px;
  -webkit-user-select: none;
  user-select: none;
`;

const LegendContainer = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LegendLeftSide = styled(Div)`
  display: flex;
`;

const LegendMarker = styled(Div)`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;

  ${({ markerColor }) => `background-color: ${markerColor}`}
`;

const LegendText = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.gray07};
`;

const LegendRightSide = styled(Div)`
  display: flex;
`;

const VerticalLine = styled(Div)`
  width: 1px;
  height: 14px;
  background-color: #d9d9d9;
  margin: 0 10px;
`;

const LegendPercentage = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.gray09};
`;

const TYPE = 'donut';

const DonutChart = ({ title, width = '100%', height = '100%', series = [], labels = [], colors }) => {
  const [percentages, setPercentages] = useState([]);
  const theme = useTheme();
  const COLORS = colors?.length ? colors : [theme.brown05, theme.brown04, theme.brown03, theme.brown02, theme.brown01];

  const handleMounted = ({ pie: { animDur: total, animBeginArr } }) => {
    const result = [];

    for (let i = 0; i < series.length; i += 1) {
      const value = animBeginArr[i + 1] - animBeginArr[i];
      result.push(((value / total) * 100).toFixed(1));
      setPercentages(result);
    }
  };

  return (
    <Container>
      <Title>{title}</Title>
      <ChartWrapper>
        <ReactApexChart
          type={TYPE}
          width={width}
          height={height}
          series={series}
          options={{
            chart: {
              type: TYPE,
              zoom: {
                enabled: false,
              },
              events: {
                mounted: handleMounted,
              },
            },
            colors: COLORS,
            legend: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
            dataLabels: {
              enabled: true,
              style: {
                fontFamily: 'LG-smart-UI',
                fontSize: '12px',
                fontWeight: '700',
                colors: [theme.gray01, theme.gray01, theme.gray01, theme.gray01, theme.brown03],
              },
              dropShadow: {
                enabled: false,
              },
            },
          }}
        />
        <Legends>
          {labels.map((label, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <LegendContainer key={index}>
              <LegendLeftSide>
                <LegendMarker markerColor={COLORS[index % COLORS.length]} />
                <LegendText>{label}</LegendText>
              </LegendLeftSide>
              <LegendRightSide>
                <VerticalLine />
                <LegendPercentage>{`${percentages[index]}%`}</LegendPercentage>
              </LegendRightSide>
            </LegendContainer>
          ))}
        </Legends>
      </ChartWrapper>
    </Container>
  );
};

DonutChart.propTypes = {
  /** 차트 위에 들어가는 타이틀 string */
  title: PropTypes.string.isRequired,
  /** 차트 width, 기본 값은 100% */
  width: PropTypes.string,
  /** 차트 width, 기본 값은 240px (figma 기준) */
  height: PropTypes.string,
  /** Apex Chart에서 지원하는 series 배열, 그래프에 표현하고 싶은 데이터들의 집합 */
  series: PropTypes.arrayOf(PropTypes.number),
  /** Apex Chart에서 지원하는 라벨, 범례(legend)에 표기되는 라벨들의 집합 */
  labels: PropTypes.arrayOf(PropTypes.string),
  /** Apex Chart에서 지원하는 도넛 차트 색 배열, 기본 값은 figma */
  colors: PropTypes.arrayOf(PropTypes.number),
};

export default DonutChart;
