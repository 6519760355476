/** 기타 관리 > 공지사항 > 공지사항 클릭(상세 화면) */

import { useCallback, useContext, useEffect, useMemo } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import styled, { css } from 'styled-components';

import { Button, Div, Modal, ModalContext, Text } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';

import PATHS from '@router/paths';
import { getNoticeDetailAPI, NoticeDeleteAPI } from '@redux/action/etcAction';
import { useDispatch, useSelector } from 'react-redux';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const ExtendedText = styled(Text)`
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.gray07};
`;
const ExtendedTextarea = styled(Div)`
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.gray07};
`;

// TODO: 유효성관련 메세지 파일 만들기
// const ERROR_MESSAGE = {
//   inputText: '내용을 입력해주세요.',
//   pwdLength: '비밀번호는 8자 이상 10자 이상이어야 합니다.',
// };

const ExternalCenterProgramProgramOverviewEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const noticeInfoId = searchParams.get('noticeInfoId');

  const notice = useSelector((state) => state.etc.noticeDetail);
  const methods = useForm();

  const { showModal, closeModal } = useContext(ModalContext);

  const handleNavigate = useCallback(
    (path) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  const NOTICE_DATA = useMemo(() => {
    return [
      {
        title: '번호',
        content: [<ExtendedText name='num'>{notice.noticeInfoId}</ExtendedText>],
      },
      {
        title: '제목',
        content: [<ExtendedText name='title'>{notice?.title}</ExtendedText>],
      },
      {
        title: '국가',
        content: [<ExtendedText name='country'>{notice?.country}</ExtendedText>],
      },
      {
        title: '카테고리',
        content: [<ExtendedText name='category'>{notice.category}</ExtendedText>],
      },
      {
        title: '내용',
        content: [<ExtendedTextarea name='content' dangerouslySetInnerHTML={{ __html: notice?.content }} />],
      },
    ];
  }, [notice]);
  const handleDelete = useCallback(() => {
    showModal(
      <Modal
        body='등록된 정보를 삭제하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                NoticeDeleteAPI(dispatch, { noticeInfoId: Number(noticeInfoId) });
                closeModal();
                handleNavigate(`/${PATHS.NOTICE}`);
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, [noticeInfoId]);

  useEffect(() => {
    const getIdsearch = searchParams.get('noticeInfoId');
    getNoticeDetailAPI(dispatch, getIdsearch);
  }, []);

  return (
    <Container>
      <PageTitle title='공지사항' />
      <FormProvider {...methods}>
        <GridLayout data={NOTICE_DATA} hasBottomBorderLine />
      </FormProvider>
      <ButtonSection>
        <Button
          onClick={() => {
            const search = createSearchParams({ noticeInfoId }).toString();
            navigate({ pathname: `/${PATHS.NOTICE_EDIT}`, search });
          }}
          $layout='square'
          $variantColor='primary'
          classes={{ Button: ButtonCss }}
        >
          수정
        </Button>
        <Button onClick={handleDelete} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
          삭제
        </Button>
        <Button
          onClick={() => {
            handleNavigate(`/${PATHS.NOTICE}`);
          }}
          $layout='square'
          $variantColor='line'
          classes={{ Button: ButtonCss }}
        >
          목록
        </Button>
      </ButtonSection>
    </Container>
  );
};
ExternalCenterProgramProgramOverviewEdit.propTypes = {};

export default ExternalCenterProgramProgramOverviewEdit;
