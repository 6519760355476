/** 기타 관리 > 설문 > 단건 조회 */

import { useCallback, useContext, useEffect, useMemo } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { Button, Div, Modal, ModalContext, Text } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import paths from '@router/paths';
import { SurveyDetailAPI } from '@redux/action/etcAction';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled(Div)`
  border-top: 3px solid black;
  word-break: break-all;
  margin-top: 10px;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const ExtendedText = styled(Text)`
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.gray07};
`;

const FilteredChipContainer = styled(Div)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FilteredChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 8px 14px;
  border-radius: 56px;
  background-color: ${({ theme }) => theme.green01};
`;

const SurveyDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('surveyInfoId');
  const { showModal, closeModal } = useContext(ModalContext);

  const survey = useSelector((state) => state.etc.surveyDetail);
  const inputForm = useForm();

  const SURVEY_DATA = useMemo(
    () => [
      {
        title: '설문 제목',
        content: [<ExtendedText name='surveyName'>{survey?.surveyName}</ExtendedText>],
      },
      {
        title: '순서',
        content: [<ExtendedText name='surveyOrder'>{survey?.surveyOrder}</ExtendedText>],
      },
    ],
    [survey]
  );

  const SURVEY_QUSETION_DATA = useMemo(() => {
    const SESSION_DATA = [];

    if (survey.surveyQuestionList) {
      survey.surveyQuestionList.forEach((session, index) => {
        // 질문 데이터 추가
        SESSION_DATA.push(
          {
            sessionTitle: `질문 ${index + 1}`,
          },
          {
            title: '제목',
            content: [<ExtendedText>{session.questionName}</ExtendedText>],
          },
          {
            title: '선택 가능 개수',
            content: [<ExtendedText>{session.questionCount}</ExtendedText>],
          },
          {
            title: '선택지',
            content: [
              <div style={{ display: 'flex', gap: '10px' }}>
                {session.surveySelectList.map((select) => (
                  <FilteredChipContainer>
                    <FilteredChip key={select}>{select.selectName}</FilteredChip>
                  </FilteredChipContainer>
                ))}
              </div>,
            ],
          }
        );
        session.surveySubQuestionList.forEach((subQuestion, subIndex) => {
          SESSION_DATA.push(
            {
              sessionTitle: `하위 질문 ${subIndex + 1}`,
            },
            {
              title: '제목',
              content: [<ExtendedText>{subQuestion.questionName}</ExtendedText>],
            },
            {
              title: '선택 가능 개수',
              content: [<ExtendedText>{subQuestion.questionCount}</ExtendedText>],
            },
            {
              title: '선택지',
              content: [
                <div style={{ display: 'flex', gap: '10px' }}>
                  {subQuestion.surveySubSelectList.map((select) => (
                    <FilteredChipContainer>
                      <FilteredChip key={select}>{select.selectName}</FilteredChip>
                    </FilteredChipContainer>
                  ))}
                </div>,
              ],
            }
          );
        });
      });
    }

    return SESSION_DATA;
  }, [survey]);

  const onClickDelete = useCallback(() => {
    showModal(
      <Modal
        body='등록된 정보를 삭제하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                navigate({ pathname: `/${paths.SURVEY}` });
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const gotoList = useCallback(() => {
    showModal(
      <Modal
        body='목록으로 이동하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                navigate({ pathname: `/${paths.SURVEY}` });
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  useEffect(() => {
    SurveyDetailAPI(dispatch, surveyId);
  }, []);

  return (
    <Container>
      <PageTitle title='FAQ' />
      <ContentContainer>
        <FormProvider {...inputForm}>
          <GridLayout data={SURVEY_DATA} hasBottomBorderLine />
          <GridLayout data={SURVEY_QUSETION_DATA} hasBottomBorderLine />
        </FormProvider>

        <ButtonContainer>
          <ButtonContainer>
            <Button
              onClick={() => {
                const surveyInfoId = searchParams.get('surveyInfoId');
                const search = createSearchParams({ surveyInfoId }).toString();

                navigate({ pathname: `/${paths.SURVEY_EDIT}`, search });
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              수정
            </Button>
            <Button onClick={onClickDelete} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              삭제
            </Button>
            <Button onClick={gotoList} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              목록
            </Button>
          </ButtonContainer>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

export default SurveyDetail;
