import PropTypes from 'prop-types';
import Button from '@components/Input/Button/Button';
import Icon from '@components/Atoms/Icon';
import { BottomSheetIcon } from '@images';
import styled, { css } from 'styled-components';

const StyledSelectionButton = styled(Button)`
  flex-direction: row;
  width: fit-content;
  font-size: 25px;
  font: -apple-system-title1;
  line-height: 34px;

  max-width: calc(100% - 37px);
  white-space: nowrap;
  overflow: hidden;
  height: 50px;
  font-weight: 500;

  > div {
    padding: 0;
    margin: 0;
  }
`;

const StyledIcon = css`
  margin-left: 10px;
`;

const SelectionButton = ({
  id,
  ariaHaspopup,
  type,
  h,
  children,
  canSelect = true,
  focusedText,
  onClick = () => {},
}) => {
  <StyledSelectionButton
    id={id}
    h={h}
    type={type}
    aria-haspopup={ariaHaspopup}
    canSelect={canSelect}
    noRipple={!canSelect}
    endIcon={
      canSelect ? (
        <Icon
          icon={BottomSheetIcon}
          w={30}
          h={30}
          classes={{
            Icon: StyledIcon,
          }}
        />
      ) : null
    }
    onClick={canSelect ? onClick : () => {}}
    aria-label={`${focusedText}`}
  >
    {children}
  </StyledSelectionButton>;
};

SelectionButton.propTypes = {
  /** 선택 가능 여부 */
  canSelect: PropTypes.bool,
  id: PropTypes.string,
  ariaHaspopup: PropTypes.node,
  type: PropTypes.string,
  h: PropTypes.number,
  children: PropTypes.node,
  focusedText: PropTypes.string,
  onClick: PropTypes.func,
};

SelectionButton.defaultProps = {
  h: 55,
  canSelect: true,
  endIcon: <Icon icon={BottomSheetIcon} w={30} h={30} />,
};

export default SelectionButton;
