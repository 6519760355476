import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';

import MainContainer from '@components/Layout/MainContainer';
import { ModalProvider } from '@components/Context/ModalProvider';

import Router from '@router/Router';
import theme from '@styles/Themes';
import GlobalStyle from '@styles/GlobalStyle';
import GlobalFont from '@styles/GlobalFont';

const router = createBrowserRouter(Router);
// const router = createHashRouter(Router);

const App = () => {
  return (
    <ThemeProvider theme={theme.light}>
      <ModalProvider>
        <GlobalFont />
        <GlobalStyle />
        <MainContainer>
          <RouterProvider router={router} />
        </MainContainer>
      </ModalProvider>
    </ThemeProvider>
  );
};

export default App;
