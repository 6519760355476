const PATHS = {
  ROOT: '/',
  // auth
  REGISTER: 'register',
  PASSWORD: 'password/find',
  PASSWORD_RESET: 'password/reset',
  MYINFO: 'myinfo',
  // sidebar
  HOME: 'home',
  APPROVAL: 'approval',
  CP_INFO: 'cpinfo',
  CP_INFO_DETAIL: 'cpinfo/detail',
  CP_INFO_WRITE: 'cpinfo/write',
  CP_INFO_EDIT: 'cpinfo/edit',
  ECP_CONFIGURATION: 'ecp/configuration',
  ECP_CONFIGURATION_DETAIL: 'ecp/configuration/detail',
  ECP_CONFIGURATION_EDIT: 'ecp/configuration/edit',
  ECP_SCHEDULE: 'ecp/schedule',
  ECP_SCHEDULE_EDIT: 'ecp/schedule/edit',
  ECP_CREATION: 'ecp/creation',
  ECP_CREATION_EDIT: 'ecp/creation/edit',
  CONTENTS_CP: 'contents/cp',
  CONTENTS_CP_DETAIL: 'contents/cp/detail',
  CONTENTS_CP_WRITE: 'contents/cp/write',
  CONTENTS_CP_EDIT: 'contents/cp/edit',
  CONTENTS_LG: 'contents/lg',
  CONTENTS_LG_DETAIL: 'contents/lg/detail',
  CONTENTS_LG_WRITE: 'contents/lg/write',
  CONTENTS_LG_EDIT: 'contents/lg/edit',
  ACCOUNT: 'account',
  ACCOUNT_DETAIL: 'account/detail',
  ACCOUNT_EDIT: 'account/edit',
  ACCOUNT_ROLE: 'account-role',
  ACCOUNT_ROLE_DETAIL: 'account-role/detail',
  ACCOUNT_ROLE_EDIT: 'account-role/edit',
  NOTICE: 'notice',
  NOTICE_DETAIL: 'notice/detail',
  NOTICE_EDIT: 'notice/edit',
  NOTICE_WRITE: 'notice/write',
  HEALTHCAREDB: 'healthcaredb',
  HEALTHCAREDB_DETAIL: 'healthcaredb/detail',
  HEALTHCAREDB_EDIT: 'healthcaredb/edit',
  HEALTHCAREDB_WRITE: 'healthcaredb/write',
  CS_RESPONSE: 'csresponse',
  CS_RESPONSE_DETAIL: 'csresponse/detail',
  CS_RESPONSE_EDIT: 'csresponse/edit',
  CS_RESPONSE_WRITE: 'csresponse/write',
  FAQ: 'faq',
  FAQ_DETAIL: 'faq/detail',
  FAQ_EDIT: 'faq/edit',
  FAQ_WRITE: 'faq/write',
  SURVEY: 'survey',
  SURVEY_DETAIL: 'survey/detail',
  SURVEY_EDIT: 'survey/edit',
  SURVEY_WRITE: 'survey/write',
  REPORT: 'report',
  REPORT_CP: 'report/cp',
};

export default Object.freeze(PATHS);
