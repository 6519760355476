import styled from 'styled-components';
import { Div } from '@components/Atoms/Atoms';
import PropTypes from 'prop-types';
import React from 'react';
import RadioButton from './RadioButton';

const getImg = ({ image }) => image;

const Container = styled(Div)`
  display: inline-block;
`;

const ImageCotainer = styled(Div)`
  border: 1px solid ${({ theme }) => theme.gray03};
  margin-top: 10px;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  background-image: url(${getImg});
  background-repeat: no-repeat;
  background-size: contain;
`;

const RadioButtonGroupImageGroup = ({ id, name, content, disabled = [], defaultValue, classes, images }) =>
  content.map((_, index) => {
    return (
      <Container>
        <RadioButton
          // eslint-disable-next-line react/no-array-index-key
          key={`${id}_${index}`}
          id={`${id}_${index}`}
          name={name}
          value={content[index]}
          defaultValue={defaultValue && content[0]}
          classes={classes}
          disabled={disabled.includes(index)}
        />
        <ImageCotainer image={images[index]} />
      </Container>
    );
  });

RadioButtonGroupImageGroup.propTypes = {
  /** 라디오 버튼 id */
  id: PropTypes.string.isRequired,
  /** 라디오 버튼 name */
  name: PropTypes.string.isRequired,
  /** 라디오 버튼 labels */
  content: PropTypes.arrayOf(PropTypes.string),
  /** 라디오 버튼에서 disabled 하고싶은 버튼의 index ex.[0,1,2] */
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.number)], PropTypes.array),
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  /** 템플릿 이미지 */
  images: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
};

export default React.memo(RadioButtonGroupImageGroup);
