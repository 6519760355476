import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  programList: [],
  schedule: [],
  programSchduleCalendar: [],
  programPaging: {},
};

/* eslint-disable no-param-reassign */
const program = createSlice({
  name: 'program',
  initialState,
  reducers: {
    setProgramList: (state, action) => {
      state.programList = action.payload;
    },
    setProgramPaging: (state, action) => {
      state.programPaging = action.payload;
    },
    setSchdule: (state, action) => {
      state.schedule = action.payload;
    },
    setProgramSchduleCalendar: (state, action) => {
      state.programSchduleCalendar = action.payload;
    },
  },
});

/* eslint-enable no-param-reassign */

export const { setProgramList, setProgramPaging, setSchdule, setProgramSchduleCalendar } = program.actions;

export default program.reducer;
