import { setAllEnum } from '@redux/feature/slices/commonSlice';

import Axios from '@Axios';
import { HEADER, METHODS } from '@AxiosConfig';
// import PATHS from '@router/paths';

const BASE_URL = 'v1/api';

/**
 * GetAllEum API
 *
 * @param {Function} dispatch Redux dispatch 함수
 */
const getAllEum = (dispatch) => {
  const URL = `${BASE_URL}/common/enum/list`;

  const onSuccess = (res) => {
    console.log('%c✅ getAllEum RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setAllEnum(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, onSuccess, onFail, header: HEADER.GET });
};

// eslint-disable-next-line import/prefer-default-export
export { getAllEum };
