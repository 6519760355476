import { useState, createContext, useCallback, useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Div } from '@components/Atoms/Atoms';
import { Box } from '@components/Surface/Box';

const Wrapper = styled(Div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
`;
const ModalBox = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.gray01};
  color: #343c47;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;
  z-index: 110;
  /* border-top-left-radius: 5px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 5px; */
  overflow: hidden;
`;

const ModalContext = createContext();

const ModalWrapper = ({ children, isNestedModal }) => {
  const modal = document.getElementById('modal');
  const { closeModal, closeNestedModal } = useContext(ModalContext);

  const handleModalBackGroundOnClick = useCallback(() => {
    return isNestedModal ? closeNestedModal() : closeModal();
  }, []);

  const handleModalBodyOnClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return createPortal(
    <Wrapper onClick={handleModalBackGroundOnClick}>
      <ModalBox onClick={handleModalBodyOnClick}>{children}</ModalBox>
    </Wrapper>,
    modal
  );
};

ModalWrapper.propTypes = {
  /** Custom Modal Components */
  children: PropTypes.node,
};

const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);
  const [nestedModalContent, setNestedModalContent] = useState(null);
  const [ModalValue, setModalValue] = useState();
  const [nestedModalValue, setNestedModalValue] = useState();
  const [isModalValueChanged, setIsModalValueChanged] = useState();

  const showModal = useCallback((content) => {
    setModalContent(content);
  }, []);

  const closeModal = useCallback(() => {
    setModalContent(null);
  }, []);

  const showNestedModal = useCallback((content) => {
    setNestedModalContent(content);
  }, []);

  const closeNestedModal = useCallback(() => {
    setNestedModalContent(null);
  }, []);

  const contextValues = useMemo(
    () => ({
      modalContent,
      showModal,
      closeModal,
      ModalValue,
      setModalValue,
      isModalValueChanged,
      setIsModalValueChanged,
      showNestedModal,
      closeNestedModal,
      nestedModalValue,
      setNestedModalValue,
    }),
    [modalContent]
  );

  return (
    // eslint-disable-next-line
    <ModalContext.Provider value={contextValues}>
      {children}
      {modalContent && <ModalWrapper>{modalContent}</ModalWrapper>}
      {nestedModalContent && <ModalWrapper isNestedModal>{nestedModalContent}</ModalWrapper>}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  /** MainContainer */
  children: PropTypes.node,
};

export { ModalContext, ModalProvider };
