import { useCallback, useMemo } from 'react';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import { useSearchParams } from 'react-router-dom';
import { Div, Input, RadioButtonGroup, SelectBox, Text } from '@components/index';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ImageUpload from '@modules/FTA/components/ImageUpload';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';

const ExtendedText = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.gray07};
`;

const InfoContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  & > *:not(:first-child) {
    margin-top: 15px;
  }
`;

const InfoInnnerContainer = styled(Div)`
  display: flex;
  gap: 10px;
`;

const InfoText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.gray07};
`;

const StyledText = styled(Text)`
  display: flex;
  font-weight: 400;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.gray07};
  margin-bottom: 15px;
  margin-right: 10px;
`;

const nationOptions = {
  guide: '국가 선택',
  list: [
    { id: 'KR', title: '한국' },
    { id: 'US', title: '미국' },
  ],
};

const languageOptions = {
  guide: '언어 선택',
  list: [
    { id: 'kr', title: '한국어' },
    { id: 'en', title: '영어' },
  ],
};

const ExternalCenterProgramConfigurationEditFirst = () => {
  const [serarchParams] = useSearchParams();

  const programListDetail = useSelector((state) => state.program.programList);
  const exerciseCategory = useSelector((state) => state.common.list.CategoryType);
  const levelCategory = useSelector((state) => state.common.list.LevelType);
  const intensityCategory = useSelector((state) => state.common.list.ExerciseIntensityType);

  const isEdit = serarchParams.get('searchId');

  const transformData = useCallback((data) => {
    return data.map((item) => ({
      id: item.code,
      title: item.value,
    }));
  }, []);

  // 프로그램 정보
  const programOptions = useMemo(
    () => ({
      category: {
        guide: '운동 종류',
        list: (exerciseCategory && transformData(exerciseCategory)) || [],
      },
      ageGroup: {
        guide: '연령대',
        list: [
          { id: '10', title: '10대' },
          { id: '20', title: '20대' },
        ],
      },
      time: {
        guide: '운동 시간',
        list: [{ id: '1', title: '1' }],
      },
      exerciseLevel: {
        guide: '운동 수준',
        list: (levelCategory && transformData(levelCategory)) || [],
      },
      exerciseIntensity: {
        guide: '운동 강도',
        list: (intensityCategory && transformData(intensityCategory)) || [],
      },
    }),
    [exerciseCategory, levelCategory, intensityCategory]
  );

  // 컨텐츠 국가/언어
  const contentNationLanguageOptions = useMemo(
    () => ({
      // FIXME: Enum이 없음 추가해야함
      nation: nationOptions,
      // FIXME: Enum이 없음 추가해야함
      language: languageOptions,
    }),
    []
  );

  const getSelectBox = useCallback(
    (optionObject) =>
      Object.keys(optionObject).map((key) => <SelectBox name={key} key={key} options={optionObject[key]} />),
    [exerciseCategory]
  );

  const defaultInfo = useMemo(() => {
    return [
      {
        title: 'CP명',
        content: [
          isEdit ? (
            <ExtendedText>{programListDetail?.cpInfo?.cpName}</ExtendedText>
          ) : (
            // FIXME: 회원가입후 생성되는 CP명 넣기
            <ExtendedText>My Cp명</ExtendedText>
          ),
        ],
      },
      {
        title: '컨텐츠 분류',
        // FIXME: login시 컨텐츠 분류 가져오기
        content: [<Input name='content' type='text' placeholder='컨텐츠 분류' readOnly />],
      },
      {
        title: '프로그램명',
        isRequired: true,
        content: [
          <InfoContainer>
            <InfoText>앱에 프로그램을 설명할 때 사용할 언어를 선택하시고 정보를 입력하여 주세요.</InfoText>

            <InfoInnnerContainer>
              <StyledText>한국</StyledText>
              <Input name='kr.program' placeholder='프로그램명을 입력해주세요.' maxLength='30' />
              <StyledText>미국</StyledText>
              <Input name='en.program' placeholder='프로그램명을 입력해주세요.' maxLength='30' />
            </InfoInnnerContainer>
          </InfoContainer>,
        ],
      },
      {
        title: '프로그램 이미지',
        isRequired: true,
        content: [<ImageUpload name='programImage' />],
      },
      {
        title: '소개글',
        isRequired: true,
        content: [
          <InfoContainer>
            <InfoText>앱에 프로그램을 소개할 때 사용할 언어를 선택하시고 정보를 입력하여 주세요.</InfoText>
            <InfoInnnerContainer>
              <StyledText>한국</StyledText>
              <Input name='kr.introduceText' placeholder='프로그램명을 입력해주세요.' maxLength='30' />
              <StyledText>미국</StyledText>
              <Input name='en.introduceText' placeholder='프로그램명을 입력해주세요.' maxLength='30' />
            </InfoInnnerContainer>
          </InfoContainer>,
        ],
      },
      {
        title: '유/무료',
        content: [
          <RadioButtonGroup
            id='priceYn'
            name='priceYn'
            content={[
              {
                code: 'priceY',
                value: '유료',
              },
              {
                code: 'priceN',
                value: '무료',
              },
            ]}
          />,
        ],
      },
      {
        title: '프로그램 정보',
        content: [<InfoInnnerContainer>{getSelectBox(programOptions)}</InfoInnnerContainer>],
      },
      {
        title: '노출 여부',
        content: [
          <RadioButtonGroup
            id='exposeYn'
            name='exposeYn'
            content={[
              {
                code: 'exposeY',
                value: '노출',
              },
              {
                code: 'exposeN',
                value: '미노출',
              },
            ]}
          />,
        ],
      },
      {
        title: '컨텐츠 국가/언어',
        content: [<InfoInnnerContainer>{getSelectBox(contentNationLanguageOptions)}</InfoInnnerContainer>],
      },
    ];
  }, [programOptions]);

  return (
    <>
      <TitleWithDivider title='기본 정보' />
      <GridLayout data={defaultInfo} />
    </>
  );
};

export default ExternalCenterProgramConfigurationEditFirst;
