import { useCallback, useEffect, useRef, useState } from 'react';
import Checkbox from '@components/Input/Checkbox/Checkbox';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button from '@components/Input/Button/Button';
import { Div, Span } from '@components/Atoms/Atoms';
import { BottomSheetIcon } from '@images/index';
import { CheckBoxChecked, FilterResetIcon } from '@FTA/assets';
import { useFormContext, useWatch } from 'react-hook-form';

// 기본 input ui 안보이게 함
const checkboxInputStyle = css`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

// unChecked 상태 ui
const checkboxCustomUIStyle = css`
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
  border: 1px solid ${({ theme }) => theme.gray05};
  border-radius: 5px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
`;

// checked 상태 ui
const checkboxT = css`
  &:after {
    content: '';
    display: block;
    background-image: url(${CheckBoxChecked});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }
`;

const getWidth = ({ w }) => `${w || 150}px`;
const getHeight = ({ h }) => `${h || 40}px`;

const StyledContainer = styled(Div)`
  position: relative;
  color: ${({ theme }) => theme.gray07};
  background-color: ${({ theme }) => theme.gray01};
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  width: ${getWidth};
  height: ${getHeight};
  ${({ isShow }) => isShow && `border: 1px solid #303030;`}
  ${({ classes }) => classes && classes.Variant.StyledContainer}
  ${({ disabled }) => disabled && `border: 0px; opacity: 0.3;`}
`;

const LabelContainer = styled(Div)`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.gray08};
  cursor: pointer;
  user-select: none;
  &:after {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background: url(${BottomSheetIcon});
    background-size: contain;
  }
  ${({ classes }) => classes && classes.Variant.LabelContainer}
`;

const OptionList = styled.ul`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.gray08};
  position: absolute;
  top: ${getHeight};
  left: 0;
  z-index: 2;
  display: none;
  width: 100%;
  border-radius: 0 0 8px 8px;
  border: 1px solid ${({ theme }) => theme.gray03};
  background: ${({ theme }) => theme.gray01};
  color: #767676;
  overflow: hidden;
  padding: 10px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.06);
  ${({ isShow }) => isShow && `display:block;`}
  ${({ classes }) => classes && classes.Variant.OptionList}
  ${({ disabled }) => disabled && `display: none !important; height: 0px !important;`}
`;

const OptionItem = styled.li`
  padding: 10px 8px;
  border-radius: 4px;

  &:hover {
    background: #eee;
  }

  ${({ isSelected }) => isSelected && `background: #F2EBE0;`}
  ${({ classes }) => classes && classes.Variant.OptionItem}
`;

const ResetFilter = styled(OptionItem)`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
`;

const ResetText = styled(Span)`
  user-select: none;
`;

const Divider = styled(Div)`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.gray03};
  margin: 5px 0;
`;

const TableMultiSelectBox = ({
  initialLabel,
  w,
  h,
  onUpdateFilterChip,
  checkState,
  required,
  disabled,
  options,
  itemText,
  classes,
  singleSelect,
  setCheckedIndex,
  ...props
}) => {
  const formData = useWatch();
  const { setValue } = useFormContext();

  const labelBoxRef = useRef();
  const selectBoxRef = useRef();
  const [isShow, setIsShow] = useState(false);

  const onSelectBoxClick = useCallback(() => {
    setIsShow((prev) => !prev);
  }, []);
  const handlelistItemStatus = useCallback(
    (item) => {
      if (singleSelect) {
        // singleSelect가 true인 경우, 모든 체크박스를 해제한 후 현재 항목만 체크
        options.forEach((option, index) => {
          setValue(option.code, option.code === item.code);
          setCheckedIndex && option.code === item.code && setCheckedIndex(index);
        });
      } else {
        // 일반적인 다중 선택 처리
        const checkboxStatus = formData[item.code];
        setValue(item.code, !checkboxStatus);
      }
    },
    [formData, setValue, options, singleSelect]
  );

  const handleSelectedReset = useCallback(() => {
    options.forEach((item) => {
      setValue(item.code, false);
    });
    if (onUpdateFilterChip) {
      onUpdateFilterChip();
    }
    setCheckedIndex && setCheckedIndex(null);
  }, [options, setValue, onUpdateFilterChip]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectBoxRef.current &&
        !selectBoxRef.current.contains(event.target) &&
        !labelBoxRef.current.contains(event.target)
      ) {
        setIsShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectBoxRef]);

  return (
    <StyledContainer classes={classes} w={w} h={h} isShow={isShow} disabled={disabled} {...props}>
      <LabelContainer ref={labelBoxRef} classes={classes} onMouseDown={onSelectBoxClick}>
        <Div>{initialLabel}</Div>
      </LabelContainer>
      {!disabled && (
        <OptionList ref={selectBoxRef} isShow={isShow} classes={classes} h={h}>
          {options.map((item, index) => {
            return (
              <OptionItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${item?.code}-${index}`}
                isSelected={formData[item.code]}
                onClick={() => {
                  handlelistItemStatus(item);
                }}
                classes={classes}
              >
                <Checkbox
                  name={item.code}
                  item={item}
                  classes={{
                    Input: checkboxInputStyle,
                    Box: checkboxCustomUIStyle,
                    CheckboxT: checkboxT,
                  }}
                />
              </OptionItem>
            );
          })}
          <Divider />
          <ResetFilter onClick={handleSelectedReset}>
            <Button $layout='round' startIcon={FilterResetIcon} width={12} height={12} />
            <ResetText>필터 초기화</ResetText>
          </ResetFilter>
        </OptionList>
      )}
    </StyledContainer>
  );
};

TableMultiSelectBox.propTypes = {
  /** 라벨 초기 값 */
  initialLabel: PropTypes.string,
  /** 테이블 selecbox width */
  w: PropTypes.number,
  /** 테이블 selecbox height */
  h: PropTypes.number,
  /** 값 선택 필수 유무 */
  required: PropTypes.bool,
  /** Disabled 여부 */
  disabled: PropTypes.bool,
  /** SelectBox의 목록(list) */
  options: PropTypes.array.isRequired,
  /** SelectBox list의 추가 텍스트 */
  itemText: PropTypes.string,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.shape({
    Variant: PropTypes.shape({
      StyledContainer: PropTypes.objectOf(PropTypes.string),
      LabelContainer: PropTypes.objectOf(PropTypes.string),
      OptionList: PropTypes.objectOf(PropTypes.string),
      OptionItem: PropTypes.objectOf(PropTypes.string),
    }),
  }),
  /** 테이블 필터링 키워드 추가 함수 */
  onUpdateFilterChip: PropTypes.func,
  /** 테이블 필터링 키워드 초기화 함수 */
  // onResetFilteredChip: PropTypes.func,
  /** 필터링 아이템 체크 여부 상태 객체 */
  checkState: PropTypes.object,
  /** 체크박스 한 개만 선택할 수 있는지 여부 */
  singleSelect: PropTypes.bool,
  /** SingleSelect인 경우 체크박스가 체크된 index 를 set해주는 함수 */
  setCheckedIndex: PropTypes.func,
};

export default TableMultiSelectBox;
