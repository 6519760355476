import { rest } from 'msw';
import { getContents, getApprovalStatusContent } from '../data/msw/contents';

const BASE_URL = 'v1/admin';

// eslint-disable-next-line import/prefer-default-export
export const contentsHandler = [
  rest.get(`/${BASE_URL}/program/contents/list`, (req, res, ctx) => {
    const routineApprovalStatus = req.url.searchParams.get('routineApprovalStatus');

    let axiosUrl;

    if (routineApprovalStatus !== '') {
      axiosUrl = res(ctx.status(200), ctx.json(getApprovalStatusContent(routineApprovalStatus)));
    } else {
      axiosUrl = res(ctx.status(200), ctx.json(getContents()));
    }

    return axiosUrl;
  }),
];
