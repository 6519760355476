const CONTENTTYPE = {
  categoryType: { 1: '유산소' }, // 카테고리
  sftType: { 0: '하지 근력', 1: '상지 근력', 2: '민첩성' }, // 신체능력평가 종류
  alignmentType: { 0: '목 좌우 기울기', 1: '어깨 좌우 기울기', 2: '골반 좌우 기울기' }, // 근골격상태 종류
  romType: { 0: '어깨 외회전', 1: '어깨 수평 내회전', 2: '어깨 수평 외회전' }, // 관절가동범위 종류
  recommendedAgeType: { 0: '45세 미만' }, // 권장 연령대
  levelType: { 0: '초급' }, // 난이도
  exerciseIntensityType: { 0: '하' }, // 운동강도
  equipmentType: { 0: '매트', 1: '의자', 2: '루프밴드(세라밴드)' }, // 운동기구
  positionType: { 0: '서서', 1: '누워서' }, // 포지션
  bodyPartType: { 0: '목', 1: '어깨' }, // 신체 부위
  exceptionBodyPartType: { 0: '목', 1: '어깨' }, // 예외대상1 (신체부위)
  exceptionDiseaseType: { 0: '오십견', 1: '허리 디스크(척추 디스크)' }, // 예외대상2 (질환명)
};

export default Object.freeze(CONTENTTYPE);
