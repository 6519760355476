/** 기타 관리 > FAQ > FAQ 클릭(상세 화면) > 수정 */

import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import styled, { css } from 'styled-components';

import { Button, Div, Input, Modal, ModalContext, SelectBox } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';

import PATHS from '@router/paths';
import { FAQregisterAPI, getFaqDetailAPI } from '@redux/action/etcAction';
import { useDispatch, useSelector } from 'react-redux';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import TextEditor from '@modules/FTA/components/TextEditor';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

// TODO: 유효성관련 메세지 파일 만들기
// const ERROR_MESSAGE = {
//   inputText: '내용을 입력해주세요.',
//   pwdLength: '비밀번호는 8자 이상 10자 이상이어야 합니다.',
// };

const testSelectBox = {
  guide: '',
  list: [
    { id: '1', title: 'SelectBox' },
    { id: '2', title: 'SelectBox item' },
  ],
};

const FORM_NAMES = Object.freeze({
  TITLE: 'title',
  COUNTRY: 'country',
  CATEGORY: 'category',
  CONTENT: 'content',
});

const FaqEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serarchParams] = useSearchParams();
  const getFaqInfoId = serarchParams.get('faqInfoId');
  /* eslint-disable no-unneeded-ternary */
  const isEdit = getFaqInfoId ? true : false;
  const faqDetail = useSelector((state) => state.etc.faqDetail);

  const methods = useForm(
    isEdit && {
      defaultValues: {
        [FORM_NAMES.TITLE]: faqDetail.title,
        [FORM_NAMES.COUNTRY]: faqDetail.country,
        [FORM_NAMES.CATEGORY]: faqDetail.category,
        [FORM_NAMES.CONTENT]: faqDetail.content,
      },
    }
  );
  const { handleSubmit, reset } = methods;
  const { showModal, closeModal } = useContext(ModalContext);

  const FAQ_DATA = useMemo(() => {
    const formInfo = [
      { title: '번호', content: [faqDetail?.noticeInfoId] },
      {
        title: '제목',
        isRequired: true,
        content: [<Input name={FORM_NAMES.TITLE} type='text' placeholder='제목을 입력해주세요.' maxLength='100' />],
      },
      {
        title: '국가 선택',
        isRequired: true,
        content: [
          // FIXME : API 연결 후 기존 값으로 변경해야함
          <div style={{ width: '400px', display: 'flex', gap: '20px' }}>
            <SelectBox name={FORM_NAMES.COUNTRY} options={testSelectBox} />
          </div>,
        ],
      },
      {
        title: '카테고리 선택',
        isRequired: true,
        content: [
          // FIXME : API 연결 후 기존 값으로 변경해야함
          <div style={{ width: '400px', display: 'flex', gap: '20px' }}>
            <SelectBox name={FORM_NAMES.CATEGORY} options={testSelectBox} />
          </div>,
        ],
      },
      {
        title: '내용',
        isRequired: true,
        content: [<TextEditor name={FORM_NAMES.CONTENT} isEdit={isEdit} moduleOption={['link']} />],
      },
    ];
    if (!isEdit) formInfo.splice(0, 1);
    return formInfo;
  }, [faqDetail, isEdit]);

  const handleNavigate = useCallback(
    (path) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  const handleSave = useCallback((values) => {
    showModal(
      <Modal
        body='입력한 정보를 저장하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                // FIXME[CS] db에 저장하는 로직 추가 해야 함
                let params;
                if (isEdit === true) {
                  // 수정
                  params = {
                    category: values.category || faqDetail.category,
                    content: values.content,
                    country: 'KR',
                    faqInfoId: Number(getFaqInfoId),
                    title: values.title,
                  };
                } else {
                  // 저장
                  params = {
                    category: values.category,
                    content: values.content,
                    country: 'KR',
                    title: values.title,
                  };
                }
                FAQregisterAPI(dispatch, params);
                handleNavigate(`/${PATHS.FAQ}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const handleCancel = useCallback(() => {
    showModal(
      <Modal
        body='정보 저장을 취소하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                handleNavigate(`/${PATHS.FAQ}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  useEffect(() => {
    // eslint-disable-next-line object-shorthand
    const params = { faqInfoId: getFaqInfoId };
    getFaqDetailAPI(dispatch, params);
  }, [getFaqInfoId]);

  useEffect(() => {
    if (Object.values(faqDetail).length > 0 && isEdit) {
      reset({
        [FORM_NAMES.TITLE]: faqDetail.title,
        [FORM_NAMES.COUNTRY]: faqDetail.country,
        [FORM_NAMES.CATEGORY]: faqDetail.category,
        [FORM_NAMES.CONTENT]: faqDetail.content,
      });
    } else {
      reset();
    }
  }, [faqDetail, isEdit]);

  return (
    <Container>
      <PageTitle title='FAQ' />
      <FormProvider {...methods}>
        <GridLayout data={FAQ_DATA} />
        <ButtonSection>
          <Button
            onClick={handleSubmit(handleSave)}
            $layout='square'
            $variantColor='primary'
            classes={{ Button: ButtonCss }}
          >
            저장
          </Button>
          <Button onClick={handleCancel} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            취소
          </Button>
        </ButtonSection>
      </FormProvider>
    </Container>
  );
};

FaqEdit.propTypes = {};

export default FaqEdit;
