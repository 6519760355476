/** 기타 관리 > 설문 > 설문 클릭(상세 화면) > 수정 */

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
// import { useForm, useWatch } from 'react-hook-form';
import styled, { css } from 'styled-components';

import {
  Button,
  Div,
  //  FormProviderWithDevTools,
  Input,
  Modal,
  ModalContext,
  SelectBox,
} from '@components/index';
import PageTitle from '@FTA/components/PageTitle';

import PATHS from '@router/paths';
import { useDispatch, useSelector } from 'react-redux';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import DynamicSessionSubManagement from '@modules/FTA/components/DynamicSessionManagement/DynamicSessionSubManagement';
import InputChip from '@modules/FTA/components/InputChip';
import { SurveyDetailAPI, SurveyRegistAPI } from '@redux/action/etcAction';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const DivContainer = styled(Div)`
  display: flex;
`;

const DivinContainer = styled(Div)`
  width: 28%;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const OrderSelectBox = {
  guide: '',
  list: [
    { id: '1', title: '1번째' },
    { id: '2', title: '2번째' },
    { id: '2', title: '3번째' },
    { id: '2', title: '4번째' },
    { id: '2', title: '5번째' },
    { id: '2', title: '6번째' },
    { id: '2', title: '7번째' },
    { id: '2', title: '8번째' },
    { id: '2', title: '9번째' },
    { id: '2', title: '10번째' },
  ],
};

const CountSelectBox = {
  guide: '',
  list: [
    { id: 1, title: '1개' },
    { id: 2, title: '2개' },
    { id: 3, title: '3개' },
    { id: 4, title: '4개' },
    { id: 5, title: '5개' },
    { id: 6, title: '6개' },
    { id: 7, title: '7개' },
    { id: 8, title: '8개' },
    { id: 9, title: '9개' },
    { id: 10, title: '10개' },
  ],
};

const CountSubSelectBox = {
  guide: '',
  list: [
    { id: 1, title: '1개' },
    { id: 2, title: '2개' },
    { id: 3, title: '3개' },
    { id: 4, title: '4개' },
    { id: 5, title: '5개' },
    { id: 6, title: '6개' },
    { id: 7, title: '7개' },
    { id: 8, title: '8개' },
    { id: 9, title: '9개' },
    { id: 10, title: '10개' },
  ],
};

const SurveyEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [serarchParams] = useSearchParams();
  const surveyInfoId = serarchParams.get('surveyInfoId');
  const survey = useSelector((state) => state.etc.surveyDetail);

  const { showModal, closeModal } = useContext(ModalContext);

  const [valuesSubId, setValuesSubId] = useState({ parent: 0, sub: 0 });

  /* eslint-disable no-unneeded-ternary */
  const isEdit = surveyInfoId ? true : false;
  const methods = useForm(
    isEdit && {
      defaultValues: {
        surveyName: survey.surveyName,
        surveyOrder: survey.surveyOrder,
        surveyQuestionList: survey?.surveyQuestionList?.map((question) => ({
          surveyQuestionInfoId: question.urveyQuestionInfoId,
          surveyInfoId: question.surveyInfoId,
          questionName: question.questionName,
          questionOrder: question.questionOrder,
          questionCount: question.questionCount,
          surveySelectList: question.surveySelectList?.map((select) => ({
            selectName: select.selectName,
            surveySelectOrder: select.surveySelectOrder,
            languageType: 'KR',
          })),
          surveySubQuestionList: question.surveySubQuestionList.map((subQuestion) => ({
            surveySubQuestionInfoId: subQuestion.surveySubQuestionInfoId,
            surveyQuestionInfoId: subQuestion.surveyQuestionInfoId,
            questionName: subQuestion.questionName,
            questionOrder: subQuestion.questionOrder,
            questionCount: subQuestion.questionCount,
            surveySubSelectList: subQuestion.surveySubSelectList.map((selectSub) => ({
              surveySubSelectInfoId: selectSub.surveySubSelectInfoId,
              surveySubQuestionInfoId: selectSub.surveySubQuestionInfoId,
              selectName: selectSub.selectName,
              surveySelectOrder: selectSub.surveySelectOrder,
            })),
          })),
        })),
      },
    }
  );

  const { handleSubmit, control, reset, setValue } = methods;

  const selectRadio = useWatch({ name: 'questions', control });
  const selectNameCount = useWatch({ name: 'surveyQuestionList', control });

  const handleNavigate = useCallback(
    (path) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  const handleSave = useCallback((values) => {
    showModal(
      <Modal
        body='입력한 정보를 저장하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                // FIXME : INDEX 맞게 수정 . . .
                let params = {};
                if (isEdit === true) {
                  params = {
                    // eslint-disable-next-line object-shorthand
                    surveyInfoId: surveyInfoId,
                    surveyName: values.surveyName,
                    surveyOrder: values.surveyOrder,
                    languageType: 'KR',
                    surveyQuestionList: values.questions.map((el, index) => ({
                      surveyQuestionInfoId: el.surveyQuestionInfoId,
                      surveyInfoId: el.surveyQuestionInfoId,
                      question: el.question,
                      questionOrder: index,
                      questionName: el.questionName,
                    })),
                  };
                } else {
                  params = {
                    surveyName: values.surveyName,
                    surveyOrder: values.surveyOrder,
                    languageType: 'KR',
                    surveyQuestionList: values.surveyQuestionList?.map((question, index) => {
                      const transformedQuestion = {
                        questionName: question.questionName,
                        questionOrder: index + 1,
                        questionCount: question.questionCount,
                        surveySelectList: question?.surveySelectList?.map((select, selectIndex) => ({
                          selectName: select.value,
                          surveySelectOrder: selectIndex + 1,
                          languageType: 'KR',
                        })),
                      };

                      if (question.surveySubQuestionList && question.surveySubQuestionList.length > 0) {
                        transformedQuestion.surveySubQuestionList = question.surveySubQuestionList
                          .filter((item) => item !== null)
                          .map((subQuestion, subIndex) => ({
                            questionName: subQuestion.questionName,
                            questionOrder: subIndex + 1,
                            questionCount: subQuestion.questionCount,
                            surveySubSelectList: subQuestion?.surveySubSelectList?.map((select, subSelectIndex) => ({
                              selectName: select.value,
                              surveySelectOrder: subSelectIndex + 1,
                              languageType: 'KR',
                            })),
                          }));
                      }

                      return transformedQuestion;
                    }),
                  };
                }

                SurveyRegistAPI(dispatch, params);
                handleNavigate(`/${PATHS.SURVEY}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const handleCancel = useCallback(() => {
    showModal(
      <Modal
        body='정보 저장을 취소하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                handleNavigate(`/${PATHS.SURVEY}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const SURVEY_DATA = useMemo(
    () => [
      {
        title: '설문 제목',
        isRequired: true,
        content: [<Input name='surveyName' type='text' placeholder='제목을 입력해주세요.' maxLength='100' />],
      },
      {
        title: '순서 선택',
        content: [
          // FIXME : API 연결 후 기존 값으로 변경해야함
          <SelectBox name='surveyOrder' options={OrderSelectBox} />,
        ],
      },
    ],
    [isEdit]
  );

  const initSession = useCallback(
    (parent = 1, defaultValue = {}) => {
      const id = parent - 1;
      setValue(`surveyQuestionList.${id}.questionOrder`, parent);
      return {
        dynamicItems: [
          {
            title: '제목',
            content: (
              <Input
                key={`surveyQuestionList.${id}.questionName`}
                name={`surveyQuestionList.${id}.questionName`}
                type='text'
                placeholder='제목을 입력해주세요.'
                maxLength='100'
                defaultValue={defaultValue.questionName || ''}
              />
            ),
          },
          {
            title: '선택 가능 개수',
            content: (
              <DivContainer>
                <DivinContainer>
                  <SelectBox
                    id='questionCount'
                    control={control}
                    name={`surveyQuestionList.${id}.questionCount`}
                    options={CountSelectBox}
                    defaultValue={defaultValue?.surveySelectList?.length || 0}
                  />
                </DivinContainer>
              </DivContainer>
            ),
          },
          {
            title: '선택지',
            content: (
              <InputChip
                name={`surveyQuestionList.${id}.surveySelectList`}
                id={`surveyQuestionList.${id}.surveySelectList`}
                maxName={`surveyQuestionList.${id}.questionCount`}
                placeholder='선택지를 입력해주세요.'
                defaultValue={defaultValue.surveySelectList?.map((item) => item.selectName)}
              />
            ),
          },
        ],
        subList: defaultValue?.surveySubQuestionList || [],
      };
    },
    [selectRadio, survey, isEdit, selectNameCount, control]
  );

  const initSubSession = useCallback(
    (parent = 1, sub = 1, defaultValue = {}) => {
      const parentId = parent - 1;
      const subId = sub;
      return {
        dynamicItems: [
          {
            title: '제목',
            content: (
              <Input
                key={`surveyQuestionList.${parentId}.surveySubQuestionList.${subId}.questionName`}
                name={`surveyQuestionList.${parentId}.surveySubQuestionList.${subId}.questionName`}
                type='text'
                placeholder='제목을 입력해주세요.'
                maxLength='100'
                defaultValue={defaultValue.questionName || ''}
              />
            ),
          },
          {
            title: '선택 가능 개수',
            content: (
              <DivContainer>
                <DivContainer>
                  <SelectBox
                    control={control}
                    name={`surveyQuestionList.${parentId}.surveySubQuestionList.${subId}.questionCount`}
                    options={CountSubSelectBox}
                    defaultValue={defaultValue?.surveySubSelectList?.length || 0}
                    onChangeHandler={() => {
                      setValuesSubId({ parent: parentId, sub: subId });
                    }}
                  />
                </DivContainer>
              </DivContainer>
            ),
          },
          {
            title: '선택지',
            content: (
              <InputChip
                name={`surveyQuestionList.${parentId}.surveySubQuestionList.${subId}.surveySubSelectList`}
                maxLength={100}
                maxName={selectNameCount?.[parentId]?.surveySubQuestionList?.subId?.questionCount}
                placeholder='선택지를 입력해주세요.'
                defaultValue={defaultValue.surveySubSelectList?.map((item) => item.selectName)}
              />
            ),
          },
        ],
      };
    },
    [valuesSubId, survey, isEdit]
  );

  useEffect(() => {
    if (isEdit === true) SurveyDetailAPI(dispatch, surveyInfoId);
  }, [dispatch, isEdit]);

  useEffect(() => {
    if (Object.values(survey).length > 0 && isEdit === true) {
      reset({
        surveyName: survey.surveyName,
        surveyOrder: survey.surveyOrder,
        surveyQuestionList: survey?.surveyQuestionList?.map((question) => ({
          surveyQuestionInfoId: question.urveyQuestionInfoId,
          surveyInfoId: question.surveyInfoId,
          questionName: question.questionName,
          questionOrder: question.questionOrder,
          questionCount: question.questionCount,
          surveySelectList: question.surveySelectList?.map((select) => ({
            selectName: select.selectName,
            surveySelectOrder: select.surveySelectOrder,
            languageType: 'KR',
            surveyQuestionInfoId: select.surveyQuestionInfoId,
            surveySelectInfoId: select.surveySelectInfoId,
          })),
          surveySubQuestionList: question.surveySubQuestionList.map((subQuestion) => ({
            surveySubQuestionInfoId: subQuestion.surveySubQuestionInfoId,
            surveyQuestionInfoId: subQuestion.surveyQuestionInfoId,
            questionName: subQuestion.questionName,
            questionOrder: subQuestion.questionOrder,
            questionCount: subQuestion.questionCount,
            surveySubSelectList: subQuestion.surveySubSelectList.map((selectSub) => ({
              surveySubSelectInfoId: selectSub.surveySubSelectInfoId,
              surveySubQuestionInfoId: selectSub.surveySubQuestionInfoId,
              selectName: selectSub.selectName,
              surveySelectOrder: selectSub.surveySelectOrder,
              languageType: selectSub.languageType,
            })),
          })),
        })),
      });
    } else {
      reset();
    }
  }, [survey, isEdit]);

  return (
    <Container>
      <PageTitle title='설문' />
      <FormProvider {...methods}>
        <GridLayout data={SURVEY_DATA} />
        <DynamicSessionSubManagement
          edit={isEdit}
          initData={survey?.surveyQuestionList}
          // initId={allValuesIs}
          initSubDataName='surveySubQuestionList'
          initSession={initSession}
          initSubSession={initSubSession}
          section={{ title: '질문', subTitle: '하위질문', addBtn: '질문 추가', addSubBtn: '하위 질문 추가' }}
          name={selectRadio}
        />
        <ButtonSection>
          <Button
            onClick={handleSubmit(handleSave)}
            $layout='square'
            $variantColor='primary'
            classes={{ Button: ButtonCss }}
          >
            저장
          </Button>
          <Button onClick={handleCancel} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            취소
          </Button>
        </ButtonSection>
      </FormProvider>
    </Container>
  );
};

SurveyEdit.propTypes = {};

export default SurveyEdit;
