import styled, { css } from 'styled-components';

import BasicTable from '@components/DataDisplay/Table/BasicTable';
import { Div, Grid } from '@components/index';

const Container = styled(Div)``;

const gridStyle = css`
  margin-bottom: 40px;
`;

// FIXME: MOCK_DATA
const data = {
  title: ['Name', 'Age', 'Email'],
  rows: [
    ['John Doe', 30, 'john@example.com'],
    ['Jane Smith', 25, 'jane@example.com'],
    ['Sam Johnson', 35, 'sam@example.com'],
  ],
};

const Survey = () => {
  return (
    <Container>
      <Grid title='기본 정보' column={4} classes={{ Grid: gridStyle }}>
        <BasicTable data={data} title='현재 나이' />
        <BasicTable data={data} title='성별' />
        <BasicTable data={data} title='키' />
        <BasicTable data={data} title='몸무게' />
      </Grid>
      <Grid title='바디 정보' column={4} classes={{ Grid: gridStyle }}>
        <BasicTable data={data} title='질환' />
        <BasicTable data={data} title='신체활동 제한질환 보유여부' />
        <BasicTable data={data} title='통증 부위' />
        <BasicTable data={data} title='1년이내 수술여부' />
      </Grid>
      <Grid title='운동 정보' column={4} classes={{ Grid: gridStyle }}>
        <BasicTable data={data} title='운동목적' />
        <BasicTable data={data} title='관심 신체부위' />
        <BasicTable data={data} title='선호운동' />
        <BasicTable data={data} title='주간운동 횟수' />
        <BasicTable data={data} title='하루 운동시간' />
      </Grid>
      <Grid title='생활 정보' column={4} classes={{ Grid: gridStyle }}>
        <BasicTable data={data} title='흡연여부' />
        <BasicTable data={data} title='음주여부' />
        <BasicTable data={data} title='통증 부위' />
        <BasicTable data={data} title='1년이내 수술여부' />
      </Grid>
    </Container>
  );
};

export default Survey;
