/* eslint-disable */

import React, { useCallback, useState, useRef } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Mousewheel, Keyboard } from 'swiper/modules';
import { Swiper as SwiperContainer, SwiperSlide } from 'swiper/react';

import { Div } from '@components/Atoms/Atoms';

const Container = styled(Div)`
  display: grid;
  grid-auto-rows: auto;
`;

const NabigationContainer = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 46px;
  background-color: green;
`;

const PrevSlideButton = styled(Div)`
  width: 46px;
  height: 46px;
  background-color: red;
  ${({ disable }) => disable && `opacity: 0.35;`}
`;
const NextSlideButton = styled(Div)`
  width: 46px;
  height: 46px;
  background-color: blue;
  ${({ disable }) => disable && `opacity: 0.35;`}
`;

const ExtendedSwiper = styled(SwiperContainer)`
  width: 100%;
  height: 110%;
`;

const ProgramSwiper = ({ children }) => {
  const swiperRef = useRef(null);
  const lastSlideIndex = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const canPrev = activeIndex !== 0;
  const canNext = activeIndex !== lastSlideIndex.current;

  const prevToSlide = () => {
    if (canPrev) {
      swiperRef.current.slideTo(activeIndex - 1);
    }
  };
  const nextToSlide = () => {
    if (canNext) {
      swiperRef.current.slideTo(activeIndex + 1);
    }
  };
  const handleOnSwiper = useCallback((swiper) => {
    swiperRef.current = swiper;
    lastSlideIndex.current = swiper.slides.length - 1;
    setActiveIndex(swiper.activeIndex); // 초기화시 activeIndex 설정
    swiper.on('slideChange', () => {
      setActiveIndex(swiper.activeIndex);
    });
  }, []);

  return (
    <Container>
      <NabigationContainer>
        <PrevSlideButton onClick={prevToSlide} disable={!canPrev} />
        <NextSlideButton onClick={nextToSlide} disable={!canNext} />
      </NabigationContainer>
      <ExtendedSwiper
        onSwiper={(swiper) => {
          handleOnSwiper(swiper);
        }}
        modules={[Mousewheel, Keyboard]}
        className='mySwiper'
        slidesPerView={1}
        spaceBetween={30}
        // observeParents
      >
        {React.Children.map(children, (child) => (
          <SwiperSlide>{child}</SwiperSlide>
        ))}
      </ExtendedSwiper>
    </Container>
  );
};

ProgramSwiper.propTypes = {
  /** SwiperSlides */
  children: PropTypes.node,
};

export default ProgramSwiper;
