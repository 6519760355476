import { forwardRef, createElement } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Skeleton from '@components/Atoms/Skeleton';
import Icon from '@components/Atoms/Icon';
import { RequiredIcon } from '@FTA/assets';

const getFontSize = ({ fontSize }) => `${fontSize || 16}px`;
const getFont = ({ font }) => `${font || 'body'}`; // TODO: apple-system 참고 문서
const getFontWeight = ({ fontWeight }) => `${fontWeight || 400}`;
const getColor = ({ color }) => `${color}`;
const getWidth = ({ width }) => width && `${width}px`;
const getLineHeight = ({ lineHeight }) => lineHeight && `${lineHeight}px`;

const styles = css`
  display: block;
  font-size: ${getFontSize};
  font: -apple-system-${getFont};
  font-weight: ${getFontWeight};
  width: ${getWidth};
  color: ${getColor};
  line-height: ${getLineHeight};
  ${({ classes }) => classes && classes.Text}
`;

const StyledText = styled(
  // eslint-disable-next-line react/prop-types
  forwardRef(({ isLoading = false, loader = {}, ...props }, ref) =>
    isLoading ? (
      <Skeleton variant={loader.variant || 'text'} width={loader.width} height={loader.height || 20} />
    ) : (
      // eslint-disable-next-line react/prop-types
      createElement(`${props.type || 'p'}`, { ...props, ref }, props.children)
    )
  )
)`
  ${styles}
`;

const TextContainer = styled.div`
  display: flex;
  ${({ classes }) => classes && classes.Container}
`;

const Text = forwardRef(
  (
    {
      children,
      type,
      fontSize,
      font,
      fontWeight,
      width,
      color,
      isLoading,
      className,
      classes,
      inner,
      innerHtml,
      required,
      ...rest
    },
    ref
  ) => {
    let Result;

    if (inner || innerHtml) {
      Result = (
        <StyledText
          type={type}
          fontSize={fontSize}
          font={font}
          fontWeight={fontWeight}
          width={width}
          color={color}
          isLoading={isLoading}
          className={className}
          classes={classes}
          ref={ref}
          dangerouslySetInnerHTML={{
            __html: inner || innerHtml,
          }}
          {...rest}
        />
      );
    } else {
      Result = (
        <StyledText
          ref={ref}
          type={type}
          fontSize={fontSize}
          font={font}
          fontWeight={fontWeight}
          color={color}
          isLoading={isLoading}
          className={className}
          classes={classes}
          {...rest}
        >
          {children}
        </StyledText>
      );
    }

    if (required)
      return (
        <TextContainer classes={classes}>
          {Result}
          <Icon icon={RequiredIcon} iconWidth={7} iconHeight={7} />
        </TextContainer>
      );

    return Result;
  }
);

Text.propTypes = {
  /** Children */
  children: PropTypes.node,
  /** Text 타입 */
  type: PropTypes.string,
  /** Font 사이즈(px) */
  fontSize: PropTypes.number,
  /** Dynamic font(apple system font) */
  font: PropTypes.string,
  /** Font weight */
  fontWeight: PropTypes.number,
  /** Width */
  width: PropTypes.number,
  /** Font 색상 */
  color: PropTypes.string,
  /** Loading 화면 표시 */
  isLoading: PropTypes.bool,
  /** Loader 설정 */
  loader: PropTypes.shape({
    variant: PropTypes.oneOf(['text', 'rectangular', 'rounded', 'circular']).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  /** ClassName */
  className: PropTypes.string,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Text 컴포넌트에 직접 넣을 inner */
  inner: PropTypes.string,
  /** Text 컴포넌트에 직접 넣을 innerHtml */
  innerHtml: PropTypes.string,
  /** Text가 필수 값인지 확인하는 값 */
  required: PropTypes.bool,
};

Text.displayName = 'Text';

export default Text;
