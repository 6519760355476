import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Div } from '@components/index';

const CellContainer = styled(Div)`
  box-sizing: border-box;
  position: relative;
  display: grid;
  align-items: flex-start;
  padding: 20px;
  height: 150px;
  border: 1px solid ${({ theme }) => theme.gray04};
  border-radius: 12px;
  line-height: 1.17;
  overflow: hidden;
  background: ${({ theme }) => theme.brown01};
  height: ${({ height }) => height};
  ${({ classes }) => classes && classes.Cell}

  ::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: 86%;
    height: 1px;
    background-color: ${({ theme }) => theme.gray05};
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.gray08};
`;

const Value = styled.div`
  font-size: 26px;
  font-weight: 700;
  align-self: flex-end;
  color: ${({ theme }) => theme.gray10};
`;

const Cell = ({ classes, title, value, height }) => {
  return (
    <CellContainer classes={classes} height={height}>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </CellContainer>
  );
};

Cell.propTypes = {
  /** 부모 컴포넌트가 전달한 style */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  /** 카드 상단에 들어가는 타이틀 값 */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** 카드 타이틀에 상응하는 실제 값 */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  /** Cell의 높이 */
  height: PropTypes.string,
};

export default Cell;
