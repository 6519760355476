/** 관리자 계정 관리 > 관리자 등급설정 > 등급 선택 */

import { useCallback, useContext, useEffect } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountRoleDetailAPI } from '@redux/action/accountsAction';

import styled, { css } from 'styled-components';

import { Button, Div, Modal, ModalContext, Text } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';

import PATHS from '@router/paths';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const ExtendedText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.gray08};
`;

const AccessibleMenuListContainer = styled(Div)`
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 150px));
  gap: 14px 6px;
`;

const AccessibleMenu = styled(Div)`
  padding: 8px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.gray03};
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.gray08};
`;

const AccountRoleDetail = () => {
  const dispatch = useDispatch();
  const myAccountClassName = useSelector((state) => state.auth.loggedUserInfo.className);
  const accountRole = useSelector((state) => state.accounts.accountRoleDetail);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const adminClassInfoId = searchParams.get('adminClassInfoId');

  const { showModal, closeModal } = useContext(ModalContext);

  const accountRoleGridData = [
    {
      title: '관리자 등급',
      content: [<ExtendedText>{accountRole?.className}</ExtendedText>],
    },
    {
      title: '접근 가능 메뉴',
      content: [
        <AccessibleMenuListContainer>
          {accountRole?.accessibleMenuList?.map(({ value }) => (
            <AccessibleMenu>{value}</AccessibleMenu>
          ))}
        </AccessibleMenuListContainer>,
      ],
    },
    {
      title: '승인 권한',
      content: [<ExtendedText>{accountRole?.approvalAuthority ? '가능' : '불가'}</ExtendedText>], // NOTE: enum이 없음
    },
  ];

  const handleNavigate = useCallback(
    (path) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  const gotoList = useCallback(() => {
    showModal(
      <Modal
        body='목록으로 이동하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                // Todo: table로 이동
                handleNavigate(`/${PATHS.ACCOUNT_ROLE}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const onClickEdit = useCallback(() => {
    const search = createSearchParams({ adminClassInfoId }).toString();
    navigate({ pathname: `/${PATHS.ACCOUNT_ROLE_EDIT}`, search });
  }, []);

  useEffect(() => {
    getAccountRoleDetailAPI(dispatch, { adminClassInfoId });
  }, []);

  return (
    <Container>
      <PageTitle title='관리자 등급설정' />
      <TitleWithDivider title='기본 정보' />
      <GridLayout data={accountRoleGridData} hasBottomBorderLine />
      <ButtonSection>
        {/* FIXME: level === 0이 맞는지 확인이 필요 ? (level은 삭제된 것으로 알고 있음) */}
        {myAccountClassName === 'SUPER' ? (
          <Button onClick={onClickEdit} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
            수정
          </Button>
        ) : null}
        <Button onClick={gotoList} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
          목록
        </Button>
      </ButtonSection>
    </Container>
  );
};

export default AccountRoleDetail;
