import Cell from '@FTA/components/Cell';
import { Grid } from '@components/index';
import { css } from 'styled-components';

const GridStyle = css`
  margin-bottom: 40px;
`;

const Commerce = () => {
  return (
    <>
      <Grid title='상품 구매 동향' column={4} classes={{ Grid: GridStyle }}>
        <Cell title='신상품 노출 수' value='1250' />
        <Cell title='구매사이트 이동 수 ' value='3800' />
        <Cell title='구매 수' value='34:33' />
      </Grid>

      <Grid title='결제' column={3} classes={{ Grid: GridStyle }}>
        <Cell title='결제 수 (카드)' value='1250' />
        <Cell title='결제 수 (계좌이체) ' value='3800' />
        <Cell title='TV앱 결제 수 ' value='3800' />
        <Cell title='모바일 결제수 ' value='3800' />
        <Cell title='Q카드 결제 수 ' value='3800' />
      </Grid>
    </>
  );
};

Commerce.propTypes = {};

export default Commerce;
