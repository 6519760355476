import { useRef, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Div, FileInput, Img, Text } from '@components/index';
import Themes from '@styles/Themes';
import { HEADER, getUrl } from '@redux/function/AxiosConfig';
import axios from 'axios';
import { useFormContext } from 'react-hook-form';

const Container = styled(Div)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  min-width: 400px;
  min-height: 128px;
  gap: 12px;
  border: 1px solid ${({ theme }) => theme.gray04};
  border-radius: 8px;
`;

const TextContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
`;

const ImgPreview = styled(Img)`
  width: 80%;
  height: 80%;
  margin-top: 20px;
`;

const Label = styled.label``;

const UploadBtn = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 101px;
  height: 36px;
  border-radius: 50px;
  opacity: 0px;
  background-color: ${({ theme }) => theme.gray03};
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.gray04};
  }
`;

const InputDisplay = css`
  display: none;
`;

const ImageUpload = ({ name, required, onFileChange, shouldGetCloudFrontUrl = true }) => {
  const [image, setImage] = useState(null);
  const imageFileRef = useRef(null);
  const { setValue } = useFormContext();
  const uuid = uuidv4();

  // 파일 선택 시 이미지 미리보기 함수
  const handleImagePreview = useCallback(
    (event) => {
      const selectedFile = event.target.files[0];

      // FileReader 객체 생성
      const reader = new FileReader();

      // 파일 읽기가 완료되면 실행되는 콜백 함수
      reader.onload = async () => {
        // 이미지 URL을 상태에 설정하여 미리보기
        setImage(reader.result);
        onFileChange && onFileChange(selectedFile, reader);

        if (shouldGetCloudFrontUrl) {
          const BASE_URL = 'v1/mys3/image';
          const URL = getUrl(BASE_URL);
          const formData = new FormData();
          formData.append('file', selectedFile);

          const res = await axios.post(URL, formData, HEADER.MULTIPART);

          setValue(name, res.data);
        }
      };

      // 파일을 읽음
      reader.readAsDataURL(selectedFile);
    },
    [name]
  );

  return (
    <Container>
      {image && <ImgPreview src={image} />}
      {!image && (
        <TextContainer>
          <Text
            fontSize={14}
            fontWeight={400}
            color={Themes.light.gray07}
            inner='소개 이미지는 JPG, GIF, PNG 포맷으로'
          />
          <Text
            fontSize={14}
            fontWeight={400}
            color={Themes.light.gray07}
            inner='이미지 크기는 최대 0000*0000 px 이내여야 합니다.'
          />
        </TextContainer>
      )}

      {/* eslint-disable-next-line */}
      <Label htmlFor={`image-fileInput-${name}-${uuid}`}>
        <UploadBtn>
          <Text fontSize={12} fontWeight={600} color={Themes.light.gray08} inner='이미지 업로드' />
        </UploadBtn>
        <FileInput
          id={`image-fileInput-${name}-${uuid}`}
          classes={{ Variant: InputDisplay }}
          onChange={handleImagePreview}
          ref={imageFileRef}
          name={name}
          required={required}
          accept='.jpg, .jpeg, .png'
        />
      </Label>
    </Container>
  );
};

ImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  onFileChange: PropTypes.func.isRequired,
  shouldGetCloudFrontUrl: PropTypes.bool.isRequired,
};

export default ImageUpload;
