import Axios from '@Axios';
import { HEADER, METHODS } from '@AxiosConfig';
import { CONFIG } from '@modules/FTA/data';
import { setProgramList, setProgramPaging, setProgramSchduleCalendar } from '@redux/feature/slices/programSlice';
import { omit } from '@utils';

const BASE_URL = 'v1/admin';

const programAPI = (dispatch) => {
  const URL = `${BASE_URL}/program/list`;

  const onSuccess = (res) => {
    console.log('%c✅ programAPI RESOPONSE >>>>>', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setProgramList(res.data.content));
    const paging = omit(res.data, ['content']);
    dispatch(setProgramPaging(paging));
  };

  const onFail = (err, fallbackMockData) => {
    console.error(URL, err);

    if (CONFIG.USE_MOCKDATA_ONFAIL && fallbackMockData) {
      dispatch(setProgramList(fallbackMockData.data.programList));
    }
  };

  Axios({ type: METHODS.GET, path: URL, params: {}, onSuccess, onFail, header: HEADER.GET });
};

const programDetailAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/program/${params.programInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ programDetailAPI RESOPONSE >>>>>', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setProgramList(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: 'get', path: URL, onSuccess, onFail, header: HEADER.GET });
};

const programScheduleAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/program/list/${params.programInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ programScheduleAPI RESOPONSE >>>>>', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setProgramList(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: 'get', path: URL, onSuccess, onFail, header: HEADER.GET });
};
// /v1/admin/program/schedule/{programSessionInfoId}

// eslint-disable-next-line
const programScheduleCalendarAPI = (dispatch, params) => {
  // const URL = `${BASE_URL}/program/schedule/${params.programSessionInfoId}`;
  const URL = `${BASE_URL}/program/schedule/1`; // FIXME: 동적 param 변환

  const onSuccess = (res) => {
    console.log('%c✅ programScheduleAPI RESOPONSE >>>>>', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setProgramSchduleCalendar(res.data.schedule));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: 'get', path: URL, onSuccess, onFail, header: HEADER.GET });
};

const programRegisterAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/program/register`;

  const onSuccess = (res) => {
    console.log('%c✅ programRegisterAPI RESOPONSE >>>>>', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    // dispatch(setProgramList(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: 'post', path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

const programDeleteApi = (dispatch, params) => {
  const URL = `${BASE_URL}/program/${params.programInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ programDeleteApi RESOPONSE >>>>>', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    programAPI(dispatch);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.DELETE, path: URL, params, onSuccess, onFail, header: HEADER.DELETE });
};

// eslint-disable-next-line import/prefer-default-export
export {
  programAPI,
  programDetailAPI,
  programScheduleAPI,
  programScheduleCalendarAPI,
  programRegisterAPI,
  programDeleteApi,
};
