import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '@components/index';
import { convertDateToString } from '@utils';
import { CloseDarkIcon, pagingFirstLeftIcon, pagingLastRightIcon, pagingLeftIcon, pagingRightIcon } from '@FTA/assets';

const CalendarHeaderContainer = styled.div`
  --vertical-gap: 10px;
`;

const CalendarHeaderWrapper = styled.div`
  margin-top: var(--vertical-gap);
  padding-bottom: var(--vertical-gap);
  position: relative;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.gray03};
`;

const CalendarHeaderTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.gray10};
`;

const CalendarCloseButton = styled(Button)`
  position: absolute;
  height: 20px;
  top: 0;
  right: 0;
`;

const CalendarDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  margin: 0 6.4px;
  padding: 20px 0;
`;

const CalendarButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const CalendarDate = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.gray08};
`;

const CalendarDateControlButton = styled(Button)`
  margin: 0;
  padding: 0;

  & > img {
    height: 18px;
  }
`;

const CalendarHeader = ({ monthDate, decreaseMonth, increaseMonth, decreaseYear, increaseYear, setOpen }) => {
  const handleCloseButtonClick = () => setOpen && setOpen(false);

  return (
    <CalendarHeaderContainer>
      <CalendarHeaderWrapper>
        <CalendarHeaderTitle>날짜 선택</CalendarHeaderTitle>
        <CalendarCloseButton
          onClick={handleCloseButtonClick}
          $layout='round'
          $variantColor='white'
          startIcon={CloseDarkIcon}
        />
      </CalendarHeaderWrapper>
      <CalendarDateWrapper>
        <CalendarButtonWrapper>
          <CalendarDateControlButton onClick={decreaseYear} startIcon={pagingFirstLeftIcon} />
          <CalendarDateControlButton onClick={decreaseMonth} startIcon={pagingLeftIcon} />
        </CalendarButtonWrapper>
        <CalendarDate>{convertDateToString(monthDate, 'ko')}</CalendarDate>
        <CalendarButtonWrapper>
          <CalendarDateControlButton onClick={increaseMonth} startIcon={pagingRightIcon} />
          <CalendarDateControlButton onClick={increaseYear} startIcon={pagingLastRightIcon} />
        </CalendarButtonWrapper>
      </CalendarDateWrapper>
    </CalendarHeaderContainer>
  );
};

CalendarHeader.propTypes = {
  /** React-datepicker 현재 날짜 */
  monthDate: PropTypes.PropTypes.instanceOf(Date),
  /** React-datepicker calendar에서 month가 내려가는 함수 */
  decreaseMonth: PropTypes.func,
  /** React-datepicker calendar에서 month가 올라가는 함수 */
  increaseMonth: PropTypes.func,
  /** React-datepicker calendar에서 year가 내려가는 함수 */
  decreaseYear: PropTypes.func,
  /** React-datepicker calendar에서 year가 올라가는 함수 */
  increaseYear: PropTypes.func,
  /** React-datepicker calendar에서 open/close를 조절하는 함수 */
  setOpen: PropTypes.func,
};

export default CalendarHeader;
