/** 기타 관리 > 공지사항 */

import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { createColumnHelper } from '@tanstack/react-table';

import { convertDateToString } from '@utils';
import { Button, Div, Table } from '@components/index';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';
import DetailButton from '@FTA/components/DetailButton';
import PATHS from '@router/paths';
import styled, { css } from 'styled-components';
import { getNoticeListAPI } from '@redux/action/etcAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';

const columnHelper = createColumnHelper();

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const FooterButtonStyle = css`
  padding: 8px 4px;
`;

// FIXME[CS] 추후에 real data로 넘어갈 때 value 값 수정
const TABLE_KEYS = Object.freeze({
  NO: 'no',
  COUNTRY: 'country',
  CATEGORY: 'category',
  NOTICE_TITLE: 'title',
  REGISTRATION_DATE: 'registDate',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, title: 'no', checked: false },
  { code: TABLE_KEYS.COUNTRY, title: '국가', checked: false },
  { code: TABLE_KEYS.NOTICE_TITLE, title: '제목', checked: false },
];

const Notice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm();
  const { searchParams, getSearchParamsObject } = useSearchParams();

  const noticeList = useSelector((state) => state.etc.noticeList);
  const noticePaging = useSelector((state) => state.etc.noticePaging);

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.NO, {
        id: TABLE_KEYS.NO,
        header: () => <span>No</span>,
        cell: ({ row: { index } }) => <span>{noticeList && noticeList?.length > 0 && noticeList.length - index}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.COUNTRY, {
        id: TABLE_KEYS.COUNTRY,
        header: () => <span>국가</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.CATEGORY, {
        id: TABLE_KEYS.CATEGORY,
        header: () => <span>카테고리</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.NOTICE_TITLE, {
        id: TABLE_KEYS.NOTICE_TITLE,
        header: () => <span>제목</span>,
        cell: ({ getValue, row: { original } }) => {
          const { noticeInfoId = '' } = original;
          return (
            <DetailButton pathname={PATHS.NOTICE_DETAIL} searchParams={{ noticeInfoId }}>
              {getValue()}
            </DetailButton>
          );
        },
      }),
      columnHelper.accessor(TABLE_KEYS.REGISTRATION_DATE, {
        id: TABLE_KEYS.REGISTRATION_DATE,
        header: () => <span>등록일</span>,
        cell: ({ getValue }) => <span>{convertDateToString(new Date(getValue()), '.')}</span>,
      }),
    ],
    [noticeList]
  );
  /* eslint-enable react/no-unstable-nested-components */

  const gotoAdd = useCallback(() => {
    navigate(`/${PATHS.NOTICE_WRITE}`);
  }, []);

  useEffect(() => {
    const param = getSearchParamsObject();
    getNoticeListAPI(dispatch, param);
  }, [searchParams]);

  if (!noticeList) return <EmptyData text='등록된 공지사항이  없습니다.' />;

  return (
    <>
      <FormProvider {...methods}>
        <SearchContainer options={TABLE_OPTIONS} hasChip hasColumnFilter hasInputSelectBox hasDateFilter />
      </FormProvider>
      <Table
        data={noticeList}
        columns={COLUMNS_DATA}
        paging={noticePaging}
        hasChip
        hasColumnFilter
        hasInputSelectBox
        buttonList={
          <ButtonContainer>
            <Button
              classes={{ Button: FooterButtonStyle }}
              $layout='square'
              $variantColor='primary'
              width={51}
              height={36}
              fontSize={14}
              onClick={gotoAdd}
            >
              등록
            </Button>
          </ButtonContainer>
        }
      />
    </>
  );
};

export default Notice;
