import PropTypes from 'prop-types';
// import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import { Divider } from '@components/index';
import { CalendarDefaultStyles, CalendarDivider } from '@modules/FTA/components/Calendar/modules/CalendarStyles';
import TableSingleDropdown from './TableSingleDropdown';
import { useController, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

const CalendarWrapper = styled.div`
  ${CalendarDefaultStyles}

  display: flex;
  column-gap: 10px;
  align-items: center;
`;

const DEFAULT_VALUE = [{ name: '', title: '', checked: false }];

const TableSingleSelectBox = ({ name, hasDropdown = true, defaultValueIndex = 0, options, labelTitle }) => {
  // NOTE[CS]
  // 만약 취소 버튼을 눌렀을 때 예전에 선택된 값으로 되돌리는 동작을 구현해야 한다면
  // previous value를 저장하도록 추가 구현이 필요함

  const { control } = useFormContext();
  const { field } = useController({
    name,
    defaultValue: { ...options[defaultValueIndex], checked: true },
    control,
  });

  useEffect(() => {
    field.onChange({ ...options[defaultValueIndex], checked: true } ?? DEFAULT_VALUE);
  }, []);

  return (
    <CalendarWrapper>
      {hasDropdown ? (
        <>
          <TableSingleDropdown field={field} options={options} labelTitle={labelTitle} />
          <Divider classes={{ Divider: CalendarDivider }} />
        </>
      ) : null}
    </CalendarWrapper>
  );
};

TableSingleSelectBox.propTypes = {
  name: PropTypes.string.isRequired,
  hasDropdown: PropTypes.bool,
  /** Index 기본 선택 값 */
  defaultValueIndex: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
  labelTitle: PropTypes.string,
  /** RangeCalendar format 형식 */

  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.shape({
    RangeCalendar: PropTypes.objectOf(PropTypes.string),
  }),
};

export default TableSingleSelectBox;
