import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Div, Img } from '@components/Atoms/Atoms';

const Layout = {
  square: css`
    width: 240px;
    height: 64px;
    top: 679px;
    left: 60px;
    padding: 20px 16px 21px 16px;
    gap: 0px;
    border-radius: 8px;
  `,
  round: css`
    width: 240px;
    height: 64px;
    top: 1578px;
    left: 60px;
    padding: 20px 0px 21px 0px;
    gap: 0px;
    border-radius: 50px;
    opacity: 0px;
  `,
};

const VariantColor = {
  primary: css`
    color: ${({ theme }) => theme.gray01};
    background-color: ${({ theme }) => theme.gray10};

    &:hover {
      background-color: ${({ theme }) => theme.gray09};
    }
    &:disabled {
      color: ${({ theme }) => theme.gray05};
      background-color: ${({ theme }) => theme.gray04};
    }
  `,
  secondary: css`
    color: ${({ theme }) => theme.gray10};
    background-color: ${({ theme }) => theme.gray02};

    &:hover {
      background-color: ${({ theme }) => theme.gray04};
    }
    &:disabled {
      color: ${({ theme }) => theme.gray05};
      background-color: ${({ theme }) => theme.gray02};
    }
  `,
  white: css`
    background-color: ${({ theme }) => theme.gray01};

    &:hover {
      background-color: ${({ theme }) => theme.gray03};
    }
    &:disabled {
      color: ${({ theme }) => theme.gray05};
      background-color: ${({ theme }) => theme.gray01};
    }
  `,
  line: css`
    border: 2px solid ${({ theme }) => theme.gray09};
    color: ${({ theme }) => theme.gray10};

    &:hover {
      border: 2px solid ${({ theme }) => theme.gray10};
      color: ${({ theme }) => theme.gray10};
      background-color: ${({ theme }) => theme.gray03};
    }
    &:disabled {
      border: 2px solid ${({ theme }) => theme.gray05};
      color: ${({ theme }) => theme.gray05};
      background-color: ${({ theme }) => theme.gray01};
    }
  `,
  green: css`
    border: 1px solid ${({ theme }) => theme.green02};
    color: ${({ theme }) => theme.green02};

    &:hover {
      border: 1px solid ${({ theme }) => theme.green02};
      color: ${({ theme }) => theme.green02};
      background-color: ${({ theme }) => theme.green01};
    }
    &:disabled {
      border: 2px solid ${({ theme }) => theme.gray05};
      color: ${({ theme }) => theme.gray05};
      background-color: ${({ theme }) => theme.gray01};
    }
  `,
  gray: css`
    color: ${({ theme }) => theme.gray08};
    background-color: ${({ theme }) => theme.gray03};

    &:hover {
      color: ${({ theme }) => theme.gray08};
      background-color: ${({ theme }) => theme.gray04};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.gray02};
      color: ${({ theme }) => theme.gray05};
    }
  `,
  darkGray: css`
    background-color: ${({ theme }) => theme.gray05};

    &:hover {
      background-color: ${({ theme }) => theme.gray06};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.gray03};
    }
  `,

  underLinePrimary: css`
    color: ${({ theme }) => theme.gray08};
    background-color: ${({ theme }) => theme.gray03};
    ${({ classes }) => classes && classes.UnderLineStyle?.NormalStyle}

    &:hover {
      color: ${({ theme }) => theme.gray08};
      background-color: ${({ theme }) => theme.gray03};
      text-decoration: underline;
      ${({ classes }) => classes && classes.UnderLineStyle?.HoverStyle}
    }
    &:disabled {
      background-color: ${({ theme }) => theme.gray03};
      color: ${({ theme }) => theme.gray05};
      text-decoration: none;
      ${({ classes }) => classes && classes.UnderLineStyle?.DisabledStyle}
    }
  `,
  underLine: css`
    color: ${({ theme }) => theme.gray08};
    text-decoration: underline;
    ${({ classes }) => classes && classes.UnderLineStyle?.NormalStyle}

    &:hover {
      color: ${({ theme }) => theme.gray10};
      text-decoration: underline;
      ${({ classes }) => classes && classes.UnderLineStyle?.HoverStyle}
    }
    &:disabled {
      color: ${({ theme }) => theme.gray05};
      ${({ classes }) => classes && classes.UnderLineStyle?.DisabledStyle}
    }
  `,
  brown: css`
    background-color: ${({ theme }) => theme.brown01};
    color: ${({ theme }) => theme.brown03};

    &:hover {
      color: ${({ theme }) => theme.brown04};
      ${({ classes }) => classes && classes.UnderLineStyle?.HoverStyle}
    }
  `,
};

const ImgVariant = {
  play: {
    layout: css`
      font-size: 14px;
      line-height: 20px;
      width: auto;
      height: auto;
      padding: 6px 14px 6px 14px;
      gap: 4px;
    `,
    size: css`
      width: 12px;
      height: 12px;
    `,
  },
  download: {
    layout: css`
      font-size: 16px;
      width: auto;
      height: auto;
      padding: 10px 12px 10px 12px;
      gap: 6px;
    `,
  },
  add: {
    layout: css`
      font-size: 12px;
      width: auto;
      height: auto;
      gap: 2px;
      padding: 3px 12px 0px 8px;
    `,
  },
  arrow: {
    layout: css`
      width: 44px;
      height: 44px;
    `,
    size: css`
      width: 6.5px;
      height: 13px;
    `,
  },
  close: {
    layout: css`
      width: 10px;
      height: 10px;
      padding: 12px;
    `,
    size: css`
      width: 10px;
      height: 10px;
    `,
  },
  plus: {
    layout: css`
      width: 10px;
      height: 10px;
      padding: 12px;
    `,
    size: css`
      width: 15px;
      height: 15px;
    `,
  },
};

const getWidth = ({ width }) => width && `width : ${width}px;`;
const getHeight = ({ height }) => height && `height: ${height}px;`;
const getFontColor = ({ color }) => color && `color : ${color};`;
const getSize = ({ fontSize }) => fontSize && `font-size : ${fontSize}px;`;
const getBorder = ({ border }) => border && `border:${border};`;
const getBackgroundColor = ({ backGroundColor }) => backGroundColor && `background-color:${backGroundColor};`;
const isBold = ({ isbold }) => isbold && `font-weight: bold;`;
const getOpacity = ({ opacity }) => opacity && `opacity:${opacity};`;
const getPadding = ({ padding }) => padding && `padding:${padding}`;
const getMargin = ({ margin }) => margin && `margin:${margin}`;
// 오버라이딩을 위한 classes
const getLayout = ({ $layout }) => Layout[$layout];
const getVariantColor = ({ $variantColor }) => VariantColor[$variantColor || 'underLine'];
const getImgSize = ({ $imgVariant }) => $imgVariant && ImgVariant[$imgVariant].size;
const getImgLayout = ({ $imgVariant }) => $imgVariant && ImgVariant[$imgVariant].layout;

const getClasses = ({ classes }) => classes && classes.Button;

const styles = css`
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  pointer-events: initial;
  max-width: 100%;
  text-decoration: none;
  padding: 0 10px;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  margin: 0 2px;
  color: ${({ theme }) => theme.gray01};
  font-size: 20px;
  font-weight: 700;
  line-height: 23.4px;
  text-align: center;
  ${getLayout}
  ${getVariantColor}
  ${getImgLayout}
  ${getWidth}
  ${getHeight}
  ${getBorder}
  ${isBold}
  ${getBackgroundColor}
  ${getFontColor}
  ${getSize}
  ${getMargin}
  ${getPadding}
  ${getClasses}
  ${({ disabled }) => disabled && `cursor:default`}
`;

const StartIconContainer = styled(Img)`
  display: inline-flex;
  min-width: 19px;
  height: 14px;
  ${getImgSize}
  ${({ disabled }) => disabled && `opacity:0.2`}
  ${getOpacity}
`;

const EndIconContainer = styled(Img)`
  display: inline-flex;
  min-width: 19px;
  height: 14px;
  ${getImgSize}
  ${({ disabled }) => disabled && `opacity:0.2`}
  ${getOpacity}
`;

const LabelContainer = styled(Div)`
  display: inline-block;
  width: 100%;
`;

const StyledButton = styled.button`
  ${styles}
`;
const Button = ({ startIcon, endIcon, $variantColor, opacity, disabled, children, ...rest }) => {
  const commonProps = {
    $variantColor,
    opacity,
    disabled,
  };

  return (
    <StyledButton {...commonProps} {...rest}>
      {startIcon && <StartIconContainer {...commonProps} src={startIcon} alt='startIcon' />}
      {children && <LabelContainer>{children}</LabelContainer>}
      {endIcon && <EndIconContainer {...commonProps} src={endIcon} alt='endIcon' />}
    </StyledButton>
  );
};

Button.propTypes = {
  /** 버튼 좌측 icon 이미지 경로 */
  startIcon: PropTypes.string,
  /** 버튼 우측 icon 이미지 경로 */
  endIcon: PropTypes.string,
  /** 버튼 색(폰트,배경) 타입 */
  $variantColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'white',
    'line',
    'green',
    'gray',
    'darkGray',
    'underLinePrimary',
    'underLine',
    'brown',
  ]),
  /** 이미지 opacity */
  opacity: PropTypes.string,
  /** 버튼 비활성화 여부 */
  disabled: PropTypes.bool,
  /** 버튼 명 */
  children: PropTypes.node,
  /** 버튼 커스텀 스타일 오브젝트 */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** 버튼 레이아웃 타입 */
  $layout: PropTypes.oneOf(['square', 'round']),
  /** 이미지 $layout, size */
  $imgVariant: PropTypes.oneOf(['play', 'download', 'add', 'arrow', 'close', 'plus']),
};

export default Button;
