import { createSlice } from '@reduxjs/toolkit';
import ArrayToObjectByKey from '@lib/ArrayToObjectByKey';

const initialState = {
  list: [],
  objectMap: {},
  detail: {},
  paging: {},
};

const mapper = new ArrayToObjectByKey([]);

/* eslint-disable no-param-reassign */
const cp = createSlice({
  name: 'cp',
  initialState,
  reducers: {
    setCpList: (state, { payload }) => {
      state.list = payload;
      console.log('###payload', payload);
      mapper.array = payload;
      state.objectMap = mapper.mapByKey('cpManagementId');
      state.detail = Object.values(payload).flat();
    },
    setCpDetail: (state, { payload }) => {
      const temp = { ...payload }; // FIXME: temp 변수로 임시처리
      temp.cpManageDetailList[0].features = temp.cpManageDetailList[0].features[0].split(',');
      console.log('###', temp);
      state.detail = temp;
    },
    setCpPaging: (state, { payload }) => {
      state.paging = payload;
    },
  },
});

/* eslint-enable no-param-reassign */

export const { setCpList, setCpDetail, setCpPaging } = cp.actions;
export default cp.reducer;
