import { useEffect, useMemo, useRef, useState } from 'react';

import ApexCharts from 'react-apexcharts';
import styled, { css, useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { Button, Div } from '@components/index';
import ControlledCheckbox from './modules/ControlledCheckbox';
import LineChartBottomLegend from './modules/LineChartBottomLegend';

import { ExportButtonIcon } from '@FTA/assets/index';
import { adjustNumber } from '@utils';

const Container = styled(Div)`
  --padding: 32px;

  position: relative;
  padding: var(--padding);

  ${({ width }) => `width: ${width};`};
  ${({ height }) => `height: ${height};`};

  // NOTE: 기본 툴팁 스타일 제거
  .apexcharts-tooltip,
  .apexcharts-xaxistooltip {
    display: none;
  }
`;

const LegendContainer = styled(Div)`
  position: absolute;
  top: var(--padding);
  right: var(--padding);
  z-index: 99;
  display: flex;
  column-gap: 28px;
`;

const ExportButton = styled(Button)`
  text-decoration: none;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  padding: 10px 12px;
  border-radius: 8px;

  ${({ theme }) => `color: ${theme.gray08};`};
  ${({ theme }) => `background-color: ${theme.gray03};`};

  :hover {
    text-decoration: none;
  }
`;

const ExportButtonCss = css`
  > img {
    margin-right: 6px;
  }
`;

const ReactApexChart = styled(ApexCharts)`
  position: relative;
`;

const CustomTooltipContainer = styled(Div)`
  --tooltip-width: 46px;
  --tooltip-height: 30px;

  position: absolute;
  -webkit-user-select: none;
  user-select: none;
  width: var(--tooltip-width);
  height: var(--tooltip-height);
  border-radius: 6px;
  padding: 8px 10px;
  background-color: ${({ theme }) => theme.gray09};

  ${({ customTooltip: { top, left }, markersSize }) =>
    `
    top: calc(${top}px - var(--tooltip-height) - 10px);
    left: calc(${left}px - (var(--tooltip-width) / 2) + ${(markersSize.size + markersSize.strokeWidth) / 2}px);
  `};
`;

const TooltipText = styled(Div)`
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.gray01};
`;

const CustomTooltipTail = styled(Div)`
  --side-length: 6px;

  position: absolute;
  width: var(--side-length);
  aspect-ratio: 1;
  top: calc(var(--tooltip-height) - (var(--side-length) / 2));
  left: calc((var(--tooltip-width) - var(--side-length)) / 2);
  background-color: ${({ theme }) => theme.gray09};
  transform: rotate(45deg);
`;

const BottomLegendContainer = styled(Div)`
  display: flex;
  justify-content: center;
  column-gap: 16px;
`;

const LineChart = ({
  title: text,
  width = '100%',
  height = '408px',
  series = [],
  showLegend = { top: true, bottom: true },
  showTooltip = true,
  markersSize = { size: 5, strokeWidth: 2 },
  colors,
}) => {
  const theme = useTheme();
  const [seriesChecked, setSeriesChecked] = useState(Array.from({ length: series.length }, () => true));
  const [containerCoords, setContainerCoords] = useState({ top: 0, left: 0 });
  const [customTooltip, setCustomTooltip] = useState({ visible: false, top: 0, left: 0, value: '' });
  const containerRef = useRef(null);
  const chartRef = useRef(null);
  const COLORS = colors?.length ? colors : [theme.graphy01, theme.graphy02, theme.graphy03];
  const maxData = useMemo(() => Math.max(...series.map((elem) => Math.max(...elem.data))), [series]);

  const handleLegendCheckChange = ({ name, index }) => {
    const updatedCheckedState = seriesChecked.map((item, i) => (i === index ? !item : item));
    setSeriesChecked(updatedCheckedState);

    if (chartRef.current) {
      const chartInstance = chartRef.current.chart;
      chartInstance.toggleSeries(name);
    }
  };

  const handleExportButtonClick = () => {
    alert('have to development for button logic');
  };

  const handleMouseOver = (event, { w }, { seriesIndex, dataPointIndex }) => {
    const {
      globals: {
        dom: { baseEl },
        series: targetSeries,
      },
    } = w;

    if (seriesIndex === -1 || dataPointIndex === -1 || !containerCoords.top || !containerCoords.left) return;

    const currentSeries = baseEl.querySelector(`.apexcharts-line-series >.apexcharts-series[zIndex="${seriesIndex}"]`);
    const currentMaker = currentSeries.querySelector(`.apexcharts-marker[j="${dataPointIndex}"]`);
    const { top: containerTop, left: containerLeft } = containerCoords;
    const { top: markerTop, left: markerLeft } = currentMaker.getBoundingClientRect();

    setCustomTooltip({
      visible: true,
      top: markerTop - containerTop,
      left: markerLeft - containerLeft,
      value: targetSeries[seriesIndex][dataPointIndex],
    });
  };

  const handleMouseLeave = () => {
    setCustomTooltip({ visible: false, top: 0, left: 0, value: '' });
  };

  useEffect(() => {
    if (containerRef.current) {
      const { top, left } = containerRef.current.getBoundingClientRect();
      setContainerCoords({ top, left });
    }
  }, []);

  return (
    <Container width={width} height={height} ref={containerRef} onMouseLeave={handleMouseLeave}>
      <LegendContainer>
        {showLegend.top
          ? series.map((elem, index) => (
              <ControlledCheckbox
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                checked={seriesChecked[index]}
                onChange={() => handleLegendCheckChange({ name: elem.name, index })}
                id={elem.name}
                label={elem.name}
              />
            ))
          : null}
        <ExportButton
          startIcon={ExportButtonIcon}
          classes={{ Button: ExportButtonCss }}
          onClick={handleExportButtonClick}
        >
          내보내기
        </ExportButton>
      </LegendContainer>
      <ReactApexChart
        ref={chartRef}
        width='100%'
        height='100%'
        series={series}
        options={{
          title: {
            text,
            align: 'left',
            style: {
              fontSize: '18px',
            },
          },
          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            events: {
              mouseMove: handleMouseOver,
              mouseLeave: handleMouseLeave,
            },
          },
          colors: COLORS,
          stroke: {
            curve: 'smooth',
          },
          grid: {
            borderColor: '#e7e7e7',
          },
          markers: {
            size: markersSize.size,
            colors: '#fff',
            strokeWidth: markersSize.strokeWidth,
            strokeColors: COLORS,
            hover: {
              size: markersSize.size,
            },
          },
          xaxis: {
            categories: [], // NOTE: x축 라벨 추후에는 동적으로 받아야 함
          },
          yaxis: {
            min: 0,
            // NOTE: max를 지정하지 않으면 legend toggle시 y축이 변경되는 문제가 있어서 max를 지정해줌
            max: adjustNumber({ number: maxData, N: maxData.toString().length - 1, type: 'ceil' }),
          },
          legend: {
            show: false,
          },
          tooltip: {
            // NOTE: false로 하면 mouseMove event에서 dataPointIndex, seriesIndex을 받을 수 없음
            enabled: showTooltip,
          },
        }}
      />
      {showTooltip && customTooltip.visible ? (
        <CustomTooltipContainer customTooltip={customTooltip} markersSize={markersSize}>
          <TooltipText>{customTooltip.value}</TooltipText>
          <CustomTooltipTail />
        </CustomTooltipContainer>
      ) : null}
      {showLegend.bottom ? (
        <BottomLegendContainer>
          {series.map((elem, index) => (
            <LineChartBottomLegend
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              checked={seriesChecked[index]}
              onChange={() => handleLegendCheckChange({ name: elem.name, index })}
              id={elem.name}
              label={elem.name}
              color={COLORS[index % COLORS.length]}
            />
          ))}
        </BottomLegendContainer>
      ) : null}
    </Container>
  );
};

LineChart.propTypes = {
  /** 차트 위에 들어가는 타이틀 string */
  title: PropTypes.string.isRequired,
  /** 차트 width, 기본 값은 100% */
  width: PropTypes.string,
  /** 차트 width, 기본 값은 408px (figma 기준) */
  height: PropTypes.string,
  /** Apex Chart에서 지원하는 series 배열, 그래프에 표현하고 싶은 데이터들의 집합 */
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
    })
  ),
  /** Custom chart legend의 노출 여부를 결정, top은 우측 상단에 체크박스와 같이 표시되며 bottom은 그래프 하단 중앙에 표시 됨 */
  showLegend: PropTypes.shape({
    top: PropTypes.bool,
    bottom: PropTypes.bool,
  }),
  /** Custom chart tooltip의 노출 여부를 결정 */
  showTooltip: PropTypes.bool,
  /** Marker의 지름 */
  markersSize: PropTypes.shape({
    size: PropTypes.number,
    strokeWidth: PropTypes.number,
  }),
  /** Line의 색상들 */
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default LineChart;
