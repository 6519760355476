/** CP 관리 > CP 정보 > 컨텐츠 클릭(상세 화면) */
import { useCallback, useContext, useEffect } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CpDeleteAPI, getCpManagementDetailAPI, HandleCPStatusAPI } from '@redux/action/cpAction';

import styled, { css } from 'styled-components';

import { Button, Div, Modal, ModalContext } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';

import CPDefaultDetail from './modules/detail/CPDefaultDetail';
import CPMainCategoryInfoDetail from './modules/detail/CPMainCategoryInfoDetail';
import useContentMode from '@modules/FTA/hooks/useContentMode';

import PATHS from '@router/paths';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const CPInfoDetail = () => {
  const dispatch = useDispatch();
  const currentAccountInfo = useSelector((state) => state.auth.loggedUserInfo);

  const navigate = useNavigate();
  const { targetId: cpManagementId } = useContentMode('cpManagementId');

  const { showModal, closeModal } = useContext(ModalContext);

  const onClickEdit = useCallback(() => {
    const search = createSearchParams({ cpManagementId }).toString();
    navigate({ pathname: `/${PATHS.CP_INFO_EDIT}`, search });
  }, []);

  const onClickDelete = useCallback(() => {
    showModal(
      <Modal
        body='등록된 정보를 삭제하시겠습니까? CP 정보를 삭제하게 되면 해당 CP는 탈퇴 처리됩니다.'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                CpDeleteAPI(dispatch, cpManagementId);
                navigate(`/${PATHS.CP_INFO}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const onClickApprovalRequset = useCallback(() => {
    showModal(
      <Modal
        body='등록한 정보를 승인 요청하시겠습니까? 승인 심사 중에는 정보를 수정할 수 없습니다.'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                HandleCPStatusAPI(cpManagementId, 'reqApproval');
                navigate(`/${PATHS.CP_INFO}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const onClickApproval = useCallback(() => {
    showModal(
      <Modal
        body='승인하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                HandleCPStatusAPI(cpManagementId, 'approval');
                navigate(`/${PATHS.CP_INFO}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const onClickReject = useCallback(() => {
    showModal(
      <Modal
        body='반려하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                HandleCPStatusAPI(cpManagementId, 'refusal');
                navigate(`/${PATHS.CP_INFO}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const gotoList = useCallback(() => {
    navigate(`/${PATHS.CP_INFO}`);
  }, []);

  useEffect(() => {
    getCpManagementDetailAPI(dispatch, cpManagementId);
  }, []);

  return (
    <Container>
      <PageTitle title='CP 정보' />
      <CPDefaultDetail />
      <CPMainCategoryInfoDetail />
      <ButtonSection>
        {currentAccountInfo.approvalAuthority ? (
          <>
            <Button onClick={onClickApproval} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
              승인
            </Button>
            <Button onClick={onClickReject} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              반려
            </Button>
          </>
        ) : (
          <Button
            onClick={onClickApprovalRequset}
            $layout='square'
            $variantColor='primary'
            classes={{ Button: { ...ButtonCss, width: '100px' } }}
          >
            승인 요청
          </Button>
        )}
        <Button onClick={onClickEdit} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
          수정
        </Button>
        <Button onClick={onClickDelete} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
          삭제
        </Button>
        <Button onClick={gotoList} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
          목록
        </Button>
      </ButtonSection>
    </Container>
  );
};

CPInfoDetail.propTypes = {};

export default CPInfoDetail;
