import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`    
  ${reset}
  #root {
    font-family : "LG-smart-UI"
  }

  * {
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
