import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';
import { programAPI } from '@redux/action/programAction';

const useExternalCenterProgramManagement = () => {
  const dispatch = useDispatch();
  const { searchParams, getSearchParamsObject } = useSearchParams();
  const programList = useSelector((state) => state.program.programList);
  const programPaging = useSelector((state) => state.program.programPaging);
  const { convertEnum } = useEnumSearch();

  useEffect(() => {
    const param = getSearchParamsObject();
    programAPI(dispatch, param);
  }, [searchParams]);

  return {
    programList,
    programPaging,
    convertEnum,
  };
};

export default useExternalCenterProgramManagement;
