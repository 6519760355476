import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@components/Atoms/Icon';

const getStyles = ({ classes }) => classes && classes.IconButton;
const width = ({ w }) => `${w || 32}px`;
const height = ({ h }) => `${h || 32}px`;
const getBorderRadius = ({ borderRadius }) => `${borderRadius || 100}%`;
const overflow = ({ overflowHiddenYn }) => (overflowHiddenYn ? 'overflow: hidden;' : '');

const IconContainer = styled.div`
  ${overflow}
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  padding: 0px;
  ${getBorderRadius}
  border: 0;
  width: ${width};
  height: ${height};
  ${getStyles}
`;

const IconButton = forwardRef((props, ref) => {
  <IconContainer {...props} role='button' tabIndex={0} aria-label={props.label}>
    <Icon {...props} ref={ref} />
  </IconContainer>;
});

IconButton.propTypes = {
  /** Icon image */
  icon: PropTypes.string,
  /** 이미지 아이콘 width */
  w: PropTypes.number,
  /** 이미지 아이콘 height */
  h: PropTypes.number,
  /** 버튼 커스텀 스타일 오브젝트 */
  disabled: PropTypes.bool,
  /** IconButton 스타일 */
  classes: PropTypes.objectOf,
  /** IconButton 접근성 텍스트 */
  label: PropTypes.string,
  /** IconButton overflow 유무 */
  overflowHiddenYn: PropTypes.bool,
};

IconButton.defaultProps = {
  w: 32,
  h: 32,
  disabled: false,
  overflowHiddenYn: true,
};

export default IconButton;
