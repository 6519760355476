import PropTypes from 'prop-types';
import { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styled, { css } from 'styled-components';

import { Button, Div, Table, ModalContext } from '@components/index';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';

const Container = styled(Div)`
  padding: 20px;
`;

const EmptyContainer = styled(Div)`
  margin: 10px;
`;

const FooterButtonStyle = css`
  padding: 8px 4px;
`;

const TableHeader = styled(Div)`
  display: flex;
  justify-content: space-between;
`;

const TableTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 60px;
`;

const SubOptionContainer = styled(Div)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.gray08};
  border-top: 1px solid ${({ theme }) => theme.gray03};
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const ContentChoosePopUp = ({ closeModal, popupElements }) => {
  const searchForm = useForm();

  const { handleContentTitle, valueKey, popUpTitle, popupData, popupColumns, emptyText } = popupElements;

  const { ModalValue } = useContext(ModalContext);

  const onClickConfirm = useCallback(() => {
    closeModal();
    // // Todo: 키값 컨텐츠 선택[idx]로 변경해야함 => 추가버튼때문
    // // idx 어떻게 받아오는가?
    handleContentTitle(valueKey || '컨텐츠 선택', ModalValue);
  }, [ModalValue]);

  if (popupData.length === 0)
    return (
      <EmptyContainer>
        <TableHeader>
          <TableTitle>{popUpTitle}</TableTitle>
        </TableHeader>
        <SubOptionContainer>
          <EmptyData text={emptyText || '등록된 컨텐츠가 없습니다.'} />
        </SubOptionContainer>
        <ButtonContainer>
          <Button $layout='square' $variantColor='primary' onClick={closeModal} width={160} height={48} fontSize={16}>
            확인
          </Button>
        </ButtonContainer>
      </EmptyContainer>
    );

  return (
    <FormProvider {...searchForm}>
      <Container>
        <SearchContainer
          title={popUpTitle || '컨텐츠 선택'}
          hasChip={false}
          hasColumnFilter
          hasInputSelectBox={false}
          hasDateFilter={false}
        />
        <Table
          data={popupData}
          columns={popupColumns}
          buttonList={
            <Button
              classes={{ Button: FooterButtonStyle }}
              $layout='square'
              $variantColor='primary'
              width={51}
              height={36}
              fontSize={14}
              onClick={onClickConfirm}
            >
              확인
            </Button>
          }
        />
      </Container>
    </FormProvider>
  );
};
ContentChoosePopUp.propTypes = {
  /** 모달을 닫는 데 사용되는 함수 */
  closeModal: PropTypes.func.isRequired,
  popupElements: PropTypes.shape({
    /** 팝업 상단 타이틀 */
    popUpTitle: PropTypes.string,
    /** 팝업에서 getValues 할 key(hook form - name) */
    valueKey: PropTypes.string,
    /** SetValue 하는 함수 */
    handleContentTitle: PropTypes.func.isRequired,
    /** Table에서 사용되는 데이터 객체 */
    popupData: PropTypes.array.isRequired,
    /** Table에서 사용되는 column 객체 */
    popupColumns: PropTypes.array.isRequired,
    /** RadioButton 클릭을 통해 선택된 value */
    selectedValue: PropTypes.string.isRequired,
    /** 팝업에 표시할 table Data가 없을때 나타나는 문구 */
    emptyText: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContentChoosePopUp;
