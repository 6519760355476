import { useCallback, useContext, useEffect, useMemo } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getAccountDetailAPI, HandleAccountStatusAPI } from '@redux/action/accountsAction';
import styled, { css } from 'styled-components';

import { Button, Div, Img, Modal, ModalContext, Text } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';

import PATHS from '@router/paths';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const ExtendedText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.gray08};
`;

const ExtendedImg = styled(Img)`
  width: 150px;
  height: 150px;
  border-radius: 8px;
`;

const ACCOUNT_STATUS = Object.freeze({
  approval: '승인',
  refusal: '반려',
  reqApproval: '승인요청',
  delete: '삭제',
});

const SEARCH_PARAMS_KEY = 'adminInfoId';

const AccountDetail = () => {
  const dispatch = useDispatch();
  const accountDetail = useSelector((state) => state.accounts.accountDetail);
  const currentAccountInfo = useSelector((state) => state.auth.loggedUserInfo);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const adminInfoId = searchParams.get(SEARCH_PARAMS_KEY);

  const { showModal, closeModal } = useContext(ModalContext);

  const accountGridData = useMemo(
    () => [
      {
        title: 'ID',
        content: [<ExtendedText>{accountDetail?.adminId}</ExtendedText>],
      },
      {
        title: '이름',
        content: [<ExtendedText>{accountDetail?.adminName}</ExtendedText>],
      },
      {
        title: '닉네임',
        content: [<ExtendedText>{accountDetail?.adminNickname}</ExtendedText>],
      },
      {
        title: '프로필 이미지',
        content: [<ExtendedImg src={accountDetail?.adminImageFile?.fileUrl} />],
      },
      {
        title: '국적',
        content: [<ExtendedText>{accountDetail?.adminCountry}</ExtendedText>],
      },
      {
        title: '관리자 등급',
        content: [<ExtendedText>{accountDetail?.className}</ExtendedText>],
      },
    ],
    [accountDetail]
  );

  const gotoList = useCallback(() => {
    navigate(`/${PATHS.ACCOUNT}`);
  }, []);

  const handleAccountStatusChangeClick = (status) => {
    const onOkClick = () => {
      HandleAccountStatusAPI({ adminInfoId }, status);
      closeModal();
      gotoList();
    };

    const currentStatus = ACCOUNT_STATUS[status];

    showModal(
      <Modal
        body={`${currentStatus} 하시겠습니까?`}
        btns={
          <ButtonSection>
            <Button onClick={onOkClick} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  };

  const handleEditClick = useCallback(() => {
    const search = createSearchParams({ adminInfoId }).toString();
    navigate({ pathname: `/${PATHS.ACCOUNT_EDIT}`, search });
  }, []);

  useEffect(() => {
    getAccountDetailAPI(dispatch, { adminInfoId });
  }, []);

  return (
    <Container>
      <PageTitle title='관리자 계정' />
      <TitleWithDivider title='기본 정보' />
      <GridLayout data={accountGridData} hasBottomBorderLine />
      <ButtonSection>
        {currentAccountInfo.approvalAuthority ? (
          <>
            <Button
              onClick={() => handleAccountStatusChangeClick('approval')}
              $layout='square'
              $variantColor='line'
              classes={{ Button: ButtonCss }}
            >
              승인
            </Button>
            <Button
              onClick={() => handleAccountStatusChangeClick('refusal')}
              $layout='square'
              $variantColor='line'
              classes={{ Button: ButtonCss }}
            >
              반려
            </Button>
          </>
        ) : (
          <Button
            onClick={() => handleAccountStatusChangeClick('reqApproval')}
            $layout='square'
            $variantColor='primary'
            classes={{ Button: { ...ButtonCss, width: '95px' } }}
          >
            승인요청
          </Button>
        )}
        {accountDetail.approvalStatus === 'AAS000' ? null : (
          <Button onClick={handleEditClick} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
            수정
          </Button>
        )}
        <Button
          onClick={() => handleAccountStatusChangeClick('delete')}
          $layout='square'
          $variantColor='line'
          classes={{ Button: ButtonCss }}
        >
          삭제
        </Button>
        <Button onClick={gotoList} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
          목록
        </Button>
      </ButtonSection>
    </Container>
  );
};

export default AccountDetail;
