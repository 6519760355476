import { createSlice } from '@reduxjs/toolkit';
import { omit } from '@utils';

const initialState = {
  accountsList: [],
  accountDetail: {},
  accountsPaging: {},
  accountsRoleList: [],
  accountRoleDetail: {},
  accountsRolePaging: {},
};

/* eslint-disable no-param-reassign */
const accounts = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccountsList: (state, { payload }) => {
      state.accountsList = payload;
    },
    setAccountDetail: (state, { payload }) => {
      state.accountDetail = payload;
    },
    setAccountsPaging: (state, { payload }) => {
      state.accountsPaging = omit(payload, ['content']);
    },
    setAccountsRoleList: (state, { payload }) => {
      state.accountsRoleList = payload;
    },
    setAccountRoleDetail: (state, { payload }) => {
      state.accountRoleDetail = payload;
    },
    setAccountsRolePaging: (state, { payload }) => {
      state.accountsRolePaging = omit(payload, ['content']);
    },
  },
});

/* eslint-enable no-param-reassign */

export const {
  setAccountsList,
  setAccountDetail,
  setAccountsPaging,
  setAccountsRoleList,
  setAccountRoleDetail,
  setAccountsRolePaging,
} = accounts.actions;
export default accounts.reducer;
