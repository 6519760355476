/** CP 관리 > CP 정보 */
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styled, { css } from 'styled-components';
import { createColumnHelper } from '@tanstack/react-table';

import { Button, Div, Table } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';
import DetailButton from '@FTA/components/DetailButton';
import ApprovalStatusChip from '@FTA/components/ApprovalStatusChip';

import PATHS from '@router/paths';
import { convertDateToString } from '@utils';
import useCPInfo from '@modules/FTA/hooks/cpManagement/useCPInfo';

const columnHelper = createColumnHelper();

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: end;
  margin-top: 30px;
`;

const FooterButtonStyle = css`
  padding: 8px 4px;
`;

const TABLE_KEYS = Object.freeze({
  NO: 'no',
  CP_NAME: 'cpName',
  MAIN_CATEGORY: 'mainCategory',
  SUB_CATEGORY: 'subCategory',
  EXPOSE_COUNT: 'exposeCount',
  CONTENTS_COUNT: 'contentsCount',
  // IS_FREE: 'isFree', // FIXME: 기획과 디자인에는 있지만 API에서는 없음
  PRICE_COUNT: 'priceCount',
  // RATING: 'rating', // FIXME: 기획과 디자인에는 있지만 API에서는 없음 => 평점은 구독자 수 대비 좋아요를 누른 수로 집계되지만, 구독 정보가 없어 이 값 또한 제공하기 어려운 상황입니다.
  APPROVAL_STATUS_TYPE: 'approvalStatusType',
  REGIST_DATE: 'registDate',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, title: 'no', checked: false },
  { code: TABLE_KEYS.CP_NAME, title: 'CP명', checked: false },
];

const CPInfo = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const { cpList, cpPaging, convertEnum } = useCPInfo();

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.NO, {
        id: TABLE_KEYS.NO,
        header: () => <span>번호</span>,
        cell: ({ row: { index } }) => <span>{cpList && cpList.length - index}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.CP_NAME, {
        id: TABLE_KEYS.CP_NAME,
        header: () => <span>CP명</span>,
        cell: ({ getValue, row: { original } }) => {
          const { cpManagementId = '' } = original;
          return (
            <DetailButton pathname={PATHS.CP_INFO_DETAIL} searchParams={{ cpManagementId }}>
              {getValue()}
            </DetailButton>
          );
        },
      }),
      columnHelper.accessor(TABLE_KEYS.MAIN_CATEGORY, {
        id: TABLE_KEYS.MAIN_CATEGORY,
        header: () => <span>컨텐츠 분류 1</span>,
        cell: ({ getValue }) => <span>{convertEnum(getValue())}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.SUB_CATEGORY, {
        id: TABLE_KEYS.SUB_CATEGORY,
        header: () => <span>컨텐츠 분류 2</span>,
        cell: ({ getValue }) => <span>{convertEnum(getValue()?.[0])}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.EXPOSE_COUNT, {
        id: TABLE_KEYS.EXPOSE_COUNT,
        header: () => <span>노출 프로그램 수</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.CONTENTS_COUNT, {
        id: TABLE_KEYS.CONTENTS_COUNT,
        header: () => <span>컨텐츠 수</span>,
        cell: ({ row: { original } }) => {
          const { motionContentsInfoCount = 0, outdoorContentsInfoCount = 0 } = original;
          return <span>{motionContentsInfoCount + outdoorContentsInfoCount}</span>;
        },
      }),
      // columnHelper.accessor(TABLE_KEYS.IS_FREE, {
      //   id: TABLE_KEYS.IS_FREE,
      //   header: () => <span>유/무료</span>,
      //   cell: ({ getValue }) => <span>{getValue()}</span>,
      // }),
      columnHelper.accessor(TABLE_KEYS.PRICE_COUNT, {
        id: TABLE_KEYS.PRICE_COUNT,
        header: () => <span>구독자 수</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      // columnHelper.accessor(TABLE_KEYS.RATING, {
      //   id: TABLE_KEYS.RATING,
      //   header: () => <span>평점</span>,
      //   cell: ({ getValue }) => <span>{getValue()}</span>,
      // }),
      columnHelper.accessor(TABLE_KEYS.APPROVAL_STATUS_TYPE, {
        id: TABLE_KEYS.APPROVAL_STATUS_TYPE,
        header: () => <span>승인상태</span>,
        cell: ({ getValue }) => <ApprovalStatusChip code={getValue()} />,
      }),
      columnHelper.accessor(TABLE_KEYS.REGIST_DATE, {
        id: TABLE_KEYS.REGIST_DATE,
        header: () => <span>등록일</span>,
        cell: ({ getValue }) => <span>{convertDateToString(new Date(getValue()), '.')}</span>,
      }),
    ],
    [cpList]
  );
  /* eslint-enable react/no-unstable-nested-components */

  return (
    <>
      <PageTitle title='CP 정보' />
      {cpList?.length > 0 ? (
        <>
          <FormProvider {...methods}>
            <SearchContainer options={TABLE_OPTIONS} hasChip hasColumnFilter hasInputSelectBox hasDateFilter />
          </FormProvider>
          <Table data={cpList} columns={COLUMNS_DATA} paging={cpPaging} hasChip hasColumnFilter hasInputSelectBox />
        </>
      ) : (
        <EmptyData text='등록된 컨텐츠가 없습니다.' />
      )}
      <ButtonContainer>
        <Button
          classes={{ Button: FooterButtonStyle }}
          $layout='square'
          $variantColor='primary'
          width={51}
          height={36}
          fontSize={14}
          onClick={() => {
            navigate({ pathname: `/${PATHS.CP_INFO_WRITE}` });
          }}
        >
          등록
        </Button>
      </ButtonContainer>
    </>
  );
};

CPInfo.propTypes = {};

export default CPInfo;
