import styled, { css } from 'styled-components';
import { Button, Div, Input, Modal } from '@components/index';
import propTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const ModalRequst = ({ type, text, navi, requestAPI, closeModal }) => {
  const modalForm = useForm();
  return type === 'reject' ? (
    <Modal
      body={
        <FormProvider {...modalForm}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div>반려하시겠습니까?</div>
            <div style={{ display: 'flex' }}>
              <Input
                style={{ height: '200px', marginTop: '20px' }}
                name='reason'
                type='text'
                placeholder='반려사유를 적어주세요.'
                maxLength='1000'
              />
            </div>
          </div>
        </FormProvider>
      }
      btns={
        <ButtonSection>
          <Button
            onClick={() => {
              navi && navi();
              requestAPI && requestAPI();
              closeModal && closeModal();
            }}
            $layout='square'
            $variantColor='primary'
            classes={{ Button: ButtonCss }}
          >
            확인
          </Button>
          <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            취소
          </Button>
        </ButtonSection>
      }
    />
  ) : (
    <Modal
      body={text}
      btns={
        <ButtonSection>
          <Button
            onClick={() => {
              navi();
              requestAPI();
              closeModal();
            }}
            $layout='square'
            $variantColor='primary'
            classes={{ Button: ButtonCss }}
          >
            확인
          </Button>
          <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            취소
          </Button>
        </ButtonSection>
      }
    />
  );
};

ModalRequst.propTypes = {
  type: propTypes.string,
  text: propTypes.string,
  navi: propTypes.any,
  requestAPI: propTypes.any,
  closeModal: propTypes.any,
};

export default ModalRequst;
