import { createSearchParams, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '@components/index';

const TableButton = styled(Button)`
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
`;

const DetailButton = ({ children, pathname, searchParams, ...rest }) => {
  const navigate = useNavigate();

  const handleContentClick = () => {
    if (pathname === undefined || Object.keys(searchParams).length === 0) return;

    const search = createSearchParams(searchParams).toString();
    navigate({ pathname: `/${pathname}`, search });
  };

  return (
    <TableButton onClick={handleContentClick} {...rest}>
      {children}
    </TableButton>
  );
};

DetailButton.propTypes = {
  /** Button 안에 들어올 자식 컴포넌트 */
  children: PropTypes.node.isRequired,
  /** Button OnClick callback에서 navigate될 URL pathname */
  pathname: PropTypes.string.isRequired,
  /** Button OnClick callback에서 navigate될 searchParams */
  searchParams: PropTypes.shape({
    adminInfoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default DetailButton;
