import { useEffect, useState, useRef, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@components/Input/Button/IconButton';

const ToggleButton = memo((props) => {
  const iconRef = useRef(null);
  const [getToggle, setGetToggle] = useState(props.toggle);

  const toggleAnimation = useCallback(
    (target, toggle) => {
      const targetStyle = target.style;
      if (toggle) {
        targetStyle.transform = `rotate(${props.toggleOnDegree}deg)`;
      } else {
        targetStyle.transform = `rotate(${props.toggleOffDegree}deg)`;
      }
    },
    [props.toggleOnDegree, props.toggleOffDegree]
  );

  useEffect(() => {
    toggleAnimation(iconRef.current, props.toggle);
    setGetToggle(props.toggle);
  }, [props.toggle, toggleAnimation]);

  const toggleEvent = () => {
    toggleAnimation(iconRef.current, !getToggle);
    props.onToggle(!getToggle);
    setGetToggle(!getToggle);
  };

  return <IconButton ref={iconRef} {...props} onClick={toggleEvent} />;
});

ToggleButton.propTypes = {
  /** Icon image */
  icon: PropTypes.string.isRequired,
  /** 이미지 아이콘 width */
  w: PropTypes.number,
  /** 이미지 아이콘 height */
  h: PropTypes.number,
  /** Toggle 상태 true:on, false:off */
  toggle: PropTypes.bool,
  /** Toggle on시 이미지 각도 */
  toggleOnDegree: PropTypes.number,
  /** Toggle off시 이미지 각도 */
  toggleOffDegree: PropTypes.number,
  /** Toggle event */
  onToggle: PropTypes.func,
};

ToggleButton.defaultProps = {
  w: 32,
  h: 32,
  toggle: false,
  toggleOnDegree: 180,
  toggleOffDegree: 0,
};

export default ToggleButton;
