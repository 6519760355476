import { BottomSheetIcon } from '@images/index';
import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const AccordionContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.gray03};
  border-radius: 4px;
  padding-top: 10px;
`;

const AccordionHeader = styled.div`
  display: flex;
  padding: 10px 0px;
  cursor: pointer;
`;

const AccordionContent = styled.div`
  padding: 10px 0px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const AccordionTitle = styled.span`
  flex: 1;
  font-size: 24px;
  font-weight: 700;
`;

const Icon = styled.img`
  opacity: 0.9;
  width: 40px;
  height: 40px;
  transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
`;
const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionContainer>
      <AccordionHeader onClick={toggleAccordion}>
        <AccordionTitle>{title}</AccordionTitle>
        <Icon isOpen={isOpen} src={BottomSheetIcon} />
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
    </AccordionContainer>
  );
};

Accordion.propTypes = {
  /** 타이틀 */
  title: PropTypes.string,
  /** Grid Item components */
  children: PropTypes.node,
};

export default Accordion;
