import { fakerKO as faker } from '@faker-js/faker';
import { getDummyImageUrl } from '@utils';

const RANDOM_LENGTH = 20 + Math.floor(Math.random() * 30);
const DUMMY_DATA_LENGTH = RANDOM_LENGTH > 22 ? RANDOM_LENGTH : 0;
const adminList = new Array(DUMMY_DATA_LENGTH).fill(0).map((_, index) => {
  return {
    adminInfoId: index + 1, // FIXME: 실제 데이터에서는 해당 값이 없어 논의 필요
    adminId: faker.internet.email({ firstName: 'Jeanne' }),
    adminName: faker.internet.displayName(),
    adminCountry: faker.location.country(),
    className: faker.helpers.arrayElement(['Super', 'Admin', 'CP Admin']),
    approvalStatus: faker.helpers.arrayElement(['승인', '심사중', '반려']),
    registDate: faker.date.anytime(),
    isMock: true,
  };
});

const ACCOUNTS = {
  adminList,
  pageable: {
    sort: {
      unsorted: true,
      sorted: false,
      empty: true,
    },
    pageSize: 10,
    pageNumber: 0,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  totalPages: 1,
  totalElements: 9,
  last: true,
  numberOfElements: 9,
  first: true,
  size: 10,
  sort: {
    unsorted: true,
    sorted: false,
    empty: true,
  },
  number: 0,
  empty: false,
};

class MockAccounts {
  constructor() {
    this.accounts = ACCOUNTS;
  }

  getAccounts() {
    return this.accounts;
  }

  getAccount(adminInfoId) {
    const target = this.accounts.adminList.find((account) => +account.adminInfoId === +adminInfoId);

    return {
      ...target,
      adminNickname: '관리자짱',
      adminImageFile: {
        fileId: 1,
        fileType: '파일타입',
        fileLocation: '파일 저장 위치',
        fileUrl: getDummyImageUrl({ width: 200, height: 200 }),
        originalFileName: '오리지널 파일명(file.xlsx)',
        realFileName: '디스크저장 파일명(1234242.xlsx)',
        useYn: 1,
        registDate: '등록 일자',
        registUser: '등록자',
        registUtc: '등록 UTC',
      },
    };
  }

  updateAccount({ adminInfoId, approvalStatus, className }) {
    const target = this.accounts.adminList.find((account) => +account.adminInfoId === +adminInfoId);

    if (!target) return false;

    target.approvalStatus = approvalStatus;
    target.className = className;

    return true;
  }

  deleteAccount(adminInfoId) {
    const targetIndex = this.accounts.adminList.findIndex((account) => +account.adminInfoId === +adminInfoId);

    if (targetIndex === -1) return false;

    this.accounts.adminList.splice(targetIndex, 1);

    return true;
  }
}

export default new MockAccounts();
