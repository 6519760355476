import { setAdminNoteList, setCPList } from '@redux/feature/slices/adminSlice';
import Axios from '@Axios';

import { HEADER, METHODS } from '@AxiosConfig';
import { ERRORS } from '@FTA/data';

const BASE_URL = 'v1/api/admin';

/**
 * 관리자 계정 등록 시 필요한 CP List 호출 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {string} params.languageType 언어 타입
 */
const adminCPListAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/cpList`;

  const onSuccess = (res) => {
    console.log('%c✅ adminCPListAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setCPList(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 관리자 계정 중복확인 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {string} params.adminId 아이디(이메일)
 * @param {object} options 아이디 중복 시 에러 객체를 설정하기 위한 객체
 * @param {Function} options.setError 아이디 중복 시 RHF의 Error 객체를 설정하기 위한 함수
 * @param {string} options.target 아이디 중복 시 RHF의 Error 객체를 설정하기 위한 필드명
 * @param {Function} options.setState 아이디 중복 검사 버튼을 눌렀는지 여부를 설정하기 위한 함수
 */
const adminIdExistsAPI = (dispatch, params, { setError, target, setState }) => {
  const URL = `${BASE_URL}/${params.adminId}/exists`;

  const onSuccess = (res) => {
    console.log('%c✅ adminIdExistsAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);

    if (res.data.code === '999') {
      setError && setError(target, { type: 'manual', message: ERRORS.DUPLICATED_ID });
    }

    if (res.data.code === '0000') {
      setState(false);
    }
  };

  const onFail = (err) => {
    console.error(URL, err);
    setError && setError(target, { type: 'manual', message: ERRORS.DUPLICATED_ID });
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 관리자 계정 등록/수정 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {FormData} params API 호출 시 필요한 파라미터
 * @param {string} params.adminId 아이디(이메일)
 * @param {string} params.adminPw 비밀번호
 * @param {string} params.adminName 이름
 * @param {string} params.adminNickname 닉네임
 * @param {string} params.adminCountry 가입 국가 코드 (e.g. KR, JP, CN)
 * @param {object} [params.profileImageFile] 프로필 이미지 파일 정보를 담은 객체
 */
const adminRegistAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/regist`;

  const onSuccess = (res) => {
    console.log('%c✅ adminRegistAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.POST, path: URL, params, onSuccess, onFail, header: HEADER.MULTIPART });
};

const adminResetSendEmailAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/send/email/${params.adminId}`;

  const onSuccess = (res) => {
    console.log('%c✅ adminResetSendEmailAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: 'post', path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

const adminResetPasswordAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/password`;

  const onSuccess = (res) => {
    console.log('%c✅ adminResetPasswordAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: 'get', path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

// 리스트조회 API
const putNoteListAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/admin/notes/list`;

  const onSuccess = (res) => {
    console.log('%c✅ putNoteListAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setAdminNoteList(params));
  };
  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: 'put', path: URL, params, onSuccess, onFail });
};

export {
  adminCPListAPI,
  adminIdExistsAPI,
  putNoteListAPI,
  adminRegistAPI,
  adminResetSendEmailAPI,
  adminResetPasswordAPI,
};
