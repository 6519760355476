/** 승인 관리 */

import { useState } from 'react';

import PageTitle from '@FTA/components/PageTitle';
import TabMenu from '@components/TabMenu/TabMenu';
import PendingApprovalContent from './modules/PendingApprovalContent';
import PendingApprovalProgram from './modules/PendingApprovalProgram';
import PendingApprovalCP from './modules/PendingApprovalCP';

const TAB_DATA = [
  { id: 'pendingApprovalContent', title: '승인 대기 콘텐츠', content: <PendingApprovalContent /> },
  { id: 'PendingApprovalProgram', title: '승인 대기 프로그램', content: <PendingApprovalProgram /> },
  { id: 'PendingApprovalCP', title: '승인 대기 CP', content: <PendingApprovalCP /> },
];

const Approval = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  return (
    <>
      <PageTitle title={TAB_DATA[currentTabIndex].title} />
      <TabMenu tabData={TAB_DATA} currentTabIndex={currentTabIndex} setCurrentTabIndex={setCurrentTabIndex} />
    </>
  );
};

Approval.propTypes = {};

export default Approval;
