import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Text } from '@components/index';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';

import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';

const CPDefaultDetail = () => {
  const cpDetailInfo = useSelector((state) => state.cp.detail);
  const { convertEnum } = useEnumSearch();

  const DEFAULT_INFO = useMemo(
    () => [
      {
        title: 'CP명',
        content: [<Text>{cpDetailInfo.cpManageDetailList?.[0].cpName}</Text>],
      },
      {
        title: '컨텐츠 분류 1',
        content: [<Text>{convertEnum(cpDetailInfo.mainCategory)}</Text>],
      },
      {
        title: '컨텐츠 분류 2',
        content: [<Text>{convertEnum(cpDetailInfo.subCategory?.[0])}</Text>],
      },
      {
        title: '국가 / 언어',
        content: [
          <Text>
            {cpDetailInfo.national} / {cpDetailInfo.language}
          </Text>,
        ],
      },
    ],
    [cpDetailInfo]
  );

  return (
    <>
      <TitleWithDivider title='기본 정보' />
      <GridLayout data={DEFAULT_INFO} hasBottomBorderLine />
    </>
  );
};

export default CPDefaultDetail;
