/** 외부 센터프로그램 관리 > 스케줄링 > 수정 */

import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styled, { css } from 'styled-components';

import { Button, Checkbox, Div, RadioButtonGroup, SelectBox, Span, Text } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';

import { CheckBoxChecked } from '@FTA/assets';
import { useNavigate } from 'react-router-dom';
import paths from '@router/paths';

const weekdays = [
  { code: 'sun', value: '일요일' },
  { code: 'mon', value: '월요일' },
  { code: 'tue', value: '화요일' },
  { code: 'wed', value: '수요일' },
  { code: 'sat', value: '목요일' },
  { code: 'fri', value: '금요일' },
  { code: 'sat', value: '토요일' },
];

const scheduleData = ['10:00 AM', '11:00 AM', '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM'];

const periodSelectBox = {
  guide: '기간선택',
  list: [
    { id: '1', title: '1' },
    { id: '2', title: '2' },
  ],
};

const manageLevelSelectBox = {
  guide: '반복 주기 선택',
  list: [
    { id: '1', title: '1' },
    { id: '2', title: '2' },
  ],
};

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const PageDesc = styled(Text)`
  color: ${({ theme }) => theme.gray06};
  span {
    color: red;
  }
`;

const TitleText = styled(Text)`
  font-size: 22px;
  font-weight: 700;
  line-height: 25.74px;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const isRequired = ({ required }) =>
  required &&
  css`
    &::after {
      content: '*';
      color: rgba(244, 75, 74, 1);
    }
  `;

const ProgramStyledContainer = css`
  width: 150px;
  height: 51px;
`;

const ProgramLaybelContainer = css`
  color: rgba(153, 153, 153, 1);
  padding: 16px 22px;
`;

const ProgramOptionList = css`
  color: rgba(153, 153, 153, 1);
`;

const ProgramSelectBoxCss = {
  StyledContainer: ProgramStyledContainer,
  LaybelContainer: ProgramLaybelContainer,
  OptionList: ProgramOptionList,
};

const ManageStyledContainer = css`
  width: 400px;
  height: 51px;
`;
const ManageLaybelContainer = css`
  color: rgba(153, 153, 153, 1);
  padding: 16px 22px 16px 22px;
`;

const ManageOptionList = css`
  color: rgba(153, 153, 153, 1);
`;

const ManageSelectBoxCss = {
  StyledContainer: ManageStyledContainer,
  LaybelContainer: ManageLaybelContainer,
  OptionList: ManageOptionList,
};

const ContentContainer = styled(Div)`
  border-top: 3px solid black;
  word-break: break-all;
`;

const TextContainer = styled(Div)`
  display: flex;
  min-height: 51px;
  gap: 20px;
  margin: 30px 0px;
`;

const TitleContainer = styled(Div)`
  display: flex;
  align-items: center;
  min-width: 140px;
  padding: 16px 0px 16px 0px;
`;

const Title = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: left;
  ${isRequired}
`;

const DescContainer = styled(Div)`
  width: 100%;
  padding: 16px 0px 16px 0px;
`;

const Desc = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  line-height: 18.72px;
  text-align: left;
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const Table = styled.table`
  width: 80%;
  margin-bottom: 40px;
`;

const Tableheader = styled.tr`
  display: flex;
  background: ${({ theme }) => theme.gray02};
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
`;

const TableheaderText = styled.td`
  min-width: 14.2%;
  padding: 20px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;

const TableContent = styled.tr`
  display: flex;
  background: ${({ theme }) => theme.FFFFFF};
  border-bottom: 1px solid gray;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
`;

const TableContentText = styled.td`
  padding: 20px;
  text-align: center;
`;

const ScheduleDataContainer = styled(Div)`
  padding: 20px 0px;
  min-width: 14.3%;
`;

const FlexBox = styled(Div)`
  display: flex;
  align-items: center;
`;

const InLineBox = styled(Div)`
  display: inline-block;
  align-items: center;
`;

const InnerInLineBox = styled(Div)`
  padding: 16px 0px 16px 0px;
  gap: 8px;
`;

const HelperText = styled(Span)`
  color: rgba(153, 153, 153, 1);
  color: ${({ color }) => color};
  margin-left: 10px;
`;

const FirstNormalStyle = css`
  width: 67px;
  height: 24px;
  padding: 15px 0px;
  font-size: 12px;
  text-decoration: underline;
`;

const FirstHoverStyle = css`
  color: ${({ theme }) => theme.gray10};
`;

const FirstDisabledStyle = css`
  background-color: ${({ theme }) => theme.gray02};
  text-decoration: underline;
`;

const FirstUnderLineStyle = {
  NormalStyle: FirstNormalStyle,
  HoverStyle: FirstHoverStyle,
  DisabledStyle: FirstDisabledStyle,
};

const checkboxInputStyle = css`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

const checkboxCustomUIStyle = css`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border: 1px solid ${({ theme }) => theme.gray05};
  border-radius: 5px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
`;

const checkboxT = css`
  &:after {
    content: '';
    display: block;
    background-image: url(${CheckBoxChecked});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }
`;

const checkBoxDisabled = css`
  background-color: ${({ theme }) => theme.gray04};
`;

const ExternalCenterProgramScheduleEdit = () => {
  const navigate = useNavigate();
  const { control } = useForm();
  const radioForm = useForm();

  const exFunc = () => {
    alert('무언가 동작을 기술해주세요');
  };

  const setDayButton = useCallback((func) => {
    return (
      <Button
        onClick={func}
        $layout='square'
        $variantColor='green'
        width='105'
        height='45'
        fontSize='16'
        margin='0px 15px;'
      >
        날짜 설정
      </Button>
    );
  }, []);

  const setTimeButton = useCallback((func) => {
    return (
      <ButtonContainer>
        <Button
          onClick={func}
          $layout='round'
          $variantColor='underLinePrimary'
          classes={{ UnderLineStyle: FirstUnderLineStyle }}
        >
          시간 설정
        </Button>
      </ButtonContainer>
    );
  }, []);

  const handleNavigate = useCallback(
    (path) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  const cancel = useCallback(() => {
    handleNavigate(`/${paths.ECP_SCHEDULE}`);
  }, []);

  const Data = [
    {
      title: '프로그램 기간',
      required: true,
      desc: (
        <FlexBox>
          <SelectBox
            control={control}
            name='programPeriod'
            options={periodSelectBox}
            classes={{ Variant: ProgramSelectBoxCss }}
          />
          <HelperText>주</HelperText>
        </FlexBox>
      ),
    },
    {
      title: '관리자 레벨',
      required: true,
      desc: (
        <FlexBox>
          <SelectBox
            control={control}
            name='manageLevel'
            options={manageLevelSelectBox}
            classes={{ Variant: ManageSelectBoxCss }}
          />
        </FlexBox>
      ),
    },
    {
      title: '반복 기준',
      required: true,
      desc: (
        <InLineBox>
          <InnerInLineBox>
            <RadioButtonGroup
              id='repetitionCriteria'
              name='repetitionCriteria'
              content={[
                { code: 'date', value: '날짜' },
                { code: 'day', value: '요일' },
              ]}
            />
          </InnerInLineBox>
          <InnerInLineBox>
            <HelperText color='rgba(76, 76, 76, 1)'>매월 1일</HelperText>
            {setDayButton(exFunc)}
          </InnerInLineBox>
          <InnerInLineBox>
            <RadioButtonGroup id='repetitionWeekDays' name='repetitionWeekDays' content={weekdays} />
          </InnerInLineBox>
        </InLineBox>
      ),
    },
    {
      title: '시작일',
      required: true,
      desc: (
        <FlexBox>
          <HelperText> 2023.07.03</HelperText>
          {setDayButton(exFunc)}
        </FlexBox>
      ),
    },
    {
      title: '종료일',
      required: true,
      desc: (
        <InLineBox>
          <InnerInLineBox>
            <RadioButtonGroup
              id='endDate'
              name='endDate'
              content={[
                { code: 'none', value: '없음(무한반복)' },
                { code: 'targetDay', value: '지정일' },
              ]}
            />
            {setDayButton(exFunc)}
          </InnerInLineBox>
        </InLineBox>
      ),
    },
    {
      title: '알림 시간',
      desc: (
        <Table>
          <thead>
            <Tableheader>
              {weekdays.map((day, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableheaderText key={index}>
                  <Checkbox
                    name={`checkbox_test_${index}`}
                    label={day}
                    classes={{
                      Input: checkboxInputStyle,
                      Box: checkboxCustomUIStyle,
                      CheckboxT: checkboxT,
                      Disabled: checkBoxDisabled,
                    }}
                  />
                </TableheaderText>
              ))}
            </Tableheader>
          </thead>
          <tbody>
            <TableContent>
              {scheduleData.map((time) => (
                <ScheduleDataContainer>
                  <ButtonContainer>
                    <TableContentText>{time}</TableContentText>
                  </ButtonContainer>
                  {setTimeButton(exFunc)}
                </ScheduleDataContainer>
              ))}
            </TableContent>
          </tbody>
        </Table>
      ),
    },
  ];

  return (
    <Container>
      <PageTitle title='스케쥴링' />
      <PageDesc>
        별표(<span>*</span>)가 표시된 항목은 필수입력 항목입니다.
      </PageDesc>
      <TitleText>기본 정보</TitleText>
      <FormProvider {...radioForm}>
        <ContentContainer>
          {Data.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <TextContainer>
                <TitleContainer>
                  <Title required={item.required}>{item.title}</Title>
                </TitleContainer>
                <DescContainer>
                  <Desc>{item.desc}</Desc>
                </DescContainer>
              </TextContainer>
            </div>
          ))}
          <ButtonContainer>
            <Button $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
              저장
            </Button>
            <Button onClick={cancel} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonContainer>
        </ContentContainer>
      </FormProvider>
    </Container>
  );
};

export default ExternalCenterProgramScheduleEdit;
