import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import { Button, Div, Input } from '@components/index';

const Container = styled(Div)`
  display: flex;
  gap: 10px;
`;

const DisabledStyle = css``;

const ChooseContentButton = ({ name, showPopupClick, initialPlaceholder, buttonTitle, defaultValue }) => {
  return (
    <Container>
      <Input
        type='text'
        readOnly
        name={name}
        placeholder={initialPlaceholder}
        classes={{ Variant: DisabledStyle }}
        defaultValue={defaultValue}
      />
      <Button onClick={showPopupClick} $layout='square' $variantColor='green' width='120' height='51' fontSize='16'>
        {buttonTitle || '컨텐츠 선택'}
      </Button>
    </Container>
  );
};

export default ChooseContentButton;

ChooseContentButton.propTypes = {
  /** HandleSubmit에서 사용되는 고유 key 값 */
  name: PropTypes.string,
  /** 팝업 클릭 버튼 */
  showPopupClick: PropTypes.func,
  /** 팝업 클릭전 기본 placeholder */
  initialPlaceholder: PropTypes.string,
  /** 버튼의 title */
  buttonTitle: PropTypes.string,
  /** Input의 defaultValue */
  defaultValue: PropTypes.string,
};
