/* eslint-disable react/no-array-index-key */
import { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Div, FileInput, Text } from '@components/index';
import Themes from '@styles/Themes';
// import { uploadFileAPI } from '@redux/action/uploadAction';

const Container = styled(Div)`
  display: flex;
  gap: 10px;
`;

const FileNameContainer = styled(Div)`
  display: flex;
  width: 627px;
  height: 49px;
  border: 1px solid ${({ theme }) => theme.gray04};
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;

const Label = styled.label``;

const UploadBtn = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 101px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.gray05};
  opacity: 0px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.gray04};
  }
`;

const InputDisplay = css`
  display: none;
`;

const FileUpload = ({ name, placeHolder, required, onFileChange }) => {
  const [fileName, setFileName] = useState(placeHolder);
  const fileRef = useRef(null);
  const uuid = uuidv4();

  // 파일 선택 시 이미지 미리보기 함수
  const handleImagePreview = useCallback(
    (event) => {
      const selectedFile = event.target.files[0];

      // FileReader 객체 생성
      const reader = new FileReader();

      // 파일 읽기가 완료되면 실행되는 콜백 함수
      reader.onload = async () => {
        // 이미지 URL을 상태에 설정하여 미리보기
        setFileName(reader.result);
        onFileChange && onFileChange(selectedFile, reader);

        // if (shouldGetCloudFrontUrl) {
        //   const BASE_URL = 'v1/mys3/image';
        //   const URL = getUrl(BASE_URL);
        //   const formData = new FormData();
        //   formData.append('file', selectedFile);

        //   const res = await axios.post(URL, formData, HEADER.MULTIPART);

        //   setValue(name, res.data);
        // }
      };

      // 파일을 읽음
      reader.readAsDataURL(selectedFile);
    },
    [name]
  );

  return (
    <Container>
      <FileNameContainer>
        <Text fontSize={12} fontWeight={400} color={Themes.light.gray07} inner={fileName} />
      </FileNameContainer>
      <Label htmlFor={`video-fileInput-${name}-${uuid}`}>
        <UploadBtn>
          <Text fontSize={12} fontWeight={600} color={Themes.light.gray08} inner='파일 선택' />
        </UploadBtn>
        <FileInput
          id={`video-fileInput-${name}-${uuid}`}
          classes={{ Variant: InputDisplay }}
          onChange={handleImagePreview}
          ref={fileRef}
          name={name}
          required={required}
        />
      </Label>
    </Container>
  );
};

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  onFileChange: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
};

export default FileUpload;
