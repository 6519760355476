import { useCallback } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useFormContext, useWatch } from 'react-hook-form';

import TableMultiSelectBox from '@components/DataDisplay/Table/modules/TableMultiSelectBox';
import { Div, Button, Input } from '@components/index';
import RangeCalendar from '@FTA/components/Calendar/RangeCalendar';

import { CloseDarkIcon } from '@FTA/assets';
import TableSingleSelectBox from '@components/DataDisplay/Table/modules/TableSingleSelectBox';

const Container = styled(Div)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TableHeader = styled(Div)`
  display: flex;
  justify-content: space-between;
`;

const TableTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 60px;
`;

const DeleteFilteredButtonStyle = css`
  background-color: ${({ theme }) => theme.green01};
`;

const SubOptionContainer = styled(Div)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.gray08};
  border-top: 1px solid ${({ theme }) => theme.gray03};
  border-bottom: 1px solid ${({ theme }) => theme.gray03};
`;

const ColumnBox = styled(Div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`;

const Row = styled(Div)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterRow = styled(Row)`
  gap: 10px;
  justify-content: flex-start;

  &:last-child {
    justify-content: flex-end;
  }
`;

const FilteredChipContainer = styled(Div)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FilteredChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 8px 14px;
  border-radius: 56px;
  background-color: ${({ theme }) => theme.green01};
`;

const SearchContainer = ({
  title,
  options,
  contentOption,
  hasSearchInput,
  hasChip,
  hasColumnFilter,
  hasColumnContentsFilter,
  hasDateFilter,
  hasInputSelectBox = true,
  onClick,
  labelTitle,
  contentName,
}) => {
  const { setValue, handleSubmit } = useFormContext();

  const onUpdateFilterChip = useCallback(() => {
    //   setFilteredItem(
    //     testOption.map((option) => (option.title === item ? { ...option, checked: checkState[item] } : option))
    //   );
  }, []);

  const onRemoveFilteredChip = useCallback(
    (name) => {
      const targetName = name;
      setValue(targetName, false);
    },
    [options]
  );

  const handleSearchClick = useCallback((data) => {
    // FIXME[CS] 추후에 store에 table data를 저장하면, 그 데이터를 filtering하는 로직을 추가해야 함
    console.table(data);
    onClick && onClick(data);
  }, []);

  // const selectboxList = useMemo(() => {
  //   const list = Object.keys(options);
  //   return list;
  // }, [options]);

  return (
    <Container>
      {title && (
        <TableHeader>
          <TableTitle>{title}</TableTitle>
        </TableHeader>
      )}
      <SubOptionContainer>
        <ColumnBox>
          <FilteredChipContainer>
            {hasChip &&
              hasSearchInput &&
              // FIXME[CS] options가 배열이면 무조건 length = 1; 이므로 selectboxList = [0, 1, 2, ...], 즉 해당 배열의 index와 다를게 없음
              options.map((option) => {
                const checked = useWatch({ name: option.code });
                return checked ? (
                  <FilteredChip key={option.code}>
                    {option.title}
                    <Button
                      classes={{ Button: DeleteFilteredButtonStyle }}
                      $layout='round'
                      $variantColor='white'
                      startIcon={CloseDarkIcon}
                      $imgVariant='close'
                      onClick={() => onRemoveFilteredChip(option.code)}
                    />
                  </FilteredChip>
                ) : null;
              })}
          </FilteredChipContainer>
          <Row>
            {hasColumnFilter && (
              <FilterRow>
                {hasInputSelectBox && (
                  <TableMultiSelectBox
                    options={options}
                    initialLabel={labelTitle ?? '필터 선택'}
                    onUpdateFilterChip={onUpdateFilterChip}
                  />
                )}
                {hasSearchInput && (
                  <>
                    <Input
                      type='text'
                      name='search-filter'
                      placeholder='검색어를 입력해주세요.'
                      w={471}
                      h={40}
                      fontSize={14}
                    />
                    <Button
                      $layout='square'
                      $variantColor='green'
                      width={59}
                      height={40}
                      fontSize={14}
                      padding='4px 8px'
                      onClick={handleSubmit(handleSearchClick)}
                    >
                      검색
                    </Button>
                  </>
                )}
                {hasChip &&
                  !hasSearchInput &&
                  options.map((option) => {
                    const checked = useWatch({ name: option.code });
                    return checked ? (
                      <FilteredChip key={option.code}>
                        {option.title}
                        <Button
                          classes={{ Button: DeleteFilteredButtonStyle }}
                          $layout='round'
                          $variantColor='white'
                          startIcon={CloseDarkIcon}
                          $imgVariant='close'
                          onClick={() => onRemoveFilteredChip(option.code)}
                        />
                      </FilteredChip>
                    ) : null;
                  })}
              </FilterRow>
            )}
            {hasColumnContentsFilter && (
              <FilterRow>
                {hasInputSelectBox && (
                  <>
                    <TableMultiSelectBox
                      options={options}
                      initialLabel='필터 선택'
                      onUpdateFilterChip={onUpdateFilterChip}
                    />
                    <TableSingleSelectBox
                      name={contentName}
                      options={contentOption}
                      labelTitle={labelTitle}
                      defaultValueIndex={0}
                    />
                  </>
                )}
                <Input
                  type='text'
                  name='search-filter'
                  placeholder='검색어를 입력해주세요.'
                  w={471}
                  h={40}
                  fontSize={14}
                />
                <Button
                  $layout='square'
                  $variantColor='green'
                  width={59}
                  height={40}
                  fontSize={14}
                  padding='4px 8px'
                  onClick={handleSubmit(handleSearchClick)}
                >
                  검색
                </Button>
              </FilterRow>
            )}
            {hasDateFilter && (
              <FilterRow>
                <RangeCalendar name='range' />
              </FilterRow>
            )}
          </Row>
        </ColumnBox>
      </SubOptionContainer>
    </Container>
  );
};

SearchContainer.propTypes = {
  /** Table title */
  title: PropTypes.string,
  /** 검색어로 검색할 condition */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
  contentOption: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
  labelTitle: PropTypes.string,
  /** 검색 Inpiut를 가질 것인지 여부 */
  hasSearchInput: PropTypes.bool,
  /** Chip ui를 가질 것인지 */
  hasChip: PropTypes.bool,
  /** 검색어로 table data의 filtering 유무 */
  hasColumnFilter: PropTypes.bool,
  /** 검색어로 table data의 filtering 유무 */
  hasColumnContentsFilter: PropTypes.bool,
  /** 달력으로 table data의 filtering 유무 */
  hasDateFilter: PropTypes.bool,
  /** Table Input 옆 필터 SelectBox 존재유무 */
  hasInputSelectBox: PropTypes.bool,
  /** 부모 컴포넌트에서 전달된 handleSubmit의 data 객체를 전달받는 callback */
  onClick: PropTypes.func,
  /** 컨텐츠에서 쓰이는 selectbox의 rhf 이름 */
  contentName: PropTypes.string,
};

export default SearchContainer;
