import { useMemo } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Input, RadioButton, Text } from '@components/index';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';

import useUserInfo from '@modules/FTA/hooks/useUserInfo';
import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';
import { useWatch } from 'react-hook-form';

const RadioButtonContainer = styled(Div)`
  display: flex;
`;

const TARGET_ENUM_KEYS = Object.freeze({
  SUB_CATEGORY: 'SubCategoryType',
  CONTENTS_STORAGE_LOCATION_TYPE: 'ContentsStorageLocationType',
});

const SUB_CATEGORY_WHITE_LIST = Object.freeze({
  MCT000: ['SCT000', 'SCT001', 'SCT002', 'SCT004'],
  MCT001: ['SCT000', 'SCT001', 'SCT002', 'SCT004'],
  MCT002: ['SCT003', 'SCT005', 'SCT006', 'SCT007'],
  MCT003: ['SCT000', 'SCT001', 'SCT002', 'SCT004'],
});

const ContentsDefaultForm = ({ formNames }) => {
  const contentStorageLocationWatch = useWatch({ name: formNames.CONTENTS_STORAGE_LOCATION });

  const { getEnumsByKey } = useEnumSearch();
  const userInfo = useUserInfo();
  const subCategoryEnumList = getEnumsByKey(TARGET_ENUM_KEYS.SUB_CATEGORY);
  const contentStorageLocationEnumList = getEnumsByKey(TARGET_ENUM_KEYS.CONTENTS_STORAGE_LOCATION_TYPE);

  const filteredSubCategoryEnumList = useMemo(() => {
    const { mainCategory = '' } = userInfo.cpManagementList[0];

    if (!mainCategory) return [];

    return subCategoryEnumList?.filter((subCategoryEnum) =>
      SUB_CATEGORY_WHITE_LIST[mainCategory]?.includes(subCategoryEnum.code)
    );
  }, [subCategoryEnumList, userInfo]);

  const BASIC_INPUT = [
    {
      title: '컨텐츠명',
      isRequired: true,
      content: [
        <Input name={formNames.CONTENTS_NAME} type='text' placeholder='컨텐츠명을 입력해주세요.' maxLength='100' />,
      ],
    },
    {
      title: 'CP명',
      isRequired: true,
      content: [<Text>{userInfo.cpManagementList[0].cpManageDetailList[0].cpName}</Text>],
    },
    {
      title: '컨텐츠 분류 1',
      isRequired: true,
      content: [<Input readOnly name={formNames.MAIN_CATEGORY} type='text' />],
    },
    {
      title: '컨텐츠 분류 2',
      isRequired: true,
      content: [
        <RadioButtonContainer>
          {filteredSubCategoryEnumList?.map((subCategoryEnum) => (
            <RadioButton
              required
              key={subCategoryEnum.code}
              id={subCategoryEnum.code}
              name={formNames.SUB_CATEGORY}
              item={subCategoryEnum}
            />
          ))}
        </RadioButtonContainer>,
      ],
    },
    {
      title: '컨텐츠 저장 위치',
      isRequired: true,
      content: [
        <RadioButtonContainer>
          {contentStorageLocationEnumList?.map((contentStorageLocationEnum) => (
            <RadioButton
              required
              key={contentStorageLocationEnum.code}
              id={contentStorageLocationEnum.code}
              name={formNames.CONTENTS_STORAGE_LOCATION}
              item={contentStorageLocationEnum}
            />
          ))}
          {contentStorageLocationWatch === 'CSL001' ? (
            // NOTE[CS] IP주소를 입력받는 params가 현재 API에 존재하지 않음
            <Input name={formNames.CONTENTS_STORAGE_LOCATION_IP} placeholder='IP주소를 입력하세요.' />
          ) : null}
        </RadioButtonContainer>,
      ],
    },
  ];

  return (
    <>
      <TitleWithDivider title='기본 정보' />
      <GridLayout data={BASIC_INPUT} />
    </>
  );
};

ContentsDefaultForm.propTypes = {
  formNames: PropTypes.objectOf(PropTypes.string),
};

export default ContentsDefaultForm;
