/** 기타 관리 > FAQ */

import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Div, Table } from '@components/index';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';
import DetailButton from '@FTA/components/DetailButton';
import PATHS from '@router/paths';
import PageTitle from '@FTA/components/PageTitle';
import styled, { css } from 'styled-components';
import { getFaqListAPI } from '@redux/action/etcAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const FooterButtonStyle = css`
  padding: 8px 4px;
`;

const columnHelper = createColumnHelper();

// FIXME[CS] 추후에 real data로 넘어갈 때 value 값 수정
const TABLE_KEYS = Object.freeze({
  NO: 'no',
  COUNTRY: 'country',
  CATEGORY: 'category',
  TiTLE: 'title',
  REGISTRATION_DATE: 'registDate',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, title: 'no', checked: false },
  { code: TABLE_KEYS.COUNTRY, title: '국가', checked: false },
  { code: TABLE_KEYS.TiTLE, title: '제목', checked: false },
];

const Faq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm();
  const { searchParams, getSearchParamsObject } = useSearchParams();
  const faqList = useSelector((state) => state.etc.faqList.content);
  const faqPaging = useSelector((state) => state.etc.faqPaging);

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.NO, {
        id: TABLE_KEYS.NO,
        header: () => <span>No</span>,
        cell: ({ row: { index } }) => <span>{index}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.COUNTRY, {
        id: TABLE_KEYS.COUNTRY,
        header: () => <span>국가</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.CATEGORY, {
        id: TABLE_KEYS.CATEGORY,
        header: () => <span>카테고리</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.TiTLE, {
        id: TABLE_KEYS.TiTLE,
        header: () => <span>제목</span>,
        cell: ({ getValue, row: { original } }) => {
          const { faqInfoId = '' } = original;
          return (
            <DetailButton pathname={PATHS.FAQ_DETAIL} searchParams={{ faqInfoId }}>
              {getValue()}
            </DetailButton>
          );
        },
      }),
      columnHelper.accessor(TABLE_KEYS.REGISTRATION_DATE, {
        id: TABLE_KEYS.REGISTRATION_DATE,
        header: () => <span>등록일</span>,
        cell: ({ getValue }) => <span>{moment(getValue()).format('YYYY-MM-DD')}</span>,
      }),
    ],
    []
  );
  /* eslint-enable react/no-unstable-nested-components */

  const gotoAdd = useCallback(() => {
    navigate(`/${PATHS.FAQ_WRITE}`);
  }, []);

  useEffect(() => {
    const param = getSearchParamsObject();
    getFaqListAPI(dispatch, param);
  }, [searchParams]);

  // FIXME[CS] 추후에 실제 데이터의 길이로 변경
  if (!faqList) return <EmptyData text='등록된 FAQ가  없습니다.' />;

  return (
    <>
      <PageTitle title='FAQ' subItem='FAQ' />
      <FormProvider {...methods}>
        <SearchContainer options={TABLE_OPTIONS} hasChip hasColumnFilter hasInputSelectBox hasDateFilter />
        <Table
          data={faqList}
          columns={COLUMNS_DATA}
          paging={faqPaging}
          hasChip
          hasColumnFilter
          hasInputSelectBox
          buttonList={
            <ButtonContainer>
              <Button
                classes={{ Button: FooterButtonStyle }}
                $layout='square'
                $variantColor='primary'
                width={61}
                height={36}
                fontSize={14}
                onClick={gotoAdd}
              >
                등록
              </Button>
            </ButtonContainer>
          }
        />
      </FormProvider>
    </>
  );
};

export default Faq;
