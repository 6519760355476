import styled from 'styled-components';
import { Div } from '@components/Atoms/Atoms';

const LabelDiv = styled(Div)`
  width: 0px;
  height: 0px;
  display: none;
`;
const HiddenDiv = (props) => <LabelDiv {...props} />;

export default HiddenDiv;
