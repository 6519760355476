import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import PATHS from '@router/paths';

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: ${({ theme }) => theme.gray07};
`;

const BreadCrumb = styled(Link)`
  text-decoration: ${({ $current }) => ($current ? 'under-line' : 'none')};
  color: ${({ theme }) => theme.gray07};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;

const CrumbDivider = styled.span``;

const BreadCrumbs = ({ crumbs }) => {
  return (
    <Container>
      <BreadCrumb to={`/${PATHS.HOME}`}>홈</BreadCrumb>
      {crumbs.map((crumb, index) => (
        <Fragment key={uuidv4()}>
          <CrumbDivider>/</CrumbDivider>
          <BreadCrumb to={crumb.path ? `/${crumb.path}` : ''} $current={crumbs.length - 1 === index}>
            {crumb.name}
          </BreadCrumb>
        </Fragment>
      ))}
    </Container>
  );
};

BreadCrumbs.propTypes = {
  /** BreadCrumbs를 구성하는 요소로 길이는 1 혹은 2 */
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default BreadCrumbs;
