import axios from 'axios';
import { createQueryString } from '@utils';
import { CURRENT_SERVER_TYPE, getUrl, METHODS, SERVER_TYPE } from './AxiosConfig';
import reduxStore from '@redux/store';
import { CONFIG } from '@modules/FTA/data';
import fallbackMockData from '@modules/FTA/mocks/data/errorFallbackMockData';

const getFallbackMockData = (url) => {
  const fallbackMockDataUrls = Object.keys(fallbackMockData);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < fallbackMockDataUrls.length; i++) {
    if (url.includes(fallbackMockDataUrls[i])) {
      return fallbackMockData[fallbackMockDataUrls[i]];
    }
  }

  return {};
};

/**
 * @namespace Axios
 * @param {object} args Axios arguments
 * @param {string} args.type Http Method
 * @param {string} args.path Api 요청 path EX) /api/1.0/app/
 * @param {object} args.params 요청 params
 * @param {Function} args.onSuccess 요청 성공 시 호출할 callback function
 * @param {Function} args.onFail 요청 실패 시 호출할 callback function
 * @param {object} args.header 요청 실패 시 호출할 callback function
 * @returns {Promise} Axios promise
 */
// eslint-disable-next-line consistent-return
const Axios = async ({ type, path, params = {}, onSuccess, onFail, header = {} }) => {
  const serverType = CURRENT_SERVER_TYPE;

  const urlparams = createQueryString(params);
  let url = getUrl(path);

  if (type === METHODS.GET && Object.keys(params).length > 0) url += `?${urlparams}`;

  if (serverType !== SERVER_TYPE.PRD) console.log(`Axios called | method: ${type} | url: ${url} | params: ${params}`);

  const { store } = reduxStore;
  const { token } = store.getState().auth.authInfo;
  const instanceHeader = token
    ? {
        ...header,
        headers: {
          ...header.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    : header;

  let axiosIns = null;
  switch (type) {
    case METHODS.GET:
      axiosIns = axios[type](url, instanceHeader);
      break;
    case METHODS.POST:
      axiosIns = axios[type](url, params, instanceHeader);
      break;
    case METHODS.PUT:
      axiosIns = axios[type](url, params, instanceHeader);
      break;
    case METHODS.DELETE:
      axiosIns = axios[type](url, instanceHeader);
      break;
    default:
      break;
  }

  if (!axiosIns) return console.warn('⚠️Axios invalid case method: ', type);

  axiosIns
    .then((res) => {
      if (serverType !== SERVER_TYPE.PRD) console.log(`Axios response | url: ${url} | res: ${res.data}`);
      onSuccess && onSuccess(res);
    })
    .catch((error) => {
      console.error(`⚠️Axios Error | url: ${url} | error: ${error}`);

      if (CONFIG.USE_MOCKDATA_ONFAIL && serverType !== SERVER_TYPE.PRD) {
        onFail && onFail(error, getFallbackMockData(url));
        return;
      }
      onFail && onFail(error);
    });
};

export default Axios;
