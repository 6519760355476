import Axios from '@Axios';
import { HEADER, METHODS } from '@AxiosConfig';
import { setCpDetail, setCpList, setCpPaging } from '@redux/feature/slices/cpSlice';
import { omit } from '@utils';

// import PATHS from '@router/paths';

const HANDLE_TYPE = Object.freeze({
  APPROVAL: 'approval',
  REFUSAL: 'refusal',
  REQAPPROVAL: 'reqApproval',
});

const BASE_URL = 'v1/api/cpManagement';

/**
 * GetCpManagementAPI
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const getCpManagementAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/list`;

  const onSuccess = (res) => {
    console.log('%c✅ getCpManagementAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setCpList(res.data.content));
    const paging = omit(res.data, ['content']);
    dispatch(setCpPaging(paging));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * GetCpManagementDetailAPI
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {string} cpManagementInfoId CP 고유 ID
 */
const getCpManagementDetailAPI = (dispatch, cpManagementInfoId) => {
  const URL = `${BASE_URL}/${cpManagementInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ getCpManagementDetailAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setCpDetail(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params: {}, onSuccess, onFail, header: HEADER.GET });
};

/**
 * CpManagementRegistAPI
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const CpManagementRegistAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/regist`;

  const onSuccess = (res) => {
    console.log('%c✅ CpManagementRegistAPI RESOPONSE ', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.POST, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * CP정보 삭제 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} cpManagementInfoId 삭제 대상 CP ID
 */
const CpDeleteAPI = (dispatch, cpManagementInfoId) => {
  const URL = `${BASE_URL}/${cpManagementInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ CpDeleteAPI RESOPONSE ', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    const param = {};
    getCpManagementAPI(dispatch, param);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.DELETE, path: URL, onSuccess, onFail, header: HEADER.DELETE });
};

/**
 * CP 정보 승인 / 반려 / 승인요청 요청 API
 *
 * @param {string} cpManagementInfoId 승인요청할 관리자 계정 ID(PK)
 * @param {'approval' | 'refusal' | 'reqApproval'} type 승인 / 반려 / 승인요청 타입 선택
 */
const HandleCPStatusAPI = (cpManagementInfoId, type) => {
  const currentType = HANDLE_TYPE[type.toUpperCase()];
  const URL = `${BASE_URL}/${cpManagementInfoId}/${currentType}`;

  const onSuccess = (res) => {
    console.log('%c✅ HandleCPStatusAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.PUT, path: URL, onSuccess, onFail, header: HEADER.PUT });
};

export { getCpManagementAPI, getCpManagementDetailAPI, CpManagementRegistAPI, CpDeleteAPI, HandleCPStatusAPI };
