import { useEffect, useRef, memo, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Div } from '@components/Atoms/Atoms';
import styled, { css } from 'styled-components';

const Container = styled(Div)`
  position: fixed;
  top: 0;
  width: calc(100% - env(safe-area-inset-right) - env(safe-area-inset-left));
  min-height: 56px;
  border-bottom: 1px solid #e1e1e1;
  background: ${({ theme }) => theme.gray01};
  z-index: 100;
  font-size: 0;
  ${(props) => props.classes && props.classes.AppBar}
`;

const HeaderContainer = styled(Div)`
  display: flex;
  align-items: center;
  height: 56px;
  _padding: 12px 10px 12px 15px;
  padding: 0 10px 0 15px;
  ${(props) => props.classes && props.classes.HeaderContainer}
`;

const Title = styled.h1`
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: calc(100vw - 75px);
  color: #262626;
  font-size: 18px;
  font: -apple-system-title3;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) => props.classes && props.classes.Title}
`;

const RightIcon = css``;

const StatusBar = memo((props) => {
  const appBarRef = useRef(null);

  useEffect(() => {
    if (appBarRef && appBarRef.current) {
      setTimeout(() => {
        appBarRef.current.focus();
      }, 0);
    }
  }, [appBarRef]);

  useEffect(() => {
    if (appBarRef && appBarRef.current && props?.needAppBarFocus) {
      setTimeout(() => {
        appBarRef.current.focus();
        if (props.afterAppBarFocus) {
          props.afterAppBarFocus();
        }
      }, 500);
    }
  }, [props?.needAppBarFocus]);

  return (
    <Container classes={props.classes} ref={appBarRef}>
      <HeaderContainer classes={props.classes}>
        {props.title && <Title classes={props.classes}>{props.title}</Title>}
        {props.icons &&
          props.icons.map((icon, index) =>
            cloneElement(icon, {
              // eslint-disable-next-line react/no-array-index-key
              key: `appbar-icon${index}`,
              classes: { IconButton: RightIcon, ...icon.props.classes },
            })
          )}
        {props.input && props.input}
      </HeaderContainer>
    </Container>
  );
});

StatusBar.propTypes = {
  /** AppBar의 Focus 속성이 필요한지 여부 */
  needAppBarFocus: PropTypes.bool,
  /** AppBar의 Focus가 이루어진후 수행할 함수 */
  afterAppBarFocus: PropTypes.func, // PropTypes.bool
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.objectOf(PropTypes.string),
  /** StatusBar의 메인 타이틀명 */
  title: PropTypes.string,
  /** AppBar의 아이콘 Array */
  icons: PropTypes.arrayOf(PropTypes.element),
  /** AppBar의 Input 태그 */
  input: PropTypes.node,
};

export default StatusBar;
