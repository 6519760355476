import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reduxStore from './redux/store';

import App from '@App';

import { CURRENT_SERVER_TYPE, SERVER_TYPE } from '@redux/function/AxiosConfig';
import './index.css';
import { worker } from '@FTA/mocks/browser';

const { store, persistor } = reduxStore;

if (process.env.NODE_ENV === 'development') {
  window.store = store;
}

const enableMocking = async () => {
  if (CURRENT_SERVER_TYPE === SERVER_TYPE.LOCALHOST) {
    worker.start();
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));

enableMocking().then(() => {
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
});
