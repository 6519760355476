/* eslint-disable */
import DynamicSessionManagement from '@modules/FTA/components/DynamicSessionManagement/DynamicSessionManagement';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';

const ExternalCenterProgramConfigurationEditSecond = ({
  initialSession,
  exerciseInfo,
  setExerciseInfo,
  sessionOrder,
  setSessionOrder,
}) => {
  return (
    <>
      <TitleWithDivider title='운동 세션 정보' />
      <DynamicSessionManagement
        initialSession={initialSession}
        exerciseInfo={exerciseInfo}
        setExerciseInfo={setExerciseInfo}
        sessionOrder={sessionOrder}
        setSessionOrder={setSessionOrder}
      />
    </>
  );
};

export default ExternalCenterProgramConfigurationEditSecond;
