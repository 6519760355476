import Axios from '@Axios';
import { HEADER, METHODS } from '@AxiosConfig';
import { setContentDetail, setContentsList } from '@redux/feature/slices/contentsSlice';

const CONTENT_TYPE = Object.freeze({
  LG: 'lg',
  CP: 'cp',
});

const HANDLE_TYPE = Object.freeze({
  APPROVAL: 'approval',
  REFUSAL: 'refusal',
  REQAPPROVAL: 'reqApproval',
});

const BASE_URL = 'v1/admin/program';

/**
 * CP 컨텐츠 관리 아웃도어 산책 & 조깅 컨텐츠 목록 호출 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {number} params.page 몇 개의 페이지를 조회할지
 * @param {number} params.size 한 페이지에 몇 개의 계정이 들어갈지
 */
const CPcontentsOutdoorListAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/contents/outdoorActivity/walkingJogging/list`;

  const onSuccess = (res) => {
    console.log('%c✅ contentsAPI RESOPONSE >>>>>', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setContentsList({ result: res.data, type: CONTENT_TYPE.CP }));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * CP 컨텐츠 아웃도어 산책 & 조깅 단건 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {number} params.page 몇 개의 페이지를 조회할지
 * @param {number} params.size 한 페이지에 몇 개의 계정이 들어갈지
 */
const CPcontentsOutdoorActivityAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/contents/outdoorActivity/walkingJogging/${params.contentsInfoId}`;

  const onSuccess = (res) => {
    console.log(
      '%c✅ CPcontentsOutdoorActivityAPI RESOPONSE >>>>>',
      'background: rgba(255, 103, 103, 0.3); color: white',
      res
    );
    dispatch(setContentDetail(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, onSuccess, onFail, header: HEADER.GET });
};

/**
 * CP 컨텐츠 관리 루틴 컨텐츠 목록 호출 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {number} params.page 몇 개의 페이지를 조회할지
 * @param {number} params.size 한 페이지에 몇 개의 계정이 들어갈지
 */
const CPcontentsRoutineListAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/contents/routine/list`;

  const onSuccess = (res) => {
    console.log('%c✅ contentsAPI RESOPONSE >>>>>', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setContentsList({ result: res.data, type: CONTENT_TYPE.CP }));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * CP 루틴 컨텐츠 단건 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {number} params.page 몇 개의 페이지를 조회할지
 * @param {number} params.size 한 페이지에 몇 개의 계정이 들어갈지
 */
const CPcontentsRoutineAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/contents/routine/${params.contentsInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ CPcontentsRoutineAPI RESOPONSE >>>>>', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setContentDetail(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, onSuccess, onFail, header: HEADER.GET });
};

/**
 * CP 컨텐츠 삭제 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {number} params.page 몇 개의 페이지를 조회할지
 * @param {number} params.size 한 페이지에 몇 개의 계정이 들어갈지
 */
const CPcontentsDeleteAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/contents/routine/${params.contentsInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ CPcontentsDeleteAPI RESOPONSE >>>>>', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    const param = {};
    CPcontentsRoutineListAPI(dispatch, param);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.DELETE, path: URL, onSuccess, onFail, header: HEADER.DELETE });
};

/**
 * LG 시그니처 컨텐츠 관리 리스트 호출 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {number} params.page 몇 개의 페이지를 조회할지
 * @param {number} params.size 한 페이지에 몇 개의 계정이 들어갈지
 */
const LGContentsOutdoorListAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/lg/contents/outdoorActivity/list`;

  const onSuccess = (res) => {
    console.log(
      '%c✅ LGContentsOutdoorListAPI RESOPONSE >>>>>',
      'background: rgba(255, 103, 103, 0.3); color: white',
      res
    );
    dispatch(setContentsList({ result: res.data, type: CONTENT_TYPE.LG }));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

const LGContentsRotineListAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/lg/contents/routine/list`;

  const onSuccess = (res) => {
    console.log(
      '%c✅ LGContentsRotineListAPI RESOPONSE >>>>>',
      'background: rgba(255, 103, 103, 0.3); color: white',
      res
    );
    dispatch(setContentsList({ result: res.data, type: CONTENT_TYPE.LG }));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * Lg 루틴 컨텐츠 단건 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {number} params.page 몇 개의 페이지를 조회할지
 * @param {number} params.size 한 페이지에 몇 개의 계정이 들어갈지
 */
const LGcontentsRoutineAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/lg/contents/routine/${params.contentsInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ LGcontentsRoutineAPI RESOPONSE >>>>>', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setContentDetail(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, onSuccess, onFail, header: HEADER.GET });
};

/**
 * LG 컨텐츠 아웃도어 산책 & 조깅 단건 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {number} params.page 몇 개의 페이지를 조회할지
 * @param {number} params.size 한 페이지에 몇 개의 계정이 들어갈지
 */
const LGcontentsOutdoorActivityAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/lg/contents/outdoorActivity/walkingJogging/${params.contentsInfoId}`;

  const onSuccess = (res) => {
    console.log(
      '%c✅ LGcontentsOutdoorActivityAPI RESOPONSE >>>>>',
      'background: rgba(255, 103, 103, 0.3); color: white',
      res
    );
    dispatch(setContentDetail(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, onSuccess, onFail, header: HEADER.GET });
};

const contentRoutineRegisterAPI = (params) => {
  const URL = `${BASE_URL}/contents/routine/regist`;

  const onSuccess = (res) => {
    console.log(
      '%c✅ contentRoutineRegisterAPI RESOPONSE >>>>>',
      'background: rgba(255, 103, 103, 0.3); color: white',
      res
    );
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.POST, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

const contentOutdoorRegisterAPI = (params) => {
  const URL = `${BASE_URL}/contents/outdoorActivity/walkingJogging/regist`;

  const onSuccess = (res) => {
    console.log(
      '%c✅ contentOutdoorRegisterAPI RESOPONSE >>>>>',
      'background: rgba(255, 103, 103, 0.3); color: white',
      res
    );
    CPcontentsOutdoorListAPI();
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.POST, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * Contents 정보 승인 / 반려 / 승인요청 요청 API
 *
 * @param {string} contentsInfoId 승인요청할 콘텐츠 Id
 * @param {'approval' | 'refusal' | 'reqApproval'} type 승인 / 반려 / 승인요청 타입 선택
 */

const HandleContentsStatusAPI = (contentsInfoId, type) => {
  const currentType = HANDLE_TYPE[type.toUpperCase()];
  const URL = `${BASE_URL}/contents/${contentsInfoId}/${currentType}`;

  const onSuccess = (res) => {
    console.log('%c✅ HandleContentsStatusAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.PUT, path: URL, onSuccess, onFail, header: HEADER.PUT });
};

// NOTE: MCT000: 루틴(LG, CP), MCT001: AI, MCT002: 아웃도어 산책 & 조깅
const contentAPIs = Object.freeze({
  [CONTENT_TYPE.CP]: {
    MCT000: {
      list: CPcontentsRoutineListAPI,
      detail: CPcontentsRoutineAPI,
      regist: contentRoutineRegisterAPI,
    },
    // NOTE: TBD
    MCT001: {
      // FIXME: 개발용 임시 추가
      list: CPcontentsRoutineListAPI,
      detail: CPcontentsRoutineAPI,
      regist: contentRoutineRegisterAPI,
    },
    MCT002: {
      list: CPcontentsOutdoorListAPI,
      detail: CPcontentsOutdoorActivityAPI,
      regist: contentOutdoorRegisterAPI,
    },
    MCT003: {
      list: () => {},
      detail: () => {},
    },
  },
  [CONTENT_TYPE.LG]: {
    MCT000: {
      list: LGContentsRotineListAPI,
      detail: LGcontentsRoutineAPI,
    },
    // NOTE: TBD
    MCT001: {
      // FIXME: 개발용 임시 추가
      list: LGContentsRotineListAPI,
      detail: LGcontentsRoutineAPI,
    },
    MCT002: {
      list: LGContentsOutdoorListAPI,
      detail: LGcontentsOutdoorActivityAPI,
    },
    MCT003: {
      list: LGContentsRotineListAPI,
      detail: LGcontentsRoutineAPI,
    },
  },
});

export {
  CPcontentsOutdoorListAPI,
  CPcontentsRoutineListAPI,
  CPcontentsOutdoorActivityAPI,
  CPcontentsRoutineAPI,
  LGContentsOutdoorListAPI,
  LGContentsRotineListAPI,
  LGcontentsRoutineAPI,
  LGcontentsOutdoorActivityAPI,
  CPcontentsDeleteAPI,
  contentRoutineRegisterAPI,
  contentOutdoorRegisterAPI,
  HandleContentsStatusAPI,
  contentAPIs,
};
