import Cell from '@FTA/components/Cell';
import { Grid } from '@components/index';
import { css } from 'styled-components';

const GridStyle = css`
  margin-bottom: 40px;
`;

const Income = () => {
  return (
    <>
      <Grid title='Subscription(Revenue,억원)' column={4} classes={{ Grid: GridStyle }}>
        <Cell title='프리미엄 요금제 수익' value='1250' />
        <Cell title='스탠다드 요금제 수익 ' value='3800' />
        <Cell title='라이트 요금제 수익' value='34:33' />
      </Grid>

      <Grid title='광고수익' column={3} classes={{ Grid: GridStyle }}>
        <Cell title='비디오 광고 수익' value='1250' />
        <Cell title='배너 광고 수익 ' value='3800' />
      </Grid>

      <Grid title='연결수수료' column={5} classes={{ Grid: GridStyle }}>
        <Cell title='홈케어 프로그램' value='12333' />
        <Cell title='아웃도어 액티비티' value='1150' />
      </Grid>
    </>
  );
};

Income.propTypes = {};

export default Income;
