import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const getDisabled = ({ disabled }) =>
  disabled
    ? `background-color: #d8d8d8;
    color: #6c1d1d !important;
    border: 1px solid #eee !important;`
    : '';

const styles = css`
  box-sizing: border-box;
  width: 100%;
  height: 38px;
  padding: 0 0.2em;
  margin: 0 0.2em;
  display: inline-block;
  border: none;
  border-radius: 3px;
  text-align: center;
  ${getDisabled}
`;

const StyledInput = styled.input`
  ${styles}
  ${({ classes }) => classes && classes.Variant}
`;

const FileInput = forwardRef(({ className, classes, name, required, onChange, ...rest }, ref) => {
  const { register } = useFormContext();
  const {
    ref: refForFileInput,
    onChange: onChangeForFileInput,
    ...restForFileInput
  } = register(name, {
    required,
  });

  return (
    <StyledInput
      type='file'
      className={className}
      classes={classes}
      onChange={(event) => {
        onChangeForFileInput(event);
        onChange && onChange(event);
      }}
      {...restForFileInput}
      {...rest}
      ref={(element) => {
        refForFileInput(element);
        ref.current = element;
      }}
    />
  );
});

FileInput.propTypes = {
  /** ClassName */
  className: PropTypes.string,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.objectOf(PropTypes.array),
  /** HandleSubmit에서 사용되는 고유 key 값 */
  name: PropTypes.string,
  /** 값 선택 필수 유무 */
  required: PropTypes.bool,
  /** RHF에서 제공하는 기본적인 onChange 함수 외에 필요한 onChange 함수 */
  onChange: PropTypes.func,
};

FileInput.displayName = 'FileInput';

export default FileInput;
