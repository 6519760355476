/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';
import RadioButton from './RadioButton';

const RadioButtonGroup = ({ id, name, content = [], disabled = [], defaultValue = '', classes }) => {
  if (!content || content.length === 0) {
    return null;
  }
  return (
    <>
      {/* {content.map((item, index) => (
        <
          key={`${id}_${index}`}
          id={`${id}_${index}`}
          name={name}
          value={item}
          checked={value === item}
          defaultValue={defaultValue || content[0]}
          classes={classes}
          disabled={disabled.includes(index)}
          onChange={() => onChange(item)}
          readOnly={readOnly}
        />
      ))} */}
      {content.map((item, index) => {
        return (
          <RadioButton
            key={`${id}_${index}`}
            id={`${id}_${index}`}
            name={name}
            item={item}
            defaultValue={defaultValue}
            classes={classes}
            disabled={disabled.includes(index)}
            // onChange={() => onChange(item.value)}
          />
        );
      })}
    </>
  );
};

RadioButtonGroup.propTypes = {
  /** 라디오 버튼 id */
  id: PropTypes.string.isRequired,
  /** 라디오 버튼 name */
  name: PropTypes.string.isRequired,
  /** 라디오 버튼 labels */
  content: PropTypes.arrayOf(PropTypes.string),
  /** 라디오 버튼에서 disabled 하고싶은 버튼의 index ex.[0,1,2] */
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.number)], PropTypes.array),
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  /** 라디오 기본 checked 값(첫번째 라디오) 존재 여부 */
  defaultValue: PropTypes.string,
};

export default React.memo(RadioButtonGroup);
