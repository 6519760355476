import * as React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@mui/base/Menu';
import { MenuItem } from '@mui/base/MenuItem';
import { MenuButton } from '@mui/base/MenuButton';
import { Dropdown } from '@mui/base/Dropdown';
import { CssTransition } from '@mui/base/Transitions';
import { PopupContext } from '@mui/base/Unstable_Popup';
import styled from 'styled-components';

const cyan = {
  50: '#E9F8FC',
  100: '#BDEBF4',
  200: '#99D8E5',
  300: '#66BACC',
  400: '#1F94AD',
  500: '#0D5463',
  600: '#094855',
  700: '#063C47',
  800: '#043039',
  900: '#022127',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const TriggerButtonIntroduction = styled(MenuButton)`
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 150ms ease 0s;
  cursor: pointer;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(218, 226, 237);
  color: rgb(28, 32, 37);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

  &:hover {
    background: ${grey[50]};
    border-color: ${grey[300]};
  }

  &:active {
    background: ${grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${cyan[200]};
    outline: none;
  }

  ${(props) => props.classes && props.classes.TriggerButtonIntroduction}
`;

const MenuIntroduction = styled(Menu)`
  z-index: 1;

  .closed {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition:
      opacity 200ms ease-in,
      transform 200ms ease-in;
  }

  .open {
    opacity: 1;
    transform: scale(1, 1);
    transition:
      opacity 100ms ease-out,
      transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top {
    transform-origin: bottom;
  }

  .placement-bottom {
    transform-origin: top;
  }

  .MenuIntroduction--listbox {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px;
    border-radius: 12px;
    overflow: auto;
    outline: 0px;
    background: #fff;
    border: 1px solid ${grey[200]};
    color: ${grey[900]};
    box-shadow: 0px 4px 30px ${grey[200]};
  }

  ${(props) => props.classes && props.classes.listBox}
`;

const MenuIntroductionItem = styled(MenuItem)`
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    outline: 3px solid ${cyan[200]};
    background-color: ${grey[100]};
    color: ${grey[900]};
  }

  ${(props) => props.classes && props.classes.MenuIntroductionItem}
`;

const Listbox = React.forwardRef((props, ref) => {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error('The `Listbox` component cannot be rendered outside a `Popup` component');
  }

  const verticalPlacement = popupContext.placement.split('-')[0];

  return (
    // CssTransition: Material-UI에서 제공 되는 컴포넌트, css를 사용하여 트랜지션 효과를 구현
    // 주로 UI 요소가 사라지거나 나타나는등의 상태 변화에 대해 css 효과 적용
    <CssTransition className={`placement-${verticalPlacement}`} enterClassName='open' exitClassName='closed'>
      <ul {...other} ref={ref} />
    </CssTransition>
  );
});

Listbox.propTypes = {
  /** Material-UI의 컴포넌트에서 내부적으로 사용되며, 주로 스타일링을 위해 사용 */
  ownerState: PropTypes.object.isRequired,
};

const DropDown = ({ title, content, dropDownFunc, classes }) => {
  return (
    <Dropdown>
      <TriggerButtonIntroduction classes={classes}>{title}</TriggerButtonIntroduction>

      <MenuIntroduction
        slots={{
          listbox: Listbox,
        }}
        slotProps={{
          listbox: { className: 'MenuIntroduction--listbox' },
        }}
        classes={classes}
      >
        {content.map((item, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <MenuIntroductionItem classes={classes} key={index} onClick={dropDownFunc()[index]}>
              {item}
            </MenuIntroductionItem>
          );
        })}
      </MenuIntroduction>
    </Dropdown>
  );
};

DropDown.propTypes = {
  /** DropDown Button의 타이틀 */
  title: PropTypes.string.isRequired,
  /** DropDown 각 item에 쓰일 타이틀들 */
  content: PropTypes.arrayOf(PropTypes.string),
  /** DropDown 각 item(index 기준)에 쓰일 함수들 */
  dropDownFunc: PropTypes.func,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
};

export default DropDown;
