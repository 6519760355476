import { createSlice } from '@reduxjs/toolkit';
import { omit } from '@utils';

const initialState = {
  csList: [],
  csDetail: [],
  csStatus: [],
  faqList: [],
  faqDetail: [],
  faqPaging: {},
  noticeList: [],
  noticeDetail: [],
  noticePaging: {},
  healthcareList: [],
  healthcareDetail: [],
  surveyList: [],
  surveyDetail: [],
  surveyPaging: {},
};

/* eslint-disable no-param-reassign */
const etc = createSlice({
  name: 'etc',
  initialState,
  reducers: {
    setCSList: (state, action) => {
      state.csList = action.payload;
    },
    setCSDetail: (state, action) => {
      state.csDetail = action.payload;
    },
    setCSPaging: (state, action) => {
      state.csPaging = omit(action.payload, ['content']);
    },
    setCSStatus: (state, action) => {
      state.csStatus = action.payload;
    },
    setFaqList: (state, action) => {
      state.faqList = action.payload;
    },
    setFaqPaging: (state, action) => {
      state.faqPaging = omit(action.payload, ['content']);
    },
    setFaqDetail: (state, action) => {
      state.faqDetail = action.payload;
    },
    setNoticeList: (state, action) => {
      state.noticeList = action.payload;
    },
    setNoticeDetail: (state, action) => {
      state.noticeDetail = action.payload;
    },
    setNoticePaging: (state, action) => {
      state.noticePaging = omit(action.payload, ['content']);
    },
    setHealthcareList: (state, action) => {
      state.healthcareList = action.payload;
    },
    setHealthcarePaging: (state, action) => {
      state.healthcarePaging = omit(action.payload, ['content']);
    },
    setHealthcareDetail: (state, action) => {
      state.healthcareDetail = action.payload;
    },
    setSurveyList: (state, action) => {
      state.surveyList = action.payload;
    },
    setSurveyPaging: (state, action) => {
      state.surveyPaging = omit(action.payload, ['content']);
    },
    setSurveyDetail: (state, action) => {
      state.surveyDetail = action.payload;
    },
  },
});

/* eslint-enable no-param-reassign */

export const {
  setCSList,
  setCSDetail,
  setCSPaging,
  setCSStatus,
  setFaqList,
  setFaqDetail,
  setFaqPaging,
  setNoticeList,
  setNoticeDetail,
  setNoticePaging,
  setHealthcareList,
  setHealthcarePaging,
  setHealthcareDetail,
  setSurveyList,
  setSurveyPaging,
  setSurveyDetail,
} = etc.actions;
export default etc.reducer;
