import { useFormContext, useWatch } from 'react-hook-form';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@components/Input/Button/Button';
import { CloseLightIcon } from '@FTA/assets';
import { Div } from '@components/Atoms/Atoms';
import { PlusIcon } from '@images/index';

const getStyles = ({ classes }) => classes && classes.Variant;
const getWidth = ({ w }) => `${w || 400}px`;
const getHeight = ({ h }) => `${h || 50}px`;
const getFontSize = ({ fontSize }) => fontSize && `font-size : ${fontSize}px;`;

const styles = css`
  box-sizing: border-box;
  width: ${getWidth};
  height: ${getHeight};
  padding: 16px 22px;
  border: 1px solid ${({ theme }) => theme.gray04};
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  ${getFontSize};
  ${getStyles};

  &::placeholder {
    color: ${({ theme }) => theme.gray06};
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.gray06};
  }

  &:read-only {
    font-weight: 600;
    font-size: 16px;
    color: ${({ theme }) => theme.gray07};
    border: 0px;
    padding: 0px;

    &:focus {
      outline: none;
    }
  }

  &:disabled {
    background-color: ${({ theme }) => theme.gray03};
    border: 1px solid #dbd7d4;
    cursor: not-allowed;

    &::placeholder {
      color: ${({ theme }) => theme.gray05};
    }
  }
`;

const Container = styled(Div)``;

const Wrapper = styled(Div)`
  position: relative;
`;

const ButtonContainer = styled(Div)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 10;
  padding-right: 22px;
`;

const ErrorMsg = styled.p`
  margin-top: 5px;
  color: ${({ theme }) => theme.red};
  font-size: 14px;
  font-weight: 400;
`;

const Length = styled.p`
  margin-top: 5px;
  color: ${({ theme }) => theme.gray07};
  font-size: 14px;
  font-weight: 400;
`;

const StyledInput = styled.input`
  ${styles}
`;

const Input = ({
  name,
  type,
  required,
  disabled,
  readOnly,
  maxLength,
  className,
  classes,
  errorMessage,
  hasDeleteIcon = false,
  hasAddIcon = false,
  onClickAdd,
  RegisterOptions,
  ...rest
}) => {
  const {
    register,
    resetField,
    formState: { errors },
  } = useFormContext();
  const inputValue = useWatch({ name });

  const handleClear = () => {
    resetField(name);
  };

  return (
    <Container>
      <Wrapper>
        <StyledInput
          type={type}
          className={className}
          classes={classes}
          {...register(name, { required, disabled, readOnly, ...RegisterOptions })}
          {...rest}
          readOnly={readOnly}
        />
        {hasDeleteIcon && inputValue && (
          <ButtonContainer>
            <Button
              onClick={handleClear}
              $layout='round'
              $variantColor='darkGray'
              startIcon={CloseLightIcon}
              $imgVariant='close'
            />
          </ButtonContainer>
        )}
        {hasAddIcon && (
          <ButtonContainer>
            <Button startIcon={PlusIcon} $imgVariant='close' onClick={onClickAdd} />
          </ButtonContainer>
        )}
      </Wrapper>
      {errors[name] && <ErrorMsg>{errors[name].message}</ErrorMsg>}
      {maxLength && (
        <Length>
          {!inputValue ? 0 : inputValue.length}/{maxLength}
        </Length>
      )}
    </Container>
  );
};

Input.propTypes = {
  /** HandleSubmit에서 사용되는 고유 key 값 */
  name: PropTypes.string,
  /** Input type */
  type: PropTypes.string.isRequired,
  /** Input width */
  w: PropTypes.number,
  /** Input height */
  h: PropTypes.number,
  /** 값 선택 필수 유무 */
  required: PropTypes.bool,
  /** 비활성화 유무 */
  disabled: PropTypes.bool,
  /** 읽기전용 유무 */
  readOnly: PropTypes.bool,
  /** Input 최대 입력 길이 */
  maxLength: PropTypes.number,
  /** ClassName */
  className: PropTypes.string,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.objectOf(),
  /** Input 유효성 검사 에러 메세지 */
  errorMessage: PropTypes.string,
  /** Input 삭제 버튼 존재 유무 */
  hasDeleteIcon: PropTypes.bool,
  /** Input 추가 버튼 존재 유무 */
  hasAddIcon: PropTypes.bool,
  RegisterOptions: PropTypes.objectOf(),
  /** Input 추가 버튼 클릭시 작동하는 func */
  onClickAdd: PropTypes.func,
};

Input.displayName = 'Input';

export default Input;
