import { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import { Div } from '@components/Atoms/Atoms';
import { BottomSheetIcon } from '@images';

const HasScrollStyle = css`
  height: 380px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 100px;
    box-shadow: inset 2px 2px 5px 0 ${({ theme }) => `rgba(${theme.light.gray01}, 0.5)`};
    border-radius: 100px;
  }
`;

const StyledContainer = styled(Div)`
  background-color: ${({ theme }) => theme.gray01};
  position: relative;
  width: 100%;
  min-width: 96px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;

  ${({ classes }) => classes && classes.Variant.StyledContainer}
`;

const LaybelContainer = styled(Div)`
  position: relative;
  display: flex;
  align-items: center;
  height: inherit;
  border: 1px solid #ddd;
  padding-left: 15px;
  border-radius: 8px;
  padding-right: 45px;
  font-size: 16px;
  white-space: nowrap;
  cursor: pointer;

  &:after {
    position: absolute;
    right: 16px;
    top: 13px;
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background: url(${BottomSheetIcon});
    background-size: contain;
  }
  ${({ classes }) => classes && classes.Variant.LaybelContainer}
`;

const OptionList = styled.ul`
  position: absolute;
  top: 46px;
  left: 0;
  z-index: 1;
  display: none;
  width: 100%;
  border-radius: 0 0 8px 8px;
  background: ${({ theme }) => theme.gray01};
  color: #767676;
  overflow: hidden;
  line-height: 140%;
  padding: 0;
  margin: 0;
  list-style: none;
  ${({ isShow }) => isShow && `display:block;`}
  ${({ classes }) => classes && classes.Variant.OptionList}
  ${({ disabled }) => disabled && `display: none !important; height: 0px !important;`}
  ${({ hasScroll }) => hasScroll && HasScrollStyle};
`;
const OptionItem = styled.li`
  padding: 9px 16px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;

  &:first-child {
    border-top: 1px solid #ddd;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
    border-bottom: 1px solid #ddd;
  }

  ${({ classes }) => classes && classes.Variant.OptionItem}
  ${({ hasScroll }) => hasScroll && ` border-right: 0px`}
  ${({ hover }) => hover && `background: #eee;`}
`;

const SelectBox = ({ name, required, disabled, hasScroll = false, options, classes, selectValue, ...rest }) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isShow, setIsShow] = useState(false);
  const { control } = useFormContext();
  const { field } = useController({
    name,
    defaultValue: !options.guide ? options?.list[0].id : null,
    control,
    rules: {
      required,
      disabled,
    },
  });

  const label = useMemo(() => {
    if (!options?.guide) {
      return currentIndex === -1 ? options?.list[0]?.title : options?.list[currentIndex]?.title;
    }
    return currentIndex === -1 ? options.guide : options?.list[currentIndex]?.title;
  }, [currentIndex, options.guide, options.list]);

  const onSelectBoxClick = useCallback(() => {
    setIsShow((prev) => !prev);
  }, []);

  const onOptionClick = useCallback(
    ({ item }) => {
      field.onChange(item.id);
      if (selectValue) {
        selectValue(item);
      }
    },
    [field]
  );

  useEffect(() => {
    if (options.list && field.value) {
      const index = options.list?.findIndex(({ id }) => id === field.value);
      setCurrentIndex(index);
    }
  }, [field.value, options.list]);

  return (
    <StyledContainer onClick={onSelectBoxClick} {...rest} classes={classes}>
      <LaybelContainer classes={classes}>
        <div>{label}</div>
      </LaybelContainer>
      <OptionList isShow={isShow} hasScroll={hasScroll} classes={classes}>
        {!disabled &&
          options?.list.map((item, index) => (
            <OptionItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${item?.id}-${index}`}
              onClick={(event) => onOptionClick({ event, item })}
              classes={classes}
            >
              {item.title}
            </OptionItem>
          ))}
      </OptionList>
    </StyledContainer>
  );
};

SelectBox.propTypes = {
  /** SelectBox를 구분하기 위한 name */
  name: PropTypes.string.isRequired,
  /** 값 선택 필수 유무 */
  required: PropTypes.bool,
  /** Disabled 여부 */
  disabled: PropTypes.bool,
  /** Scroll 존재 유무 */
  hasScroll: PropTypes.node,
  /** SelectBox의 초기 label(guide), 목록 (list)값이 있는 Object */
  options: PropTypes.shape({
    guide: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
      })
    ),
  }).isRequired,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  selectValue: PropTypes.any,
};

export default SelectBox;
