import styled from 'styled-components';
import propTypes from 'prop-types';

import { Div } from '@components/index';

import { CheckBoxChecked, CheckBoxUnchecked } from '@FTA/assets';

const Container = styled(Div)`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
`;

const InputUI = styled(Div)`
  background-image: ${({ checked }) => (checked ? `url(${CheckBoxChecked})` : `url(${CheckBoxUnchecked})`)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  -webkit-user-select: none;
  user-select: none;
  transform: translate(0, 1.5px); // NOTE: 속아줘야 함
  cursor: pointer;
`;

const ControlledCheckbox = ({ checked, label, onChange, id }) => {
  return (
    <Container>
      <Input type='checkbox' checked={checked} onChange={onChange} id={`${id}-checkbox`} />
      <InputUI checked={checked} />
      <Label htmlFor={`${id}-checkbox`}>{label}</Label>
    </Container>
  );
};

ControlledCheckbox.propTypes = {
  checked: propTypes.bool.isRequired,
  id: propTypes.string.isRequired,
  label: propTypes.string,
  onChange: propTypes.func,
};

export default ControlledCheckbox;
