/** 기타 관리 > FAQ > 단건 조회 */

import { useCallback, useContext, useEffect } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { Button, Div, Modal, ModalContext, Text } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import paths from '@router/paths';
import { useDispatch, useSelector } from 'react-redux';
import { FAQdeleteAPI, getFaqDetailAPI } from '@redux/action/etcAction';
import { FormProvider, useForm } from 'react-hook-form';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const ExtendedText = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.gray07};
`;

const FaqDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { showModal, closeModal } = useContext(ModalContext);
  const faqInfoId = searchParams.get('faqInfoId');
  const faqDetail = useSelector((state) => state.etc.faqDetail);
  const methods = useForm();

  const FAQ_DATA = [
    {
      title: '번호',
      content: [
        <div style={{ width: '400px' }}>
          <ExtendedText name='num'>{1}</ExtendedText>
        </div>,
      ],
    },
    {
      title: '제목',
      content: [
        <div style={{ width: '400px' }}>
          <ExtendedText name='title'>{faqDetail?.title}</ExtendedText>
        </div>,
      ],
    },
    {
      title: '국가',
      content: [
        <div style={{ width: '400px' }}>
          <ExtendedText name='country'>{faqDetail?.country}</ExtendedText>
        </div>,
      ],
    },
    {
      title: '카테고리',
      content: [
        <div style={{ width: '400px' }}>
          <ExtendedText name='category'>{faqDetail.category}</ExtendedText>
        </div>,
      ],
    },
    {
      title: '내용',
      content: [
        <div style={{ width: '400px' }}>
          <ExtendedText name='content'>{faqDetail?.content}</ExtendedText>
        </div>,
      ],
    },
  ];

  const onClickDelete = useCallback(() => {
    const uuid = searchParams.get('uuid');
    const search = createSearchParams({ uuid }).toString();

    showModal(
      <Modal
        body='등록된 정보를 삭제하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                const param = { faqInfoId };
                FAQdeleteAPI(dispatch, param);
                navigate({ pathname: `/${paths.FAQ}`, search });
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const gotoList = useCallback(() => {
    const uuid = searchParams.get('uuid');
    const search = createSearchParams({ uuid }).toString();

    showModal(
      <Modal
        body='목록으로 이동하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                navigate({ pathname: `/${paths.FAQ}`, search });
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  useEffect(() => {
    const params = {
      faqInfoId,
    };
    getFaqDetailAPI(dispatch, params);
  }, []);

  return (
    <Container>
      <PageTitle title='FAQ' />
      <FormProvider {...methods}>
        <GridLayout data={FAQ_DATA} />
      </FormProvider>
      <ButtonContainer>
        <ButtonContainer>
          <Button
            onClick={() => {
              const search = createSearchParams({ faqInfoId }).toString();
              navigate({ pathname: `/${paths.FAQ_EDIT}`, search });
            }}
            $layout='square'
            $variantColor='primary'
            classes={{ Button: ButtonCss }}
          >
            수정
          </Button>
          <Button onClick={onClickDelete} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            삭제
          </Button>
          <Button onClick={gotoList} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            목록
          </Button>
        </ButtonContainer>
      </ButtonContainer>
    </Container>
  );
};

export default FaqDetail;
