import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Div } from '@components/Atoms/Atoms';
import { Box } from '@components/Surface/Box';
import Header from '@components/Surface/Header';

const CardContainer = ({ classes }) => css`
  display: block;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 0px;
  ${classes && classes.CardContainer}
`;

const HeaderContainer = css`
  padding: 14px 15px;
`;

const HeaderTitle = css`
  font-size: 16px;
  font: -apple-system-body;
  font-weight: bold;
  line-height: 1.375;
  color: #262626;
`;

const CardContents = styled(Div)`
  padding: 15px;
  font-size: 0;
`;

const Card = ({ title, children, ...props }) => (
  <Box classes={{ Box: CardContainer(props) }} outline>
    {title && <Header classes={{ Header: HeaderContainer, Title: HeaderTitle }} title={title} />}
    <CardContents>{children}</CardContents>
  </Box>
);

Card.propTypes = {
  /** 카드의 메인 타이틀명 */
  title: PropTypes.string.isRequired,
  /** 카드의 내부 컴포넌트 형태로 사용 */
  children: PropTypes.node,
};
export default Card;
