import { useCallback, useContext } from 'react';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Text, Input, RadioButtonGroup, SelectBox, ModalContext, Modal, Button } from '@components/index';
import RadioButtonImageGroup from '@components/Input/Radio/RadioButtonImageGroup';
import SectionItem from '@FTA/components/SectionItem';
import CheckBoxGroup from '@FTA/components/CheckBoxGroup';
import ImageUpload from '@FTA/components/ImageUpload';
import VideoUpload from '@FTA/components/VideoUpload';
import ChooseContentButton from '@FTA/components/ChooseContentButton';
import InputChip from '@FTA/components/InputChip';
import ContentChoosePopUp from '@FTA/pages/contentManagement/ContentChoosePopUp';

import Themes from '@styles/Themes';
import ImagePreview from '../ImagePreview';
import TextEditor from '../TextEditor';

const ContainerStyle = css`
  padding: 10px 50px 10px 0;
  display: flex;
  padding: 0;
`;

// radio
const InputStyle = css`
  width: 15px;
  height: 15px;
`;

const LabelStyle = css`
  font-size: 14px;
`;

const RadioCss = {
  ContainerStyle,
  InputStyle,
  LabelStyle,
};

// sectionitem
const ItemContainer = styled(Div)`
  position: relative;
  display: flex;
  min-height: 100px;
  gap: 10px;
  padding: 40px 0;
`;

const ItemName = styled(Text)`
  flex-basis: 150px;
  ${({ required }) =>
    required &&
    `&:after{content:"*"; color: #F44B4A; font-size:13px;
 }`}
`;

const MultiLineSection = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;
const MultiLineItemContainer = styled(Div)`
  display: flex;
`;
const PrefixText = css`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;
const MetricsText = css`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const DualSelectbox = styled(Div)`
  display: flex;
  gap: 10px;
`;

const Template = styled(Div)`
  display: flex;
  position: absolute;
  top: 10px;
  left: 165px;
  font: rgba(76, 76, 76, 1);
`;

const ButtonContainer = styled(Div)``;

const ThirdNormalStyle = css`
  text-decoration: none;
  color: rgba(32, 32, 32, 1);
  font-size: 16px;
`;

const ThirdUnderLineStyle = {
  NormalStyle: ThirdNormalStyle,
};

const ListBox = styled(Div)`
  display: flex;
  gap: 6px;
  width: 65%;
  flex-wrap: wrap;
`;

const List = styled(Div)`
  width: 170px;
  height: 40px;
  padding: 8px 18px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.gray03};
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const ItemInputText = styled(Input)``;
const InputComponent = ({ data, errors, popupElements }) => {
  const { showModal, closeModal, setModalValue, ModalValue } = useContext(ModalContext);
  setModalValue(popupElements?.selectedValue);

  const showContentAlert = useCallback(() => {
    showModal(<Modal btns={<ContentChoosePopUp popupElements={popupElements} closeModal={closeModal} />} />);
  }, [ModalValue]);

  const createInputEl = useCallback(
    (item) => {
      const ret = [];
      // eslint-disable-next-line
      item.data?.forEach((inputData, index) => {
        switch (inputData.type) {
          case 'text':
            return ret.push(
              <>
                {inputData.prefix && (
                  <Text
                    classes={{ Text: PrefixText }}
                    fontSize={16}
                    fontWeight={600}
                    color={Themes.light.gray08}
                    inner={inputData.prefix}
                  />
                )}
                <ItemInputText
                  type={inputData.type}
                  name={`${item.name}-${inputData.type}-${index}`}
                  placeholder={inputData.placeholder}
                  required={item.required}
                  errorMessage={errors[item.name]?.message}
                  maxLength={inputData.maxLength}
                />
                {inputData.metrics && (
                  <Text
                    classes={{ Text: MetricsText }}
                    fontSize={16}
                    fontWeight={600}
                    color={Themes.light.gray08}
                    inner={inputData.metrics}
                  />
                )}
              </>
            );
          case 'button':
            return ret.push(
              <ButtonContainer>
                <Button onClick={inputData.onClick} classes={{ UnderLineStyle: ThirdUnderLineStyle }}>
                  {inputData.value}
                </Button>
              </ButtonContainer>
            );
          case 'listBox':
            return ret.push(
              <ListBox>
                {inputData.content.map((contentTitle) => (
                  <List>{contentTitle}</List>
                ))}
              </ListBox>
            );
          case 'selectbox':
            return ret.push(<SelectBox name={inputData.name} options={inputData.options} />);
          case 'dual-selectbox':
            return ret.push(
              <DualSelectbox>
                {inputData.name.map((name, idx) => (
                  <SelectBox name={name} options={inputData.options[idx]} />
                ))}
              </DualSelectbox>
            );
          case 'radio':
            return ret.push(
              <RadioButtonGroup
                id={`${item.name}-${inputData.type}-${index}`}
                name={`${item.name}-${inputData.type}-${index}`}
                content={inputData.content}
                classes={RadioCss}
                defaultValue={inputData.defaultValue}
              />
            );
          case 'image-radio':
            return ret.push(
              <RadioButtonImageGroup
                id={`${item.name}-${inputData.type}-${index}`}
                name={`${item.name}-${inputData.type}-${index}`}
                content={inputData.content}
                images={inputData.images}
                classes={{ InputStyle }}
                defaultValue={inputData.defaultValue}
              />
            );
          case 'string':
            if (item.type === 'image') {
              return ret.push(
                <Template>
                  <Text fontSize={14} fontWeight={700} color={Themes.light.gray07} inner={inputData.content} />
                </Template>
              );
            }
            return ret.push(
              <Text fontSize={14} fontWeight={700} color={Themes.light.gray07} inner={inputData.content} />
            );

          case 'textarea':
            return ret.push(
              <Text
                fontSize={14}
                fontWeight={700}
                width={400}
                lineHeight={24}
                color='rgba(76, 76, 76, 1)'
                inner={inputData.content}
              />
            );
          case 'checkbox':
            return ret.push(
              <CheckBoxGroup name={`${inputData.type}-${item.name}`} content={inputData.content} rootIndex={index} />
            );
          case 'image':
            return ret.push(<ImageUpload name={item.name} required={item.required} />);
          case 'image-preview':
            return ret.push(<ImagePreview image={inputData.image} />);
          case 'video':
            return ret.push(<VideoUpload name={item.name} required={item.required} />);
          case 'choose-content-button':
            return ret.push(
              <ChooseContentButton
                name={item.name}
                initialPlaceholder={inputData.initialPlaceholder}
                buttonTitle={inputData.buttonTitle}
                showPopupClick={showContentAlert}
              />
            );
          case 'chip':
            return ret.push(
              <InputChip
                name={item.name}
                required={item.required}
                placeholder={inputData.placeholder}
                maxLength={inputData.maxLength}
                content={inputData.content}
                maxContent={inputData.maxContent}
              />
            );
          case 'text-editor':
            return ret.push(<TextEditor name={item.name} />);
          default:
            break;
        }
      });

      return item.multiline ? (
        <ItemContainer key={`ItemContainer-${item.name}-multiline`}>
          <ItemName
            required={item.required}
            fontSize={16}
            fontWeight={700}
            color={Themes.light.gray10}
            inner={item.name}
          />
          <MultiLineSection>
            {ret.map((el, index) => {
              return (
                // eslint-disable-next-line
                <MultiLineItemContainer key={`MultiLineItemContainer-${item.name}-multilineItem${index}`}>
                  {el}
                </MultiLineItemContainer>
              );
            })}
          </MultiLineSection>
        </ItemContainer>
      ) : (
        <SectionItem
          name={item.name && item.name}
          label={item.label && item.label}
          value={item.value}
          type={item.type}
          input={ret}
          required={item.required}
        />
      );
    },
    [errors]
  );

  return data.map((item) => {
    return createInputEl(item);
  });
};

InputComponent.propTypes = {
  data: PropTypes.array,
  errors: PropTypes.object,
  label: PropTypes.string,
  popupElements: PropTypes.shape({
    /** 팝업 상단 타이틀 */
    popUpTitle: PropTypes.string,
    /** 팝업에서 getValues 할 key(hook form - name) */
    valueKey: PropTypes.string,
    /** SetValue 하는 함수 */
    handleContentTitle: PropTypes.func.isRequired,
    /** Table에서 사용되는 데이터 객체 */
    popupData: PropTypes.array.isRequired,
    /** Table에서 사용되는 column 객체 */
    popupColumns: PropTypes.array.isRequired,
    /** RadioButton 클릭을 통해 선택된 value */
    selectedValue: PropTypes.string.isRequired,
    /** 팝업에 표시할 table Data가 없을때 나타나는 문구 */
    emptyText: PropTypes.string.isRequired,
  }),
};

export default InputComponent;
