import { useContext } from 'react';

import styled, { css, useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Img, Span, Text } from '@components/Atoms/Atoms';
import { ModalContext } from '@components/Context/ModalProvider';
import Divider from '@components/Surface/Divider';

import { ModalCloseBtnIcon } from '@images/index';

const LoginModalContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 370px;
  min-height: 240px;

  ${({ classes }) => classes && classes.Container}
`;

const LoginModalHeader = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.gray01};
  color: #343c47;
  ${({ classes }) => classes && classes.Header}
`;

const LoginModalCloseButton = styled(Span)`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 0;
  cursor: pointer;
  width: 15px;
  height: 15px;
  ${({ classes }) => classes && classes.CloseBtn}
`;

const LoginModalBody = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  ${({ classes }) => classes && classes.Body}
`;

const DividerDefault = css`
  margin-top: 16px;
  margin-bottom: 36px;
`;

const Modal = ({ title, body, btns, isNestedModal, classes }) => {
  const { closeModal, closeNestedModal } = useContext(ModalContext);
  const theme = useTheme();

  return (
    <LoginModalContainer>
      <LoginModalHeader>
        {title && (
          <Text
            classes={{ Text: classes?.Text }}
            fontSize={20}
            fontWeight={700}
            color={theme.gray07}
            innerHtml={title}
          />
        )}
        <LoginModalCloseButton onClick={isNestedModal ? closeNestedModal : closeModal}>
          <Img src={ModalCloseBtnIcon} alt='닫기' />
        </LoginModalCloseButton>
        {title && <Divider classes={{ Divider: classes?.Text ?? DividerDefault }} />}
      </LoginModalHeader>
      <LoginModalBody>{body}</LoginModalBody>
      {btns && btns}
    </LoginModalContainer>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.node]),
  btns: PropTypes.node,
  /** 중첩된 팝업 (두번째 팝업) 인지 여부 */
  isNestedModal: PropTypes.bool,
};

export default Modal;
