import { useState, useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Skeleton from './Skeleton';

const Container = styled.div`
  ${({ classes }) => classes && classes.Div}
`;

const Div = forwardRef(
  ({ className, classes, innerHtml = null, isLoading = false, loader = {}, children, ...props }, ref) => {
    const skeletonRef = useRef(null);
    const [skeletonRect, setSkeletonRect] = useState({});

    useEffect(() => {
      if (skeletonRef.current) {
        const { width, height } = skeletonRef.current.getBoundingClientRect();
        setSkeletonRect({ width, height });
      }
    }, [skeletonRef]);

    return innerHtml && !isLoading ? (
      <Container
        ref={ref}
        className={className}
        classes={classes}
        {...props}
        dangerouslySetInnerHTML={{
          __html: innerHtml,
        }}
      />
    ) : (
      <Container
        ref={(r) => {
          if (ref) {
            // eslint-disable-next-line no-param-reassign
            ref.current = r;
          }
          skeletonRef.current = r;
        }}
        className={className}
        classes={classes}
        {...props}
      >
        {isLoading ? (
          <Skeleton
            variant={loader.variant || 'rectangular'}
            width={loader.width || '100%'}
            height={loader.height || skeletonRect.height}
          />
        ) : (
          children
        )}
      </Container>
    );
  }
);

Div.propTypes = {
  /** Children */
  children: PropTypes.node,
  /** Loading 화면 표시 */
  isLoading: PropTypes.bool,
  /** Loader 설정 */
  loader: PropTypes.shape({
    variant: PropTypes.oneOf(['text', 'rectangular', 'rounded', 'circular']).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  /** ClassName */
  className: PropTypes.string,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  /** Text 컴포넌트에 직접 넣을 innerHtml */
  innerHtml: PropTypes.string,
};

Div.displayName = 'Div';

export default Div;
