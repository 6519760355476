import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useSearchParams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState(new URLSearchParams(location.search));

  const setParam = (key, value) => {
    searchParams.set(key, value);
    navigate({ search: searchParams.toString() });
  };

  const deleteParam = (key) => {
    searchParams.delete(key);
    navigate({ search: searchParams.toString() });
  };

  const getParam = (key) => {
    return searchParams.get(key);
  };

  const getParams = () => {
    return Object.fromEntries(searchParams.entries());
  };

  const addParamIfExists = (param, key, value) => {
    if (value !== null && value !== undefined) {
      return { ...param, [key]: value };
    }
    return param;
  };

  const createParams = (paramsObj) => {
    return Object.entries(paramsObj).reduce((param, [key, value]) => {
      return addParamIfExists(param, key, value);
    }, {});
  };

  const getSearchParamsObject = useCallback(() => {
    const searchParamObj = {
      page: getParam('page') ? Number(getParam('page')) - 1 : 0,
      size: getParam('size') ? Number(getParam('size')) : 10,
      sortColumn: getParam('sortColumn'),
      sortDirection: getParam('sortDirection'),
      startDate: getParam('startDate'),
      endDate: getParam('endDate'),
    };
    return createParams(searchParamObj);
  }, [searchParams]);

  useEffect(() => {
    setSearchParams(new URLSearchParams(location.search));
  }, [location.search]);

  return { searchParams, setParam, deleteParam, getParam, getParams, getSearchParamsObject };
};

export default useSearchParams;
