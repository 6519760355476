/** 관리자 계정 관리 > 관리자 등급설정 > 등급 선택 > 수정 */
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOrUpdateAccountRoleAPI, getAccountRoleDetailAPI } from '@redux/action/accountsAction';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import styled, { css } from 'styled-components';

import {
  Button,
  Checkbox,
  Div,
  FormProviderWithDevTools,
  Modal,
  ModalContext,
  RadioButton,
  Text,
} from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';

import PATHS from '@router/paths';
import { CheckBoxChecked } from '@modules/FTA/assets';
import useContentMode from '@modules/FTA/hooks/useContentMode';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const PageDesc = styled(Text)`
  color: ${({ theme }) => theme.gray06};
  span {
    color: red;
  }
`;

const AccessibleMenuListContainer = styled(Div)`
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 150px));
  gap: 14px 6px;
`;

const ExtendedCheckbox = styled(Checkbox)`
  color: #605039;
`;

const checkboxInputStyle = css`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

const checkboxCustomUIStyle = css`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border: 1px solid ${({ theme }) => theme.gray05};
  border-radius: 5px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 10px;
`;

const checkboxT = css`
  &:after {
    content: '';
    display: block;
    background-image: url(${CheckBoxChecked});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }
`;

const checkBoxDisabled = css`
  background-color: ${({ theme }) => theme.gray04};
`;

const FORM_NAMES = Object.freeze({
  LEVEL: 'level',
  ACCESSIBLE_MENU: 'accessibleMenu',
  APPROVAL_AUTHORITY: 'approvalAuthority',
});

const schema = yup.object().shape({
  [FORM_NAMES.ACCESSIBLE_MENU]: yup.object().required('접근 가능 메뉴를 선택해주세요.'),
  [FORM_NAMES.APPROVAL_AUTHORITY]: yup.string().required('승인 권한을 선택해주세요.'),
});

const AccountRoleEdit = () => {
  const dispatch = useDispatch();
  const accountRole = useSelector((state) => state.accounts.accountRoleDetail);
  const accessibleMenuEnumList = useSelector((state) => state.common.list.AccessibleMenuType);
  const approvalAuthorityEnumList = [
    { code: '1', value: '가능' },
    { code: '0', value: '불가' },
  ];

  const navigate = useNavigate();
  const { targetId: adminClassInfoId } = useContentMode('adminClassInfoId');

  const { showModal, closeModal } = useContext(ModalContext);

  const inputForm = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });
  const { handleSubmit, reset } = inputForm;

  const accountRoleGridData = useMemo(
    () => [
      {
        title: '관리자 등급',
        content: [<Text>{accountRole?.className}</Text>],
      },
      {
        title: '접근 가능 메뉴',
        content: [
          <AccessibleMenuListContainer>
            {accessibleMenuEnumList?.map((menu) => (
              <ExtendedCheckbox
                id={menu.code}
                name={`${FORM_NAMES.ACCESSIBLE_MENU}.${menu.code}`}
                item={menu}
                classes={{
                  Input: checkboxInputStyle,
                  Box: checkboxCustomUIStyle,
                  CheckboxT: checkboxT,
                  Disabled: checkBoxDisabled,
                }}
              />
            ))}
          </AccessibleMenuListContainer>,
        ],
      },
      {
        title: '승인 권한',
        content: [
          // NOTE: enum이 없음
          approvalAuthorityEnumList.map((authority, index) => (
            <RadioButton id={index} name={FORM_NAMES.APPROVAL_AUTHORITY} item={authority} />
          )),
        ],
      },
    ],
    [accountRole, accessibleMenuEnumList]
  );

  const handleSaveClick = ({ accessibleMenu, approvalAuthority }) => {
    const filteredAccessibleMenu = Object.values(accessibleMenu).filter((item) => item);
    const params = {
      adminClassInfoId,
      accessibleMenu: filteredAccessibleMenu,
      approvalAuthority: !!parseInt(approvalAuthority, 10),
    };
    createOrUpdateAccountRoleAPI(dispatch, params);
    navigate(`/${PATHS.ACCOUNT_ROLE}`);
    closeModal();
  };

  const handleSave = useCallback(() => {
    showModal(
      <Modal
        body='입력한 정보를 저장하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={handleSubmit(handleSaveClick)}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              저장
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, [handleSubmit]);

  const handleCancel = useCallback(() => {
    showModal(
      <Modal
        body='정보 저장을 취소하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                navigate(`/${PATHS.ACCOUNT_ROLE}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  useEffect(() => {
    getAccountRoleDetailAPI(dispatch, { adminClassInfoId });
  }, []);

  useEffect(() => {
    const accessibleMenu = accountRole?.accessibleMenuList?.reduce((acc, { code }) => {
      acc[code] = code;
      return acc;
    }, {});
    console.log('### accountRole', accountRole?.accessibleMenuList);
    console.log('### accessibleMenu', accessibleMenu);

    reset({
      [FORM_NAMES.ACCESSIBLE_MENU]: accessibleMenu,
      [FORM_NAMES.APPROVAL_AUTHORITY]: accountRole?.approvalAuthority ? '1' : '0',
    });
  }, [accountRole, accessibleMenuEnumList]);

  return (
    <Container>
      <PageTitle title='관리자 등급설정' />
      <PageDesc>
        별표(<span>*</span>)가 표시된 항목은 필수입력 항목입니다.
      </PageDesc>
      <FormProviderWithDevTools {...inputForm}>
        <TitleWithDivider title='기본 정보' />
        <GridLayout data={accountRoleGridData} />
      </FormProviderWithDevTools>
      <ButtonSection>
        {/* FIXME: 여기 눌렀을 때 validation 하고 안되어있으면 modal 안뜨도록 해야 함 */}
        <Button onClick={handleSave} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
          저장
        </Button>
        <Button onClick={handleCancel} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
          취소
        </Button>
      </ButtonSection>
    </Container>
  );
};

export default AccountRoleEdit;
