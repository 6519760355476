import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import useAuth from '@FTA/hooks/useAuth';
import { Div, Text } from '@components/index';
import { formatTime } from '@utils';

const Container = styled(Div)`
  padding-top: 3px;
  display: flex;
`;

const MINUTE = 60 * 1000;
const SECONDS = 1000;

const Timer = () => {
  const expireTime = useSelector((state) => state.auth.authInfo.expireTime);
  const startTime = useMemo(() => expireTime - new Date().getTime(), [expireTime]);
  const [currentTime, setCurrentTime] = useState(startTime);
  const { logout } = useAuth();

  const minutes = Math.floor(currentTime / MINUTE);
  const seconds = Math.floor((currentTime % MINUTE) / SECONDS);

  useEffect(() => {
    setCurrentTime(startTime);

    const timer = setInterval(() => {
      setCurrentTime((prevTime) => {
        if (prevTime <= 0) {
          logout();
          clearInterval(timer);
          return 0;
        }
        return prevTime - SECONDS;
      });
    }, SECONDS);

    return () => clearInterval(timer);
  }, [startTime]);

  return (
    <Container>
      <Text>{formatTime(minutes)}</Text>
      <Text>:</Text>
      <Text>{formatTime(seconds)}</Text>
    </Container>
  );
};

Timer.propTypes = {};

export default Timer;
