import { useRef, useCallback, useState } from 'react';
import { Div, FileInput, Text } from '@components/index';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import Themes from '@styles/Themes';
import axios from 'axios';
import { getUrl } from '@redux/function/AxiosConfig';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const Container = styled(Div)`
  display: flex;
  position: relative;
  align-items: center;

  gap: 14px;
`;
const FileNameContainer = styled(Div)`
  display: flex;
  width: 627px;
  height: 49px;
  border: 1px solid ${({ theme }) => theme.gray04};
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;

const Label = styled.label``;
const InputDisplay = css`
  display: none;
`;
const UploadBtn = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 101px;
  height: 36px;
  border-radius: 50px;
  opacity: 0px;
  background-color: ${({ theme }) => theme.gray03};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.gray04};
  }
`;
const BgmUpload = ({ name, required, onFileChange }) => {
  const bgmFileRef = useRef(null);
  const [fileName, setFileName] = useState('효과음 파일을 업로드해주세요.');
  const { setValue } = useFormContext();
  const { token } = useSelector((state) => state.auth.authInfo);
  const uuid = uuidv4();

  const handlebgmFile = useCallback((event) => {
    const selectedFile = event.target.files[0];

    // FileReader 객체 생성
    const reader = new FileReader();

    // 파일 읽기가 완료되면 실행되는 콜백 함수
    reader.onload = async () => {
      // 이미지 URL을 상태에 설정하여 미리보기
      setFileName(event.target.files[0].name);

      onFileChange && onFileChange(selectedFile, reader);

      const BASE_URL = 'v1/mys3/media-upload';
      const URL = getUrl(BASE_URL);
      const formData = new FormData();
      formData.append('file', selectedFile);

      const res = await axios.post(URL, formData, {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
      });

      setValue(name, res.data);
    };

    // 파일을 읽음
    reader.readAsDataURL(selectedFile);
  }, []);

  return (
    <Container>
      <FileNameContainer>
        <Text fontSize={12} fontWeight={400} color={Themes.light.gray07} inner={fileName} />
      </FileNameContainer>
      <Label htmlFor={`bgm-fileInput-${name}-${uuid}`}>
        <UploadBtn>
          <Text fontSize={12} fontWeight={600} color={Themes.light.gray08} inner='찾아보기' />
        </UploadBtn>
        <FileInput
          id={`bgm-fileInput-${name}-${uuid}`}
          classes={{ Variant: InputDisplay }}
          onChange={handlebgmFile}
          ref={bgmFileRef}
          name={name}
          required={required}
        />
      </Label>
    </Container>
  );
};

BgmUpload.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  onFileChange: PropTypes.func.isRequired,
};

export default BgmUpload;
