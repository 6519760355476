const ERRORS = {
  INVALID_VALUE: '유효하지 않은 입력입니다.',
  INVALID_CREDENTIALS: '잘못된 ID 또는 비밀번호 입력입니다.',
  ACCOUNT_SUSPENDED:
    '현재 로그인한 계정은 관리자에 의해 사용이 중단되었습니다. 자세한 내용은 LG 전자 헬스케어 사업부로 문의해주세요.',

  // NOTE[CS] PPT 30 입력값 유효성 검증 정의 2/2 에 없는 에러메세지이지만 필요하다고 판단된 메세지
  EMPTY_INPUT: '내용을 입력해주세요.',
  PASSWORD_NOT_MATCHED: '비밀번호가 일치하지 않습니다.',
  DUPLICATED_ID: '이미 사용중인 아이디입니다.',
};

export default Object.freeze(ERRORS);
