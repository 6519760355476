import { useLocation, useSearchParams } from 'react-router-dom';

const useContentMode = (id) => {
  const location = useLocation();
  const isWriteMode = location.pathname.includes('write');
  const [searchParams] = useSearchParams();
  const targetId = !isWriteMode && searchParams.get(id);

  return { isWriteMode, targetId };
};

export default useContentMode;
