import { useContext, useCallback, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styled, { css, useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Div, Img, Span, Icon, Divider, Text, Button, ModalContext } from '@components/index';
import SessionRefreshModal from '@FTA/components/SessionRefreshModal';
import Timer from './Timer';

import { Plus, Minus, FTA_LOGO, ClockIcon, UserProfile } from '@FTA/assets';
import MENU_MAP from '@router/menuMap';
import useUserInfo from '../hooks/useUserInfo';

const Container = styled(Div)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  padding-top: 23px;
  flex: 1;
`;

const SideBarHeader = styled(Div)`
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 252px;
  padding-left: 28px;
  padding-right: 28px;
  box-sizing: border-box;
`;

const DividerMargin = css`
  margin-top: 16px;
  margin-bottom: 36px;
`;

const UserProfileContainer = styled(Div)`
  display: flex;
  gap: 10px;
`;

const UserProfileIcon = styled(Icon)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.graphy01};
`;

const UserInfoContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const UserNameCSS = css`
  flex-grow: 1;
`;

const UserAuthCSS = css`
  flex-grow: 1;
  line-height: 1.5;
`;

const SessionContainer = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding: 0 12px;
  margin-top: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.gray09};
`;

const TimerContainer = styled(Div)`
  display: flex;
  color: ${({ theme }) => theme.graphy01};
`;

const SecondNormalStyle = css`
  color: ${({ theme }) => theme.gray05};
`;

const SecondHoverStyle = css`
  color: ${({ theme }) => theme.gray02};
`;

const SecondUnderLineStyle = {
  NormalStyle: SecondNormalStyle,
  HoverStyle: SecondHoverStyle,
};

const MainUl = styled(Div)`
  list-style: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.gray10};
`;

const SubUl = styled(Div)`
  list-style-type: none;
`;

const MenuItem = styled(Div)`
  padding: 0;
`;

const MenuTitleRow = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 28px;
  padding-right: 28px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
const MenuTitle = styled(Text)``;

const SubItemContainer = styled(Div)``;

const SubItem = styled(Text)`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 11.5px 36px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: ${({ theme }) => theme.graph01} !important;
  }
  ${({ color }) => color && `color: ${color};`}
`;

const SpanArrowStyle = css`
  width: 20px;
  height: 20px;
  display: inline-block;
  overflow: hidden;
`;

const MainIcon = styled(Icon)`
  cursor: pointer;
`;

const SideBarAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { adminNickname, adminId, profileImageFile } = useUserInfo();

  const theme = useTheme();

  const { showModal } = useContext(ModalContext);

  const [expandedMenu, setExpandedMenu] = useState(Array(MENU_MAP.length).fill(false));
  const [currentPath, setCurrentPath] = useState(null);

  const handleRefresh = useCallback(() => {
    showModal(<SessionRefreshModal />);
  }, []);

  const handleExpandedStatus = useCallback((prevValue, index, { path }) => {
    setExpandedMenu((prev) => {
      const newValue = [...prev];
      newValue[index] = !prevValue;
      return newValue;
    });
    if (path) {
      navigate(path);
    }
  }, []);

  const handleNavigateSubPath = useCallback(({ path }) => {
    if (path) {
      navigate(path);
    }
  }, []);

  const handleNavigate = useCallback((path) => {
    if (path) {
      navigate(path);
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    setCurrentPath(location.pathname);
  }, [location]);

  return (
    <Container>
      <SideBarHeader>
        <MainIcon onClick={() => handleNavigate('home')} icon={FTA_LOGO} iconWidth={100} iconHeight={36} />
        <Divider classes={{ Divider: DividerMargin }} />
        <UserProfileContainer>
          <UserProfileIcon icon={profileImageFile?.fileUrl ?? UserProfile} iconWidth={40} iconHeight={40} />
          <UserInfoContainer>
            <Text
              classes={{ Text: UserNameCSS }}
              fontSize={18}
              fontWeight={600}
              color={theme.gray03}
              innerHtml={adminNickname} // NOTE: 어떤 값을 노출할지 정의 없음
            />
            <Text
              classes={{ Text: UserAuthCSS }}
              fontSize={14}
              fontWeight={600}
              color='rgba(229, 229, 229, 0.6)'
              innerHtml={adminId} // NOTE: 어떤 값을 노출할지 정의 없음
            />
          </UserInfoContainer>
        </UserProfileContainer>
        <SessionContainer>
          <TimerContainer>
            <Icon icon={ClockIcon} w={16} h={16} />
            <Timer />
          </TimerContainer>
          <Button
            onClick={handleRefresh}
            layout='text'
            classes={{ UnderLineStyle: SecondUnderLineStyle }}
            fontSize={14}
          >
            연장하기
          </Button>
        </SessionContainer>
      </SideBarHeader>
      <MainUl>
        {MENU_MAP.map((item, index) => (
          <MenuItem key={uuidv4()}>
            <MenuTitleRow onClick={() => handleExpandedStatus(expandedMenu[index], index, item)}>
              <MenuTitle fontSize={18} fontWeight={600} color={theme.gray04} innerHtml={item.mainTitle} />
              {item.subItem && (
                <Span classes={{ Span: SpanArrowStyle }}>
                  <Img src={expandedMenu[index] ? Minus : Plus} alt='더보기' />
                </Span>
              )}
            </MenuTitleRow>
            {expandedMenu[index] && item.subItem && (
              <SubUl>
                <SubItemContainer>
                  {item.subItem.map((subItem) => (
                    <SubItem
                      key={uuidv4()}
                      onClick={() => handleNavigateSubPath(subItem)}
                      fontSize={16}
                      fontWeight={600}
                      color={currentPath === subItem.path ? theme.gray01 : theme.gray06}
                      innerHtml={subItem.subTitle}
                    />
                  ))}
                </SubItemContainer>
              </SubUl>
            )}
          </MenuItem>
        ))}
      </MainUl>
    </Container>
  );
};

export default SideBarAdmin;
