import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';

import { Div, Icon, Text } from '@components/index';

import { EmptyIcon } from '@FTA/assets';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  margin: auto 0;
`;

const TextStyles = css`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.17;
  color: ${({ theme }) => theme.gray07};
`;

const EmptyData = ({ text }) => {
  return (
    <Container>
      <Icon icon={EmptyIcon} iconWidth={120} iconHeight={120} />
      <Text classes={{ Text: TextStyles }}>{text}</Text>
    </Container>
  );
};

EmptyData.propTypes = {
  text: PropTypes.string,
};

export default EmptyData;
