/** 승인 관리 > 승인 대기 컨텐츠 */

import { FormProvider, useForm } from 'react-hook-form';
import { createColumnHelper } from '@tanstack/react-table';

import { convertDateToString } from '@utils';
import { Table } from '@components/index';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';
import { useCallback, useMemo } from 'react';
// import styled, { css } from 'styled-components';
import useApprovalPendingContent from '@modules/FTA/hooks/approvalPending/useApprovalPendingContent';
import DetailButton from '@modules/FTA/components/DetailButton';
import paths from '@router/paths';

// const ButtonContainer = styled(Div)`
//   display: flex;
//   justify-content: end;
//   margin-top: 30px;
// `;

// const FooterButtonStyle = css`
//   padding: 8px 4px;
// `;

const columnHelper = createColumnHelper();

// FIXME[CS] 추후에 real data로 넘어갈 때 value 값 수정
const TABLE_KEYS = Object.freeze({
  UUID: 'uuid',
  NO: 'no',
  CONTENT_NAME: 'contentsName',
  CP_NAME: 'cpName',
  MAIN_CATEGORY: 'mainCategoryType',
  SUB_CATEGORY: 'subCategoryType',
  CATEGORY: 'categoryType',
  RECOMMENDED_AGE: 'recommendAgeType',
  LEVEL: 'levelType',
  EXERCISE_INTENSITY: 'exerciseIntensityType',
  REGIST_DATE: 'registDate',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, title: 'no', checked: false },
  { code: TABLE_KEYS.CONTENT_NAME, title: '컨텐츠명', checked: false },
  { code: TABLE_KEYS.CP_NAME, title: 'CP명', checked: false },
];

const PendingApprovalContent = () => {
  const methods = useForm();
  const { pendingContentList, pendingCotentPaging, convertEnum } = useApprovalPendingContent();

  const convertToMapEnum = useCallback((el) => {
    /* eslint-disable no-else-return */
    if (Array.isArray(el)) {
      const convert = el.map((item) => convertEnum(item));
      return convert;
    } else {
      return convertEnum(el);
    }
  }, []);

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.NO, {
        id: TABLE_KEYS.NO,
        header: () => <span>No</span>,
        cell: ({ row: { index } }) => <span>{pendingContentList && pendingContentList.length - index}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.CONTENT_NAME, {
        id: TABLE_KEYS.CONTENT_NAME,
        header: () => <span>컨텐츠명</span>,
        cell: ({ getValue, row: { original } }) => {
          const contentsInfoId = original?.contentsInfoId;
          const mainCategory = original?.mainCategoryType;
          const content = 'cp';
          return (
            <DetailButton pathname={paths.CONTENTS_CP_DETAIL} searchParams={{ contentsInfoId, mainCategory, content }}>
              {getValue()}
            </DetailButton>
          );
        },
      }),
      columnHelper.accessor(TABLE_KEYS.CP_NAME, {
        id: TABLE_KEYS.CP_NAME,
        header: () => <span>CP명</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.MAIN_CATEGORY, {
        id: TABLE_KEYS.MAIN_CATEGORY,
        header: () => <span>컨텐츠 분류 1</span>,
        cell: ({ getValue }) => <span>{convertEnum(getValue())}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.SUB_CATEGORY, {
        id: TABLE_KEYS.SUB_CATEGORY,
        header: () => <span>컨텐츠 분류 2</span>,
        cell: ({ getValue }) => <span>{convertToMapEnum(getValue())}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.CATEGORY, {
        id: TABLE_KEYS.CATEGORY,
        header: () => <span>카테고리</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.RECOMMENDED_AGE, {
        id: TABLE_KEYS.RECOMMENDED_AGE,
        header: () => <span>권장 연령대</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.LEVEL, {
        id: TABLE_KEYS.LEVEL,
        header: () => <span>난이도</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.EXERCISE_INTENSITY, {
        id: TABLE_KEYS.EXERCISE_INTENSITY,
        header: () => <span>운동강도</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.REGIST_DATE, {
        id: TABLE_KEYS.REGIST_DATE,
        header: () => <span>등록일</span>,
        cell: ({ getValue }) => <span>{convertDateToString(new Date(getValue()), '.')}</span>,
      }),
    ],
    [pendingContentList]
  );
  /* eslint-enable react/no-unstable-nested-components */

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {pendingContentList && pendingContentList.length > 0 ? (
        <>
          <FormProvider {...methods}>
            <SearchContainer options={TABLE_OPTIONS} hasChip hasColumnFilter hasInputSelectBox hasDateFilter />
          </FormProvider>
          <Table
            data={pendingContentList}
            columns={COLUMNS_DATA}
            paging={pendingCotentPaging}
            hasChip
            hasColumnFilter
            hasInputSelectBox
          />
        </>
      ) : (
        <EmptyData text='승인 대기 중인 컨텐츠가 없습니다.' />
      )}
      {/* <ButtonContainer>
        <Button
          classes={{ Button: FooterButtonStyle }}
          $layout='square'
          $variantColor='primary'
          width={51}
          height={36}
          fontSize={14}
          onClick={() => {}}
        >
          등록
        </Button>
      </ButtonContainer> */}
    </>
  );
};

export default PendingApprovalContent;
