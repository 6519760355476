import { useRef, forwardRef, cloneElement } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Span } from '@components/Atoms/Atoms';

const StyledContainer = styled(Div)`
  position: relative;
  z-index: 50;
  ${(props) => props.classes && props.classes.Container}
`;

const StyledContainerlist = styled.ul`
  position: relative;
  display: inline-flex;
  ${(props) => props.classes && props.classes.Containerlist}
`;

const ContainerSlider = styled(Span)`
  position: absolute;
  display: block;
  content: '';
  bottom: 0;
  min-width: 5.857142857142857em;
  height: 2px;
  border-radius: 20px 20px 0 0;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${(props) => props.classes && props.classes.TabSliderStyle}
`;

const HorizontalScrollableContainer = forwardRef(({ id, classes, children }, ref) => {
  const sliderRef = useRef(null);

  return (
    <StyledContainer id={id} ref={ref} classes={classes}>
      <StyledContainerlist classes={classes} role='tablist'>
        {children &&
          children.map((child, index) =>
            cloneElement(child, {
              key: `StyledContainerItem${index + 1}`,
              classes: { ...child.classes },
            })
          )}
        <ContainerSlider ref={sliderRef} classes={classes} />
      </StyledContainerlist>
    </StyledContainer>
  );
});

HorizontalScrollableContainer.propTypes = {
  /** Container id */
  id: PropTypes.string,
  /** 부모 컴포넌트가 전달한 style */
  classes: PropTypes.string,
  /** 부모가 전달한 자식요소 */
  children: PropTypes.node,
};

export default HorizontalScrollableContainer;
