import { rest } from 'msw';
import MockProgramsInstance from '../data/msw/program';

const BASE_URL = 'v1/admin';

// eslint-disable-next-line import/prefer-default-export
export const programHandler = [
  // NOTE: 프로그램, 세션 목록 조회 API
  rest.get(`/${BASE_URL}/program/list`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(MockProgramsInstance.getPrograms()));
  }),
  // NOTE: 프로그램, 세션 목록 단건 조회 API
  rest.get(`/${BASE_URL}/program/list/:programInfoId`, (req, res, ctx) => {
    const { programInfoId } = req.params;

    if (!programInfoId || programInfoId === 'undefined') return res(ctx.status(500));

    const account = MockProgramsInstance.getAccount(programInfoId);

    if (account) {
      return res(ctx.status(200), ctx.json(account));
    }
    return res(ctx.status(404));
  }),

  // rest.get(`/${BASE_URL}/program/list`, (req, res, ctx) => {
  //   const approvalStatusPending = req.url.searchParams.get('approvalStatus');

  //   let axiosUrl;

  //   if (approvalStatusPending === 'pending' || approvalStatusPending) {
  //     axiosUrl = res(ctx.status(200), ctx.json(MockProgramsInstance.getApprovalStatusProgram(approvalStatusPending)));
  //   } else {
  //     axiosUrl = res(ctx.status(200), ctx.json(MockProgramsInstance.getPrograms()));
  //   }

  //   return axiosUrl;
  // }),
];
