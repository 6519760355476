import Cell from '@FTA/components/Cell';
import { Grid } from '@components/index';
import { css } from 'styled-components';

const GridStyle = css`
  margin-bottom: 40px;
`;

const Advertisement = () => {
  return (
    <>
      <Grid title='비디오광고' column={4} classes={{ Grid: GridStyle }}>
        <Cell title='비디오 광고 노출수' value='19900' />
        <Cell title='비디오광고 노출시간(sec) ' value='24' />
      </Grid>

      <Grid title='배너광고' column={3} classes={{ Grid: GridStyle }}>
        <Cell title='배너 광고 클릭수' value='17400' />
        <Cell title='배너 광고 클릭:제휴 사이트 노출시간(sec)' value='21' />
      </Grid>

      <Grid title='아웃도어 액티비티' column={5} classes={{ Grid: GridStyle }}>
        <Cell title='산책 TV 광고표지판 클릭 수' value='12333' />
        <Cell title='산책 TV 광고표지판클릭 : 제휴사이트 노출시간(sec)' value='1150' />
      </Grid>
    </>
  );
};

Advertisement.propTypes = {};

export default Advertisement;
