import styled from 'styled-components';
import { Div } from '@components/Atoms/Atoms';
import PropTypes from 'prop-types';
import { useCallback, useContext } from 'react';
import { Button, Modal, ModalContext } from '@components/index';

const getImg = ({ image }) => image;

const Container = styled(Div)`
  display: flex;
`;

const ImageCotainer = styled(Div)`
  border: 1px solid ${({ theme }) => theme.gray03};
  margin-top: 10px;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  background-image: url(${getImg});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
`;

const ButtonContainer = styled(Div)`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

const PreviewImageCotainer = styled(Div)`
  border: 1px solid ${({ theme }) => theme.gray03};
  margin-top: 10px;
  width: 500px;
  height: 500px;
  border-radius: 8px;
  background-image: url(${getImg});
  background-repeat: no-repeat;
  background-size: contain;
`;

const ImagePreview = ({ image }) => {
  const { showModal } = useContext(ModalContext);

  const onClickImagePreview = useCallback(
    (img) => {
      showModal(<Modal body={<PreviewImageCotainer image={img} />} />);
    },
    [image]
  );

  return (
    <Container>
      <ImageCotainer image={image} onClick={() => onClickImagePreview(image)} />
      <ButtonContainer>
        <Button
          onClick={() => onClickImagePreview(image)}
          $layout='round'
          $variantColor='gray'
          width={85}
          height={35}
          $imgVariant='play'
        >
          미리보기
        </Button>
      </ButtonContainer>
    </Container>
  );
};
ImagePreview.propTypes = {
  /** 이미지 경로 */
  image: PropTypes.string.isRequired,
};

export default ImagePreview;
