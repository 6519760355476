import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Input, RadioButton, SelectBox } from '@components/index';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';

const RadioButtonContainer = styled(Div)``;

const SelectBoxContainer = styled(Div)`
  display: grid;
  grid-template-columns: repeat(2, 400px);
  column-gap: 8px;
`;

const SelectBoxCountry = {
  guide: '국가 선택',
  list: [
    { id: 'KR', title: 'KR' },
    { id: 'EN', title: 'EN' },
  ],
};

const SelectBoxLanguage = {
  guide: '언어 선택',
  list: [
    { id: 'KR', title: 'KR' },
    { id: 'EN', title: 'EN' },
  ],
};

const TARGET_ENUM_KEYS = Object.freeze({
  MAIN_CATEGORY: 'MainCategoryType',
  SUB_CATEGORY: 'SubCategoryType',
});

const SUB_CATEGORY_WHITE_LIST = Object.freeze({
  MCT000: ['SCT000', 'SCT001', 'SCT002', 'SCT004'],
  MCT001: ['SCT000', 'SCT001', 'SCT002', 'SCT004'],
  MCT002: ['SCT003', 'SCT005', 'SCT006', 'SCT007'],
  MCT003: ['SCT000', 'SCT001', 'SCT002', 'SCT004'],
});

const CPDefaultInfoForm = ({ formNames }) => {
  const { getEnumsByKey } = useEnumSearch();
  const mainCategoryEnumList = getEnumsByKey(TARGET_ENUM_KEYS.MAIN_CATEGORY);
  const subCategoryEnumList = getEnumsByKey(TARGET_ENUM_KEYS.SUB_CATEGORY);

  const mainCategoryWatch = useWatch({ name: formNames.MAIN_CATEGORY });

  const filteredSubCategoryEnumList = useMemo(
    () =>
      subCategoryEnumList?.filter((subCategoryEnum) =>
        SUB_CATEGORY_WHITE_LIST[mainCategoryWatch]?.includes(subCategoryEnum.code)
      ),
    [subCategoryEnumList, mainCategoryWatch]
  );

  const DEFAULT_INFO = useMemo(
    () => [
      {
        title: 'CP명',
        isRequired: true,
        content: [
          <Input required name={formNames.CP_NAME} type='text' placeholder='CP명을 입력해주세요.' maxLength='100' />,
        ],
      },
      {
        title: '컨텐츠 분류 1',
        isRequired: true,
        content: [
          <RadioButtonContainer>
            {mainCategoryEnumList?.map((mainCategoryEnum) => (
              <RadioButton
                required
                key={mainCategoryEnum.code}
                id={mainCategoryEnum.code}
                name={formNames.MAIN_CATEGORY}
                item={mainCategoryEnum}
              />
            ))}
          </RadioButtonContainer>,
        ],
      },
      {
        title: '컨텐츠 분류 2',
        isRequired: true,
        content: [
          <RadioButtonContainer>
            {filteredSubCategoryEnumList?.map((subCategoryEnum) => (
              <RadioButton
                required
                key={subCategoryEnum.code}
                id={subCategoryEnum.code}
                name={formNames.SUB_CATEGORY}
                item={subCategoryEnum}
              />
            ))}
          </RadioButtonContainer>,
        ],
      },
      {
        title: '국가 / 언어',
        content: [
          <SelectBoxContainer>
            <SelectBox name={formNames.NATIONAL} options={SelectBoxCountry} />
            <SelectBox name={formNames.LANGUAGE} options={SelectBoxLanguage} />
          </SelectBoxContainer>,
        ],
      },
    ],
    [mainCategoryEnumList, filteredSubCategoryEnumList]
  );

  return (
    <>
      <TitleWithDivider title='기본 정보' />
      <GridLayout data={DEFAULT_INFO} />
    </>
  );
};

CPDefaultInfoForm.propTypes = {
  formNames: PropTypes.objectOf(PropTypes.string),
};

export default CPDefaultInfoForm;
