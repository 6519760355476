import PATHS from '@router/paths';

const MENU_MAP = [
  { mainTitle: '승인 관리', path: PATHS.APPROVAL },
  {
    mainTitle: 'CP 관리',
    subItem: [{ subTitle: 'CP 정보', path: PATHS.CP_INFO }],
  },
  {
    mainTitle: '외부 센터프로그램 관리',
    subItem: [{ subTitle: '프로그램 목록', path: PATHS.ECP_CONFIGURATION }],
  },
  {
    mainTitle: '컨텐츠 관리',
    subItem: [
      { subTitle: 'CP 컨텐츠 관리', path: PATHS.CONTENTS_CP },
      { subTitle: '자사 컨텐츠 관리', path: PATHS.CONTENTS_LG },
    ],
  },
  {
    mainTitle: '관리자 계정 관리',
    subItem: [
      { subTitle: '관리자 계정', path: PATHS.ACCOUNT },
      { subTitle: '관리자 등급설정', path: PATHS.ACCOUNT_ROLE },
    ],
  },
  {
    mainTitle: '기타 관리',
    subItem: [
      { subTitle: '공지사항', path: PATHS.NOTICE },
      { subTitle: '헬스케어 DB', path: PATHS.HEALTHCAREDB },
      { subTitle: 'CS 대응', path: PATHS.CS_RESPONSE },
      { subTitle: 'FAQ', path: PATHS.FAQ },
      { subTitle: '설문', path: PATHS.SURVEY },
    ],
  },
  {
    mainTitle: '통계 리포트',
    subItem: [
      { subTitle: '앱 통계', path: PATHS.REPORT },
      { subTitle: 'CP 통계', path: PATHS.REPORT_CP },
    ],
  },
];

export default MENU_MAP;
