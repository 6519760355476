import { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { throttle } from 'throttle-debounce';

const ScrollerContainer = styled.div`
  height: ${({ height }) => height || 'calc(100%)'};
  height: ${({ height }) => height || '-webkit-calc(100%)'};
`;

const WrapperEndSlot = styled.div`
  justify-content: center;
  display: flex;
  position: relative;
  bottom: 20px;
`;

let actionTriggered = false;
let el;
let target;

const Scroller = ({ height, fetch, hasMore, scrollThreshold, children, loader, ...rest }) => {
  const [isLoading, setLoading] = useState(false);
  const isBottom = (targetElement, threshold) => {
    const clientHeight =
      targetElement === document.body || targetElement === document.documentElement
        ? window.screen.availHeight
        : targetElement.clientHeight;

    const { scrollTop } = targetElement;
    const { scrollHeight } = targetElement;
    return scrollTop + clientHeight >= threshold * scrollHeight;
  };

  const fetchEvent = useCallback(
    (event) => {
      let tg;
      if (height) {
        tg = event ? event.target : target;
      } else {
        tg = document.documentElement.scrollTop ? document.documentElement : document.body;
      }

      if (actionTriggered) return;
      const atBottom = isBottom(tg, scrollThreshold);
      if (atBottom && hasMore && fetch) {
        actionTriggered = true;
        setLoading(true);
        fetch();
      }
    },
    [fetch, hasMore, height, scrollThreshold]
  );

  const onScrollEvent = (event) => {
    fetchEvent(event);
  };

  const onThrottleScrollEvent = throttle(150, onScrollEvent);

  useEffect(() => {
    actionTriggered = false;
    setLoading(false);
  }, [children, hasMore]);

  useEffect(() => {
    target = height ? el : window;
    if (target) {
      fetchEvent(target);
      target.addEventListener('scroll', onThrottleScrollEvent);
    }
    return () => {
      target.removeEventListener('scroll', onThrottleScrollEvent);
    };
  }, [onThrottleScrollEvent, height, fetchEvent]);

  return (
    <ScrollerContainer
      ref={(scrollRef) => {
        el = scrollRef;
      }}
      height={height}
      {...rest}
    >
      {children}
      {isLoading && hasMore && <WrapperEndSlot>{loader}</WrapperEndSlot>}
    </ScrollerContainer>
  );
};

Scroller.propTypes = {
  /** 스크롤 fetch 데이터 존재 여부 */
  hasMore: PropTypes.bool,
  /** 스크롤 하단 이동시 호출될 함수 */
  fetch: PropTypes.func,
  /** Fetch 함수 수행되어야 하는 지점 (0~1) */
  scrollThreshold: PropTypes.number,
  /** 스트롤 내부 리스트 컴포넌트 */
  children: PropTypes.node,
  /** Fetch 수행시 표시할 로더 컴포넌트 */
  loader: PropTypes.node,
  /** 스크롤 컨테이너 높이 */
  height: PropTypes.string,
};

Scroller.defaultProps = {
  hasMore: false,
  scrollThreshold: 1,
};

export default Scroller;
