/* eslint-disable */
import { useCallback, useMemo } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Div } from '@components/Atoms/Atoms';
import { SortArrow } from '@images/index';
import TableSelectBox from './modules/TableSelectBox';
// import useTablePagination from './modules/useTablePagination';
import TablePagination from './modules/TablePagination';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';

const PAGESIZE_OPTION = Object.freeze({
  pageSize: [5, 10, 25, 30, 50],
});

const hover = ({ isHover }) => isHover && `background-color: #b2b2b2; color: #fff;  cursor: pointer;`;
const titleTr = ({ isTitle }) => !isTitle && ` background-color: #f5f6fa;`;

const Container = styled(Div)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0;
`;

const Thead = styled.thead`
  /* display: table-header-group; */
  vertical-align: middle;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
`;

const Tbody = styled.tbody`
  /* display: table-row-group; */
  vertical-align: middle;
`;

const Tr = styled.tr`
  ${titleTr}
  &:hover {
    ${hover}
  }
  background-color: ${({ theme }) => theme.gray02};
`;

const Th = styled.th`
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 700;
  height: 46px;
  flex: 1;
  background-color: ${({ theme }) => theme.gray02};
  padding: 0 20px;

  & > div {
    display: flex;
    align-items: center;
  }
`;

const Td = styled.td`
  vertical-align: middle;
  font-size: 12px;
  font-weight: 400;
  height: 46px;
  padding: 0 20px;
  border-bottom: 1px solid #d9d9d9;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
`;

const SortBtn = styled.div`
  border: 0;
  outline: 0;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${SortArrow});
`;

const TableInfoContainer = styled(Div)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.gray08};
`;

const TotalLength = styled(Div)`
  padding: 4px 30px 4px 0px;
  border-right: 1px solid ${({ theme }) => theme.gray04};
`;

const Footer = styled(Div)`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 42px;
  justify-content: space-between;
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: end;
`;

const Table = ({ data, columns, isHover = true, buttonList = [], paging }) => {
  /** 테이블 커스텀 훅 */
  // const { pagination, setPagination, onChangePageIndex } = useTablePagination();
  const { searchParams, getParam, setParam } = useSearchParams();
  const table = useReactTable({
    data,
    columns,
    /** NOTE: 페이지네이션 관련 설정 */
    enableRowSelection: false,
    autoResetPageIndex: true,
    // onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),

    /** NOTE: Column 정렬 함수 */
    getSortedRowModel: getSortedRowModel(),
    /** NOTE: 해당 함수를 임포트 해야 필터링된 Row Model을 출력 가능 */
    getFilteredRowModel: getFilteredRowModel(),
    /** NOTE: 각 column 안에 있는 unique한 value들을 종류별로 출력해주는 함수 (컬럼별로 필터 목록을 출력) */
    getFacetedUniqueValues: getFacetedUniqueValues(),
    /** NOTE: Table pagination을 위한 함수 */
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleSort = useCallback(
    (column) => {
      setParam('sortColumn', column.id);
      const direction = getParam('sortDirection');
      if (direction !== 'asc') {
        setParam('sortDirection', 'asc');
      } else {
        setParam('sortDirection', 'desc');
      }
    },
    [searchParams]
  );
  // console.log('###table', pagination);

  return (
    <Container>
      <TableInfoContainer>
        <TotalLength>총 {paging?.totalElements}개 항목</TotalLength>
        <TableSelectBox
          table={table}
          options={PAGESIZE_OPTION.pageSize}
          w={126}
          h={40}
          initialLabel='10개씩 보기'
          itemText='개씩 보기'
        />
        <strong>
          {paging?.number !== undefined ? paging?.number + 1 : 0}/{paging?.totalPages} 페이지
        </strong>
      </TableInfoContainer>
      <StyledTable>
        {/* NOTE: 테이블 헤더 데이터 렌더링 */}
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id} isTitle>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  style={{
                    width: header.getSize(),
                    cursor: header.column.getCanSort() ? 'pointer' : 'default',
                    color: header.column.getIsSorted() ? '#006eb9' : '#000',
                  }}
                  onClick={() => handleSort(header.column)}
                >
                  <div>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort() && !header.column.getIsSorted() ? <SortBtn /> : null}
                    {
                      {
                        asc: <SortBtn />,
                        desc: <SortBtn />,
                      }[header.column.getIsSorted()]
                    }
                  </div>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {/* NOTE: 테이블 바디 데이터 렌더링 */}
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} isHover={isHover}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </StyledTable>
      <Footer>
        <TablePagination table={table} paging={paging} />
        {buttonList && <ButtonContainer>{buttonList}</ButtonContainer>}
      </Footer>
    </Container>
  );
};

Table.propTypes = {
  /** Table에서 사용되는 데이터 객체 */
  data: PropTypes.array.isRequired,
  /** Table에서 사용되는 column 객체 */
  columns: PropTypes.array.isRequired,
  /** Table td hover 배경색 유무 */
  isHover: PropTypes.bool,
  /** Table 상단 버튼 배열 */
  buttonList: PropTypes.array,
  /** Table paging */
  paging: PropTypes.object,
};

export default Table;
