import { Div } from '@components/index';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled(Div)`
  display: grid;
  grid-template-columns: 1fr;
  /* gap: 16px; */
  /* padding: 16px; */
`;

const GridItem = styled(Div)`
  display: grid;
  grid-template-columns: 140px 15fr;
  gap: 20px;
  padding: 16px;
`;

const Title = styled(Div)`
  font-weight: bold;
  padding-right: 8px;
  width: 100%;
  word-break: keep-all;

  ${({ isRequired }) =>
    isRequired &&
    `
      &:after {
        content: '*';
        color: #f44b4a;
        font-size: 13px;
      }
    `}
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
`;

const Content = styled(Div)`
  /* padding-left: 8px; */
`;

const FullLineTitleContainer = styled(Div)`
  display: flex;
  align-items: center;
  width: 80vw;
  height: 59px;
  background: rgba(250, 250, 250, 1);
  border-top: 1px solid rgba(229, 229, 229, 1);
  border-bottom: 1px solid rgba(229, 229, 229, 1);
`;

const FullLineTitle = styled(Div)`
  font-size: 16px;
  font-weight: 700;
  padding-left: 5px;
`;

const ContentContainer = styled(Div)``;

const GridSessionLayout = ({ data, buttonGroup }) => {
  return (
    <Container>
      {data.map((item, index) => {
        if (!item) {
          return null;
        }
        const { title, content, sessionTitle, isRequired, dynamicItems } = item;
        return (
          <>
            <GridItem>
              {buttonGroup && (
                <FullLineTitleContainer>
                  <FullLineTitle>세션{[index + 1]}</FullLineTitle>
                </FullLineTitleContainer>
              )}
              {sessionTitle && (
                <FullLineTitleContainer>
                  <FullLineTitle>{sessionTitle}</FullLineTitle>
                </FullLineTitleContainer>
              )}
              <Title isRequired={isRequired}>{title}</Title>
              {dynamicItems
                ? dynamicItems.map((dynamicItem) => (
                    <>
                      <Title>{dynamicItem.title}</Title>
                      <ContentContainer>{dynamicItem.content}</ContentContainer>
                    </>
                  ))
                : Array.isArray(content) &&
                  content.map((contentItem) => (
                    <ContentContainer>
                      <Content>{contentItem}</Content>
                    </ContentContainer>
                  ))}
            </GridItem>
            <ButtonContainer>{buttonGroup && buttonGroup(index)}</ButtonContainer>
          </>
        );
      })}
    </Container>
  );
};
GridSessionLayout.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fullLineTitle: PropTypes.string,
      title: PropTypes.string.isRequired,
      isRequired: PropTypes.bool,
      content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
      dynamicItems: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          content: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  buttonGroup: PropTypes.node,
};

export default GridSessionLayout;
