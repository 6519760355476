/** 기타 관리 > 헬스케어 DB */

import { useCallback, useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { createColumnHelper } from '@tanstack/react-table';

import { convertDateToString } from '@utils';
import { Button, Div, Table } from '@components/index';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';
import DetailButton from '@FTA/components/DetailButton';
import paths from '@router/paths';
import PageTitle from '@FTA/components/PageTitle';
import styled, { css } from 'styled-components';
import { HealthcareListAPI } from '@redux/action/etcAction';
import { useDispatch, useSelector } from 'react-redux';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';
import { useNavigate } from 'react-router-dom';
import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const FooterButtonStyle = css`
  padding: 8px 4px;
`;

const columnHelper = createColumnHelper();

// FIXME[CS] 추후에 real data로 넘어갈 때 value 값 수정
const TABLE_KEYS = Object.freeze({
  NO: 'no',
  FEEDBACK_DB_TYPE: 'mainCategoryType',
  RATING_DB_CRITERIA: 'subCategoryType',
  COUNTRY: 'country',
  UPDATE_CONTENT: 'content',
  REGISTRATION_DATE: 'registDate',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, title: 'no', checked: false },
  { code: TABLE_KEYS.COUNTRY, title: '국가', checked: false },
  { code: TABLE_KEYS.FEEDBACK_DB_TYPE, title: '제목', checked: false },
];

const HealthCareDB = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchParams, getSearchParamsObject } = useSearchParams();
  const healthcareList = useSelector((state) => state.etc.healthcareList);
  const healthcarePaging = useSelector((state) => state.etc.healthcarePaging);
  const { convertEnum } = useEnumSearch();

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.NO, {
        id: TABLE_KEYS.NO,
        header: () => <span>No</span>,
        cell: ({ row: { index } }) => <span>{healthcareList?.length && healthcareList.length - index}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.FEEDBACK_DB_TYPE, {
        id: TABLE_KEYS.FEEDBACK_DB_TYPE,
        header: () => <span>고객 피드백용 DB종류</span>,
        cell: ({ getValue, row: { original } }) => {
          const { healthCareInfoId = '' } = original;
          return (
            <DetailButton pathname={paths.HEALTHCAREDB_DETAIL} searchParams={{ healthCareInfoId }}>
              {convertEnum(getValue())}
            </DetailButton>
          );
        },
      }),
      columnHelper.accessor(TABLE_KEYS.RATING_DB_CRITERIA, {
        id: TABLE_KEYS.RATING_DB_CRITERIA,
        header: () => <span>등급기준 DB 종류</span>,
        cell: ({ getValue }) => <span>{convertEnum(getValue())}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.UPDATE_CONTENT, {
        id: TABLE_KEYS.UPDATE_CONTENT,
        header: () => <span>업데이트 사항</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.COUNTRY, {
        id: TABLE_KEYS.COUNTRY,
        header: () => <span>국가 / 언어</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.REGISTRATION_DATE, {
        id: TABLE_KEYS.REGISTRATION_DATE,
        header: () => <span>등록일</span>,
        cell: ({ getValue }) => <span>{convertDateToString(new Date(getValue()), '.')}</span>,
      }),
    ],
    [healthcareList]
  );
  /* eslint-enable react/no-unstable-nested-components */

  useEffect(() => {
    const param = getSearchParamsObject();
    HealthcareListAPI(dispatch, param);
  }, [searchParams]);

  const gotoAdd = useCallback(() => {
    navigate(`/${paths.HEALTHCAREDB_WRITE}`);
  }, []);

  // FIXME[CS] 추후에 실제 데이터의 길이로 변경
  if (healthcareList?.length === 0) return <EmptyData text='등록된 헬스케어 DB가 없습니다.' />;

  return (
    <>
      <PageTitle title='헬스케어 DB' />
      <FormProvider {...methods}>
        <SearchContainer options={TABLE_OPTIONS} hasChip hasColumnFilter hasInputSelectBox hasDateFilter />
      </FormProvider>
      <Table
        data={healthcareList}
        columns={COLUMNS_DATA}
        paging={healthcarePaging}
        hasChip
        hasColumnFilter
        hasInputSelectBox
        buttonList={
          <ButtonContainer>
            <Button
              classes={{ Button: FooterButtonStyle }}
              $layout='square'
              $variantColor='primary'
              width={51}
              height={36}
              fontSize={14}
              onClick={gotoAdd}
            >
              등록
            </Button>
          </ButtonContainer>
        }
      />
    </>
  );
};

export default HealthCareDB;
