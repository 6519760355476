/* eslint-disable */

import { useCallback, useEffect, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import axios from 'axios';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import { useDispatch } from 'react-redux';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import { getUrl } from '@redux/function/AxiosConfig';

Quill.register('modules/imageResize', ImageResize);

// const ExtenedTextEditor = styled(ReactQuill)`
//   width: 100%;

//   .ql-container {
//     min-height: 200px;
//     min-width: 640px;
//   }
// `;

const TextEditor = ({ name, defaultValue, isEdit, moduleOption = ['link', 'image'] }) => {
  const dispatch = useDispatch();
  const quillRef = useRef(null);
  const { control } = useFormContext();
  const { field } = useController({ name, control, defaultValue });

  const toolbarOptions = [
    moduleOption,
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
  ];
  const modules = {
    toolbar: {
      container: toolbarOptions,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
  };
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
  ];

  const handleChange = useCallback((content) => {
    field.onChange(content);
  }, []);

  useEffect(() => {
    if (defaultValue) {
      field.onChange(defaultValue);
    }
  }, [defaultValue, field]);

  const BASE_URL = 'v1/mys3/image';
  const URL = getUrl(BASE_URL);
  const uploadCallback = async (file) => {
    const upLoadHeader = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const url = URL;
    const selectedFile = file;
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = async () => {
        try {
          const formData = new FormData();
          formData.append('file', file);
          const res = await axios.post(url, formData, upLoadHeader);
          if (res) {
            const imageUrl = res.data.fileUrl;
            resolve({ url: imageUrl });
          } else {
            reject(new Error('Invalid response format'));
          }
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      const range = quillRef.current.getEditor().getSelection(true);

      try {
        const { url } = await uploadCallback(file);
        // 정상적으로 업로드 됐다면 로딩 placeholder 삭제
        quillRef.current.getEditor().deleteText(range.index, 1);
        // 받아온 url을 이미지 태그에 삽입
        quillRef.current.getEditor().insertEmbed(range.index, 'image', url);
        // 사용자 편의를 위해 커서 이미지 오른쪽으로 이동
        quillRef.current.getEditor().setSelection(range.index + 1);
      } catch (e) {
        quillRef.current.getEditor().deleteText(range.index, 1);
      }
    };
  };
  useEffect(() => {
    if (quillRef.current) {
      const toolbar = quillRef.current.getEditor().getModule('toolbar');
      toolbar.addHandler('image', handleImage);
    }
  }, []);

  return (
    <div>
      <ReactQuill
        style={{ height: '230px' }}
        ref={quillRef}
        theme='snow'
        modules={modules}
        formats={formats}
        value={field?.value}
        placeholder={isEdit === true ? defaultValue : '내용을 입력해주세요'}
        onChange={handleChange}
      />
    </div>
  );
};

TextEditor.propTypes = {
  /** React-hook-form 사용을 위한 name prop */
  name: PropTypes.string.isRequired,
  /** 기본값 */
  defaultValue: PropTypes.string,
  isEdit: PropTypes.bool,
  moduleOption: PropTypes.array,
};

export default TextEditor;
