/** 컨텐츠 관리 > 컨텐츠 관리 > 컨텐츠 클릭(상세 화면 / 단건 조회) */

import { useCallback, useContext, useEffect, useMemo } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import styled, { css } from 'styled-components';

import PATHS from '@router/paths';
import { Button, Div, Modal, ModalContext } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import { useDispatch } from 'react-redux';
import { contentAPIs, CPcontentsDeleteAPI, HandleContentsStatusAPI } from '@redux/action/contentsAction';
import ContentsDetailRoutine from './modules/ContentsDetailRoutine';
import ContentsDetailOutdoor from './modules/ContentsDetailOutdoor';
import ModalRequst from '@modules/FTA/components/ModalRequst';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const ContentsCPDetail = () => {
  const { showModal, closeModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const getContent = searchParams.get('content');
  const getIdsearch = searchParams.get('contentsInfoId');
  const mainCategory = searchParams.get('mainCategory');
  const onClickEdit = useCallback(() => {
    const search = createSearchParams({ contentsInfoId: getIdsearch, adminType: getContent }).toString();

    navigate({ pathname: `/${getContent === 'cp' ? PATHS.CONTENTS_CP_EDIT : PATHS.CONTENTS_LG_EDIT}`, search });
  }, []);

  const onClickDelete = useCallback(() => {
    showModal(
      <Modal
        body='등록된 정보를 삭제하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                const params = {
                  contentsInfoId: getIdsearch,
                };

                if (getContent === 'cp') {
                  CPcontentsDeleteAPI(dispatch, params);
                }

                navigate(`/${getContent === 'lg' ? PATHS.CONTENTS_LG : PATHS.CONTENTS_CP}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const gotoList = useCallback(() => {
    showModal(
      <Modal
        body='목록으로 이동하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                navigate(`/${getContent === 'lg' ? PATHS.CONTENTS_LG : PATHS.CONTENTS_CP}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const contentInfoBottom = useMemo(() => {
    return {
      [getContent]: {
        MCT000: { type: 'Routine', title: '루틴/추천 운동 정보' },
        // NOTE: TBD
        MCT001: { type: 'Routine', title: '루틴/동작별 영상(AI 추천 컨텐츠) 정보' },
        MCT002: { type: 'Outdoor', title: '아웃도어 액티비티 정보' },
        // NOTE: TBD
        MCT003: { type: 'Routine', title: '루틴/추천 운동 정보' },
      },
    };
  }, [getContent, mainCategory]);

  useEffect(() => {
    if (!getContent || !mainCategory) return;

    const params = {
      contentsInfoId: getIdsearch,
    };

    contentAPIs[getContent][mainCategory].detail(dispatch, params);
  }, [getContent, mainCategory, getIdsearch]);

  return (
    <Container>
      <PageTitle title='컨텐츠 관리' />
      {contentInfoBottom[getContent][mainCategory].type === 'Outdoor' && (
        <ContentsDetailOutdoor title={contentInfoBottom[getContent][mainCategory].title} />
      )}
      {contentInfoBottom[getContent][mainCategory].type === 'Routine' && (
        <ContentsDetailRoutine title={contentInfoBottom[getContent][mainCategory].title} />
      )}
      <ButtonContainer>
        {/* TODO:  authSlice loggedUserInfo className 권한 보고 버튼 노출 여부 판별해야 함. */}
        {/* TODO: 기획서에만 적용되어 있고 아직 GUI 반영 안되어 있음. (240718) */}
        <ButtonSection>
          <Button
            onClick={() => {
              showModal(
                <ModalRequst
                  type='approvalRequset'
                  text='등록한 정보를 승인 요청하시겠습니까? 승인 심사 중에는 정보를 수정할 수 없습니다.'
                  closeModal={closeModal}
                  requestAPI={() => HandleContentsStatusAPI(getIdsearch, 'reqApproval')}
                  // FIXME: LG CP인지에따라 이동 바꿔줘야함
                  navi={() => navigate(`/${PATHS.CONTENTS_CP}`)}
                />
              );
            }}
            $layout='square'
            $variantColor='line'
            classes={{ Button: ButtonCss }}
          >
            승인 요청
          </Button>
          <Button
            onClick={() => {
              showModal(
                <ModalRequst
                  type='approval'
                  text='승인하시겠습니까?.'
                  closeModal={closeModal}
                  requestAPI={() => HandleContentsStatusAPI(getIdsearch, 'approval')}
                  // FIXME: LG CP인지에따라 이동 바꿔줘야함
                  navi={() => navigate(`/${PATHS.CONTENTS_CP}`)}
                />
              );
            }}
            $layout='square'
            $variantColor='primary'
            classes={{ Button: ButtonCss }}
          >
            승인
          </Button>
          <Button
            onClick={() => {
              showModal(
                <ModalRequst
                  type='reject'
                  text='반려하시겠습니까?.'
                  closeModal={closeModal}
                  requestAPI={() => HandleContentsStatusAPI(getIdsearch, 'refusal')}
                  // FIXME: LG CP인지에따라 이동 바꿔줘야함
                  navi={() => navigate(`/${PATHS.CONTENTS_CP}`)}
                />
              );
            }}
            $layout='square'
            $variantColor='line'
            classes={{ Button: ButtonCss }}
          >
            반려
          </Button>
        </ButtonSection>
        <ButtonSection>
          <Button onClick={onClickEdit} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
            수정
          </Button>
          <Button onClick={onClickDelete} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            삭제
          </Button>
          <Button onClick={gotoList} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            목록
          </Button>
        </ButtonSection>
      </ButtonContainer>
    </Container>
  );
};

ContentsCPDetail.propTypes = {};

export default ContentsCPDetail;
