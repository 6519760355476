/** 기타 관리 > 공지사항 > 공지사항 클릭(상세 화면) > 수정 */

import { useCallback, useContext, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import styled, { css } from 'styled-components';

import { Button, Div, Input, Modal, ModalContext } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';

import PATHS from '@router/paths';
import TextEditor from '@modules/FTA/components/TextEditor';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import { CSregisterAPI } from '@redux/action/etcAction';
import { useDispatch } from 'react-redux';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

// TODO: 유효성관련 메세지 파일 만들기
// const ERROR_MESSAGE = {
//   inputText: '내용을 입력해주세요.',
// };

const CsResponseEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serarchParams] = useSearchParams();
  const csId = serarchParams.get('getcsRequestInfoId');
  const methods = useForm();
  const { handleSubmit } = methods;

  const { showModal, closeModal } = useContext(ModalContext);

  const FORM_NAMES = Object.freeze({
    TITLE: 'title',
    CONTENT: 'content',
  });

  const CS_DATA = useMemo(
    () => [
      {
        title: '제목',
        isRequired: true,
        content: [<Input name={FORM_NAMES.TITLE} type='text' placeholder='제목을 입력해주세요.' maxLength='100' />],
      },
      {
        title: '내용',
        isRequired: true,
        content: [<TextEditor name={FORM_NAMES.CONTENT} isEdit />],
      },
    ],
    []
  );

  const handleNavigate = useCallback(
    (path) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  const handleSave = useCallback((values) => {
    showModal(
      <Modal
        body='입력한 정보를 저장하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                const param = {
                  title: values.title,
                  content: values.content,
                };
                CSregisterAPI(dispatch, csId, param);
                handleNavigate(`/${PATHS.CS_RESPONSE}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const handleCancel = useCallback(() => {
    showModal(
      <Modal
        body='정보 저장을 취소하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                handleNavigate(`/${PATHS.CS_RESPONSE}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  return (
    <Container>
      <PageTitle title='CS 대응' />
      <FormProvider {...methods}>
        <GridLayout data={CS_DATA} />
        <ButtonSection>
          <Button
            onClick={handleSubmit(handleSave)}
            $layout='square'
            $variantColor='primary'
            classes={{ Button: ButtonCss }}
          >
            저장
          </Button>
          <Button onClick={handleCancel} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            취소
          </Button>
        </ButtonSection>
      </FormProvider>
    </Container>
  );
};

CsResponseEdit.propTypes = {};

export default CsResponseEdit;
