import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Div } from '@components/Atoms/Atoms';
import styled, { css } from 'styled-components';

const Container = styled(Div)`
  min-width: 220px;
  padding: 1em;
  padding-top: 0;
  box-sizing: border-box;
  border-right: ${({ theme }) => `1px solid ${theme.gray03}`};
  ${({ classes }) => classes && classes.SideBar}
`;

const HeaderContainer = styled(Div)`
  padding: 12px 10px 12px 15px;
  ${({ classes }) => classes && classes.HeaderContainer}
`;

const Title = styled.h1`
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: calc(100vw - 75px);
  color: #262626;
  font-size: 18px;
  font: -apple-system-title3;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ classes }) => classes && classes.Title}
`;
const RightIcon = css``;

const SideBar = ({ classes, title, icons, input, children }) => {
  return (
    <Container classes={classes}>
      <HeaderContainer classes={classes}>
        {/* <State
          type="back"
          onBeforeState={onBack}
          label={`${t("@CP_UX30_ACCESS_BACK")}`}
        > */}
        {/* <IconButton
            tabIndex="1"
            aria-hidden="true"
            classes={{ IconButton: BackButtonIcon }}
            icon={BackIcon}
          /> */}
        {/* </State> */}
        {title && <Title classes={classes}>{title}</Title>}
        {icons &&
          icons.map((icon, index) =>
            cloneElement(icon, {
              // eslint-disable-next-line react/no-array-index-key
              key: `appbar-icon${index}`,
              classes: { IconButton: RightIcon, ...icon.classes },
            })
          )}
        {input && input}
        {children}
      </HeaderContainer>
    </Container>
  );
};

SideBar.propTypes = {
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  /** SideBar의 메인 타이틀명 */
  title: PropTypes.string,
  /** AppBar의 아이콘 Array */
  icons: PropTypes.arrayOf(PropTypes.element),
  /** AppBar의 Input 태그 */
  input: PropTypes.node,
  /** Children */
  children: PropTypes.node,
};

export default SideBar;
