import Cell from '@FTA/components/Cell';
import { Grid } from '@components/index';
import { css } from 'styled-components';

const GridStyle = css`
  margin-bottom: 40px;
`;

const CPManagement = () => {
  return (
    <Grid title='CPADMIN 승인' column={4} classes={{ Grid: GridStyle }}>
      <Cell title='CP ADMIN 승인 요청수 (Total)' value='1250' />
      <Cell title='CP ADMIN 승인 수 (Total) ' value='3800' />
      <Cell title='CP ADMIN 승인 요청수 (O2O 정규프로그램)' value='34:33' />
      <Cell title='CP ADMIN 승인 수 (O2O 정규프로그램)' value='34:33' />
      <Cell title='CP ADMIN 승인 요청수 (운동추천 프로그램)' value='1250' />
      <Cell title='CP ADMIN 승인 수 (운동추천 프로그램) ' value='3800' />
      <Cell title='CP ADMIN 승인 요청수 (산책/조깅/하이킹 프로그램)' value='34:33' />
      <Cell title='CP ADMIN 승인 수 (산책/조깅/하이킹 프로그램)' value='34:33' />
      <Cell title='CP ADMIN 승인 요청수 (트레이닝 프로그램)' value='1250' />
      <Cell title='CP ADMIN 승인 수 (트레이닝 프로그램) ' value='3800' />
      <Cell title='CP ADMIN 승인 요청수 (골프 프로그램)' value='34:33' />
      <Cell title='CP ADMIN 승인 수 (골프 프로그램)' value='34:33' />
    </Grid>
  );
};

export default CPManagement;
