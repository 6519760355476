/** 외부 센터프로그램 관리 > 스케줄링 */

import { useCallback, useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { Button, Div, Text } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import { programScheduleAPI } from '@redux/action/programAction';
import { useDispatch, useSelector } from 'react-redux';
import paths from '@router/paths';

const weekdays = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];

const scheduleData = ['10:00 AM', '11:00 AM', '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM'];

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled(Div)`
  border-top: 3px solid black;
  word-break: break-all;
  margin-top: 10px;
`;

const TextContainer = styled(Div)`
  display: flex;
  min-height: 51px;
  gap: 20px;
  margin: 30px 0px;
`;

const TitleContainer = styled(Div)`
  min-width: 140px;
  padding: 16px 0px 16px 0px;
`;

const Title = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: left;
`;

const DescContainer = styled(Div)`
  width: 100%;
  padding: 16px 0px 16px 0px;
`;

const Desc = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  line-height: 18.72px;
  text-align: left;
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: center;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const Table = styled.table`
  width: 80%;
  margin-bottom: 40px;
`;

const Tableheader = styled.tr`
  background: ${({ theme }) => theme.gray02};
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
`;

const TableheaderText = styled.td`
  padding: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
`;

const TableContent = styled.tr`
  background: ${({ theme }) => theme.FFFFFF};
  border-bottom: 1px solid gray;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
`;

const TableContentText = styled.td`
  padding: 20px;
  text-align: center;
`;

const ExternalCenterProgramSchedule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const schduleDetail = useSelector((state) => state.schedule);
  const [schedule, setShedule] = useState([
    { title: '프로그램명', desc: 'LG 4주 프로그램' },
    { title: '반복 주기', desc: '매월' },
    { title: '반복 기준', desc: '날짜 (매월1일)' },
    { title: '시작일', desc: '2023.07.03' },
    { title: '종료일', desc: '2023.12.31' },
    {
      title: '알림 시간',
      desc: (
        <Table>
          <thead>
            <Tableheader>
              {weekdays.map((day, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableheaderText key={index}>{day}</TableheaderText>
              ))}
            </Tableheader>
          </thead>
          <tbody>
            <TableContent>
              {scheduleData.map((time, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableContentText key={index}>{time}</TableContentText>
              ))}
            </TableContent>
          </tbody>
        </Table>
      ),
    },
  ]);

  const handleNavigate = useCallback(
    (path) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  const edit = useCallback(() => {
    const uuid = searchParams.get('uuid');
    const search = createSearchParams({ uuid }).toString();
    navigate({ pathname: `/${paths.ECP_SCHEDULE_EDIT}`, search });
  }, []);

  const gotoList = useCallback(() => {
    handleNavigate(`/${paths.ECP_SCHEDULE}`);
  }, []);

  useEffect(() => {
    const searchId = searchParams.get('programInfoId');
    const params = {
      programInfoId: searchId,
    };

    programScheduleAPI(dispatch, params);
  }, [searchParams]);

  useEffect(() => {
    if (schduleDetail) {
      setShedule(schduleDetail);
    }
  }, [schduleDetail]);

  return (
    <Container>
      <PageTitle title='스케쥴링' />
      <ContentContainer>
        {schedule &&
          schedule.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <TextContainer>
                <TitleContainer>
                  <Title>{item.title}</Title>
                </TitleContainer>
                <DescContainer>
                  <Desc>{item.desc}</Desc>
                </DescContainer>
              </TextContainer>
            </div>
          ))}

        <ButtonContainer>
          <Button onClick={edit} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
            수정
          </Button>
          <Button $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            삭제
          </Button>
          <Button onClick={gotoList} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            목록
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

export default ExternalCenterProgramSchedule;
