import PropTypes from 'prop-types';
import { Div } from '@components/Atoms/Atoms';
import { Checkbox } from '@components/index';
import { CheckBoxChecked } from '@FTA/assets';
import styled, { css } from 'styled-components';

// checkbox
// 기본 input ui 안보이게 함
const checkboxInputStyle = css`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

// unChecked 상태 ui
const checkboxCustomUIStyle = css`
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
  border: 1px solid ${({ theme }) => theme.gray05};
  border-radius: 5px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
`;

// checked 상태 ui
const checkboxT = css`
  &:after {
    content: '';
    display: block;
    background-image: url(${CheckBoxChecked});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }
`;

// disapbled 상태 background 추가
const checkBoxDisabled = css`
  background-color: ${({ theme }) => theme.gray04};
`;

const Container = styled(Div)`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  cursor: pointer;
  ${(props) => props.classes && props.classes.ContainerStyle}
`;

const CheckBoxGroup = ({ name, content, rootIndex }) => {
  return (
    <Container key={`${name}-container}`}>
      {content.map((value, index) => {
        return (
          <Checkbox
            // eslint-disable-next-line
            key={`${name}-${index}`}
            name={`${name}-${index}-${rootIndex}`}
            label={value}
            classes={{
              Input: checkboxInputStyle,
              Box: checkboxCustomUIStyle,
              CheckboxT: checkboxT,
              Disabled: checkBoxDisabled,
            }}
          />
        );
      })}
    </Container>
  );
};

CheckBoxGroup.propTypes = {
  /** 상위 데이터 */
  name: PropTypes.string,
  /** Checkbox data row name */
  content: PropTypes.array,
  /** RootData index: checkbox name을 셋업할 때 중복을 피하기위해 필요 */
  rootIndex: PropTypes.number,
};

export default CheckBoxGroup;
