import { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styled, { css } from 'styled-components';

import { Button, Div, Grid, Text } from '@components/index';
import GridBox from '@components/Layout/GridBox';
import BarChart from '@FTA/components/Chart/BarChart';
import LineChart from '@FTA/components/Chart/LineChart';
import SearchContainer from '../components/SearchContainer';
import TableMultiSelectBox from '@components/DataDisplay/Table/modules/TableMultiSelectBox';
import MapChart from '../components/Chart/MapChart';
import DonutChart from '../components/Chart/DonutChart';
import Swiper from '@components/DataDisplay/Swiper/Swiper';
import Cell from '../components/Cell';
import useUserInfo from '../hooks/useUserInfo';

const Container = styled(Div)`
  --height: 400px;
  --padding: 10px;
  --border-radius: 16px;
  --border: 1px solid ${({ theme }) => theme.gray04};
  --line-padding: 0px 0px 0px 32px;
`;

const Welcome = styled(Div)`
  display: flex;
  gap: 10px;
  margin-bottom: 36px;
`;

const LineChartContainer = styled(Div)`
  border: var(--border);
  border-radius: var(--border-radius);
  height: var(--height);
`;

const BarChartContainer = styled(Div)`
  position: relative;
  border: var(--border);
  border-radius: var(--border-radius);
  height: var(--height);
  padding: var(--line-padding);
`;

const MapChartContainer = styled(Div)`
  border: var(--border);
  border-radius: var(--border-radius);
  height: var(--height);
`;

const DonutChartContainer = styled(Div)`
  position: relative;
  border: var(--border);
  border-radius: var(--border-radius);
  padding: var(--padding);
  height: var(--height);
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: end;
  margin-top: 12px;
  margin-right: 32px;
`;

const buttonBasic = css`
  width: 57px;
  height: 30px;
  padding: 7px 12px;
  font-size: 14px;
`;

const buttonCss = css`
  ${buttonBasic}
`;

const unselectedButtonCss = css`
  ${buttonBasic}
  background: ${({ theme }) => theme.gray02};
  color: ${({ theme }) => theme.gray07};
`;

const gridStyle = css`
  margin-bottom: 40px;
`;

const gridBoxCellStyle = css`
  background: ${({ theme }) => theme.gray02};
`;

const GridContainer = styled(Div)`
  width: 100%;
  margin-top: 50px;
`;

const GridBoxContainer = styled(Div)`
  display: grid;
  -webkit-box-align: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  grid-auto-rows: 1;
  grid-template-columns: 5fr 1fr;
`;

const GridArea = styled(Div)``;

const SelectBoxContainer = styled(Div)`
  display: flex;
  gap: 15px;
  margin-bottom: 40px;
`;

const ChipDateContainer = styled(Div)`
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
`;

const CP_OPTIONS = [
  { code: '서초 지점', title: '서초 지점', checked: false },
  { code: '학동 지점', title: '학동 지점', checked: false },
  { code: '교대 지점', title: '교대 지점', checked: false },
];

const REGION_OPTIONS = [{ code: '대한민국', value: '대한민국' }];

const PLATFORM_OPTIONS = [
  { code: 'WebOS 4.0', value: 'WebOS 4.0' },
  { code: 'WebOS 4.5', value: 'WebOS 4.5' },
  { code: 'WebOS 5.0', value: 'WebOS 5.0' },
  { code: 'WebOS 6.0', value: 'WebOS 6.0' },
  { code: 'WebOS 22', value: 'WebOS 22' },
  { code: 'WebOS 23', value: 'WebOS 23' },
];

const AGE_GROUP_OPTIONS = [
  { code: '20대 미만', value: '20대 미만' },
  { code: '20대', value: '20대' },
  { code: '30대', value: '30대' },
  { code: '40대', value: '40대' },
  { code: '50대', value: '50대' },
  { code: '60대', value: '60대' },
  { code: '70대', value: '70대' },
  { code: '80대 이상', value: '80대 이상' },
];

const PRIMARY_MENU_OPTIONS = [
  { code: '바디 체크업', value: '바디 체크업' },
  { code: '홈케어 프로그램', value: '홈케어 프로그램' },
  { code: '아웃도어 액티비티', value: '아웃도어 액티비티' },
];

const DAY_OPTIONS = [
  { code: '일', value: '일' },
  { code: '월', value: '월' },
  { code: '화', value: '화' },
  { code: '수', value: '수' },
  { code: '목', value: '목' },
  { code: '금', value: '금' },
  { code: '토', value: '토' },
];

const SECONDARY_MENU_OPTIONS = [
  [
    { code: '체형분석', value: '체형분석' },
    { code: '관절가동범위', value: '관절가동범위' },
    { code: '신체 균형', value: '신체 균형' },
    { code: '신체 능력', value: '신체 능력' },
  ],
  [
    { code: '프로그램', value: '프로그램' },
    { code: 'AI 추천 프로그램', value: 'AI 추천 프로그램' },
  ],
  [
    { code: '산책/조깅', value: '산책/조깅' },
    { code: '하이킹', value: '하이킹' },
    { code: '골프 코칭', value: '골프 코칭' },
    { code: '트레이닝', value: '트레이닝' },
  ],
];

const Home = () => {
  const userInfo = useUserInfo();
  const [swichPeriod, setSwichPeriod] = useState(0);
  const [swiperWidth, setSwiperWidth] = useState(null);
  const [primaryMenuSelectedIndex, setPrimaryMenuSelectedIndex] = useState(null);
  const [secondaryMenuOptions, setSecondaryMenuOptions] = useState([]);

  const inputForm = useForm();

  const TIME_OPTIONS = useMemo(() => {
    const options = [];
    for (let hour = 0; hour <= 23; hour += 1) {
      const timeString = `${hour.toString().padStart(2, '0')}:00`;
      options.push({ code: timeString, value: timeString });
    }
    return options;
  }, []);

  const chartData = useMemo(() => {
    const chartSeries = [
      [
        {
          name: '요일',
          data: [10, 241, 135, 51, 20, 162, 69],
        },
      ],
      [{ name: '분기', data: [10, 241, 135, 51] }],
    ];
    const categories = [
      ['일', '월', '화', '수', '목', '금', '토'],
      ['1분기', '2분기', '3분기', '4분기'],
    ];
    return {
      series: chartSeries[swichPeriod],
      categories: categories[swichPeriod],
    };
  }, [swichPeriod]);
  const lineChartRef = useRef(null);

  const handleResizeBarChart = useCallback(() => {
    setSwiperWidth(lineChartRef.current.clientWidth - 50);
  }, []);

  useEffect(() => {
    handleResizeBarChart();
    window.addEventListener('resize', handleResizeBarChart);

    return () => window.removeEventListener('resize', handleResizeBarChart);
  }, []);

  useEffect(() => {
    if (primaryMenuSelectedIndex != null) {
      setSecondaryMenuOptions(SECONDARY_MENU_OPTIONS[primaryMenuSelectedIndex]);
    }
  }, [primaryMenuSelectedIndex]);

  return (
    <Container>
      <FormProvider {...inputForm}>
        <Welcome>
          <Text fontSize={32} fontWeight={700} inner='Welcome' />
          <Text fontSize={32} fontWeight={700} color='#666666' inner={userInfo.adminNickname} />
        </Welcome>
        <ChipDateContainer>
          <SearchContainer
            options={CP_OPTIONS}
            labelTitle='CP 선택'
            hasChip
            hasColumnFilter
            hasDateFilter
            hasSearchInput={false}
          />
        </ChipDateContainer>
        <SelectBoxContainer>
          <TableMultiSelectBox options={REGION_OPTIONS} initialLabel='권역 선택' />
          <TableMultiSelectBox options={PLATFORM_OPTIONS} initialLabel='플랫폼 선택' />
          <TableMultiSelectBox options={AGE_GROUP_OPTIONS} initialLabel='연령대 선택' />
          <TableMultiSelectBox
            options={PRIMARY_MENU_OPTIONS}
            initialLabel='1차 메뉴 선택'
            singleSelect
            setCheckedIndex={setPrimaryMenuSelectedIndex}
          />
          <TableMultiSelectBox
            options={secondaryMenuOptions}
            initialLabel='2차 메뉴 선택'
            disabled={primaryMenuSelectedIndex == null}
          />
          <TableMultiSelectBox options={DAY_OPTIONS} initialLabel='요일 선택' />
          <TableMultiSelectBox options={TIME_OPTIONS} initialLabel='시간 선택' />
        </SelectBoxContainer>
      </FormProvider>
      <Swiper>
        <LineChartContainer ref={lineChartRef}>
          <LineChart
            height='100%'
            title='산책/조깅서비스 무료'
            series={[
              { name: '이용자 수', data: [10, 241, 135, 51, 20, 162, 69, 191] },
              { name: '사용시간(hr)', data: [30, 26, 34, 10, 30, 26, 34, 10] },
            ]}
            showLegend={{ top: true, bottom: true }}
          />
        </LineChartContainer>

        <BarChartContainer>
          <ButtonContainer>
            <Button
              $layout='square'
              $variantColor='brown'
              width='57'
              height='30'
              onClick={() => setSwichPeriod(0)}
              classes={{ Button: swichPeriod === 0 ? buttonCss : unselectedButtonCss }}
            >
              요일
            </Button>
            <Button
              $layout='square'
              $variantColor='brown'
              onClick={() => setSwichPeriod(1)}
              className='button'
              classes={{ Button: swichPeriod === 1 ? buttonCss : unselectedButtonCss }}
            >
              분기
            </Button>
          </ButtonContainer>

          <BarChart
            title='요일/분기별 현황'
            series={chartData.series}
            borderRadius={9}
            categories={chartData.categories}
            showGrid={false}
            enabledDataLabels={false}
            showLabels={false}
            width={`${swiperWidth}px`}
          />
        </BarChartContainer>
        <DonutChartContainer>
          <DonutChart
            width='240px'
            height='240px'
            title='운동목적'
            series={[19, 37, 23, 31, 92]}
            labels={['목 운동', '팔 운동', '등 운동', '허리 운동', '다리 운동']}
          />
        </DonutChartContainer>

        <MapChartContainer>
          <MapChart title='권역별 현황' />
        </MapChartContainer>
      </Swiper>

      <GridContainer>
        <Grid title='사용자 필수실행 조건' column={5} classes={{ Grid: gridStyle }}>
          <Cell title='앱 Lock 실행자 수' value='980' />
          <Cell title='약관에 동의한 사람수' value='3150' />
          <Cell title='설문조사 완료자 수' value='750' />
          <Cell title='설문조사 완료자 수' value='750' />
          <Cell title='설문조사 완료자 수' value='750' />
        </Grid>
        <GridBoxContainer>
          <GridArea>
            <GridBox title='접속자 수/사용량' subtitle='나의 몸 알아보기 측정자수' totalCount='122222' column={4}>
              <Cell title='DAU' value='1250' classes={{ Cell: gridBoxCellStyle }} />
              <Cell title='MAU' value='3800' classes={{ Cell: gridBoxCellStyle }} />
              <Cell title='하루 평균 사용시간' value='34:33' classes={{ Cell: gridBoxCellStyle }} />
              <Cell title='하루 평균 사용 페이지 수' value='12' classes={{ Cell: gridBoxCellStyle }} />
            </GridBox>
          </GridArea>
          <GridArea>
            <Cell title='하루 평균 사용 페이지 수' value='12' height='170px' />
          </GridArea>
        </GridBoxContainer>
        <GridBox title='접속자 수/사용량' subtitle='나의 몸 알아보기 측정자수' totalCount='122222' column={3}>
          <Cell title='DAU' value='1250' classes={{ Cell: gridBoxCellStyle }} />
          <Cell title='MAU' value='3800' classes={{ Cell: gridBoxCellStyle }} />
          <Cell title='하루 평균 사용시간' value='34:33' classes={{ Cell: gridBoxCellStyle }} />
        </GridBox>
        <GridBox title='접속자 수/사용량' subtitle='나의 몸 알아보기 측정자수' totalCount='122222' column={4}>
          <Cell title='DAU' value='1250' classes={{ Cell: gridBoxCellStyle }} />
          <Cell title='MAU' value='3800' classes={{ Cell: gridBoxCellStyle }} />
          <Cell title='하루 평균 사용시간' value='34:33' classes={{ Cell: gridBoxCellStyle }} />
          <Cell title='하루 평균 사용시간' value='34:33' classes={{ Cell: gridBoxCellStyle }} />
        </GridBox>
        <GridBox title='접속자 수/사용량' subtitle='나의 몸 알아보기 측정자수' totalCount='122222' column={5}>
          <Cell title='DAU' value='1250' classes={{ Cell: gridBoxCellStyle }} />
          <Cell title='MAU' value='3800' classes={{ Cell: gridBoxCellStyle }} />
          <Cell title='하루 평균 사용시간' value='34:33' classes={{ Cell: gridBoxCellStyle }} />
          <Cell title='하루 평균 사용시간' value='34:33' classes={{ Cell: gridBoxCellStyle }} />
          <Cell title='하루 평균 사용시간' value='34:33' classes={{ Cell: gridBoxCellStyle }} />
        </GridBox>
        <Grid title='유료회원 수' column={5} classes={{ Grid: gridStyle }}>
          <Cell title='프리미엄 요금제 가입자 수' value='620' />
          <Cell title='스탠다드 요금제 가입자 수' value='1150' />
          <Cell title='라이트 요금제 가입자 수' value='980' />
          <Cell title='유료가입 이탈자 수' value='150' />
          <Cell title='유료가입 이탈율' value='12%' />
        </Grid>

        <Grid title='TV 스펙' column={4} classes={{ Grid: gridStyle }}>
          <Cell title='O24 Chip(SoC 칩) 사양 TV 구매자 수' value='550' />
          <Cell title='O22 Chip(SoC 칩) 사양 TV 구매자 수' value='780' />
          <Cell title='K24 Chip(SoC 칩) 사양 TV 구매자 수' value='420' />
          <Cell title='외산 Chip(SoC 칩) 사양 TV 구매자 수' value='310' />
        </Grid>

        <Grid title='Connectivity' column={2} classes={{ Grid: gridStyle }}>
          <Cell title='USB 카메라 연결자 수' value='280' />
          <Cell title='DHD(Digital Healthcare Device) 연결자 수' value='150' />
        </Grid>
      </GridContainer>
    </Container>
  );
};
Home.propTypes = {};

export default Home;
