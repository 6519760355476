import { Fragment, useCallback, useContext, useMemo } from 'react';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Button, Div, Divider, Img, ModalContext, Text } from '@components/index';
import VideoModal from '@FTA/components/VideoModal';

import Themes from '@styles/Themes';
import { PlayIcon } from '@FTA/assets';

const ItemContainer = styled(Div)`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100px;
  gap: 10px;
  padding: 40px 0;
`;
const ItemName = styled(Text)``;

const ItemValueText = styled(Text)``;

const ItemValueImage = styled(Img)`
  width: 120px;
  height: 120px;
`;

const ItemValueVideoContainer = styled(Div)`
  display: flex;
  gap: 4px;
  margin: 40px 0;
  align-items: center;
`;

const ItemDivider = css`
  background: ${({ theme }) => theme.gray03};
  opacity: 1;
`;

const ItemNameContainer = styled(Div)`
  flex-basis: 150px;
  min-width: 150px;
`;

const SectionItem = ({ name, label, value, type, hasDivider, required, input }) => {
  const { showModal } = useContext(ModalContext);

  const handleVideoModal = useCallback(() => {
    showModal(<VideoModal src={value.src} />);
  }, []);

  const statusColor = useMemo(() => {
    switch (value) {
      case '승인':
        return Themes.light.green;
      case '심사중':
        return Themes.light.yellow;
      case '반려':
        return Themes.light.red;
      default:
        return Themes.light.gray01;
    }
  }, []);

  return (
    <Fragment key={uuidv4()}>
      {hasDivider && <Divider classes={{ Divider: ItemDivider }} />}
      <ItemContainer key={`ItemContainer-${name}-${value}`}>
        <ItemNameContainer>
          <ItemName
            required={required}
            fontSize={16}
            fontWeight={700}
            color={Themes.light.gray10}
            inner={label || name}
          />
        </ItemNameContainer>
        {type === 'string' && (
          <ItemValueText fontSize={16} fontWeight={600} color={Themes.light.gray08} inner={value} />
        )}
        {type === 'image' && <ItemValueImage src={value} loder={{ width: '262px', height: '164px' }} lazyLoad />}
        {type === 'video' && (
          <ItemValueVideoContainer>
            <ItemValueText fontSize={16} fontWeight={600} color={Themes.light.gray08} inner={value.filename} />
            <Button
              onClick={handleVideoModal}
              $layout='round'
              $variantColor='gray'
              startIcon={PlayIcon}
              $imgVariant='play'
            >
              재생
            </Button>
          </ItemValueVideoContainer>
        )}
        {type === 'status' && <ItemValueText fontSize={16} fontWeight={600} color={statusColor} inner={value} />}
        {input && input}
      </ItemContainer>
    </Fragment>
  );
};

SectionItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node, PropTypes.array, PropTypes.object]),
  type: PropTypes.oneOf(['string', 'node', 'image', 'video', 'status', 'input-text']),
  hasDivider: PropTypes.bool,
  required: PropTypes.bool,
  input: PropTypes.node,
};

export default SectionItem;
