import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Input, RadioButton } from '@components/index';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import ImageUpload from '@modules/FTA/components/ImageUpload';
import InputChip from '@modules/FTA/components/InputChip';

const RadioButtonContainer = styled(Div)``;

const CENTER_INTRODUCTION_IMAGE_WHITE_LIST = ['MCT000', 'MCT003'];

const TITLE = Object.freeze({
  MCT000: '외부 센터프로그램 CP 정보',
  MCT001: '동작별 영상 (AI추천 컨텐츠) CP 정보',
  MCT002: '아웃도어 액티비티 CP 정보',
  MCT003: 'LG 시그니처 프로그램',
});

const CPMainCategoryInfoForm = ({ formNames }) => {
  const ExposeYnEnumList = [
    { code: '1', value: '노출' },
    { code: '0', value: '미노출' },
  ];

  const selectedMainCategory = useWatch({ name: formNames.MAIN_CATEGORY });

  const ACTIVITY_INFO = useMemo(
    () => [
      {
        title: '로고',
        isRequired: true,
        content: [<ImageUpload name={formNames.LOGO_IMAGE} />],
      },
      {
        title: '소개 이미지',
        isRequired: true,
        content: [<ImageUpload name={formNames.INTRODUCTION_IMAGE} />],
      },
      CENTER_INTRODUCTION_IMAGE_WHITE_LIST.includes(selectedMainCategory) && {
        title: '센터 소개 이미지',
        isRequired: true,
        content: [<ImageUpload name={formNames.CENTER_INTRODUCTION_IMAGE} />],
      },
      {
        title: '소개글',
        content: [
          <Input name={formNames.INTRODUCTION} type='text' placeholder='소개글을 입력해주세요.' maxLength='1000' />,
        ],
      },
      {
        title: '특징',
        isRequired: true,
        content: [<InputChip name={formNames.FEATURES} placeholder='특징을 추가해주세요.' />],
      },
      {
        title: '노출 여부',
        content: [
          <RadioButtonContainer>
            {ExposeYnEnumList.map((exposeYnEnum) => (
              <RadioButton
                key={exposeYnEnum.code}
                name={formNames.EXPOSE_YN}
                id={exposeYnEnum.code}
                item={exposeYnEnum}
              />
            ))}
          </RadioButtonContainer>,
        ],
      },
    ],
    [selectedMainCategory]
  );

  return !selectedMainCategory ? null : (
    <>
      <TitleWithDivider title={TITLE[selectedMainCategory]} />
      <GridLayout data={ACTIVITY_INFO} />
    </>
  );
};

CPMainCategoryInfoForm.propTypes = {
  formNames: PropTypes.objectOf(PropTypes.string),
};

export default CPMainCategoryInfoForm;
