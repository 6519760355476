import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: {},
  flatEnums: [],
};

/* eslint-disable no-param-reassign */
const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setAllEnum: (state, { payload }) => {
      state.list = payload;
      state.flatEnums = Object.values(payload).flat();
    },
  },
});

/* eslint-enable no-param-reassign */

export const { setAllEnum } = common.actions;
export default common.reducer;
