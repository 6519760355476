/** 기타 관리 > 설문 */

import { useCallback, useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { createColumnHelper } from '@tanstack/react-table';

import { convertDateToString } from '@utils';
import { Button, Div, Table } from '@components/index';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';
import DetailButton from '@FTA/components/DetailButton';
import PATHS from '@router/paths';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';
import { SurveyListAPI } from '@redux/action/etcAction';

const columnHelper = createColumnHelper();

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: end;
  margin-top: 30px;
`;

const FooterButtonStyle = css`
  padding: 8px 4px;
`;

// FIXME[CS] 추후에 real data로 넘어갈 때 value 값 수정
const TABLE_KEYS = Object.freeze({
  NO: 'no',
  TITLE: 'surveyName',
  REGISTRATION_DATE: 'registDate',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, title: 'no', checked: false },
  { code: TABLE_KEYS.TITLE, title: '제목', checked: false },
];

const Survey = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm();
  const { searchParams, getSearchParamsObject } = useSearchParams();

  const surveyList = useSelector((state) => state.etc.surveyList);
  const surveyPaing = useSelector((state) => state.etc.surveyPaging);

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.NO, {
        id: TABLE_KEYS.NO,
        header: () => <span>No</span>,
        cell: ({ row: { index } }) => <span>{surveyList && surveyList.length - index}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.TITLE, {
        id: TABLE_KEYS.TITLE,
        header: () => <span>제목</span>,
        cell: ({ getValue, row: { original } }) => {
          const { surveyInfoId } = original;
          return (
            <DetailButton pathname={PATHS.SURVEY_DETAIL} searchParams={{ surveyInfoId }}>
              {getValue()}
            </DetailButton>
          );
        },
      }),
      columnHelper.accessor(TABLE_KEYS.REGISTRATION_DATE, {
        id: TABLE_KEYS.REGISTRATION_DATE,
        header: () => <span>등록일</span>,
        cell: ({ getValue }) => <span>{convertDateToString(new Date(getValue()), '.')}</span>,
      }),
    ],
    []
  );
  /* eslint-enable react/no-unstable-nested-components */

  const gotoAdd = useCallback(() => {
    navigate(`/${PATHS.SURVEY_WRITE}`);
  }, []);

  useEffect(() => {
    const param = getSearchParamsObject();
    SurveyListAPI(dispatch, param);
  }, [searchParams]);

  return (
    <>
      {surveyList?.length > 0 ? (
        <>
          <FormProvider {...methods}>
            <SearchContainer options={TABLE_OPTIONS} hasChip hasColumnFilter hasInputSelectBox hasDateFilter />
          </FormProvider>
          <Table
            data={surveyList}
            columns={COLUMNS_DATA}
            paging={surveyPaing}
            hasChip
            hasColumnFilter
            hasInputSelectBox
          />
        </>
      ) : (
        <EmptyData text='등록된 설문이 없습니다.' />
      )}
      <ButtonContainer>
        <Button
          classes={{ Button: FooterButtonStyle }}
          $layout='square'
          $variantColor='primary'
          width={51}
          height={36}
          fontSize={14}
          onClick={gotoAdd}
        >
          등록
        </Button>
      </ButtonContainer>
    </>
  );
};

export default Survey;
