// import PropTypes from 'prop-types'
import { useContext } from 'react';
import styled from 'styled-components';

import { Div, Modal, Button, ModalContext } from '@components/index';
import useAuth from '../hooks/useAuth';

const BoxContainer = styled(Div)`
  display: flex;
  gap: 10px;
  margin: 20px;
`;
const LogoutModal = () => {
  const { closeModal } = useContext(ModalContext);
  const { logout } = useAuth();

  const handleConfirm = () => {
    logout();
    closeModal();
  };

  const handleClose = () => {
    closeModal();
  };
  return (
    <Modal
      body='로그아웃 하시겠습니까?'
      btns={
        <BoxContainer>
          <Button onClick={handleClose} $layout='square' $variantColor='line' width={160} height={48} fontSize={16}>
            취소
          </Button>
          <Button
            onClick={handleConfirm}
            $layout='square'
            $variantColor='primary'
            width={160}
            height={48}
            fontSize={16}
          >
            확인
          </Button>
        </BoxContainer>
      }
    />
  );
};

LogoutModal.propTypes = {};

export default LogoutModal;
