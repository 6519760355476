import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@components/Atoms/Icon';

const getStyles = ({ classes }) => classes && classes.IconButton;

const getLeftButton = ({ classes, toggle }) =>
  classes && toggle ? classes.OnOffStyle.OnButton : classes.OnOffStyle.OffButton;

const getRightButton = ({ classes, toggle }) =>
  classes && toggle ? classes.OnOffStyle.OnButton : classes.OnOffStyle.OffButton;

const width = ({ w }) => `${w || 40}px`;
const height = ({ h }) => `${h || 40}px`;
const getBorderRadius = ({ borderRadius }) => `${borderRadius && borderRadius}`;

const OnContainer = styled.button`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  padding: 0px;
  border: 0;
  width: ${width};
  height: ${height};
  border-radius: 8px 0px 0px 8px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${getBorderRadius}
  ${getLeftButton}
  ${getStyles}
`;

const OffContainer = styled.button`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  padding: 0px;
  border: 0;
  width: ${width};
  height: ${height};
  border-radius: 0px 8px 8px 0px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${getBorderRadius}
  ${getStyles}
  ${getRightButton}
`;

const IconButton = ({
  leftIcon,
  rightIcon,
  isLeftActive,
  isRightActive,
  handleLeftButton,
  handleRightButton,
  ...rest
}) => {
  return (
    <>
      <OnContainer {...rest} onClick={handleLeftButton} toggle={isLeftActive}>
        <Icon leftIcon={leftIcon} {...rest} iconWidth='17' />
      </OnContainer>
      <OffContainer {...rest} onClick={handleRightButton} toggle={isRightActive}>
        <Icon rightIcon={rightIcon} {...rest} iconWidth='17' />
      </OffContainer>
    </>
  );
};

IconButton.propTypes = {
  /** 버튼 우측에 들어갈 Icon image */
  leftIcon: PropTypes.string.isRequired,
  /** 버튼 좌측에 들어갈 Icon image */
  rightIcon: PropTypes.string.isRequired,
  /** 버튼 좌측 Active 상태 */
  isLeftActive: PropTypes.bool.isRequired,
  /** 버튼 우측 Active 상태 */
  isRightActive: PropTypes.bool.isRequired,
  /** 버튼 좌측 쿨릭시 event */
  handleLeftButton: PropTypes.bool.isRequired,
  /** 버튼 우측 쿨릭시 event */
  handleRightButton: PropTypes.bool.isRequired,
};

const OnOffButton = memo(
  ({ leftIcon, rightIcon, isLeftActive, isRightActive, handleLeftButton, handleRightButton, ...rest }) => {
    return (
      <IconButton
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        isLeftActive={isLeftActive}
        isRightActive={isRightActive}
        handleLeftButton={handleLeftButton}
        handleRightButton={handleRightButton}
        {...rest}
      />
    );
  }
);

OnOffButton.propTypes = {
  /** 버튼 우측에 들어갈 Icon image */
  leftIcon: PropTypes.string.isRequired,
  /** 버튼 좌측에 들어갈 Icon image */
  rightIcon: PropTypes.string.isRequired,
  /** 버튼 좌측 Active 상태 */
  isLeftActive: PropTypes.bool.isRequired,
  /** 버튼 우측 Active 상태 */
  isRightActive: PropTypes.bool.isRequired,
  /** 버튼 좌측 쿨릭시 event */
  handleLeftButton: PropTypes.bool.isRequired,
  /** 버튼 우측 쿨릭시 event */
  handleRightButton: PropTypes.bool.isRequired,
};

export default OnOffButton;
