import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Span, Text, Div } from '@components/index';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import ImagePreview from '@modules/FTA/components/ImagePreview';

const FilteredChipContainer = styled(Div)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
`;

const FilteredChip = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 8px 14px;
  border-radius: 56px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.38px;
  background-color: ${({ theme }) => theme.green01};
  color: ${({ theme }) => theme.green02};
`;

const FilterValue = styled(Span)``;

const CENTER_INTRODUCTION_IMAGE_WHITE_LIST = ['MCT000', 'MCT003'];

const TITLE = Object.freeze({
  MCT000: '외부 센터프로그램 CP 정보',
  MCT001: '동작별 영상 (AI추천 컨텐츠) CP 정보',
  MCT002: '아웃도어 액티비티 CP 정보',
  MCT003: 'LG 시그니처 프로그램',
});

const CPMainCategoryInfoDetail = () => {
  const cpDetailInfo = useSelector((state) => state.cp.detail);

  const ACTIVITY_INFO = useMemo(
    () => [
      {
        title: '로고',
        content: [<ImagePreview image={cpDetailInfo.logoImage?.fileUrl} />],
      },
      {
        title: '소개 이미지',
        content: [<ImagePreview image={cpDetailInfo.introductionImage?.fileUrl} />],
      },
      // FIXME: 현재 API상에서는 배열이 아닌 객체로 넘어오기 때문에 N개의 이미지를 넣을 수 없는 구조임
      CENTER_INTRODUCTION_IMAGE_WHITE_LIST.includes(cpDetailInfo.mainCategory) && {
        title: '센터 소개 이미지',
        content: [<ImagePreview image={cpDetailInfo.centerIntroductionImage?.fileUrl} />],
      },
      {
        title: '소개글',
        content: [<Text>{cpDetailInfo.cpManageDetailList?.[0].introduction}</Text>],
      },
      {
        title: '특징',
        content: [
          <FilteredChipContainer>
            {/* {cpDetailInfo.cpManageDetailList?.[0].features?.[0]?.split(',').map((feature, index) => { */}
            {cpDetailInfo.cpManageDetailList?.[0].features?.map((feature, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <FilteredChip key={`${feature}-${index}`}>
                  <FilterValue>{feature}</FilterValue>
                </FilteredChip>
              );
            })}
          </FilteredChipContainer>,
        ],
      },
      {
        title: '노출여부',
        content: [<Text>{cpDetailInfo.exposeYn ? '노출' : '미노출'}</Text>],
      },
    ],
    [cpDetailInfo]
  );

  return (
    <>
      <TitleWithDivider title={TITLE[cpDetailInfo.mainCategory]} />
      <GridLayout data={ACTIVITY_INFO} hasBottomBorderLine />
    </>
  );
};

export default CPMainCategoryInfoDetail;
