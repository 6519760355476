import Axios from '@Axios';
import { HEADER, METHODS } from '@AxiosConfig';
import {
  setCSDetail,
  setCSList,
  setCSPaging,
  setCSStatus,
  setFaqDetail,
  setFaqList,
  setFaqPaging,
  setHealthcareDetail,
  setHealthcareList,
  setHealthcarePaging,
  setNoticeDetail,
  setNoticeList,
  setNoticePaging,
  setSurveyDetail,
  setSurveyList,
  setSurveyPaging,
} from '@redux/feature/slices/etcSlice';
// import PATHS from '@router/paths';

const BASE_URL = 'v1/api/etc';

/**
 * GetCSList API cs대응조회
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const getCSListAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/cs/`;

  const onSuccess = (res) => {
    console.log('%c✅ getCSList RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setCSList(res.data));
    dispatch(setCSPaging(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * GetCSList API cs 단건 조회
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const getCSDetailAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/cs/${params?.csRequestInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ GetCSList RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setCSDetail(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, onSuccess, onFail, header: HEADER.GET });
};

/**
 * CSregister API cs 등록
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {Function} csId API 호출 시 필요한 파라미터
 * @param {object} params API 호출 시 필요한 파라미터
 */

const CSregisterAPI = (dispatch, csId, params) => {
  const URL = `${BASE_URL}/cs/register/${csId}`;

  const onSuccess = (res) => {
    console.log('%c✅ CSregister RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.POST, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * GetCSList API cs 대응 현황
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const getCSCountAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/cs/status-count`;

  const onSuccess = (res) => {
    console.log('%c✅ getCSCount RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setCSStatus(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * GetFaqList API faq 조회
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const getFaqListAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/faq/`;

  const onSuccess = (res) => {
    console.log('%c✅ getFaqListAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setFaqList(res.data));
    dispatch(setFaqPaging(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * GetFaqList API faq 단건 조회
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const getFaqDetailAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/faq/${params.faqInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ getFaqList RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setFaqDetail(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, onSuccess, onFail, header: HEADER.GET });
};

/**
 * FAQregister API cs 등록
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const FAQregisterAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/faq/register`;

  const onSuccess = (res) => {
    console.log('%c✅ FAQregister RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.POST, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * FAQdelete API 삭제
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const FAQdeleteAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/faq/delete/${params.faqInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ FAQdelete RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    getFaqListAPI(dispatch, {});
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.DELETE, path: URL, params, onSuccess, onFail, header: HEADER.DELETE });
};

/**
 * GetNoticeListAPI API faq 조회
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const getNoticeListAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/notice/`;

  const onSuccess = (res) => {
    console.log('%c✅ getNoticeList RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setNoticeList(res.data.content));
    dispatch(setNoticePaging(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * GetNoticeDetail API 공지사항 단건 조회
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const getNoticeDetailAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/notice/${params}`;

  const onSuccess = (res) => {
    console.log('%c✅ getNoticeDetail RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setNoticeDetail(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, onSuccess, onFail, header: HEADER.GET });
};

/**
 * NoticeRegisterAPI 공지사항 등록/수정
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const NoticeRegisterAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/notice/register`;

  const onSuccess = (res) => {
    console.log('%c✅ NoticeRegister RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.POST, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * NoticeDeleteAPI 공지 삭제
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const NoticeDeleteAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/notice/delete/${params.noticeInfoId}`;
  // console.log('###')
  const onSuccess = (res) => {
    console.log('%c✅ NoticeDelete RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.DELETE, path: URL, params, onSuccess, onFail, header: HEADER.DELETE });
};

/**
 * HealthcareListAPI API 조회
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const HealthcareListAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/health/`;

  const onSuccess = (res) => {
    console.log('%c✅ HealthcareListAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setHealthcareList(res.data.content));
    dispatch(setHealthcarePaging(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 헬스케어 DB 단건 조회 API 조회
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const HealthcareDetailAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/health/${params.healthCareInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ HealthcareDetailAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setHealthcareDetail(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 헬스케어 DB 등록/수정 API 조회
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const HealthcareRegistAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/health/register`;

  const onSuccess = (res) => {
    console.log('%c✅ HealthcareRegistAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    HealthcareListAPI(dispatch, {});
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.POST, path: URL, params, onSuccess, onFail, header: HEADER.MULTIPART });
};

/**
 * NoticeDeleteAPI 공지 삭제
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const HealthcareDeleteAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/health/delete/${params.healthCareInfoId}`;
  // console.log('###')
  const onSuccess = (res) => {
    console.log('%c✅ HealthcareDeleteAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    HealthcareListAPI(dispatch, {});
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.DELETE, path: URL, params, onSuccess, onFail, header: HEADER.DELETE });
};

/**
 * 설문 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const SurveyListAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/survey/`;

  const onSuccess = (res) => {
    console.log('%c✅ SurveyListAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setSurveyList(res.data.content));
    dispatch(setSurveyPaging(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 설문 단건 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const SurveyDetailAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/survey/${params}`;

  const onSuccess = (res) => {
    console.log('%c✅ SurveyDetailAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setSurveyDetail(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 설문 등록 / 수정 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const SurveyRegistAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/survey/register`;

  const onSuccess = (res) => {
    console.log('%c✅ SurveyRegistAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.POST, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

// eslint-disable-next-line import/prefer-default-export
export {
  getCSListAPI,
  getCSDetailAPI,
  CSregisterAPI,
  getCSCountAPI,
  getFaqListAPI,
  getFaqDetailAPI,
  FAQregisterAPI,
  FAQdeleteAPI,
  getNoticeListAPI,
  getNoticeDetailAPI,
  NoticeRegisterAPI,
  NoticeDeleteAPI,
  HealthcareListAPI,
  HealthcareDetailAPI,
  HealthcareRegistAPI,
  HealthcareDeleteAPI,
  SurveyListAPI,
  SurveyDetailAPI,
  SurveyRegistAPI,
};
