import Axios from '@Axios';
import { HEADER, METHODS } from '@AxiosConfig';
import { CONFIG } from '@modules/FTA/data';
import {
  setAccountDetail,
  setAccountRoleDetail,
  setAccountsList,
  setAccountsRoleList,
  setAccountsPaging,
  setAccountsRolePaging,
} from '@redux/feature/slices/accountsSlice';

const HANDLE_TYPE = Object.freeze({
  APPROVAL: 'approval',
  REFUSAL: 'refusal',
  REQAPPROVAL: 'reqApproval',
  DELETE: 'delete',
});

const BASE_URL = 'v1/api/accounts';

/**
 * 관리자 계정 리스트 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {number} params.page 몇 개의 페이지를 조회할지
 * @param {number} params.size 한 페이지에 몇 개의 계정이 들어갈지
 */
const getAccountsAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/`;

  const onSuccess = (res) => {
    console.log('%c✅ getAccountsAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setAccountsList(res.data.content));
    dispatch(setAccountDetail({}));
    dispatch(setAccountsPaging(res.data));
  };

  const onFail = (err, fallbackMockData) => {
    console.error(URL, err);

    if (CONFIG.USE_MOCKDATA_ONFAIL && fallbackMockData) {
      dispatch(setAccountsList(fallbackMockData.data.content));
    }
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 관리자 계정 단건 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {string} params.adminInfoId 조회할 관리자 계정 ID(PK)
 */
const getAccountDetailAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/${params.adminInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ getAccountDetailAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setAccountDetail(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 관리자 계정의 등급 수정 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {string} params.adminInfoId 수정할 관리자 계정 ID(PK)
 * @param {string} params.adminClassInfoId 수정할 관리자 계정 등급 ID(PK)
 */
const updateAccountAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/setting/${params.adminInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ updateAccountAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.PUT, path: URL, params, onSuccess, onFail, header: HEADER.PUT });
};

/**
 * 관리자 계정 승인 / 삭제 / 반려 / 승인요청 요청 API
 *
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {string} params.adminInfoId 승인요청할 관리자 계정 ID(PK)
 * @param {'approval' | 'refusal' | 'reqApproval' | 'delete'} type 승인 / 삭제 / 반려 / 승인요청 타입 선택
 */
const HandleAccountStatusAPI = (params, type) => {
  const currentType = HANDLE_TYPE[type.toUpperCase()];
  const URL = `${BASE_URL}/${params.adminInfoId}/${currentType}`;

  const onSuccess = (res) => {
    console.log('%c✅ HandleAccountStatusAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  if (currentType !== HANDLE_TYPE.DELETE) {
    Axios({ type: METHODS.PUT, path: URL, params, onSuccess, onFail, header: HEADER.PUT });
    return;
  }

  Axios({ type: METHODS.DELETE, path: URL, params, onSuccess, onFail, header: HEADER.DELETE });
};

/**
 * 관리자 계정 삭제 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {string} params.adminInfoId 수정할 관리자 계정 ID(PK)
 */
const deleteAccountAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/${params.adminInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ deleteAccountAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    // NOTE: delete가 되서 list 페이지로 navigate됐을 때, 업데이트 값이 반영되지 않을 수 있음
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.DELETE, path: URL, params, onSuccess, onFail, header: HEADER.DELETE });
};

/**
 * 관리자 계정 등급 설정 목록 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 */
const getAccountsRoleAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/setting/list`;

  const onSuccess = (res) => {
    console.log('%c✅ getAccountsRoleAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setAccountsRoleList(res.data.content));
    dispatch(setAccountRoleDetail({}));
    dispatch(setAccountsRolePaging(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 관리자 계정 등급 단건 조회 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {string} params.adminClassInfoId 조회할 관리자 등급 ID(PK)
 */
const getAccountRoleDetailAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/setting/${params.adminClassInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ getAccountRoleDetailAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setAccountRoleDetail(res.data));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 관리자 계정 등급 등록/수정 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {string} params.adminInfoId 수정할 관리자 계정 ID(PK)
 * @param {string} params.className 수정할 관리자 계정 등급
 * @param {string} params.ApprovalStatus 수정할 관리자 계정 승인상태
 */
const createOrUpdateAccountRoleAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/setting/regist`;

  const onSuccess = (res) => {
    console.log(
      '%c✅ createOrUpdateAccountRoleAPI RESOPONSE',
      'background: rgba(255, 103, 103, 0.3); color: white',
      res
    );
    // NOTE: update가 되서 list 페이지로 navigate됐을 때, 업데이트 값이 반영되지 않을 수 있음
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.POST, path: URL, params, onSuccess, onFail, header: HEADER.PUT });
};

/**
 * 관리자 계정 등급 삭제 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {string} params.adminClassInfoId 수정할 관리자 등급 ID(PK)
 */
const deleteAccountRoleAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/setting/level-setting/${params.adminClassInfoId}`;

  const onSuccess = (res) => {
    console.log('%c✅ deleteAccountRoleAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    // NOTE: delete가 되서 list 페이지로 navigate됐을 때, 업데이트 값이 반영되지 않을 수 있음
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.DELETE, path: URL, params, onSuccess, onFail, header: HEADER.DELETE });
};

export {
  // NOTE: 관리자 계정 관리 > 관리자 계정
  getAccountsAPI,
  getAccountDetailAPI,
  updateAccountAPI,
  HandleAccountStatusAPI,
  deleteAccountAPI,
  // NOTE: 관리자 계정 관리 > 관리자 등급설정
  getAccountsRoleAPI,
  getAccountRoleDetailAPI,
  createOrUpdateAccountRoleAPI,
  deleteAccountRoleAPI,
};
