import { memo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Div } from '@components/Atoms/Atoms';

const TabMenuContainer = styled(Div)``;

const TabMenuList = styled.div`
  position: relative;
  overflow: ${(props) => (props.transformValue !== 0 ? 'hidden' : 'visible')};

  ul {
    display: flex;
  }
`;

const TabMenuItem = styled.li`
  display: flex;

  .btn {
    height: 48px;
    position: relative;
    display: inline-flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    color: ${({ theme }) => theme.gray07};

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
    }
  }

  &.on {
    .btn {
      font-weight: bold;
      color: rgba(32, 32, 32, 1);
      font-weight: 600;
      &:after {
        height: 3px;
        background: ${({ theme }) => theme.gray10};
      }
    }
  }
`;

const ButtonDiv = styled.div``;

const TabMenuContentArea = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`;

const TabMenu = ({ tabData, currentTabIndex, setCurrentTabIndex, children }) => {
  const handleTabClick = (index) => {
    setCurrentTabIndex(index);
  };

  return (
    <TabMenuContainer>
      <TabMenuList>
        <ul>
          {tabData.map((tab, index) => (
            <TabMenuItem key={tab.id} className={currentTabIndex === index ? 'on' : ''}>
              <ButtonDiv className='btn' onClick={() => handleTabClick(index)}>
                <span>{tab.title}</span>
              </ButtonDiv>
            </TabMenuItem>
          ))}
        </ul>
      </TabMenuList>
      {children && children}
      <TabMenuContentArea>{tabData[currentTabIndex].content}</TabMenuContentArea>
    </TabMenuContainer>
  );
};

TabMenu.propTypes = {
  /** TabData :{ id: 'tab1', title: 'tabTitle1', content: <Component/> } */
  tabData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.node,
    })
  ).isRequired,
  /** 첫 진입 시 활성화 될 Tab id */
  currentTabIndex: PropTypes.number.isRequired,
  /** TabIndex setter 함수 */
  setCurrentTabIndex: PropTypes.func.isRequired,
  /** 부가적으로 렌더링할 요소 */
  children: PropTypes.node,
};

export default memo(TabMenu);
