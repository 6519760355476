import { useCallback, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Span } from '@components/Atoms/Atoms';

const CheckboxCustomUI = styled(Span)`
  ${({ classes }) => classes && classes.Box}
  ${({ classes, checked }) => (checked ? classes?.CheckboxT : classes?.CheckboxF)}
  ${({ classes, disabled }) => disabled && classes && classes.Disabled}
`;

const InputCheckbox = styled.input`
  ${({ classes }) => classes && classes.Input}
`;

const CommonLabel = styled.label`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

const Checkbox = ({
  name,
  id,
  item,
  selectAll = false,
  required,
  disabled,
  classes,
  observedSubjectNames = [],
  selectAllList,
}) => {
  const { register, reset, watch, setValue } = useFormContext();
  const isChecked = useWatch({ name });
  const observedSubjectChecked = useWatch({ name: observedSubjectNames });

  const selectAllCallback = useCallback(
    (checkboxPrefixName, checked) => {
      const formData = watch();
      const targetCheckbox = {};
      Object.keys(formData).forEach((key) => {
        // typeof formData[key] !== 'undefined' disabled인 checkbox의 value는 undefined 입니다.
        if (key.includes(checkboxPrefixName) && typeof formData[key] !== 'undefined') {
          targetCheckbox[key] = checked;
        }
      });
      reset({ ...formData, ...targetCheckbox });

      // NOTE: GROUP으로 묶인 체크박스인 경우
      if (!Array.isArray(formData[checkboxPrefixName]) && typeof formData[checkboxPrefixName] === 'object') {
        const result = Object.keys(formData[checkboxPrefixName]).reduce(
          (acc, cur) => {
            acc[checkboxPrefixName][cur] = checked;
            return acc;
          },
          { [checkboxPrefixName]: {} }
        );

        reset({ ...formData, [`${checkboxPrefixName}_selectAll`]: checked, ...result });
      }
    },
    [reset, watch]
  );

  const handleOnClick = useCallback(
    (e) => {
      const selectAllFullName = e.target.name;
      const checkboxPrefixName = selectAllFullName.substring(0, selectAllFullName.indexOf('_selectAll'));

      selectAllCallback(checkboxPrefixName, e.target.checked);
    },
    [selectAllCallback]
  );

  // NOTE: contents 관절가동 범위를 위한 selectAll 로직
  const contentsRomTypeSelectAllClick = () => {
    if (!selectAllList || selectAllList.length === 0) return;

    const prefix = name.substring(0, name.lastIndexOf('.'));
    if (isChecked) {
      selectAllList.forEach((index) => {
        setValue(`${prefix}.${parseInt(index, 10)}`, `RT${index}`);
      });
    } else {
      selectAllList.forEach((index) => {
        setValue(`${prefix}.${parseInt(index, 10)}`, false);
      });
    }
  };

  useEffect(() => {
    contentsRomTypeSelectAllClick();
  }, [isChecked]);
  // NOTE: contents 관절가동 범위를 위한 selectAll 로직

  // NOTE: GROUP으로 묶인 체크박스인 경우
  useEffect(() => {
    if (!selectAll) return;

    const isAllChecked = observedSubjectChecked.every(Boolean);

    if (isAllChecked) {
      setValue(name, true);
      return;
    }
    setValue(name, false);
  }, [observedSubjectChecked]);

  return (
    <CommonLabel>
      <InputCheckbox
        type='checkbox'
        id={id}
        value={item?.code}
        classes={classes}
        onClick={selectAll ? handleOnClick : null} // selectAll ?? handleOnClick 으로 작성할 경우 selectAll = false 일때 함수 호출됨
        {...register(name, { register, required, disabled })}
      />
      <CheckboxCustomUI classes={classes} checked={isChecked} disabled={disabled} />
      {item?.value || item?.title}
    </CommonLabel>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  /** Checkbox { code, value } */
  item: PropTypes.shape({
    /** Checkbox param code 값 */
    code: PropTypes.string,
    /** Checkbox Label 값 */
    value: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    Box: PropTypes.array,
    Input: PropTypes.array,
    Disabled: PropTypes.objectOf(PropTypes.string),
    CheckboxT: PropTypes.array,
    CheckboxF: PropTypes.array,
  }),
  onChange: PropTypes.func,
  selectAll: PropTypes.bool,
  observedSubjectNames: PropTypes.arrayOf(PropTypes.string),
  setSelectVal: PropTypes.any,
  selectAllList: PropTypes.arrayOf(
    PropTypes.shape({
      triggerName: PropTypes.string,
      targets: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    })
  ),
};

export default Checkbox;
