/* eslint-disable */
/** 컨텐츠 관리 > 컨텐츠 관리 > 등록 */
/** 컨텐츠 관리 > 컨텐츠 관리 > 컨텐츠 클릭(상세 화면) > 수정 */
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { contentAPIs, contentOutdoorRegisterAPI } from '@redux/action/contentsAction';

import styled, { css } from 'styled-components';

import { Button, Div, Modal, ModalContext } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import ContentsDefaultForm from './modules/form/ContentsDefaultForm';
import ContentsRoutineForm from './modules/form/ContentsRoutineForm';
import ContentsOutdoorForm from './modules/form/ContentsOutdoorForm';

import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';
import useContentMode from '@modules/FTA/hooks/useContentMode';
import useUserInfo from '@modules/FTA/hooks/useUserInfo';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';

import PATHS from '@router/paths';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const StorageSection = styled(Div)`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const FORM_NAMES = Object.freeze({
  // NOTE[CS] 기본 정보
  CONTENTS_NAME: 'contentsName',
  MAIN_CATEGORY: 'mainCategoryType',
  SUB_CATEGORY: 'subCategoryType',
  CONTENTS_STORAGE_LOCATION: 'contentsStorageLocation',
  CONTENTS_STORAGE_LOCATION_IP: 'contentsStorageLocationIp', // NOTE: 임시 명칭으로 API 명세서 상 IP를 받는 부분은 없음

  // NOTE[CS] 루틴/추천 운동 정보
  MUSCLE: 'muscle',
  EXERCISE_EFFECT: 'exerciseEffect',
  ACTION_DESCRIPTION: 'actionDescription',
  CATEGORY_TYPE: 'routineInfo.categoryType',
  CATEGORY_TYPE_ETC: 'routineInfo.categoryTypeEtc', // NOTE: 임시 명칭으로 API 명세서 상 IP를 받는 부분은 없음
  STF_TYPE: 'routineInfo.sftType',
  ALIGNMENT_TYPE: 'routineInfo.alignmentType',
  ROM_TYPE: 'routineInfo.romType',
  RECOMMEND_AGE_TYPE: 'routineInfo.recommendAgeType',
  LEVEL_TYPE: 'routineInfo.levelType',
  EXERCISE_INTENSITY_TYPE: 'routineInfo.exerciseIntensityType',
  EQUIPMENT_TYPE: 'routineInfo.equipmentType',
  EQUIPMENT_TYPE_ETC: 'routineInfo.equipmentTypeEtc', // NOTE: 임시 명칭으로 API 명세서 상 IP를 받는 부분은 없음
  POSITION_TYPE: 'routineInfo.positionType',
  POSITION_TYPE_ETC: 'routineInfo.positionTypeEtc', // NOTE: 임시 명칭으로 API 명세서 상 IP를 받는 부분은 없음
  BODY_PART_TYPE: 'routineInfo.bodyPartType',
  BODY_PART_TYPE_ETC: 'routineInfo.bodyPartTypeEtc', // NOTE: 임시 명칭으로 API 명세서 상 IP를 받는 부분은 없음
  EXCEPTION_BODYPART_TYPE: 'routineInfo.exceptionBodyPartType',
  EXCEPTION_BODYPART_TYPE_ETC: 'routineInfo.exceptionBodyPartTypeEtc', // NOTE: 임시 명칭으로 API 명세서 상 IP를 받는 부분은 없음
  EXCEPTION_DISEASE_TYPE: 'routineInfo.exceptionDiseaseType',
  EXCEPTION_DISEASE_TYPE_ETC: 'routineInfo.exceptionDiseaseTypeEtc', // NOTE: 임시 명칭으로 API 명세서 상 IP를 받는 부분은 없음
  EXERCISE_PURPOSE_TYPE: 'routineInfo.exercisePurposeType',
  EXERCISE_PURPOSE_TYPE_ETC: 'routineInfo.exercisePurposeTypeEtc', // NOTE: 임시 명칭으로 API 명세서 상 IP를 받는 부분은 없음
  CONTENTS_IMAGE_FILE: 'routineInfo.contentsImageFile',
  CONTENTS_ROUTINE_FILE: 'routineInfo.contentsRoutineFile',

  // NOTE[CS] 아웃도어 정보
  TRACK_TYPE: 'walkingJoggingInfo.trackType',
  HEIGHT: 'walkingJoggingInfo.height',
  TRACK_CAL: 'walkingJoggingInfo.trackCal',
  TRACK_DISTANCE: 'walkingJoggingInfo.trackDistance',
  STEPS: 'walkingJoggingInfo.steps',
  RECOMMEND_SPEED: 'walkingJoggingInfo.recommendSpeed',
  CONTENTS_PLAY_SPEED: 'walkingJoggingInfo.contentsPlaySpeed',
  THEME_ADMIN_TYPE: 'walkingJoggingInfo.themeType',
  ASSOCIATED_COUNTRY: 'walkingJoggingInfo.associatedCountry',
  WALKING_JOGGING_FILE: 'walkingJoggingInfo.walkingJoggingFile',
  WALKING_JOGGING_THUMBNAIL: 'walkingJoggingInfo.walkingJoggingThumbnail',
  BGM_FILE: 'walkingJoggingInfo.bgmFile',
  WALKING_JOGGING_DESCRIPTION: 'walkingJoggingDetailList.walkingJoggingDescription',
  WALKING_JOGGING_COURSE_INFO_LIST: 'walkingJoggingCourseInfoList',
  COURSE_MEDIAFILE: 'walkingJoggingCourseInfoList.INDEX.courseMediaFile',
});

const ContentsForm = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const currentContentDetail = useSelector((state) => state.contents.detail);
  const userInfo = useUserInfo();
  const { convertEnum } = useEnumSearch();
  const { getParams } = useSearchParams();
  const { isWriteMode } = useContentMode();

  const { adminType = '', contentsInfoId = '' } = getParams();

  const hookFormInstance = useForm();
  const { handleSubmit, reset } = hookFormInstance;

  const { showModal, closeModal } = useContext(ModalContext);

  const currentMainCategory = useMemo(
    () => currentContentDetail?.contentsInfo?.mainCategoryType || userInfo.cpManagementList?.[0]?.mainCategory,
    [currentContentDetail, userInfo]
  );
  const isOutdoor = currentMainCategory === 'MCT002';

  const onSubmit = (data) => {
    console.log('###당신 누구야', data);

    if (!currentMainCategory) return;
    const callAPI = contentAPIs?.[adminType]?.[currentMainCategory]?.regist;

    if (!callAPI) return;

    // NOTE Outdoor가 아닌 경우 (외부 센터 프로그램, AI 추천 영상, LG 시그니쳐 프로그램)
    if (!isOutdoor) {
      const {
        contentsName,
        subCategoryType,
        contentsStorageLocation,
        muscle,
        exerciseEffect,
        actionDescription,
        routineInfo,
      } = data;

      const filteredRoutineInfo = Object.keys(routineInfo).reduce((acc, key) => {
        if (Array.isArray(routineInfo[key])) {
          acc[key] = routineInfo[key].filter((item) => item);
          return acc;
        }

        if (!!routineInfo[key]) {
          acc[key] = routineInfo[key];
          return acc;
        }
      }, {});

      const params = {
        contentsInfo: {
          contentsDetailList: [
            {
              ...(!isWriteMode ? { contentsInfoId } : {}),
              contentsName,
              languageType: 'KR',
            },
          ],
          ...(!isWriteMode ? { contentsInfoId } : {}),
          // NOTE[CS] 현재는 current user의 cpManagementInfoId를 넣어주고 있으나, 기존에 컨텐츠를 작성한 사람의 id를 넣어야 하는지 확인 필요
          ...(!isWriteMode
            ? { cpManagementInfoId: userInfo.cpManagementList?.[0]?.cpManagementDetailList?.[0]?.cpManagementInfoId }
            : {}),
          mainCategoryType: currentMainCategory,
          subCategoryType: [subCategoryType],
          contentsStorageLocation,
        },
        routineDetailList: [
          {
            ...(!isWriteMode ? { routineInfoId: currentContentDetail[0]?.routineInfoId } : {}),
            muscle: [muscle],
            exerciseEffect,
            actionDescription,
            languageType: 'KR',
          },
        ],
        routineInfo: {
          ...(!isWriteMode ? { contentsInfoId } : {}),
          ...filteredRoutineInfo,
          /** NOTE[CS] 현재 값이 없어 그냥 상수로 써야 함 */
          contentsId: 202,
          exerciseNum: 22,
          exerciseType: 'ET000', // NOTE: exerciseTypeA enum
          exerciseOrder: 3,
          /** NOTE[CS] 현재 값이 없어 그냥 상수로 써야 함 */
          ...{
            routineApprovalStatusType: !isWriteMode
              ? currentContentDetail.routineInfo?.routineApprovalStatusType
              : 'RAS999',
          },
        },
      };

      console.log('###params:', params);
      callAPI(params);
    } else {
      const {
        contentsName,
        contentsStorageLocation,
        walkingJoggingCourseInfoList,
        subCategoryType,
        walkingJoggingInfo,
        walkingJoggingDetailList,
      } = data;
      console.log('###data: ', data);
      const FIXMEwalkingJoggingCourseInfoList = walkingJoggingCourseInfoList.map((item, index) => {
        return { ...item, ...{ courseOrder: index + 1 } };
      });
      const params = {
        contentsInfo: {
          contentsDetailList: [
            {
              ...(!isWriteMode ? { contentsInfoId } : {}),
              contentsName: contentsName,
              languageType: 'KR',
            },
          ],
          ...(!isWriteMode ? { contentsInfoId } : {}),
          contentsStorageLocation: contentsStorageLocation,
          mainCategoryType: 'MCT002',
          subCategoryType: [subCategoryType],
        },
        walkingJoggingCourseInfoList: FIXMEwalkingJoggingCourseInfoList,
        walkingJoggingDetailList: [{ ...walkingJoggingDetailList, ...{ languageType: 'KR' } }],
        walkingJoggingInfo: walkingJoggingInfo,
      };
      callAPI(params);
    }

    if (adminType === 'cp') {
      navigate(`/${PATHS.CONTENT_CP}`);
      return;
    }
    navigate(`/${PATHS.CONTENT_LG}`);
  };

  const handleSave = useCallback(() => {
    showModal(
      <Modal
        body='입력한 정보를 저장하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={handleSubmit(onSubmit)}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              저장
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, [handleSubmit]);

  const handleCancel = useCallback(() => {
    showModal(
      <Modal
        body='정보 저장을 취소하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  // NOTE[CS] Detail API 호출하여 값 채울 수 있도록 함
  useEffect(() => {
    if (!isWriteMode) {
      const callAPI = contentAPIs?.[adminType]?.[currentMainCategory]?.detail;
      callAPI && callAPI(dispatch, { contentsInfoId });
    }
  }, [currentMainCategory]);

  useEffect(() => {
    if (isWriteMode) {
      // NOTE: 입력 모드
      if (!isOutdoor) {
        // NOTE: Outdoor가 아닌 경우
        reset({
          [FORM_NAMES.MAIN_CATEGORY]: convertEnum(currentMainCategory),
        });
      } else {
        // NOTE: Outdoor인 경우
      }
    } else {
      // NOTE: 수정 모드
      if (!isOutdoor) {
        // NOTE: Outdoor가 아닌 경우
        reset({
          // NOTE 기본 정보
          [FORM_NAMES.CONTENTS_NAME]:
            currentContentDetail?.contentsInfo?.contentsDetailList?.[0]?.[FORM_NAMES.CONTENTS_NAME],
          [FORM_NAMES.MAIN_CATEGORY]: convertEnum(currentMainCategory),
          [FORM_NAMES.SUB_CATEGORY]: currentContentDetail?.contentsInfo?.subCategoryType?.[0],
          [FORM_NAMES.CONTENTS_STORAGE_LOCATION]:
            currentContentDetail?.contentsInfo?.[FORM_NAMES.CONTENTS_STORAGE_LOCATION],
          // NOTE 루틴/추천 운동 정보
          [FORM_NAMES.MUSCLE]: currentContentDetail?.routineDetailList?.[0]?.[FORM_NAMES.MUSCLE]?.[0],
          [FORM_NAMES.EXERCISE_EFFECT]: currentContentDetail?.routineDetailList?.[0]?.[FORM_NAMES.EXERCISE_EFFECT],
          [FORM_NAMES.ACTION_DESCRIPTION]:
            currentContentDetail?.routineDetailList?.[0]?.[FORM_NAMES.ACTION_DESCRIPTION],
          [FORM_NAMES.CATEGORY_TYPE]: currentContentDetail?.routineInfo?.categoryType,
          [FORM_NAMES.STF_TYPE]: currentContentDetail?.routineInfo?.sftType,
          [FORM_NAMES.ALIGNMENT_TYPE]: currentContentDetail?.routineInfo?.alignmentType,
          [FORM_NAMES.ROM_TYPE]: currentContentDetail?.routineInfo?.romType,
          [FORM_NAMES.RECOMMEND_AGE_TYPE]: currentContentDetail?.routineInfo?.recommendAgeType,
          [FORM_NAMES.LEVEL_TYPE]: currentContentDetail?.routineInfo?.levelType,
          [FORM_NAMES.EXERCISE_INTENSITY_TYPE]: currentContentDetail?.routineInfo?.exerciseIntensityType,
          [FORM_NAMES.EQUIPMENT_TYPE]: currentContentDetail?.routineInfo?.equipmentType,
          [FORM_NAMES.POSITION_TYPE]: currentContentDetail?.routineInfo?.positionType,
          [FORM_NAMES.BODY_PART_TYPE]: currentContentDetail?.routineInfo?.bodyPartType,
          [FORM_NAMES.EXCEPTION_BODYPART_TYPE]: currentContentDetail?.routineInfo?.exceptionBodyPartType,
          [FORM_NAMES.EXCEPTION_DISEASE_TYPE]: currentContentDetail?.routineInfo?.exceptionDiseaseType,
          [FORM_NAMES.EXERCISE_PURPOSE_TYPE]: currentContentDetail?.routineInfo?.exercisePurposeType,
          [FORM_NAMES.CONTENTS_IMAGE_FILE]: currentContentDetail?.routineInfo?.contentsImageFile,
          [FORM_NAMES.CONTENTS_ROUTINE_FILE]: currentContentDetail?.routineInfo?.contentsRoutineFile,
        });
      } else {
        // NOTE: Outdoor인 경우
        console.log('###hello');
        const courseMediaFileObj = {};
        if (
          Array.isArray(currentContentDetail?.walkingJoggingCourseInfoList) &&
          currentContentDetail?.walkingJoggingCourseInfoList.lenght > 0
        ) {
          currentContentDetail.walkingJoggingCourseInfoList.forEach((item, index) => {
            const courseMediaFileName = [FORM_NAMES.COURSE_MEDIAFILE].replace('INDEX', index);
            courseMediaFileObj[courseMediaFileName] = item.courseMediaFile;
          });
        }

        const courseMediaFileNames = Object.values(courseMediaFileObj);
        console.log('###userInfo', userInfo);
        console.log('###contents userInfo', userInfo?.cpManagementList?.[0]?.subCategory[0]);
        reset({
          [FORM_NAMES.MAIN_CATEGORY]: convertEnum(userInfo.cpManagementList[0].mainCategory),
          [FORM_NAMES.SUB_CATEGORY]: userInfo?.subCategory && userInfo?.cpManagementList?.[0]?.subCategory[0],
          [FORM_NAMES.CONTENTS_NAME]: currentContentDetail?.contentsInfo?.contentsDetailList?.[0]?.contentsName,
          [FORM_NAMES.CONTENTS_STORAGE_LOCATION]: currentContentDetail?.currentContentDetail?.contentsStorageLocation,

          [FORM_NAMES.TRACK_TYPE]: currentContentDetail?.walkingJoggingInfo?.trackType,
          [FORM_NAMES.HEIGHT]: currentContentDetail?.walkingJoggingInfo?.height,
          [FORM_NAMES.TRACK_CAL]: currentContentDetail?.walkingJoggingInfo?.trackCal,
          [FORM_NAMES.TRACK_DISTANCE]: currentContentDetail?.walkingJoggingInfo?.trackDistance,
          [FORM_NAMES.STEPS]: currentContentDetail?.walkingJoggingInfo?.steps,
          [FORM_NAMES.RECOMMEND_SPEED]: currentContentDetail?.walkingJoggingInfo?.recommendSpeed,
          [FORM_NAMES.CONTENTS_PLAY_SPEED]: currentContentDetail?.walkingJoggingInfo?.contentsPlaySpeed,
          [FORM_NAMES.THEME_ADMIN_TYPE]: currentContentDetail?.walkingJoggingInfo?.themeType,
          [FORM_NAMES.ASSOCIATED_COUNTRY]: currentContentDetail?.walkingJoggingInfo?.associatedCountry,
          [FORM_NAMES.WALKING_JOGGING_FILE]: currentContentDetail?.walkingJoggingInfo?.walkingJoggingFile,
          [FORM_NAMES.WALKING_JOGGING_THUMBNAIL]: currentContentDetail?.walkingJoggingInfo?.walkingJoggingThumbnail,
          [FORM_NAMES.BGM_FILE]: currentContentDetail?.walkingJoggingInfo?.bgmFile,
          [FORM_NAMES.WALKING_JOGGING_DESCRIPTION]:
            currentContentDetail?.walkingJoggingDetailList?.[0]?.walkingJoggingDescription,
          [FORM_NAMES.WALKING_JOGGING_COURSE_INFO_LIST]: currentContentDetail?.walkingJoggingCourseInfoList,
          courseMediaFileNames,
          courseOrder: currentContentDetail?.walkingJoggingCourseInfoList?.length?.toString(),
        });
      }
    }
  }, [userInfo, currentContentDetail, currentMainCategory, isOutdoor, isWriteMode]);

  return (
    <Container>
      <PageTitle title='컨텐츠 관리' />
      <FormProvider {...hookFormInstance}>
        <ContentsDefaultForm formNames={FORM_NAMES} />
        {isOutdoor ? <ContentsOutdoorForm formNames={FORM_NAMES} /> : <ContentsRoutineForm formNames={FORM_NAMES} />}
        <ButtonSection>
          <Button onClick={handleSave} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
            저장
          </Button>
          <Button onClick={handleCancel} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            취소
          </Button>
        </ButtonSection>
      </FormProvider>
    </Container>
  );
};

export default ContentsForm;
