/* eslint-disable */

import { useSelector } from 'react-redux';

const useUserInfo = () => {
  const userInfo = useSelector((state) => state.auth.loggedUserInfo);

  return userInfo;
};

export default useUserInfo;
