import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { deleteAuthInfo, deleteToken } from '@redux/feature/slices/authSlice';
import { loginAPI } from '@redux/action/authAction';
import Cookies from 'js-cookie';
import { CONFIG } from '../data';

const useAuth = () => {
  const dispatch = useDispatch();

  const login = useCallback(({ adminId, adminPw }) => {
    loginAPI(dispatch, { adminId, adminPw, adminType: CONFIG.USER_ADMIN_TYPE });
  }, []);

  const logout = useCallback(() => {
    dispatch(deleteAuthInfo());
    dispatch(deleteToken());
    Cookies.remove('refreshToken');
  }, []);

  return { login, logout };
};

export default useAuth;
