/* eslint-disable */

/** 컨텐츠 관리 > 컨텐츠 관리 단건 조회 > 수정 클릭 (아웃도어) */
import { Input, Text, SelectBox, Div, RadioButton } from '@components/index';
import BgmUpload from '@modules/FTA/components/BgmUpload';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import ImageUpload from '@modules/FTA/components/ImageUpload';
import VideoUpload from '@modules/FTA/components/VideoUpload';
import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const PlainContainer = styled(Div)`
  display: flex;
  column-gap: 18px;
`;

const ExtendedText = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.gray07};
`;

// const FORM_NAMES = Object.freeze({
//   TRACK_TYPE: 'walkingJoggingInfo.trackType',
//   HEIGHT: 'walkingJoggingInfo.height',
//   TRACK_CAL: 'walkingJoggingInfo.trackCal',
//   TRACK_DISTANCE: 'walkingJoggingInfo.trackDistance',
//   STEPS: 'walkingJoggingInfo.steps',
//   RECOMMEND_SPEED: 'walkingJoggingInfo.recommendSpeed',
//   CONTENTS_PLAY_SPEED: 'walkingJoggingInfo.contentsPlaySpeed',
//   THEME_ADMIN_TYPE: 'walkingJoggingInfo.themeType',
//   ASSOCIATED_COUNTRY: 'walkingJoggingInfo.associatedCountry',
//   WALKING_JOGGING_FILE: 'walkingJoggingInfo.walkingJoggingFile',
//   WALKING_JOGGING_THUMBNAIL: 'walkingJoggingInfo.walkingJoggingThumbnail',
//   BGM_FILE: 'walkingJoggingInfo.bgmFile',
//   WALKING_JOGGING_DESCRIPTION: 'walkingJoggingDetailList.walkingJoggingDescription',
//   WALKING_JOGGING_COURSE_INFO_LIST: 'walkingJoggingCourseInfoList',
//   COURSE_MEDIAFILE: 'walkingJoggingCourseInfoList.INDEX.courseMediaFile',
// });

const TARGET_ENUM_KEYS = [
  'ThemeAdminType',
  'TrackType',
  'ContentsPlaySpeedType',
  'CountryType',
  'LevelType',
  'ExerciseIntensityType',
  'EquipmentType',
];

const ContentsOutdoorForm = ({ Edit, title, formNames }) => {
  // default
  const { control } = useForm();
  const [selectVal, setSelectVal] = useState(1);
  const content = useSelector((state) => state.contents.detail);
  //
  const location = useLocation();

  const { getEnumsByKeys } = useEnumSearch();
  const [
    themeTypeList,
    trackTypeList,
    contentsPlaySpeedList,
    associatedCountry,
    levelTypeEnumList,
    exerciseIntensityTypeEnumList,
  ] = getEnumsByKeys(TARGET_ENUM_KEYS);

  const courseSelectBox = {
    guide: '',
    list: [
      { id: '1', title: '1' },
      { id: '2', title: '2' },
      { id: '3', title: '3' },
      { id: '4', title: '4' },
      { id: '5', title: '5' },
      { id: '6', title: '6' },
      { id: '7', title: '7' },
      { id: '8', title: '8' },
      { id: '9', title: '9' },
      { id: '10', title: '10' },
    ],
  };

  const themeTypeOption = useMemo(() => {
    const themeSelectBox = {
      guide: '테마',
      list: [],
    };
    themeTypeList.forEach((item, index) => {
      themeSelectBox?.list.push({ ...item, ...{ id: item.code, title: item.value } });
    });
    return themeSelectBox;
  }, [themeTypeList]);

  const contentsPlaySpeedOption = useMemo(() => {
    const speedSelectBox = {
      guide: '재생속도',
      list: [],
    };
    contentsPlaySpeedList?.forEach((item, index) => {
      speedSelectBox.list.push({ ...item, ...{ id: item.code, title: item.value } });
    });

    return speedSelectBox;
  }, [contentsPlaySpeedList]);

  const countryTypeOption = useMemo(() => {
    const countrySelectBox = {
      guide: '국가',
      list: [],
    };
    associatedCountry?.forEach((item, index) => {
      countrySelectBox.list.push({ ...item, ...{ id: item.code, title: item.value } });
    });

    return countrySelectBox;
  }, [associatedCountry]);

  console.log('###countryTypeOption', countryTypeOption);

  const INFO_INPUT = [
    {
      title: '관련 국가',
      content: [
        // FIXME : API 연결 후 기존 값으로 변경해야함
        <div style={{ width: '400px' }}>
          <SelectBox control={control} name={formNames.ASSOCIATED_COUNTRY} options={countryTypeOption} />
        </div>,
      ],
    },
    {
      title: '테마',
      content: [
        // FIXME : API 연결 후 기존 값으로 변경해야함
        <div style={{ width: '400px' }}>
          <SelectBox control={control} name={formNames.THEME_ADMIN_TYPE} options={themeTypeOption} />
        </div>,
      ],
    },
    {
      title: '액티비티 설명',
      content: [
        <Input
          name={formNames.WALKING_JOGGING_DESCRIPTION}
          type='text'
          placeholder='액티비티 설명을 입력해주세요.'
          maxLength='100'
        />,
      ],
    },
    {
      title: '트랙 유형',
      isRequired: true,
      content: [
        <PlainContainer>
          {trackTypeList?.map((trackType) => (
            <RadioButton
              required
              key={trackType.code}
              id={trackType.code}
              name={formNames.TRACK_TYPE}
              item={trackType}
            />
          ))}
        </PlainContainer>,
      ],
    },
    {
      title: '거리',
      content: [
        <Input name={formNames.TRACK_DISTANCE} type='text' placeholder='거리를 입력해주세요.' maxLength='100' />,
      ],
    },
    {
      title: '높이',
      isRequired: true,
      content: [<Input name={formNames.HEIGHT} type='text' placeholder='높이를 입력해주세요.' maxLength='100' />],
    },
    {
      title: '소모 칼로리',
      isRequired: true,
      content: [
        <Input name={formNames.TRACK_CAL} type='text' placeholder='소모 칼로리를 입력해주세요.' maxLength='100' />,
      ],
    },
    {
      title: '스탭 수',
      isRequired: true,
      content: [<Input name={formNames.STEPS} type='text' placeholder='스탭 수를 입력해주세요.' maxLength='100' />],
    },
    {
      title: '권장 속도',
      isRequired: true,
      content: [
        <Input name={formNames.RECOMMEND_SPEED} type='text' placeholder='거리를 입력해주세요.' maxLength='100' />,
      ],
    },
    {
      title: '컨텐츠 재생속도',
      isRequired: true,
      content: [
        // FIXME : API 연결 후 기존 값으로 변경해야함
        <div style={{ width: '400px' }}>
          <SelectBox control={control} name={formNames.CONTENTS_PLAY_SPEED} options={contentsPlaySpeedOption} />
        </div>,
      ],
    },
    {
      title: '영상 썸네일 이미지',
      isRequired: true,
      content: [<ImageUpload name={formNames.WALKING_JOGGING_THUMBNAIL} />],
    },
    {
      title: '영상 파일',
      isRequired: true,
      content: [
        // FIXME : API 연결 후 기존 값으로 변경해야함
        <VideoUpload name={formNames.WALKING_JOGGING_FILE} />,
      ],
    },
    {
      title: '효과음 파일',
      isRequired: true,
      content: [
        // FIXME : API 연결 후 기존 값으로 변경해야함
        <BgmUpload name={formNames.BGM_FILE} />,
      ],
    },
    {
      title: '코스 정보',
      isRequired: true,
      content: [
        // FIXME : API 연결 후 기존 값으로 변경해야함
        <div style={{ width: '400px' }}>
          <Controller
            name='courseOrder'
            control={control}
            render={({ field }) => <SelectBox {...field} options={courseSelectBox} selectValue={setSelectVal} />}
          />
        </div>,
        Array.from({ length: selectVal?.id || 1 }, (_, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
            <div style={{ width: '50px' }}>{index + 1}코스</div>
            <Input
              name={`${formNames.WALKING_JOGGING_COURSE_INFO_LIST}.${index}.courseDistance`}
              type='text'
              placeholder='시작 지점부터 코스 까지의 거리를 입력해주세요.'
              maxLength='100'
            />
          </div>
        )),
        ,
      ],
    },
    {
      title: '코스별 영상 파일',
      isRequired: true,
      content: [
        Array.from({ length: selectVal?.id || 1 }, (_, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
            {index + 1}코스 &emsp;
            <VideoUpload name={formNames.COURSE_MEDIAFILE.replace('INDEX', `[${index}]`)} />
          </div>
        )),
      ],
    },
    {
      title: 'LG 주최 마라톤 개최 여부',
      content: [<ExtendedText>-</ExtendedText>],
    },
    {
      title: '일시 정보',
      content: [<ExtendedText>-</ExtendedText>],
    },
    {
      title: 'LG 주최 마라톤 참가 설명 문구',
      content: [<ExtendedText>-</ExtendedText>],
    },
    {
      title: '대회 입상 시 경품 정보',
      content: [<ExtendedText>-</ExtendedText>],
    },
  ];

  useEffect(() => {
    console.log('###location.pathname', location.pathname);
    if (location.pathname === '/contents/cp/edit') {
      const courseNum = content?.[formNames.WALKING_JOGGING_COURSE_INFO_LIST]?.length;
      console.log('###courseNum', courseNum);
      setSelectVal({ id: courseNum, title: courseNum });
    }
  }, [location, content]);

  console.log('###selectVal', selectVal);
  return (
    <>
      <TitleWithDivider title={title} />
      <GridLayout data={INFO_INPUT} />
    </>
  );
};

ContentsOutdoorForm.propTypes = {
  Edit: PropTypes.string,
  data: PropTypes.any,
  title: PropTypes.string,
};

export default React.memo(ContentsOutdoorForm);
