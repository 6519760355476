import { useEffect, useRef, cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Div } from '@components/Atoms/Atoms';
import * as Utils from '@utils';

const StyledContainer = styled(Div)`
  position: relative;
  z-index: 50;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
  ${({ platform }) =>
    platform === 'android' &&
    `
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #aaa;
      border-radius: 9px;
    }
    &.scroll-hide::-webkit-scrollbar {
      display: none;
    }
    `}
  ${(props) => props.classes && props.classes.Container}
`;

const StyledContainerlist = styled.ul`
  position: relative;
  display: block;
  width: 100%;
  ${(props) => props.classes && props.classes.Containerlist}
`;

const VerticalScrollableContainer = ({ id, classes, children }) => {
  const containerRef = useRef(null);

  const changeScrollHide = (element, scrollClass, type) => {
    const classNames = (element.className && element.className.split(' ')) || [];
    const newClassNames = classNames.filter((targetClassName) => targetClassName !== scrollClass);

    if (type === 'add') {
      newClassNames.push(scrollClass);
    }
    element.className = newClassNames.join(' ').trim();
  };

  const setScrollEvent = (element) => {
    if (Utils.appOS !== 'IOS') {
      element.style['overflow-y'] = 'overlay';
      element.style['-webkit-overflow-scrolling'] = 'touch';
      changeScrollHide(element, 'scroll-show', 'add');
      changeScrollHide(element, 'scroll-hide', 'add');
      const scrollHandler = (e) => {
        if (window.scrollEventCallback) {
          window.scrollEventCallback(e);
        }
        clearTimeout(element.scrollTimeout);
        changeScrollHide(element, 'scroll-hide', 'remove');
        element.scrollTimeout = setTimeout(() => {
          changeScrollHide(element, 'scroll-hide', 'add');
        }, 500);
      };
      element.addEventListener('scroll', scrollHandler);
    }
  };

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setScrollEvent(containerRef.current);
    }
  }, [containerRef]);
  return (
    <StyledContainer id={id} ref={containerRef} classes={classes} platform={Utils.appOS}>
      <StyledContainerlist classes={classes} role='tablist'>
        {children &&
          children.map((child, index) =>
            cloneElement(child, {
              key: `VerticalScrollableContainerItem${index + 1}`,
              index,
              classes: { ...child.props.classes },
            })
          )}
      </StyledContainerlist>
    </StyledContainer>
  );
};
VerticalScrollableContainer.propTypes = {
  /** 컴포넌트 id */
  id: PropTypes.string,
  /** 부모 컴포넌트가 전달한 style */
  classes: PropTypes.string,
  /** Container 구성요소 */
  children: PropTypes.node,
};
export default VerticalScrollableContainer;
