import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Div } from '@components/index';

const Container = styled(Div)`
  display: flex;
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  user-select: none;
  display: flex;
  column-gap: 6px;

  ${({ enableToggle }) => enableToggle && `cursor: pointer;`};
`;

const LabelIconContainer = styled(Div)`
  --color: ${({ color }) => color};
  --horizontal-line-width: 12px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled(Div)`
  --dot-diameter: 6px;
  --dot-border: 1.5px;

  position: absolute;
  top: calc(((var(--dot-diameter)) / 2) + var(--dot-border));
  left: calc(((var(--horizontal-line-width) - var(--dot-diameter)) / 2));
  width: var(--dot-diameter);
  aspect-ratio: 1;
  border-radius: 50%;
  border: var(--dot-border) solid var(--color);
  background-color: ${({ theme }) => theme.gray01};
`;

const HorizontalLine = styled(Div)`
  width: var(--horizontal-line-width);
  height: 2px;
  border-radius: 1px;
  background-color: var(--color);
`;

const LabelText = styled.p``;

const LineChartBottomLegend = ({ id, label, checked, color, onChange, enableToggle = false }) => {
  const handleOnChange = () => {
    if (!enableToggle) return;
    onChange && onChange();
  };

  return (
    <Container>
      <Input type='checkbox' checked={checked} id={`${id}-bottom-legend`} onChange={handleOnChange} />
      <Label htmlFor={`${id}-bottom-legend`} enableToggle={enableToggle}>
        <LabelIconContainer color={color}>
          <Dot />
          <HorizontalLine />
        </LabelIconContainer>
        <LabelText>{label}</LabelText>
      </Label>
    </Container>
  );
};

LineChartBottomLegend.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  enableToggle: PropTypes.bool,
};

export default LineChartBottomLegend;
