import styled from 'styled-components';
import Loading from '@components/Feedback/Loading';
// import { InitContext } from "@init";
// import { InfoContext } from "@info";
// import { LoadContext } from "@load";
// import { Environment } from "@environment";
import ScrollableContainer from '@components/Layout/ScrollableContainer';
import PropTypes from 'prop-types';
import * as Utils from '@utils';

const Container = styled(ScrollableContainer)`
  position: relative;
  /* padding-top: 56px;
  padding-bottom: 56px; */
  min-height: 100vh;
  margin: 0 auto;
  background: ${({ theme }) => theme.gray01};
  /* min-height: calc(100vh - 70px); */

  ${({ platform }) => platform === 'ios' && 'overflow: hidden;'}
  ${({ platform }) => platform === 'android' && 'height: 0;'}

  & > img {
    display: block;
    width: 100%;
  }

  & legend {
    display: none;
  }

  & fieldset {
    border: 0;
  }
`;

const MainContainer = ({ children }) => (
  <>
    <Loading
    // show={!initContext.initialized || isLoading || infoContext.isLanding}
    // requiredText={loadingMessage || null}
    // comment={(enableMessage && loadingMessage) || null}
    />
    <Container id='main_container' platform={Utils.appOS}>
      {children}
    </Container>
  </>
);

MainContainer.propTypes = {
  /** Loading 화면 표시 */
  children: PropTypes.node.isRequired,
};

export default MainContainer;
