/** 외부 센터프로그램 관리 > 프로그램 작성 */

import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { Button, Div } from '@components/index';

import { PlusIcon } from '@FTA/assets';
import ProgramSwiper from '@components/DataDisplay/Swiper/ProgramSwiper';
import { useDispatch, useSelector } from 'react-redux';
import { programScheduleCalendarAPI } from '@redux/action/programAction';

const mockData = [
  {
    주차: '1주차',
    일요일: {
      tag: 'S01',
      title: '운동컨텐츠1',
      total: 1,
      detailContent: {
        title: '무언가운동',
        content: 22,
      },
    },
    월요일: { tag: 'S02', title: '운동컨텐츠2', total: 2 },
    화요일: { tag: 'S03', title: '운동컨텐츠3', total: 3 },
    수요일: { dayOff: true },
    목요일: null,
    금요일: null,
    토요일: null,
  },
  {
    주차: '2주차',
    일요일: { tag: 'S03', title: '운동컨텐츠3', total: 34444 },
    월요일: null,
    화요일: null,
    수요일: { dayOff: true },
    목요일: null,
    금요일: null,
    토요일: null,
  },
  {
    주차: '3주차',
    일요일: null,
    월요일: null,
    화요일: null,
    수요일: { dayOff: true },
    목요일: null,
    금요일: null,
    토요일: null,
  },
  {
    주차: '4주차',
    일요일: null,
    월요일: null,
    화요일: null,
    수요일: { dayOff: true },
    목요일: null,
    금요일: null,
    토요일: null,
  },
];

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;

  thead {
    height: 46px;
  }

  tr {
    font-weight: bold;
  }

  th,
  td {
    padding: 12px;
    border: 1px solid ${({ theme }) => theme.gray04};
  }

  th {
    background-color: ${({ theme }) => theme.gray03};
    width: 202px;
  }

  td {
    height: 50px;
    width: 202px;
  }

  .dayoff {
    color: gray;
    background-color: ${({ theme }) => theme.gray02};
  }

  .weeks {
    width: 86px;
  }

  .days {
    margin-top: 10px;
  }
`;

const TagButton = styled(Button)`
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;

  &.s01 {
    background: rgba(242, 235, 224, 1);
    color: rgba(132, 114, 88, 1);
  }

  &.s02 {
    background: rgba(229, 247, 241, 1);
    color: rgba(43, 139, 110, 1);
  }

  &.s03 {
    background: rgba(226, 238, 255, 1);
    color: rgba(64, 140, 253, 1);
  }

  &:hover {
    opacity: 0.7;
  }
`;

const ContentContainer = styled(Div)`
  min-height: 50px;
`;

const DetailContent = styled(Div)`
  color: ${({ theme }) => theme.gray07};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleContent = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled.div``;

const TitleCount = styled.div``;

const ButtonContainer = styled(Div)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const FirstNormalStyle = css`
  width: 67px;
  height: 24px;
  padding: 15px 0px;
  font-size: 12px;
  text-decoration: underline;
`;
const FirstHoverStyle = css`
  color: ${({ theme }) => theme.gray07};
`;
const FirstDisabledStyle = css`
  background-color: ${({ theme }) => theme.gray02};
  text-decoration: underline;
`;

const FirstUnderLineStyle = {
  NormalStyle: FirstNormalStyle,
  HoverStyle: FirstHoverStyle,
  DisabledStyle: FirstDisabledStyle,
};

// const ButtonCss = css`
//   width: 75px;
//   height: 55px;
//   padding: 8px 14px 8px 14px;
//   font-size: 14px;
//   font-weight: 600;
//   line-height: 18.72px;
//   text-align: center;
//   margin: 5px;
// `;

// const FooterButtonGroup = styled(Div)`
//   display: flex;
//   justify-content: end;
//   margin-top: 20px;
// `;

const ExternalCenterProgramCreation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const programSchduleCalendar = useSelector((state) => state.program.programSchduleCalendar);
  const handleNavigate = useCallback((path) => {
    if (path) {
      navigate(path);
    }
  }, []);
  // GET /v1/admin/program/schedule/{programSessionInfoId}  프로그램 작성 - 세션 - 운동 목록 조회 (캘린더)
  useEffect(() => {
    const param = {};
    programScheduleCalendarAPI(dispatch, param);
  }, []);

  console.log('###programScheduleCalendar', programSchduleCalendar);

  return (
    <>
      <ProgramSwiper>
        <StyledTable>
          <thead>
            <tr>
              <th className='weeks'>주차</th>
              {mockData.map(({ 주차, ...item }, idx) => {
                if (idx !== 0) {
                  return null;
                }
                const keys = Object.keys(item);
                return keys.map((key) => {
                  const dayData = item[key];
                  const isDayOff = dayData && dayData.dayOff === true;
                  return (
                    <th key={key} className={isDayOff ? 'dayoff' : 'days'}>
                      {key}
                    </th>
                  );
                });
              })}
            </tr>
          </thead>
          <tbody>
            {mockData.map(({ 주차, ...weekData }) => (
              <tr>
                <td className='weeks'>{주차}</td>
                {Object.values(weekData)?.map((schedule) => {
                  return (
                    <td className={schedule && schedule.dayOff && 'dayoff'}>
                      {!schedule && (
                        <ButtonContainer>
                          <Button
                            $layout='round'
                            $variantColor='secondary'
                            startIcon={PlusIcon}
                            $imgVariant='add'
                            border='2px solid rgba(48, 48, 48, 1)'
                            onClick={() => handleNavigate('edit')}
                          >
                            추가
                          </Button>
                        </ButtonContainer>
                      )}
                      {schedule && schedule.dayOff ? (
                        <> Day Off</>
                      ) : (
                        schedule && (
                          <ContentContainer>
                            <TitleContent>
                              <Title>
                                <TagButton className={schedule.tag?.toLowerCase()}>{schedule.tag}</TagButton>
                                <span>{schedule.title}</span>
                              </Title>
                              <TitleCount>
                                <span> {schedule.total}회</span>
                              </TitleCount>
                            </TitleContent>
                            <DetailContent>
                              <Title>
                                <span>{schedule.detailContent?.title}</span>
                              </Title>
                              <TitleCount>
                                {schedule.detailContent?.content && <div>{schedule.detailContent.content}회</div>}
                              </TitleCount>
                            </DetailContent>
                            <br />
                            <ButtonContainer>
                              <Button
                                $layout='round'
                                $variantColor='underLinePrimary'
                                classes={{ UnderLineStyle: FirstUnderLineStyle }}
                                onClick={() => handleNavigate('edit')}
                              >
                                수정하기
                              </Button>
                            </ButtonContainer>
                          </ContentContainer>
                        )
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </ProgramSwiper>
      {/* <FooterButtonGroup>
        <Button $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
          초기화
        </Button>
        <Button $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
          목록
        </Button>
      </FooterButtonGroup> */}
    </>
  );
};

export default ExternalCenterProgramCreation;
