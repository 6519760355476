const appOS = (() => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const targetAppOS = userAgent.indexOf('android') === -1 ? 'IOS' : 'AOS';
  // FIXME: PC/Mobile 분기필요
  return targetAppOS;
})();

// wait 함수 lint 예외처리 필요할 듯
// eslint-disable-next-line
const wait = (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

/**
 * @example
 *   import { convertDateToString } from '@utils';
 *
 *   Const { method, conditions } = dateConverter; method(new Date('2021-09-01'), conditions.DOT); // "2021.09.01"
 *
 * @param {Date} date - Date
 * @param {'ko' | string} [separator] - 구분자로 기본 값은 ''
 * @returns {string} YYYY-MM-DD
 */
const convertDateToString = (date, separator = '') => {
  // eslint-disable-next-line
  if (!date || !(date instanceof Date)) return console.error('Invalid Date');

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  switch (separator) {
    case 'ko':
      return `${year}년 ${month}월 ${day}일`;
    default:
      return `${year}${separator}${month}${separator}${day}`;
  }
};

const DATE_CALCULATOR_CONTIDIONS = Object.freeze({
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THIS_WEEK: 'thisWeek',
  LAST_WEEK: 'lastWeek',
  THIS_MONTH: 'thisMonth',
  LAST_MONTH: 'lastMonth',
  RECENT_7_DAYS: 'recent7Days',
  RECENT_30_DAYS: 'recent30Days',
});

/**
 * 조건에 따른 날짜 범위를 계산해주는 함수
 *
 * @example
 *   import { dateCalculator } from '@utils';
 *
 *   const { method, conditions } = dateCalculator;
 *   method(new Date(), conditions.THIS_MONTH);
 *   // 오늘이 2024년 4월 어느 날짜인 경우
 *   // { startDate: new Date('2024-04-01'), endDate: new Date('2024-04-30') }
 *
 * @param {Date} date - 계산의 기준이 되는 날짜
 * @param {'today'
 *   | 'yesterday'
 *   | 'thisWeek'
 *   | 'lastWeek'
 *   | 'thisMonth'
 *   | 'lastMonth'
 *   | 'recent7Days'
 *   | 'recent30Days'} condition
 *   - 어느 범위의 날짜를 계산할지 정해주는 조건 (conditions 객체 사용)
 *
 * @returns {{ startDate: Date; endDate: Date }} 계산된 날짜 범위의 시작일과 종료일을 반환
 */
const dateCalculatorMethod = (date, condition) => {
  const startDate = new Date(date);
  const endDate = new Date(date);

  switch (condition) {
    case DATE_CALCULATOR_CONTIDIONS.TODAY:
      break;
    case DATE_CALCULATOR_CONTIDIONS.YESTERDAY:
      startDate.setDate(startDate.getDate() - 1);
      endDate.setDate(endDate.getDate() - 1);
      break;
    case DATE_CALCULATOR_CONTIDIONS.THIS_WEEK:
      startDate.setDate(startDate.getDate() - startDate.getDay());
      endDate.setDate(endDate.getDate() + (6 - endDate.getDay()));
      break;
    case DATE_CALCULATOR_CONTIDIONS.LAST_WEEK:
      startDate.setDate(startDate.getDate() - startDate.getDay() - 7);
      endDate.setDate(endDate.getDate() + (6 - endDate.getDay() - 7));
      break;
    case DATE_CALCULATOR_CONTIDIONS.THIS_MONTH:
      startDate.setDate(1);
      endDate.setDate(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate());
      break;
    case DATE_CALCULATOR_CONTIDIONS.LAST_MONTH:
      startDate.setDate(1);
      startDate.setMonth(startDate.getMonth() - 1);
      endDate.setDate(0);
      break;
    case DATE_CALCULATOR_CONTIDIONS.RECENT_7_DAYS:
      startDate.setDate(startDate.getDate() - 6);
      break;
    case DATE_CALCULATOR_CONTIDIONS.RECENT_30_DAYS:
      startDate.setDate(startDate.getDate() - 29);
      break;
    default:
      break;
  }

  return { startDate, endDate };
};

const dateCalculator = Object.freeze({
  conditions: DATE_CALCULATOR_CONTIDIONS,
  method: dateCalculatorMethod,
});

/**
 * 쿼리 스트링으로 변환하는 함수
 *
 * @example
 *   import { createQueryString } from '@utils';
 *   CreateQueryString({ page: 1, size: 10, keyword: 'hello' });
 *   // "page=1&size=10&keyword=hello"
 *
 * @param {object} params - Query string이 될 key-value 쌍의 객체
 * @returns {string} EncodeURIComponent로 변환된 query string
 */
const createQueryString = (params) =>
  Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

/**
 * @example
 *   getDummyImageUrl({
 *   width: 200,
 *   height: 200,
 *   backgroundColor: '000',
 *   textColor: 'fff',
 *   format: 'jpg',
 *   text: 'dummy',
 *   });
 *   // https://dummyimage.com/200x200/000/fff.jpg&text=dummy
 *
 *   더미 이미지 url을 생성해주는 함수
 *
 * @param {object} params - Dummy image url을 구성하기 위한 params
 * @param {number} params.width - Dummy image의 가로 길이(px을 제외한 숫자)
 * @param {number} params.height - Dummy image의 세로 길이(px을 제외한 숫자)
 * @param {string} params.backgroundColor - Dummy image의 배경 색상(#을 제외한 hex code)
 * @param {string} params.textColor - Dummy image에 들어가는 텍스트 색상(#을 제외한 hex code)
 * @param {'jpg' | 'gif' | 'png'} params.format - Dummy image의 포맷
 * @param {string} [params.text] - Dummy image에 들어갈 text(전달하지 않으면 width x height가 출력)
 * @returns {string} Dummy image url
 */
const getDummyImageUrl = ({
  width = 200,
  height = 200,
  backgroundColor = '000',
  textColor = 'fff',
  format = 'jpg',
  text = '',
}) => `https://dummyimage.com/${width}x${height}/${backgroundColor}/${textColor}.${format}&text=${text}`;

// eslint-disable-next-line import/first
import { BASE_URL } from '@AxiosConfig';

const loadImageUrl = (FILE_KEY) => {
  return `${BASE_URL}/v1/mys3/media/${FILE_KEY}`;
};

/**
 * @example
 *   formatTime(1); // "01"
 *
 *   시간을 표현할 때 한자릿 수 앞에 0을 붙여주는 함수
 *
 * @param {string | number} time - 시간을 나타내는 숫자
 * @returns {string} 수정 된 숫자 string
 */
const formatTime = (time) => (time < 10 ? `0${time}` : `${time}`);

/**
 * @example
 *   const strings = ['특징1', '특징2', '특징3'];
 *   const result = transformToDefaultValues(strings);
 *   // result는 [{ name: '특징1', title: '특징1' }, { name: '특징2', title: '특징2' }, { name: '특징3', title: '특징3' }] InputChip에 사용 문자열 배열을 객체 배열로 변환하는 유틸리티 함수 문자열 배열을 `name`과 `title` 속성을 가진 객체 배열로 변환
 *
 * @param {string[]} values - 변환할 문자열 배열.
 * @returns {object[]} - 변환된 객체 배열.
 */
const transformToDefaultChipValues = (values) => {
  return values.map((value) => ({ name: value, title: value }));
};

/**
 * @example
 *   adjustNumber({ number: 241, N: 2, type: 'round' }); // 200
 *
 *   숫자를 반올림, 올림, 내림하는 함수
 *
 * @param {number} params - 숫자를 조정하기 위한 params
 * @param {number} params.targetNumber 대상이 되는 숫자
 * @param {number} params.N 대상이 되는 자릿수
 * @param {'ceil' | 'round' | 'floor'} params.type 반올림, 올림, 내림 중 선택
 * @returns {number} 반올림 된 숫자
 */
const adjustNumber = ({ number, N, type }) => {
  const factor = 10 ** N;

  switch (type) {
    case 'round':
      return Math.round(number / factor) * factor;
    case 'ceil':
      return Math.ceil(number / factor) * factor;
    case 'floor':
      return Math.floor(number / factor) * factor;
    default:
      return number;
  }
};

/**
 * Enum 전체 리스트 CODE -> value 값 변환
 *
 * @param {string} code Code
 * @param {string} enumType Key
 * @param {Array} enumAll List
 * @returns {Array} Enum List
 */

const changeCodeToValueEnum = (code, enumType, enumAll) => {
  return enumAll[enumType]?.filter((item) => item.code === code).map((item) => item.value) || [];
  // eslint-disable-next-line no-else-return
};

/**
 * 내부망인지 외부망인지 확인
 *
 * @returns {boolean} 외부망 true, 내부망 false
 */
const isInternal = () => {
  const envType = process.env.REACT_APP_BUILD_ENVIRONMENT;

  switch (envType) {
    case 'INT':
      return true;
    case 'EXT':
      return false;
    default:
      return false;
  }
};

/**
 * 객체 특정 Key 제외하고 복사
 *
 * @param {obj} obj Omit할 obj
 * @param {string} keysToOmit 제거할 Key
 * @returns {object} KeysToOmit가 제거된 new obj
 */
const omit = (obj, keysToOmit) => {
  return Object.keys(obj)
    .filter((key) => !keysToOmit.includes(key))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};

export {
  wait,
  appOS,
  convertDateToString,
  dateCalculator,
  createQueryString,
  getDummyImageUrl,
  formatTime,
  transformToDefaultChipValues,
  adjustNumber,
  changeCodeToValueEnum,
  loadImageUrl,
  isInternal,
  omit,
};
