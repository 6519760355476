import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Div, Text } from '@components/Atoms/Atoms';

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 16px; // NOTE[CS] 정확한 값을 알 수 없어서 일단은 눈대중으로 설정
`;

const GridStyled = styled(Div)`
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(${({ column }) => column}, 1fr);
  grid-template-rows: repeat(${({ row }) => row}, 1fr);
  gap: 20px;
  background: ${({ background }) => background};
  ${({ classes }) => classes && classes.Grid}
`;

const Grid = ({ classes, children, column = 1, row = 1, background, title }) => (
  <GridContainer>
    {title ? <Text fontWeight={700}>{title}</Text> : null}
    <GridStyled classes={classes} background={background} column={column} row={row}>
      {children}
    </GridStyled>
  </GridContainer>
);

Grid.propTypes = {
  /** 부모 컴포넌트가 전달한 style */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  /** Grid Item components */
  children: PropTypes.node,
  /** 열(column) */
  column: PropTypes.number,
  /** 행(row) */
  row: PropTypes.number,
  /** 배경색 값(background) */
  background: PropTypes.string,
  /** 타이틀 */
  title: PropTypes.string,
};

export default Grid;
