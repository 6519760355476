import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Div, Text } from '@components/Atoms/Atoms';
import Cell from '@modules/FTA/components/Cell';

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  row-gap: 16px;
`;

const GridStyled = styled(Div)`
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(${({ column }) => column}, 1fr);
  grid-template-rows: repeat(${({ row }) => row}, 1fr);
  gap: 20px;
  background: ${({ background }) => background};
  ${({ classes }) => classes && classes.Grid}
`;

const GridLayout = styled(Div)`
  display: inline-flex;
  width: 100%;
  padding: 10px;
  margin-bottom: 40px;
  border-radius: 12px;
  background: ${({ theme }) => theme.brown01};
`;

const cellCss = css`
  background: none;
  border: none;
`;

const GridBox = ({ classes, children, column = 1, row = 1, background, title, subtitle, totalCount }) => (
  <GridContainer>
    {title ? <Text fontWeight={700}>{title}</Text> : null}
    <GridLayout>
      <Cell title={subtitle} value={totalCount} classes={{ Cell: cellCss }} />
      <GridStyled classes={classes} background={background} column={column} row={row}>
        {children}
      </GridStyled>
    </GridLayout>
  </GridContainer>
);

GridBox.propTypes = {
  /** 부모 컴포넌트가 전달한 style */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  /** Grid Item components */
  children: PropTypes.node,
  /** 열(column) */
  column: PropTypes.number,
  /** 행(row) */
  row: PropTypes.number,
  /** 배경색 값(background) */
  background: PropTypes.string,
  /** 타이틀 */
  title: PropTypes.string,
  /** 서브 타이틀(GridBox 내부 타이틀) */
  subtitle: PropTypes.string,
  /** 서브 타이틀의 총합 */
  totalCount: PropTypes.string,
};

export default GridBox;
