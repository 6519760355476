/** 기타 관리 > CS 대응 */

import { useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { createColumnHelper } from '@tanstack/react-table';

import { Grid, Table } from '@components/index';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';
import DetailButton from '@FTA/components/DetailButton';
import PATHS from '@router/paths';
import Cell from '@FTA/components/Cell';
import { css } from '@emotion/react';
import PageTitle from '@FTA/components/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { getCSCountAPI, getCSListAPI } from '@redux/action/etcAction';
import moment from 'moment';
import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';

const columnHelper = createColumnHelper();

const GridStyle = css`
  margin-bottom: 40px;
`;

// FIXME[CS] 추후에 real data로 넘어갈 때 value 값 수정
const TABLE_KEYS = Object.freeze({
  NO: 'no',
  WRITER: 'userId',
  COUNTRY: 'country',
  CATEGORY: ' ',
  CSSTATUS: 'csStatus',
  TiTLE: 'title',
  REGISTRATION_DATE: 'registDate',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, title: 'no', checked: false },
  { code: TABLE_KEYS.COUNTRY, title: '국가', checked: false },
  { code: TABLE_KEYS.TiTLE, title: '제목', checked: false },
];

const CsResponse = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const { convertEnum } = useEnumSearch();
  const { searchParams, getSearchParamsObject } = useSearchParams();

  const csList = useSelector((state) => state.etc.csList.content);
  const csStatus = useSelector((state) => state.etc.csStatus);
  const csPaging = useSelector((state) => state.etc.csPaging);

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.NO, {
        id: TABLE_KEYS.NO,
        header: () => <span>No</span>,
        cell: ({ row: { index } }) => <span>{csList && csList.length - index}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.COUNTRY, {
        id: TABLE_KEYS.COUNTRY,
        header: () => <span>국가</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.CSSTATUS, {
        id: TABLE_KEYS.CSSTATUS,
        header: () => <span>상태</span>,
        cell: ({ getValue }) => <span>{convertEnum(getValue())}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.WRITER, {
        id: TABLE_KEYS.WRITER,
        header: () => <span>작성자</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.TiTLE, {
        id: TABLE_KEYS.TiTLE,
        header: () => <span>제목</span>,
        cell: ({ getValue, row: { original } }) => {
          const { csRequestInfoId = '' } = original;
          return (
            <DetailButton pathname={PATHS.CS_RESPONSE_DETAIL} searchParams={{ csRequestInfoId }}>
              {getValue()}
            </DetailButton>
          );
        },
      }),
      columnHelper.accessor(TABLE_KEYS.REGISTRATION_DATE, {
        id: TABLE_KEYS.REGISTRATION_DATE,
        header: () => <span>등록일</span>,
        cell: ({ getValue }) => <span>{moment(getValue()).format('YYYY-MM-DD')}</span>,
      }),
    ],
    []
  );
  /* eslint-enable react/no-unstable-nested-components */

  useEffect(() => {
    const listParam = getSearchParamsObject();
    const params = {};
    getCSListAPI(dispatch, listParam);
    getCSCountAPI(dispatch, params); // FIXME: params 비어있음
  }, [searchParams]);

  // FIXME[CS] 추후에 실제 데이터의 길이로 변경
  if (!csList) return <EmptyData text='등록된 CS 대응사항이 없습니다.' />;

  return (
    <>
      <PageTitle title='CS 대응' subItem='CS 대응' />
      <FormProvider {...methods}>
        <SearchContainer options={TABLE_OPTIONS} hasChip hasColumnFilter hasInputSelectBox hasDateFilter />
      </FormProvider>
      <Table data={csList} columns={COLUMNS_DATA} paging={csPaging} hasChip hasColumnFilter hasInputSelectBox />
      <div style={{ marginTop: '40px' }}>
        <Grid title='대응 현황' column={4} classes={{ Grid: GridStyle }}>
          <Cell title='미완료 건 수' value={csStatus?.incompleteCount} />
          <Cell title='완료 건 수' value={csStatus?.completeCount} />
          <Cell title='등록 건 수' value={csStatus?.totalCount} />
        </Grid>
      </div>
    </>
  );
};

export default CsResponse;
