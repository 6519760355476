/** 승인 관리 > 승인 대기 프로그램 */

import { FormProvider, useForm } from 'react-hook-form';
import { createColumnHelper } from '@tanstack/react-table';

import { convertDateToString } from '@utils';
import { Table } from '@components/index';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';
import { useMemo } from 'react';
import useApprovalPendingProgram from '@modules/FTA/hooks/approvalPending/useApprovalPendingProgram';
import DetailButton from '@modules/FTA/components/DetailButton';
import paths from '@router/paths';

const columnHelper = createColumnHelper();

// FIXME[CS] 추후에 real data로 넘어갈 때 value 값 수정
const TABLE_KEYS = Object.freeze({
  NO: 'no',
  PROGRAM_NAME: 'programName',
  CP_NAME: 'cpName',
  PRICE_YN: 'priceYn',
  SESSION_COUNT: 'sessionCount',
  WEEK: 'week',
  SCHDULE_YN: 'scheduleYn',
  EXPOSE_YN: 'exPoseYn',
  RESIST_DATE: 'registDate',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, title: 'no', checked: false },
  { code: TABLE_KEYS.PROGRAM_NAME, title: '프로그램명', checked: false },
  { code: TABLE_KEYS.CP_NAME, title: 'CP명', checked: false },
];

const PendingApprovalProgram = () => {
  const { pendingProgramList, pendingProgramPaging } = useApprovalPendingProgram();
  const methods = useForm();

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.NO, {
        id: TABLE_KEYS.NO,
        header: () => <span>No</span>,
        cell: ({ row: { index } }) => <span>{pendingProgramList && pendingProgramList.length - index}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.PROGRAM_NAME, {
        id: TABLE_KEYS.PROGRAM_NAME,
        header: () => <span>프로그램명</span>,
        cell: ({ getValue, row: { original } }) => {
          const programInfoId = original?.programInfoId;
          return (
            <DetailButton pathname={paths.ECP_CONFIGURATION_DETAIL} searchParams={{ programInfoId }}>
              {getValue()}
            </DetailButton>
          );
        },
      }),
      columnHelper.accessor(TABLE_KEYS.CP_NAME, {
        id: TABLE_KEYS.CP_NAME,
        header: () => <span>CP명</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.PRICE_YN, {
        id: TABLE_KEYS.PRICE_YN,
        header: () => <span>유/무료</span>,
        cell: ({ getValue }) => <span>{getValue() === true ? '유료' : '무료'}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.SESSION_COUNT, {
        id: TABLE_KEYS.SESSION_COUNT,
        header: () => <span>세션수</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.WEEK, {
        id: TABLE_KEYS.WEEK,
        header: () => <span>프로그램 기간</span>,
        cell: ({ getValue }) => <span>{getValue()}주</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.SCHDULE_YN, {
        id: TABLE_KEYS.SCHDULE_YN,
        header: () => <span>스케줄링 여부</span>,
        cell: ({ getValue }) => <span>{getValue() === true ? '완료' : '미정'}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.EXPOSE_YN, {
        id: TABLE_KEYS.EXPOSE_YN,
        header: () => <span>노출 여부</span>,
        cell: ({ getValue }) => <span>{getValue() === true ? '노출' : '비노출'}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.RESIST_DATE, {
        id: TABLE_KEYS.RESIST_DATE,
        header: () => <span>등록일</span>,
        cell: ({ getValue }) => <span>{convertDateToString(new Date(getValue()), '.')}</span>,
      }),
    ],
    [pendingProgramList]
  );
  /* eslint-enable react/no-unstable-nested-components */

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {pendingProgramList && pendingProgramList.length > 0 ? (
        <>
          <FormProvider {...methods}>
            <SearchContainer options={TABLE_OPTIONS} hasChip hasColumnFilter hasInputSelectBox hasDateFilter />
          </FormProvider>
          <Table
            data={pendingProgramList}
            columns={COLUMNS_DATA}
            paging={pendingProgramPaging}
            hasChip
            hasColumnFilter
            hasInputSelectBox
          />
        </>
      ) : (
        <EmptyData text='승인 대기 중인 프로그램이 없습니다.' />
      )}
    </>
  );
};

export default PendingApprovalProgram;
