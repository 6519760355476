import { useContext, useCallback } from 'react';
import { Outlet } from 'react-router-dom';

import styled, { css, useTheme } from 'styled-components';

import { Div, Divider, Icon, SideBar, Text, ModalContext } from '@components/index';
import SideBarAdmin from '@FTA/components/SideBarAdmin';
import LogoutModal from '@FTA/components/LogoutModal';

import { GoTop, LogoutIcon } from '@FTA/assets';
import ProtectedRoute from './ProtectedRoute';

const Container = styled(Div)`
  --sidebar-width: 300px;

  display: flex;
  min-height: 100vh;
`;

const SideBarFooter = styled(Div)`
  min-height: 60px;
  display: flex;
  align-items: center;
  margin-left: 28px;
`;

const LogoutContainer = styled(Div)`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

const MainContents = styled(Div)`
  flex: 4;
  flex-grow: 4;
  flex-shrink: 1;
  flex-basis: 0%;
  padding-top: 70px;
  box-sizing: border-box;
  padding-left: var(--sidebar-width);
`;

const PaddingWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  min-height: calc(100vh - 305px); // Footer 최소 높이 154px top padding 70px footer divider 1px divider margin 80px
`;

const Footer = styled(Div)`
  min-height: 154px;
  display: flex;
  justify-content: end;
  align-items: end;
`;

const FooterDivider = css`
  background: ${({ theme }) => theme.gray03};
  opacity: 1;
  margin-top: 80px;
`;

const GoTopIcon = styled(Div)`
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.gray04};
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
  margin-right: 36px;
  cursor: pointer;
`;

const FlexDiv = styled(Div)`
  display: flex;
`;

const VerticalDivider = css`
  height: 100%;
  :after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 10px;
    margin: 0 12px;
    background-color: ${({ theme }) => theme.gray07};
    opacity: 0.3;
    vertical-align: middle;
  }
`;

const CompanyDetails = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const Rights = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Contents = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: auto;
  padding: 28px 60px;
  gap: 14px;
`;

const SidebarContainerCSS = css`
  min-width: var(--sidebar-width);
  height: 100vh;
  padding: 0;
  background: ${({ theme }) => theme.gray10};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
`;

const SidebarHeaderContainerCSS = css`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Layout = () => {
  const { showModal } = useContext(ModalContext);
  const theme = useTheme();

  const handleLogoutModal = useCallback(() => {
    showModal(<LogoutModal />);
  }, []);

  const handleGoTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <ProtectedRoute>
      <Container>
        <SideBar classes={{ SideBar: SidebarContainerCSS, HeaderContainer: SidebarHeaderContainerCSS }}>
          <SideBarAdmin />
          <Divider />
          <SideBarFooter>
            <LogoutContainer onClick={handleLogoutModal}>
              <Icon icon={LogoutIcon} iconWidth={24} iconHeight={24} />
              <Text fontSize={18} fontWeight={600} color={theme.gray07} innerHtml='로그아웃' />
            </LogoutContainer>
          </SideBarFooter>
        </SideBar>
        <MainContents>
          <PaddingWrapper>
            <Outlet />
          </PaddingWrapper>
          <Divider classes={{ Divider: FooterDivider }} />
          <Footer>
            <Contents>
              <CompanyDetails>
                <FlexDiv>
                  <Text
                    classes={{ Text: VerticalDivider }}
                    fontSize={14}
                    fontWeight={400}
                    color={theme.gray07}
                    innerHtml='대표번호 : 02-3777-1114'
                  />
                  <Text
                    fontSize={14}
                    fontWeight={400}
                    color={theme.gray07}
                    innerHtml='주소 : 서울시 영등포구 여의대로 128 LG트윈타워'
                  />
                </FlexDiv>
                <FlexDiv>
                  <Text
                    classes={{ Text: VerticalDivider }}
                    fontSize={14}
                    fontWeight={400}
                    color={theme.gray07}
                    innerHtml='LGE.COM'
                  />
                  <Text
                    classes={{ Text: VerticalDivider }}
                    fontSize={14}
                    fontWeight={400}
                    color={theme.gray07}
                    innerHtml='사업자등록번호 : 107-86-14075'
                  />
                  <Text
                    classes={{ Text: VerticalDivider }}
                    fontSize={14}
                    fontWeight={400}
                    color={theme.gray07}
                    innerHtml='사업자 정보확인'
                  />
                  <Text
                    classes={{ Text: VerticalDivider }}
                    fontSize={14}
                    fontWeight={400}
                    color={theme.gray07}
                    innerHtml='사업자 정보확인 통신판매업신고번호 : 제1997-00084호'
                  />
                  <Text
                    fontSize={14}
                    fontWeight={400}
                    color={theme.gray07}
                    innerHtml='주소 : 서울시 영등포구 여의대로 128 LG트윈타워'
                  />
                </FlexDiv>
              </CompanyDetails>
              <Rights>
                <Text
                  fontSize={14}
                  fontWeight={400}
                  color={theme.gray07}
                  innerHtml='본 사이트의 모든 콘텐츠는 저작권법의 보호를 받는 바, 무단 전재, 복사, 배포 등을 금합니다.'
                />
                <Text
                  fontSize={14}
                  fontWeight={400}
                  color={theme.gray07}
                  innerHtml='Copyright © 2023 LG Electronics. All Rights Reserved.'
                />
              </Rights>
            </Contents>
            <GoTopIcon onClick={handleGoTop}>
              <Icon icon={GoTop} w={28} h={28} />
            </GoTopIcon>
          </Footer>
        </MainContents>
      </Container>
    </ProtectedRoute>
  );
};

export default Layout;
