import Axios from '@Axios';
import Cookies from 'js-cookie';
import { setAuthInfo, setToken } from '@redux/feature/slices/authSlice';
import { HEADER, METHODS } from '@AxiosConfig';
import { getAllEum } from './commonAction';
// import PATHS from '@router/paths';

const BASE_URL = 'v1/api/admin';

/**
 * 로그인 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {string} params.adminId 아이디(이메일)
 * @param {string} params.adminPw 비밀번호
 */
const loginAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/login`;

  const onSuccess = async (res) => {
    console.log('%c✅ loginAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);

    dispatch(setToken(res.data.token));
    dispatch(setAuthInfo(res.data));
    Cookies.set('refreshToken', res.data.refreshToken);

    // NOTE: 비동기로 setToken 이후에 실행되도록 수정해줘야 함
    // 추후 로그인 구현 완료 시 수정 필요할 듯
    getAllEum(dispatch);
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.GET, path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * 토큰 갱신 API
 *
 * @param {Function} dispatch Redux dispatch 함수
 * @param {object} params API 호출 시 필요한 파라미터
 * @param {string} params.refreshToken Refresh Token
 */
const updateTokenExpireTimeAPI = (dispatch, params) => {
  const URL = `${BASE_URL}/refreshToken`;

  const onSuccess = (res) => {
    console.log('%c✅ updateTokenExpireTimeAPI RESOPONSE', 'background: rgba(255, 103, 103, 0.3); color: white', res);
    dispatch(setToken(res.data.accessToken));
  };

  const onFail = (err) => {
    console.error(URL, err);
  };

  Axios({ type: METHODS.POST, path: URL, params, onSuccess, onFail, header: HEADER.GET }); // FIXME: 실제 API에 따라 method 변경 필요
};

// eslint-disable-next-line import/prefer-default-export
export { loginAPI, updateTokenExpireTimeAPI };
