import { rest } from 'msw';
import MockAccountsInstance from '../data/msw/accounts';

const BASE_URL = 'v1/api';

// eslint-disable-next-line import/prefer-default-export
export const accountsHandler = [
  // NOTE: 관리자 계정 리스트 조회 API
  rest.get(`/${BASE_URL}/accounts`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(MockAccountsInstance.getAccounts()));
  }),
  // NOTE: 관리자 계정 단건 조회 API
  rest.get(`/${BASE_URL}/accounts/:adminInfoId`, (req, res, ctx) => {
    const { adminInfoId } = req.params;

    if (!adminInfoId || adminInfoId === 'undefined') return res(ctx.status(500));

    const account = MockAccountsInstance.getAccount(adminInfoId);

    if (account) {
      return res(ctx.status(200), ctx.json(account));
    }
    return res(ctx.status(404));
  }),
  // NOTE: 관리자 계정 등급 및 승인상태 수정 API
  rest.put(`/${BASE_URL}/accounts/setting/:adminInfoId`, async (req, res, ctx) => {
    try {
      const { adminInfoId, approvalStatus, className } = await req.json();
      const updatedAccountStatus = MockAccountsInstance.updateAccount({ adminInfoId, className, approvalStatus });

      if (updatedAccountStatus) {
        return res(ctx.status(200), ctx.json({ message: 'Successfully updated account' }));
      }
      return res(ctx.status(500), ctx.json({ message: 'Failed to update account' }));
    } catch (error) {
      return res(ctx.status(404), ctx.json({ message: error }));
    }
  }),
  // NOTE: 관리자 계정 삭제 API
  rest.delete(`/${BASE_URL}/accounts/:adminInfoId`, (req, res, ctx) => {
    try {
      const { adminInfoId } = req.params;
      const deletedAccountStatus = MockAccountsInstance.deleteAccount(adminInfoId);

      if (deletedAccountStatus) {
        return res(ctx.status(200), ctx.json({ message: 'Successfully deleted account' }));
      }
      return res(ctx.status(500), ctx.json({ message: 'Failed to delete account' }));
    } catch (error) {
      return res(ctx.status(404), ctx.json({ message: error }));
    }
  }),
];
