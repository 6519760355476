import { FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import PropTypes from 'prop-types';

const FormProviderWithDevTools = ({ children, ...rest }) => {
  const { control } = rest;

  return (
    <FormProvider {...rest}>
      {children}
      <DevTool control={control} />
    </FormProvider>
  );
};

FormProviderWithDevTools.propTypes = {
  /** Children */
  children: PropTypes.node,
};

export default FormProviderWithDevTools;
