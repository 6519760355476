import { createSlice } from '@reduxjs/toolkit';
import { omit } from '@utils';

const initialState = {
  list: {
    cp: [],
    lg: [],
  },
  detail: {},
  paging: {},
};

/* eslint-disable no-param-reassign */
const contents = createSlice({
  name: 'contents',
  initialState,
  reducers: {
    setContentsList: (state, action) => {
      const { result, type } = action.payload;
      state.list[type] = result.content;
      state.paging = omit(action.payload.result, ['content']);
    },
    setContentDetail: (state, action) => {
      state.detail = action.payload;
    },
  },
});

/* eslint-enable no-param-reassign */

export const { setContentsList, setContentDetail, setContentsPaging } = contents.actions;
export default contents.reducer;
