import { fakerKO as faker } from '@faker-js/faker';

const RANDOM_LENGTH = 20 + Math.floor(Math.random() * 30);
const DUMMY_DATA_LENGTH = RANDOM_LENGTH > 23 ? RANDOM_LENGTH : 0;
const contentsList = new Array(DUMMY_DATA_LENGTH).fill(0).map(() => {
  return {
    contentsInfoId: faker.number.int(),
    cpName: faker.commerce.product(),
    contentsName: faker.commerce.product(),
    mainCategory: faker.helpers.arrayElement(['020', '0201', '0223']),
    subCategory: faker.helpers.arrayElement(['유연성 스트레칭', '홈워킹 근력강화', '유산소 강화']),
    category3: faker.helpers.arrayElement(['스트레칭', '근력', '유산소']),
    recommendedAge: '45세 미만',
    level: faker.helpers.arrayElement(['초급', '중급', '고급']),
    exerciseIntensity: faker.helpers.arrayElement(['상', '중', '하']),
    routineApprovalStatus: faker.helpers.arrayElement(['approval', 'reject', 'pendig']),
    registDate: faker.date.anytime(),
  };
});

const CONTENTS = {
  contentsList,
  pageable: {
    sort: {
      unsorted: true,
      sorted: false,
      empty: true,
    },
    pageSize: 10,
    pageNumber: 0,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  totalPages: 1,
  totalElements: 9,
  last: true,
  numberOfElements: 9,
  first: true,
  size: 10,
  sort: {
    unsorted: true,
    sorted: false,
    empty: true,
  },
  number: 0,
  empty: false,
};

const getContents = () => {
  return CONTENTS.contentsList;
};

const getApprovalStatusContent = (status) => {
  const getStatusContent = CONTENTS.contentsList.filter((con) => con.routineApprovalStatus === status);
  return getStatusContent;
};

export { getContents, getApprovalStatusContent };
