import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';
import { approvalContentAPI } from '@redux/action/approvalPendingAction';

const useApprovalPendingContent = () => {
  const dispatch = useDispatch();
  const { searchParams, getSearchParamsObject } = useSearchParams();
  const { convertEnum } = useEnumSearch();

  const pendingContentList = useSelector((state) => state.approvalPending.contentPendingList.content);
  const pendingCotentPaging = useSelector((state) => state.approvalPending.contentPendingPaging);

  useEffect(() => {
    const param = getSearchParamsObject();
    approvalContentAPI(dispatch, param);
  }, [searchParams]);

  return {
    pendingContentList,
    pendingCotentPaging,
    convertEnum,
  };
};

export default useApprovalPendingContent;
