/** 외부 센터프로그램 관리 > 프로그램 구성 > 프로그램 클릭(상세 화면) */
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { programDeleteApi, programDetailAPI } from '@redux/action/programAction';

import styled, { css } from 'styled-components';

import { Button, Div, Modal, ModalContext, Text } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import ImagePreview from '@FTA/components/ImagePreview';

import PATHS from '@router/paths';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ExtendedText = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.gray07};
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

const ExternalCenterProgramConfigurationDetail = () => {
  const dispatch = useDispatch();
  const programListDetail = useSelector((state) => state.program.programList);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchId = searchParams.get('programInfoId');

  const { showModal, closeModal } = useContext(ModalContext);

  useEffect(() => {
    programDetailAPI(dispatch, { programInfoId: searchId });
  }, [searchParams]);

  const gotoList = useCallback(() => {
    showModal(
      <Modal
        body='목록으로 이동하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                navigate(`/${PATHS.ECP_CONFIGURATION}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const onClickEdit = useCallback(() => {
    const search = createSearchParams({ searchId }).toString();

    showModal(
      <Modal
        body='등록된 정보를 수정하시겠습니까?
        프로그램 구성을 수정할 경우 프로그램 작성 정보가 변경되거나 삭제될 수 있으니 주의해주세요.
        '
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                navigate({ pathname: `/${PATHS.ECP_CONFIGURATION_EDIT}`, search });
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  const programDetailData = useMemo(() => {
    return [
      {
        title: 'CP명',
        content: [<ExtendedText>{programListDetail?.cpInfo?.cpName}</ExtendedText>],
      },
      {
        title: '프로그램명',
        content: [
          <ExtendedText>
            {programListDetail?.programDetailList?.length > 0 && programListDetail?.programDetailList[0]?.programName}
          </ExtendedText>,
        ],
      },
      {
        title: '프로그램 이미지',
        content: [<ImagePreview image={programListDetail?.file?.fileUrl} />],
      },
      {
        title: '소개글',
        content: [<ExtendedText>{programListDetail?.programDetailList?.[0]?.programDescription}</ExtendedText>],
      },
      {
        title: '유/무료',
        content: [<ExtendedText>{programListDetail?.priceYn ? '유료' : '무료'}</ExtendedText>],
      },
      {
        title: '프로그램 정보',
        content: [<ExtendedText>No data</ExtendedText>],
      },
      {
        title: '프로그램 노출 여부',
        content: [<ExtendedText>{programListDetail?.exposeYn ? '노출' : '비노출'}</ExtendedText>],
      },
    ];
  }, [programListDetail]);

  const programSessionData = useMemo(() => {
    const SESSION_DATA = [];

    if (programListDetail.programSessionList?.[0]) {
      programListDetail.programSessionList?.[0]?.sessionDetailList.map((session, index) =>
        SESSION_DATA.push(
          {
            sessionTitle: `세션 ${index + 1}`,
          },
          {
            title: '세션명',
            content: [<ExtendedText>{session.sessionName}</ExtendedText>],
          },
          {
            title: '설명',
            content: [<ExtendedText>{session.sessionDescription}</ExtendedText>],
          },
          {
            title: '세션 기간 및 요일 선택',
            content: [<ExtendedText>No data</ExtendedText>],
          }
        )
      );
    }
    return SESSION_DATA;
  }, [programListDetail]);

  const deleteProgram = useCallback(() => {
    closeModal();
    const params = {
      programInfoId: searchId,
    };
    programDeleteApi(dispatch, params);
    navigate(`/${PATHS.ECP_CONFIGURATION}`);
  }, []);

  const onClickDelete = useCallback(() => {
    showModal(
      <Modal
        body='등록된 정보를 삭제하시겠습니까?'
        btns={
          <ButtonSection>
            <Button onClick={deleteProgram} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  return (
    <Container>
      <PageTitle title='프로그램 구성' />
      <TitleWithDivider title='기본 정보' />
      <GridLayout data={programDetailData} />
      <TitleWithDivider title='운동 세션 정보' />
      <GridLayout data={programSessionData} />
      <ButtonSection>
        <Button onClick={onClickEdit} $layout='square' $variantColor='primary' classes={{ Button: ButtonCss }}>
          수정
        </Button>
        <Button onClick={onClickDelete} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
          삭제
        </Button>
        <Button onClick={gotoList} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
          목록
        </Button>
      </ButtonSection>
    </Container>
  );
};

ExternalCenterProgramConfigurationDetail.propTypes = {};

export default ExternalCenterProgramConfigurationDetail;
