/** 관리자 계정 관리 > 관리자 계정 */

import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountsAPI } from '@redux/action/accountsAction';

import { createColumnHelper } from '@tanstack/react-table';

import { Table } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';
import EmptyData from '@FTA/components/EmptyData';
import SearchContainer from '@FTA/components/SearchContainer';
import DetailButton from '@FTA/components/DetailButton';
import ApprovalStatusChip from '@FTA/components/ApprovalStatusChip';

import { convertDateToString } from '@utils';
import PATHS from '@router/paths';
import useSearchParams from '@modules/FTA/hooks/useSearchParams';

const columnHelper = createColumnHelper();

const TABLE_KEYS = Object.freeze({
  NO: 'no',
  ADMIN_ID: 'adminId',
  ADMIN_NAME: 'adminName',
  ADMIN_COUNTRY: 'adminCountry',
  CLASS_NAME: 'className',
  ADMIN_APPROVE_STATUS: 'adminApproveStatus',
  REGIST_DATE: 'registDate',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, title: '번호', checked: false },
  { code: TABLE_KEYS.ADMIN_ID, title: 'ID', checked: false },
];

const AccountList = () => {
  const dispatch = useDispatch();
  const accountsList = useSelector((state) => state.accounts.accountsList);
  const accountsPaging = useSelector((state) => state.accounts.accountsPaging);
  const { searchParams, getSearchParamsObject } = useSearchParams();

  const methods = useForm();

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.NO, {
        id: TABLE_KEYS.NO,
        size: 50,
        header: () => <span>번호</span>,
        cell: ({ row: { index } }) => <span>{accountsList && accountsList.length - index}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.ADMIN_ID, {
        id: TABLE_KEYS.ADMIN_ID,
        header: () => <span>ID</span>,
        cell: ({ getValue, row: { original } }) => {
          const { adminInfoId = '' } = original;
          return (
            <DetailButton pathname={PATHS.ACCOUNT_DETAIL} searchParams={{ adminInfoId }}>
              {getValue()}
            </DetailButton>
          );
        },
      }),
      columnHelper.accessor(TABLE_KEYS.ADMIN_NAME, {
        id: TABLE_KEYS.ADMIN_NAME,
        header: () => <span>이름</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.ADMIN_COUNTRY, {
        id: TABLE_KEYS.ADMIN_COUNTRY,
        header: () => <span>국적</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.CLASS_NAME, {
        id: TABLE_KEYS.CLASS_NAME,
        header: () => <span>관리자 등급</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.ADMIN_APPROVE_STATUS, {
        id: TABLE_KEYS.ADMIN_APPROVE_STATUS,
        header: () => <span>승인상태</span>,
        cell: ({ getValue }) => <ApprovalStatusChip code={getValue()} />,
      }),
      columnHelper.accessor(TABLE_KEYS.REGIST_DATE, {
        id: TABLE_KEYS.REGIST_DATE,
        header: () => <span>등록일</span>,
        cell: ({ getValue }) => <span>{convertDateToString(new Date(getValue()), '.')}</span>,
      }),
    ],
    [accountsList]
  );
  /* eslint-enable react/no-unstable-nested-components */

  useEffect(() => {
    const param = getSearchParamsObject();
    getAccountsAPI(dispatch, param);
  }, [searchParams]);

  if (accountsList.length === 0) return <EmptyData text='등록된 컨텐츠가 없습니다.' />;

  return (
    <>
      <PageTitle title='관리자 계정' />
      <FormProvider {...methods}>
        <SearchContainer options={TABLE_OPTIONS} hasChip hasColumnFilter hasInputSelectBox hasDateFilter />
      </FormProvider>
      <Table
        data={accountsList}
        columns={COLUMNS_DATA}
        paging={accountsPaging}
        hasChip
        hasColumnFilter
        hasInputSelectBox
      />
    </>
  );
};

AccountList.propTypes = {};

export default AccountList;
