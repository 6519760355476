import { createSlice } from '@reduxjs/toolkit';

// FIXME: 토큰 만료시간 설정 (개발을 위해 30일로 설정되어 있다고 함 / refresh token은 논의 후 DS Story측에서 노티 주기로 함)
const TOKEN_TIME_OUT = 30 * 24 * 60 * 60 * 1000;

const initialState = {
  // NOTE: jwt token
  authInfo: {
    authenticated: false,
    expireTime: 0,
    token: '',
  },
  // NOTE: login한 유저 정보
  loggedUserInfo: {
    accessibleMenuList: [],
    adminCountry: '',
    adminId: '',
    adminInfoId: '',
    adminName: '',
    adminNickname: '',
    adminProfileImageFileId: '',
    approvalAuthority: '',
    className: '',
    level: '',
    profileImageFile: '',
  },
};

/* eslint-disable no-param-reassign */
const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthInfo: (state, { payload }) => {
      const {
        adminInfoId,
        adminId,
        adminName,
        adminNickname,
        adminCountry,
        className,
        level,
        accessibleMenuList,
        approvalAuthority,
        profileImageFile,
        adminProfileImageFileId,
        cpManagementList,
      } = payload;
      state.loggedUserInfo = {
        adminInfoId,
        adminId,
        adminName,
        adminNickname,
        adminCountry,
        className,
        level,
        accessibleMenuList,
        approvalAuthority,
        profileImageFile,
        adminProfileImageFileId,
        cpManagementList,
      };
    },
    deleteAuthInfo: (state) => {
      state.loggedUserInfo = {
        adminInfoId: '',
        adminId: '',
        adminName: '',
        adminNickname: '',
        adminCountry: '',
        className: '',
        level: '',
        accessibleMenuList: '',
        approvalAuthority: '',
        profileImageFile: '',
        adminProfileImageFileId: '',
        cpManagementList: '',
      };
    },
    setToken: (state, { payload }) => {
      state.authInfo = {
        authenticated: true,
        expireTime: new Date().getTime() + TOKEN_TIME_OUT,
        token: payload,
      };
    },
    deleteToken: (state) => {
      state.authInfo = {
        authenticated: false,
        expireTime: 0,
        token: '',
      };
    },
  },
});
/* eslint-enable no-param-reassign */

export const { setAuthInfo, deleteAuthInfo, setToken, updateToken, deleteToken } = auth.actions;
export default auth.reducer;
