import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Div, Divider, Text } from '@components/index';
import Themes from '@styles/Themes';

const Section = styled(Div)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const SectionTitle = styled(Text)`
  padding: 20px 0;
`;

const DividerStyle = css`
  height: 2px;
  background-color: ${({ theme }) => theme.gray07};
  opacity: 1;
`;

const ContentsSection = ({ title, children }) => {
  return (
    <Section>
      <SectionTitle fontSize={22} fontWeight={700} color={Themes.light.gray10} inner={title} />
      <Divider classes={{ Divider: DividerStyle }} />
      {children}
    </Section>
  );
};

ContentsSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default ContentsSection;
