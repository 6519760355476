import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import styled from 'styled-components';

import { Icon, Input, FormProviderWithDevTools } from '@components/index';
import AuthenticationButton from '@FTA/pages/authentication/modules/AuthenticationButton';

import useAuth from '@FTA/hooks/useAuth';
import PATHS from '@router/paths';
import { ERRORS, VALIDATION_REGEXES } from '@FTA/data';
import { FTA_LOGIN_LOGO } from '@FTA/assets';

const GreetingContainer = styled.div``;

const GreetingText = styled.p`
  font-size: 40px;
  line-height: 53px;
  font-weight: 700;
  text-align: center;
  word-break: break-all;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const FormLabel = styled.p`
  font-size: 16px;
  line-height: 1.17;
  font-weight: 600;
  color: ${({ theme }) => theme.gray07};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
`;

const ExtendedInput = styled(Input)`
  width: 100%;
`;

const PasswordLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SignUpTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 4px;
  color: ${({ theme }) => theme.gray09};
`;

const AccountCheckText = styled.p``;

const SignUpText = styled.p`
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
`;

const FORM_KEYS = Object.freeze({
  ADMIN_ID: 'adminId',
  PASSWORD: 'adminPw',
});

const schema = yup.object().shape({
  [FORM_KEYS.ADMIN_ID]: yup
    .string()
    .matches(VALIDATION_REGEXES.EMAIL, ERRORS.INVALID_VALUE)
    .required(ERRORS.INVALID_CREDENTIALS),
  [FORM_KEYS.PASSWORD]: yup
    .string()
    .matches(VALIDATION_REGEXES.PASSWORD, ERRORS.INVALID_VALUE)
    .required(ERRORS.INVALID_CREDENTIALS),
});

const Login = () => {
  const navigate = useNavigate();
  const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur' });
  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;
  const { login } = useAuth();

  const handlePasswordFind = () => {
    navigate(`/${PATHS.PASSWORD}`);
  };

  const handleSignUp = () => {
    navigate(`/${PATHS.REGISTER}`);
  };

  return (
    <>
      <Icon icon={FTA_LOGIN_LOGO} iconWidth={230} iconHeight={60} />
      <GreetingContainer>
        <GreetingText>LG Intelli FiT</GreetingText>
        <GreetingText>Admin 포털에 오신 것을 환영합니다.</GreetingText>
      </GreetingContainer>
      <FormProviderWithDevTools {...methods}>
        <FormContainer>
          <InputContainer>
            <FormLabel>ID</FormLabel>
            <ExtendedInput
              hasDeleteIcon
              type='text'
              placeholder='ID를 입력해주세요'
              name={FORM_KEYS.ADMIN_ID}
              errorMessage={errors[FORM_KEYS.ADMIN_ID]?.message}
            />
          </InputContainer>
          <InputContainer>
            <PasswordLabelContainer>
              <FormLabel>비밀번호</FormLabel>
              <FormLabel onClick={handlePasswordFind} underline pointer>
                비밀번호 찾기
              </FormLabel>
            </PasswordLabelContainer>
            <ExtendedInput
              hasDeleteIcon
              type='password'
              placeholder='비밀번호를 입력해주세요'
              name={FORM_KEYS.PASSWORD}
              errorMessage={errors[FORM_KEYS.PASSWORD]?.message}
            />
          </InputContainer>
          <AuthenticationButton disabled={!isValid} onClick={handleSubmit(login)}>
            로그인
          </AuthenticationButton>
        </FormContainer>
      </FormProviderWithDevTools>
      <SignUpTextContainer>
        <AccountCheckText>LG Intelli FiT ID가 없으십니까?</AccountCheckText>
        <SignUpText onClick={handleSignUp}>계정 가입</SignUpText>
      </SignUpTextContainer>
    </>
  );
};

export default Login;
