import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Div } from '@components/index';

import PATHS from '@router/paths';

const Container = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 576px;
  row-gap: 50px;
`;

const LayoutCenter = () => {
  const authenticated = useSelector((state) => state.auth.authInfo.authenticated);
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) navigate(PATHS.HOME);
  }, [authenticated]);

  return (
    <Container>
      <Wrapper>
        <Outlet />
      </Wrapper>
    </Container>
  );
};

LayoutCenter.propTypes = {};

export default LayoutCenter;
