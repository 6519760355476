/** 컨텐츠 관리 > 컨텐츠 관리 단건 조회 (루틴) > */
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import { Div, Text, Button, ModalContext, Img } from '@components/index';
import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import VideoModal from '@FTA/components/VideoModal';
import { PlayIcon } from '@FTA/assets';
import { changeCodeToValueEnum, convertDateToString } from '@utils';

const ExtendedText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.gray07};
`;

const ExtendedImg = styled(Img)`
  width: 150px;
  height: 150px;
  border-radius: 8px;
`;

const ItemValueVideoContainer = styled(Div)`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const ContentsDetailRoutine = ({ title }) => {
  const enumAll = useSelector((state) => state.common.list);
  const cpInfo = useSelector((state) => state.auth.loggedUserInfo.cpManagementList?.[0]);

  // eslint-disable-next-line no-unused-vars
  const [info, setInfo] = useState([]);
  const [infoDetail, setInfoDetial] = useState([]);
  const [searchParams] = useSearchParams();
  const contentType = searchParams.get('content');
  const detailContent = useSelector((state) => state.contents.detail);
  const languageType = 'LANGUAGE_SET_KR';
  const { convertEnum } = useEnumSearch();

  const { showModal } = useContext(ModalContext);

  const handleVideoModal = useCallback((src) => {
    showModal(<VideoModal src={src} />);
  }, []);

  const getLanguageChange = useCallback((list, language) => {
    return list?.filter((item) => item?.languageType === language);
  }, []);

  const changeCodeToText = useCallback(
    (enumType, val) => {
      if (Array.isArray(val)) {
        return val
          .map((item) => {
            const foundItem = enumAll[enumType]?.find((enumItem) => enumItem.code === item);
            return foundItem ? foundItem.value : '-';
          })
          .join(', ')
          .replace(/\s+/g, '');
        // eslint-disable-next-line no-else-return
      } else {
        const foundItem = enumAll[enumType]?.find((enumItem) => enumItem.code === val);
        return foundItem ? foundItem.value.replace(/\s+/g, '') : '-';
      }
    },
    [enumAll]
  );

  const defaultInfo = useMemo(() => {
    return [
      {
        title: '컨텐츠명',
        content: [<ExtendedText>{detailContent?.contentsInfo?.contentsDetailList?.[0]?.contentsName}</ExtendedText>],
      },
      {
        title: 'CP/자사 구분',
        content: [<ExtendedText>{contentType === 'lg' ? '자사' : 'cp'}</ExtendedText>],
      },
      {
        title: 'CP명',
        content: [<ExtendedText>{cpInfo.cpManageDetailList?.[0]?.cpName}</ExtendedText>],
      },
      {
        title: '컨텐츠 분류 1',
        content: [<ExtendedText>{convertEnum(detailContent?.contentsInfo?.mainCategoryType)}</ExtendedText>],
      },
      {
        title: '컨텐츠 분류 2',
        content: [
          <ExtendedText>
            {changeCodeToText('SubCategoryType', detailContent?.contentsInfo?.subCategoryType)}
          </ExtendedText>,
        ],
      },
      {
        title: '컨텐츠 저장 위치',
        content: [<ExtendedText>{convertEnum(detailContent?.contentsInfo?.contentsStorageLocation)}</ExtendedText>],
      },
    ];
  }, [detailContent]);

  const routineInfo = useMemo(() => {
    return [
      {
        title: '카테고리',
        content: [
          <ExtendedText>
            {changeCodeToValueEnum(detailContent?.routineInfo?.categoryType, 'CategoryType', enumAll)?.[0]}
          </ExtendedText>,
        ],
      },
      {
        title: '신체능력평가 종류',
        content: [<ExtendedText>{changeCodeToText('StfType', detailContent?.routineInfo?.sftType)}</ExtendedText>],
      },
      {
        title: '체형분석 종류',
        content: [
          <ExtendedText>
            {changeCodeToText('BodyAlignmentType', detailContent?.routineInfo?.alignmentType)}
          </ExtendedText>,
        ],
      },
      {
        title: '관절가동범위',
        content: [<ExtendedText>{changeCodeToText('RomType', detailContent?.routineInfo?.romType)}</ExtendedText>],
      },
      {
        title: '권장 연령대',
        content: [
          <ExtendedText>
            {changeCodeToText('RecommendAgeType', detailContent?.routineInfo?.recommendAgeType)}
          </ExtendedText>,
        ],
      },
      {
        title: '난이도',
        content: [<ExtendedText>{changeCodeToText('LevelType', detailContent?.routineInfo?.levelType)}</ExtendedText>],
      },
      {
        title: '운동강도',
        content: [
          <ExtendedText>
            {changeCodeToText('ExerciseIntensityType', detailContent?.routineInfo?.exerciseIntensityType)}
          </ExtendedText>,
        ],
      },
      {
        title: '가용 기구',
        content: [
          <ExtendedText>{changeCodeToText('EquipmentType', detailContent?.routineInfo?.equipmentType)}</ExtendedText>,
        ],
      },
      {
        title: '포지션',
        content: [
          <ExtendedText>{changeCodeToText('PositionType', detailContent?.routineInfo?.positionType)}</ExtendedText>,
        ],
      },
      {
        title: '신체 부위',
        content: [
          <ExtendedText>
            {changeCodeToText('BodyPartForRoutineType', detailContent?.routineInfo?.bodyPartType)}
          </ExtendedText>,
        ],
      },
      // FIXME: 데이터 타입 확인해야함. code 아닌 value로 오는지
      {
        title: '근육명',
        content: [<ExtendedText>{detailContent?.routineDetailList?.[0]?.muscle}</ExtendedText>],
      },
      {
        title: '예외 대상 1 (신체부위)',
        content: [
          <ExtendedText>
            {changeCodeToText('ExceptionBodyPartType', detailContent?.routineInfo?.exceptionBodyPartType)}
          </ExtendedText>,
        ],
      },
      {
        title: '예외 대상 2 (질환명)',
        content: [
          <ExtendedText>
            {changeCodeToText('ExceptionDiseaseType', detailContent?.routineInfo?.exceptionDiseaseType)}
          </ExtendedText>,
        ],
      },
      {
        title: '운동 효과',
        content: [<ExtendedText>{detailContent?.routineDetailList?.[0]?.exerciseEffect}</ExtendedText>],
      },
      {
        title: '동작 설명',
        content: [<ExtendedText>{detailContent?.routineDetailList?.[0]?.actionDescription}</ExtendedText>],
      },
      {
        title: '운동 목적',
        content: [
          <ExtendedText>
            {changeCodeToText('ExercisePurposeType', detailContent?.routineInfo?.exercisePurposeType)}
          </ExtendedText>,
        ],
      },
      {
        title: '영상 썸네일 이미지',
        content: [<ExtendedImg src={detailContent?.routineInfo?.contentsImageFile?.fileUrl} />],
      },
      {
        title: '운동 시간',
        content: [<ExtendedText>{detailContent?.routineInfo?.exerciseTime}</ExtendedText>],
      },
      {
        title: '영상 파일',
        content: [
          <ItemValueVideoContainer>
            {detailContent?.routineInfo?.contentsRoutineFileList &&
              Object.keys(detailContent?.routineInfo?.contentsRoutineFileList).map((videokey) => {
                return (
                  <>
                    <ExtendedText inner={videokey} />
                    <Button
                      onClick={() => handleVideoModal(detailContent?.routineInfo?.contentsRoutineFileList[videokey])}
                      $layout='round'
                      $variantColor='gray'
                      startIcon={PlayIcon}
                      $imgVariant='play'
                    >
                      재생
                    </Button>
                  </>
                );
              })}
          </ItemValueVideoContainer>,
        ],
      },
      {
        title: '화면 비율',
        content: [<ExtendedText>16:9(MOCK_DATA)</ExtendedText>],
      },
      {
        title: '등록일',
        content: [
          <ExtendedText>{convertDateToString(new Date(detailContent?.contentsInfo?.registDate), '.')}</ExtendedText>,
        ],
      },
    ];
  }, [infoDetail, changeCodeToText, detailContent]);

  useEffect(() => {
    // 언어별 데이터 구분
    if (detailContent) {
      const infos = getLanguageChange(detailContent?.contentsInfo?.contentsDetailList || [], languageType);
      const details = getLanguageChange(detailContent?.routineDetailList || [], languageType);

      setInfo(infos?.[0]);
      setInfoDetial(details?.[0]);
    }
  }, [detailContent]);

  return (
    <>
      <TitleWithDivider title='기본 정보' />
      <GridLayout data={defaultInfo} hasBottomBorderLine />
      <TitleWithDivider title={title} />
      <GridLayout data={routineInfo} hasBottomBorderLine />
    </>
  );
};

ContentsDetailRoutine.propTypes = {
  title: PropTypes.string,
};

export default React.memo(ContentsDetailRoutine);
