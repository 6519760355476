import { useSelector } from 'react-redux';

const useEnumSearch = () => {
  const plainEnums = useSelector((state) => state.common.list);
  const flatEnums = useSelector((state) => state.common.flatEnums);

  /**
   * Enum code에 대응하는 enum value 반환하는 함수
   *
   * @param {string} targetEnum 검색할 enum code
   * @returns {string} 검색된 enum value, 없으면 targetEnum 반환
   */
  const convertEnum = (targetEnum) => {
    const target = flatEnums.find(({ code }) => code === targetEnum);
    return target ? target.value : targetEnum;
  };

  /**
   * Enum key에 대응하는 enums list 반환하는 함수
   *
   * @param {string} key 검색할 enum key
   * @returns {Array} 검색된 enums list를 반환
   */
  const getEnumsByKey = (key) => plainEnums[key];

  /**
   * Enum keys에 대응하는 enums lists 반환하는 함수
   *
   * @param {string} keys 검색할 enum keys
   * @returns {Array} 검색된 enums lists를 반환
   */
  const getEnumsByKeys = (keys) => keys.map((key) => plainEnums[key]);

  return { convertEnum, getEnumsByKey, getEnumsByKeys };
};

export default useEnumSearch;
