/* eslint-disable no-else-return */
/** 승인 관리 > 승인 대기 CP */

import { FormProvider, useForm } from 'react-hook-form';
import { createColumnHelper } from '@tanstack/react-table';

import { convertDateToString } from '@utils';
import { Table } from '@components/index';
import SearchContainer from '@FTA/components/SearchContainer';
import EmptyData from '@FTA/components/EmptyData';
// import styled, { css } from 'styled-components';
import { useCallback } from 'react';
import useApprovalPendingCp from '@modules/FTA/hooks/approvalPending/useApprovalPendingCp';
import DetailButton from '@modules/FTA/components/DetailButton';
import paths from '@router/paths';

// const ButtonContainer = styled(Div)`
//   display: flex;
//   justify-content: end;
//   margin-top: 30px;
// `;

// const FooterButtonStyle = css`
//   padding: 8px 4px;
// `;

const columnHelper = createColumnHelper();

// FIXME[CS] 추후에 real data로 넘어갈 때 value 값 수정
const TABLE_KEYS = Object.freeze({
  NO: 'no',
  CPNAME: 'cpName',
  FIRST_CONTENT_CLASSIFICATION: 'mainCategoryType',
  SECOND_CONTENT_CLASSIFICATION: 'subCategoryType',
  EXPOSED_PROGRAMS_COUNT: 'programCnt',
  CONTENTS_COUNT: 'contentsCnt',
  IS_FREE: 'priceYn',
  REGISTRATION_DATE: 'registDate',
});

// FIXME[CS] 구체적인 TABLE_OPTIONS 값이 없음
const TABLE_OPTIONS = [
  { code: TABLE_KEYS.NO, title: 'no', checked: false },
  { code: TABLE_KEYS.CPNAME, title: 'CP명', checked: false },
];

const PendingApprovalContent = () => {
  const { cpPendingList, cpPendingPaing, convertEnum } = useApprovalPendingCp();
  const methods = useForm();

  const convertToMapEnum = useCallback((el) => {
    if (Array.isArray(el)) {
      const convert = el?.map((item) => convertEnum(item));
      return convert;
    } else {
      return convertEnum(el);
    }
  }, []);

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = [
    columnHelper.accessor(TABLE_KEYS.NO, {
      id: TABLE_KEYS.NO,
      header: () => <span>No</span>,
      cell: ({ row: { index } }) => <span>{cpPendingList && cpPendingList.length - index}</span>,
    }),
    columnHelper.accessor(TABLE_KEYS.CPNAME, {
      id: TABLE_KEYS.CPNAME,
      header: () => <span>CP명</span>,
      cell: ({ getValue, row: { original } }) => {
        const cpManagementId = original.cpManagementInfoId;
        return (
          <DetailButton pathname={paths.CP_INFO_DETAIL} searchParams={{ cpManagementId }}>
            {getValue()}
          </DetailButton>
        );
      },
    }),
    columnHelper.accessor(TABLE_KEYS.FIRST_CONTENT_CLASSIFICATION, {
      id: TABLE_KEYS.FIRST_CONTENT_CLASSIFICATION,
      header: () => <span>컨텐츠 분류 1</span>,
      cell: ({ getValue }) => <span>{convertEnum(getValue())}</span>,
    }),
    columnHelper.accessor(TABLE_KEYS.SECOND_CONTENT_CLASSIFICATION, {
      id: TABLE_KEYS.SECOND_CONTENT_CLASSIFICATION,
      header: () => <span>컨텐츠 분류 2</span>,
      cell: ({ getValue }) => <span>{convertToMapEnum(getValue())}</span>,
    }),
    columnHelper.accessor(TABLE_KEYS.EXPOSED_PROGRAMS_COUNT, {
      id: TABLE_KEYS.EXPOSED_PROGRAMS_COUNT,
      header: () => <span>노출 프로그램 수</span>,
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    columnHelper.accessor(TABLE_KEYS.CONTENTS_COUNT, {
      id: TABLE_KEYS.CONTENTS_COUNT,
      header: () => <span>컨텐츠 수</span>,
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    columnHelper.accessor(TABLE_KEYS.IS_FREE, {
      id: TABLE_KEYS.IS_FREE,
      header: () => <span>유/무료</span>,
      cell: ({ getValue }) => <span>{getValue() === true ? '유료' : '무료'}</span>,
    }),
    columnHelper.accessor(TABLE_KEYS.REGISTRATION_DATE, {
      id: TABLE_KEYS.REGISTRATION_DATE,
      header: () => <span>등록일</span>,
      cell: ({ getValue }) => <span>{convertDateToString(new Date(getValue()), '.')}</span>,
    }),
  ];
  /* eslint-enable react/no-unstable-nested-components */

  return (
    <>
      {cpPendingList?.length > 0 ? (
        <>
          <FormProvider {...methods}>
            <SearchContainer options={TABLE_OPTIONS} hasChip hasColumnFilter hasInputSelectBox hasDateFilter />
          </FormProvider>
          <Table
            data={cpPendingList}
            columns={COLUMNS_DATA}
            paging={cpPendingPaing}
            hasChip
            hasColumnFilter
            hasInputSelectBox
          />
        </>
      ) : (
        <EmptyData text='승인 대기 중인 CP가 없습니다.' />
      )}
      {/* <ButtonContainer>
        <Button
          classes={{ Button: FooterButtonStyle }}
          $layout='square'
          $variantColor='primary'
          width={51}
          height={36}
          fontSize={14}
          onClick={() => {}}
        >
          등록
        </Button>
      </ButtonContainer> */}
    </>
  );
};

export default PendingApprovalContent;
