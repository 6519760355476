import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import propTypes from 'prop-types';

import PATHS from '@router/paths';

const ProtectedRoute = ({ children }) => {
  const authenticated = useSelector((state) => state.auth.authInfo.authenticated);

  return authenticated ? children : <Navigate to={`${PATHS.ROOT}`} />;
};

ProtectedRoute.propTypes = {
  children: propTypes.node.isRequired,
};

export default ProtectedRoute;
