import { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Div, Icon } from '@components/Atoms/Atoms';
import { BottomSheetIcon } from '@images/index';

const StyledContainer = styled(Div)`
  --option-width: ${({ width }) => `${width}px`};
  --option-height: ${({ height }) => `${height}px`};
  --option-font-size: 14px;
  --option-padding: 12px;

  position: relative;
  height: var(--option-height);
`;

const LabelContainer = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--option-width);
  padding: var(--option-padding);
  cursor: pointer;
  border-radius: 8px;
`;

const InitialLabel = styled.p`
  font-size: var(--option-font-size);
  font-weight: 600;
`;

const DropDownIcon = styled(Icon)`
  transform: ${({ isShow }) => (isShow ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const OptionList = styled.ul`
  position: absolute;
  top: calc(var(--option-height) + 8px);
  width: var(--option-width);
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  border: 1px solid ${({ theme }) => theme.gray03};
  border-radius: 8px;
  text-align: center;
  font-size: var(--option-font-size);
  padding: 10px;
  background-color: ${({ theme }) => theme.gray01};
  z-index: 300;
`;

const OptionItem = styled.li`
  padding: var(--option-padding);
  cursor: pointer;
  font-weight: 600;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.brown01};
      border-radius: 4px;
      color: ${({ theme }) => theme.brown04};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.hover01};
    border-radius: 4px;
  }
`;

const TableSingleDropdown = ({ field, width = 128, height = 40, options, defaultValueIndex, labelTitle, ...props }) => {
  const [isShow, setIsShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(defaultValueIndex);

  const onSelectBoxClick = useCallback(() => {
    setIsShow((prev) => !prev);
  }, []);

  const onItemClick = useCallback(
    (item, index) => {
      setCurrentIndex(index);
      field.onChange({ name: item.name, title: item?.title, checked: true });
    },
    [field]
  );

  return (
    <StyledContainer onClick={onSelectBoxClick} width={width} height={height} isShow={isShow} {...props}>
      <LabelContainer isShow={isShow}>
        <InitialLabel>{currentIndex === undefined ? options[0]?.title : options[currentIndex]?.title}</InitialLabel>
        <DropDownIcon icon={BottomSheetIcon} iconWidth={14} iconHeight={14} isShow={isShow} />
      </LabelContainer>
      {
        <OptionList isShow={isShow} height={height}>
          {options.map((item, index) => (
            <OptionItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${item?.name}-${index}`}
              isSelected={currentIndex === index}
              onClick={() => onItemClick(item, index)}
            >
              {item.title}
            </OptionItem>
          ))}
        </OptionList>
      }
    </StyledContainer>
  );
};

TableSingleDropdown.propTypes = {
  /** Calendar의 hook-form field */
  field: PropTypes.object,
  /** Calendar selecbox width */
  width: PropTypes.number,
  /** Calendar selecbox height */
  height: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
  labelTitle: PropTypes.string,
  defaultValueIndex: PropTypes.number,
};

export default TableSingleDropdown;
