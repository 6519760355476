export const METHODS = Object.freeze({
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
});

export const SERVER_TYPE = Object.freeze({
  LOCALHOST: 'LOCALHOST',
  DEV: 'DEV',
  QA: 'QA',
  PRD: 'PRD',
});

const SERVER_ADDRESS = Object.freeze({
  LOCALHOST: '',
  DEV: 'https://dev-kic-admin.lgeappstv.com',
  QA: 'https://qa-kic-admin.lgeappstv.com',
  PRD: 'PRD_IP_ADDRESS',
});

export const CURRENT_SERVER_TYPE = SERVER_TYPE.DEV; // NOTE: 현재 DEV API 정상동작하지 않아 일단 QA로 설정
const CURRENT_SERVER_ADDRESS = SERVER_ADDRESS[CURRENT_SERVER_TYPE];

export const BASE_URL = SERVER_ADDRESS[CURRENT_SERVER_TYPE];

export const getUrl = (path) => `${CURRENT_SERVER_ADDRESS}/${path}`;

export const HEADER = {
  GET: { headers: { 'Content-Type': 'application/json' } },
  PUT: { headers: { 'Content-Type': 'application/json' } },
  POST: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  DELETE: { headers: { 'Content-Type': 'application/json' } },
  MULTIPART: { headers: { 'Content-Type': 'multipart/form-data' } },
  TEXT: { headers: { 'Content-Type': 'text/plain' } },
  OCTET: { headers: { 'Content-Type': 'application/octet-stream' } },
  XML: { headers: { 'Content-Type': 'application/xml' } },
  HTML: { headers: { 'Content-Type': 'text/html' } },
  PDF: { headers: { 'Content-Type': 'application/pdf' } },
  CSV: { headers: { 'Content-Type': 'text/csv' } },
};
