// layouts
import LayoutCenter from '@FTA/layouts/LayoutCenter';
import Layout from '@FTA/layouts/Layout';

// auth layout
import Login from '@FTA/pages/authentication/Login';
import MyInfo from '@FTA/pages/authentication/MyInfo';
import PasswordFind from '@FTA/pages/authentication/PasswordFind';
import PasswordReset from '@FTA/pages/authentication/PasswordReset';
import Register from '@FTA/pages/authentication/Register';

// sidebar layout
import Home from '@FTA/pages/Home';

import Approval from '@FTA/pages/approvalManagement/Approval';

import CPInfo from '@FTA/pages/cpManagement/CPInfo';
import CPInfoDetail from '@FTA/pages/cpManagement/CPInfoDetail';
import CPInfoForm from '@FTA/pages/cpManagement/CPInfoForm';

import Contents from '@FTA/pages/contentManagement/Contents';
import ContentsDetail from '@FTA/pages/contentManagement/ContentsDetail';
import ContentsForm from '@modules/FTA/pages/contentManagement/ContentsForm';

import ExternalCenterProgramConfiguration from '@FTA/pages/externalCenterProgramManagement/ExternalCenterProgramConfiguration';
import ExternalCenterProgramConfigurationDetail from '@FTA/pages/externalCenterProgramManagement/ExternalCenterProgramConfigurationDetail';
import ExternalCenterProgramConfigurationEdit from '@modules/FTA/pages/externalCenterProgramManagement/ExternalCenterProgramConfigurationEdit/ExternalCenterProgramConfigurationEdit';
import ExternalCenterProgramSchedule from '@FTA/pages/externalCenterProgramManagement/ExternalCenterProgramSchedule';
import ExternalCenterProgramScheduleEdit from '@FTA/pages/externalCenterProgramManagement/ExternalCenterProgramScheduleEdit';
import ExternalCenterProgramCreation from '@FTA/pages/externalCenterProgramManagement/ExternalCenterProgramCreation';
import ExternalCenterProgramCreationEdit from '@FTA/pages/externalCenterProgramManagement/ExternalCenterProgramCreationEdit';

import AccountList from '@FTA/pages/adminAccountManagement/AccountList';
import AccountDetail from '@FTA/pages/adminAccountManagement/AccountDetail';
import AccountForm from '@FTA/pages/adminAccountManagement/AccountForm';

import AccountRoleList from '@FTA/pages/adminAccountManagement/AccountRoleList';
import AccountRoleDetail from '@FTA/pages/adminAccountManagement/AccountRoleDetail';
import AccountRoleForm from '@FTA/pages/adminAccountManagement/AccountRoleForm';

import Notice from '@FTA/pages/otherManagement/Notice';
import NoticeDetail from '@FTA/pages/otherManagement/NoticeDetail';
import NoticeUpdate from '@FTA/pages/otherManagement/NoticeEdit';

import HealthCareDB from '@FTA/pages/otherManagement/HealthCareDB';
import HealthCareDBDetail from '@FTA/pages/otherManagement/HealthCareDBDetail';
import HealthCareDBEdit from '@FTA/pages/otherManagement/HealthCareDBEdit';

import CsResponse from '@FTA/pages/otherManagement/CsResponse';
import CsResponseDetail from '@FTA/pages/otherManagement/CsResponseDetail';
import CsResponseEdit from '@FTA/pages/otherManagement/CsResponseEdit';

import Faq from '@FTA/pages/otherManagement/Faq';
import FaqDetail from '@FTA/pages/otherManagement/FaqDetail';
import FaqEdit from '@FTA/pages/otherManagement/FaqEdit';

import Survey from '@FTA/pages/otherManagement/Survey';
import SurveyDetail from '@FTA/pages/otherManagement/SurveyDetail';
import SurveyEdit from '@FTA/pages/otherManagement/SurveyEdit';

import ReportApp from '@FTA/pages/statisticsReport/ReportApp';
import ReportCP from '@FTA/pages/statisticsReport/ReportCP';

import NotFound from '@modules/FTA/pages/NotFound';

// paths
import PATHS from './paths';

const Router = [
  {
    path: PATHS.ROOT,
    element: <LayoutCenter />,
    children: [
      // 로그인 화면 App 진입점
      {
        path: '',
        element: <Login />,
      },
      {
        path: PATHS.REGISTER,
        element: <Register />,
      },
      {
        path: PATHS.PASSWORD,
        element: <PasswordFind />,
      },
      {
        path: PATHS.PASSWORD_RESET,
        exact: true,
        element: <PasswordReset />,
      },
      {
        path: PATHS.MYINFO,
        element: <MyInfo />,
      },
    ],
  },
  {
    path: PATHS.ROOT,
    element: <Layout />,
    children: [
      {
        path: PATHS.HOME,
        element: <Home />,
      },
      {
        path: PATHS.APPROVAL,
        element: <Approval />,
      },
      {
        path: PATHS.CP_INFO,
        element: <CPInfo />,
      },
      {
        path: PATHS.CP_INFO_DETAIL,
        element: <CPInfoDetail />,
      },
      {
        path: PATHS.CP_INFO_WRITE,
        element: <CPInfoForm />,
      },
      {
        path: PATHS.CP_INFO_EDIT,
        element: <CPInfoForm />,
      },
      {
        path: PATHS.ECP_CONFIGURATION,
        element: <ExternalCenterProgramConfiguration />,
      },
      {
        path: PATHS.ECP_CONFIGURATION_DETAIL,
        exact: true,
        element: <ExternalCenterProgramConfigurationDetail />,
      },
      {
        path: PATHS.ECP_CONFIGURATION_EDIT,
        element: <ExternalCenterProgramConfigurationEdit />,
      },
      {
        path: PATHS.ECP_SCHEDULE,
        exact: true,
        element: <ExternalCenterProgramSchedule />,
      },
      {
        path: PATHS.ECP_SCHEDULE_EDIT,
        exact: true,
        element: <ExternalCenterProgramScheduleEdit />,
      },
      {
        path: PATHS.ECP_CREATION,
        exact: true,
        element: <ExternalCenterProgramCreation />,
      },
      {
        path: PATHS.ECP_CREATION_EDIT,
        exact: true,
        element: <ExternalCenterProgramCreationEdit />,
      },
      {
        path: PATHS.CONTENTS_CP,
        element: <Contents />,
      },
      {
        path: PATHS.CONTENTS_CP_DETAIL,
        element: <ContentsDetail />,
      },
      {
        path: PATHS.CONTENTS_CP_EDIT,
        element: <ContentsForm />,
      },
      {
        path: PATHS.CONTENTS_CP_WRITE,
        element: <ContentsForm />,
      },
      {
        path: PATHS.CONTENTS_LG,
        exact: true,
        element: <Contents />,
      },
      {
        path: PATHS.CONTENTS_LG_DETAIL,
        exact: true,
        element: <ContentsDetail />,
      },
      {
        path: PATHS.CONTENTS_LG_EDIT,
        exact: true,
        element: <ContentsForm />,
      },
      {
        path: PATHS.CONTENTS_LG_WRITE,
        element: <ContentsForm />,
      },
      {
        path: PATHS.ACCOUNT,
        element: <AccountList />,
      },
      {
        path: PATHS.ACCOUNT_DETAIL,
        element: <AccountDetail />,
      },
      {
        path: PATHS.ACCOUNT_EDIT,
        element: <AccountForm />,
      },
      {
        path: PATHS.ACCOUNT_ROLE,
        exact: true,
        element: <AccountRoleList />,
      },
      {
        path: PATHS.ACCOUNT_ROLE_EDIT,
        exact: true,
        element: <AccountRoleForm />,
      },
      {
        path: PATHS.ACCOUNT_ROLE_DETAIL,
        exact: true,
        element: <AccountRoleDetail />,
      },
      {
        path: PATHS.NOTICE,
        element: <Notice />,
      },
      {
        path: PATHS.NOTICE_DETAIL,
        element: <NoticeDetail />,
      },

      {
        path: PATHS.NOTICE_EDIT,
        element: <NoticeUpdate />,
      },
      {
        path: PATHS.NOTICE_WRITE,
        element: <NoticeUpdate />,
      },
      {
        path: PATHS.HEALTHCAREDB,
        element: <HealthCareDB />,
      },
      {
        path: PATHS.HEALTHCAREDB_DETAIL,
        element: <HealthCareDBDetail />,
      },
      {
        path: PATHS.HEALTHCAREDB_EDIT,
        element: <HealthCareDBEdit />,
      },
      {
        path: PATHS.HEALTHCAREDB_WRITE,
        element: <HealthCareDBEdit />,
      },
      {
        path: PATHS.CS_RESPONSE,
        element: <CsResponse />,
      },
      {
        path: PATHS.CS_RESPONSE_DETAIL,
        element: <CsResponseDetail />,
      },
      {
        path: PATHS.CS_RESPONSE_EDIT,
        element: <CsResponseEdit />,
      },
      {
        path: PATHS.CS_RESPONSE_WRITE,
        element: <CsResponseEdit />,
      },
      {
        path: PATHS.FAQ,
        element: <Faq />,
      },
      {
        path: PATHS.FAQ_DETAIL,
        element: <FaqDetail />,
      },
      {
        path: PATHS.FAQ_EDIT,
        element: <FaqEdit />,
      },
      {
        path: PATHS.FAQ_WRITE,
        element: <FaqEdit />,
      },
      {
        path: PATHS.SURVEY,
        element: <Survey />,
      },
      {
        path: PATHS.SURVEY_DETAIL,
        element: <SurveyDetail />,
      },
      {
        path: PATHS.SURVEY_EDIT,
        element: <SurveyEdit />,
      },
      {
        path: PATHS.SURVEY_WRITE,
        element: <SurveyEdit />,
      },
      {
        path: PATHS.REPORT,
        element: <ReportApp />,
      },
      {
        path: PATHS.REPORT_CP,
        exact: true,
        element: <ReportCP />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
export default Router;
