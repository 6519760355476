import { Div, Text } from '@components/Atoms/Atoms';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled(Div)``;

const Title = styled(Text)`
  font-size: 14px;
  font-weight: 700;
  ${({ classes }) => classes && classes.Title}
`;

const Table = styled.table`
  --border: 1px solid #dddddd;

  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  text-align: left;
  font-size: 12px;
  ${({ classes }) => classes && classes.Table}
`;

const Th = styled.th`
  padding: 12px;
  border-top: var(--border);
  border-bottom: var(--border);
  background: ${({ theme }) => theme.gray02};
  font-weight: 700;
  ${({ classes }) => classes && classes.Th}
`;

const Td = styled.td`
  padding: 12px;
  border-top: var(--border);
  border-bottom: var(--border);
  :first-child {
    font-weight: 600;
    width: 54%;
  }
  :nth-child(2) {
    width: 25%;
  }
  :nth-child(3) {
    width: 20%;
  }

  ${({ classes }) => classes && classes.Td}
`;

const Tr = styled.tr``;

const BasicTable = ({ data, title, classes }) => {
  return (
    <Container>
      {title && <Title classes={classes}>{title}</Title>}
      <Table classes={classes}>
        <thead>
          <tr>
            {data.title.map((dataTitle) => (
              <Th>{dataTitle}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.rows.map((row) => (
            <Tr classes={classes}>
              {row.map((cell) => (
                <Td classes={classes}>{cell}</Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

BasicTable.propTypes = {
  /** Table Title */
  title: PropTypes.string,
  /** Table Data */
  data: PropTypes.shape({
    title: PropTypes.arrayOf(PropTypes.string),
    rows: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
};

export default BasicTable;
