import PropTypes from 'prop-types';
import styled from 'styled-components';

const RadioContanier = styled.li`
  border-bottom: 1px solid #e1e1e1;
  position: relative;
`;

const Input = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  position: absolute;

  &:checked + label {
    &::before {
      content: '';
      background-color: ${(props) =>
        (props.classes && props.classes.Active && props.classes.Active.BgColor) || '#00a0b6'};
      border-color: ${(props) =>
        (props.classes && props.classes.Active && props.classes.Active.BorderColor) || '#00a0b6'};
    }
  }

  &:checked + label {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 18px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: ${({ theme }) => theme.gray01};
      transform: translate(-50%, -50%);
    }
  }
  &:disabled + label {
    color: #262626;
    opacity: 0.2;
    &::before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      left: 18px;
      width: 18px;
      height: 18px;
      border: 1px solid #bdbdbd;
      border-radius: 100%;
      transform: translate(-50%, -50%);
      box-sizing: border-box;
    }
  }

  &:disabled + label {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 18px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #262626;
      opacity: 0.2;
      transform: translate(-50%, -50%);
    }
  }
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  text-align: left;
  font-size: 16px;
  font: -apple-system-body;
  -webkit-text-size-adjust: none;
  left: 0;
  padding: 15px 0 15px 36px;

  &::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 50%;
    left: 18px;
    width: 18px;
    height: 18px;
    border: 1px solid #bdbdbd;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }

  &::after {
    position: absolute;
    display: inline-block;
    content: none;
  }
`;

const StyledRadio = ({ onChange, show, ariaLabel, id, value, label, ...props }) => {
  const changeValueEvent = (e) => {
    onChange && onChange(e.target.value);
  };
  return (
    <RadioContanier>
      <Input type='radio' {...props} onChange={changeValueEvent} disabled={!show} aria-label={ariaLabel} />
      <Label htmlFor={id} value={value} {...props} aria-hidden='true'>
        {label}
      </Label>
    </RadioContanier>
  );
};

StyledRadio.propTypes = {
  /** 선택 가능 여부 */
  show: PropTypes.bool,
  ariaLabel: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.node,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default StyledRadio;
