/** 컨텐츠 관리 > 컨텐츠 관리 단건 조회 > 수정 클릭 (루틴) */
import { useWatch } from 'react-hook-form';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Checkbox, Input, RadioButton, Div, Text } from '@components/index';
import { CheckBoxChecked } from '@modules/FTA/assets';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import ImageUpload from '@modules/FTA/components/ImageUpload';
import VideoUpload from '@modules/FTA/components/VideoUpload';

import useEnumSearch from '@modules/FTA/hooks/useEnumSearch';

const ROM_TYPE_ALL_INDEXES = [0, 6, 10, 14, 16];

const VerticalContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const PlainContainer = styled(Div)`
  display: flex;
  column-gap: 18px;
`;

const GuideMessage = styled(Text)`
  font-size: 14px;
  line-height: 1.17;
  font-weight: 400;
  color: ${({ theme }) => theme.gray07};
`;

const NearbyGuideMessageContainer = styled(PlainContainer)`
  margin-top: 16px;
`;

const WrapContainer = styled(NearbyGuideMessageContainer)`
  max-width: 870px;
  flex-wrap: wrap;
  row-gap: 18px;
`;

const RomTypeContainer = styled(Div)`
  display: grid;
  grid-template-columns: 80px repeat(5, auto);
  gap: 16px 24px;
`;

const RomTypeChecboxContainer = styled(Div)`
  ${({ theme, $romTypeIndex }) => {
    if (ROM_TYPE_ALL_INDEXES.includes($romTypeIndex)) return `border-right: 1px solid ${theme.gray05}`;
    return '';
  }};
  grid-column: ${({ $romTypeIndex }) => {
    if ($romTypeIndex === 9) return 'span 3';
    if ($romTypeIndex === 13) return 'span 3';
    if ($romTypeIndex === 15) return 'span 5';

    return 'auto';
  }};
`;

const ExtendedCheckbox = styled(Checkbox)`
  color: ${({ theme }) => theme.brown04};
`;

const checkboxInputStyle = css`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

const checkboxCustomUIStyle = css`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: 1px solid ${({ theme }) => theme.gray05};
  border-radius: 5px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 10px;
`;

const checkboxT = css`
  &:after {
    content: '';
    display: block;
    background-image: url(${CheckBoxChecked});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }
`;

const getSelectAllList = (index) => {
  switch (index) {
    case 0:
      return ['001', '002', '003', '004', '005'];
    case 6:
      return ['007', '008', '009'];
    case 10:
      return ['011', '012', '013'];
    case 14:
      return ['015'];
    case 16:
      return ['017', '018'];
    default:
      return [];
  }
};

const TARGET_ENUM_KEYS = [
  'CategoryType',
  'StfType',
  'BodyAlignmentType',
  'RomType',
  'RecommendAgeType',
  'LevelType',
  'ExerciseIntensityType',
  'EquipmentType',
  'PositionType',
  'BodyPartForRoutineType',
  'ExceptionBodyPartType',
  'ExceptionDiseaseType',
  'ExercisePurposeType',
];

const ContentsRoutineForm = ({ formNames }) => {
  const { getEnumsByKeys } = useEnumSearch();
  const [
    categoryTypeEnumList,
    stfTypeEnumList,
    bodyAlignmentTypeEnumList,
    RomTypeEnumList,
    recommendAgeTypeEnumList,
    levelTypeEnumList,
    exerciseIntensityTypeEnumList,
    equipmentTypeEnumList,
    positionTypeEnumList,
    bodyPartTypeEnumList,
    exceptionBodyPartTypeEnumList,
    exceptionDiseaseTypeEnumList,
    exercisePurposeTypeEnumList,
  ] = getEnumsByKeys(TARGET_ENUM_KEYS);

  const [
    categoryTypeWatch,
    equipmentTypeWatch,
    positionTypeWatch,
    bodyPartTypeWatch,
    exceptionBodyPartTypeWatch,
    exceptionDiseaseTypeWatch,
    exercisePurposeTypeWatch,
  ] = useWatch({
    name: [
      formNames.CATEGORY_TYPE,
      formNames.EQUIPMENT_TYPE,
      formNames.POSITION_TYPE,
      formNames.BODY_PART_TYPE,
      formNames.EXCEPTION_BODYPART_TYPE,
      formNames.EXCEPTION_DISEASE_TYPE,
      formNames.EXERCISE_PURPOSE_TYPE,
    ],
  });

  const INFO_INPUT = [
    {
      title: '카테고리',
      isRequired: true,
      content: [
        <VerticalContainer>
          <PlainContainer>
            {categoryTypeEnumList?.map((categoryTypeEnum) => (
              <RadioButton
                required
                key={categoryTypeEnum.code}
                id={categoryTypeEnum.code}
                name={formNames.CATEGORY_TYPE}
                item={categoryTypeEnum}
              />
            ))}
          </PlainContainer>
          {categoryTypeWatch === 'CRCT003' ? (
            // NOTE[CS] 기타를 입력받는 params가 현재 API에 존재하지 않음
            <Input name={formNames.CATEGORY_TYPE_ETC} placeholder='기타를 입력하세요.' maxLength={20} />
          ) : null}
        </VerticalContainer>,
      ],
    },
    {
      title: '신체능력평가 종류',
      isRequired: true,
      content: [
        <>
          <GuideMessage>
            고객의 신체능력 평가 결과가 관리나 등급일 경우, 해당 운동이 더 많이 추천될 수 있습니다.
          </GuideMessage>
          <WrapContainer>
            {stfTypeEnumList?.map((stfTypeEnum, index) => (
              <ExtendedCheckbox
                key={stfTypeEnum.code}
                id={stfTypeEnum.code}
                name={`${formNames.STF_TYPE}.${index}`}
                item={stfTypeEnum}
                classes={{
                  Input: checkboxInputStyle,
                  Box: checkboxCustomUIStyle,
                  CheckboxT: checkboxT,
                }}
              />
            ))}
          </WrapContainer>
        </>,
      ],
    },
    {
      title: '체형분석 종류',
      isRequired: true,
      content: [
        <>
          <GuideMessage>
            고객의 체형분석 평가 결과가 관리나 위험 등급일 경우, 해당 운동이 더 많이 추천될 수 있습니다.
          </GuideMessage>
          <WrapContainer>
            {bodyAlignmentTypeEnumList?.map((bodyAlignmentTypeEnum, index) => (
              <ExtendedCheckbox
                key={bodyAlignmentTypeEnum.code}
                id={bodyAlignmentTypeEnum.code}
                name={`${formNames.ALIGNMENT_TYPE}.${index}`}
                item={bodyAlignmentTypeEnum}
                classes={{
                  Input: checkboxInputStyle,
                  Box: checkboxCustomUIStyle,
                  CheckboxT: checkboxT,
                }}
              />
            ))}
          </WrapContainer>
        </>,
      ],
    },
    {
      title: '관절가동 범위',
      isRequired: true,
      content: [
        <RomTypeContainer>
          {RomTypeEnumList?.map((romTypeEnum, index) => (
            <RomTypeChecboxContainer $romTypeIndex={index}>
              <ExtendedCheckbox
                key={romTypeEnum.code}
                id={romTypeEnum.code}
                name={`${formNames.ROM_TYPE}.${index}`}
                item={romTypeEnum}
                classes={{
                  Input: checkboxInputStyle,
                  Box: checkboxCustomUIStyle,
                  CheckboxT: checkboxT,
                }}
                selectAllList={getSelectAllList(index)}
              />
            </RomTypeChecboxContainer>
          ))}
        </RomTypeContainer>,
      ],
    },
    // NOTE[CS] 기획, 디자인은 checkbox로 되어 있으나, API는 string으로 받아 radio button으로 구현
    {
      title: '권장 연령대',
      isRequired: true,
      content: [
        <PlainContainer>
          {recommendAgeTypeEnumList?.map((recommendAgeTypeEnum) => (
            <RadioButton
              required
              key={recommendAgeTypeEnum.code}
              id={recommendAgeTypeEnum.code}
              name={formNames.RECOMMEND_AGE_TYPE}
              item={recommendAgeTypeEnum}
            />
          ))}
        </PlainContainer>,
      ],
    },
    {
      title: '난이도',
      isRequired: true,
      content: [
        <PlainContainer>
          {levelTypeEnumList?.map((levelTypeEnum) => (
            <RadioButton
              required
              key={levelTypeEnum.code}
              id={levelTypeEnum.code}
              name={formNames.LEVEL_TYPE}
              item={levelTypeEnum}
            />
          ))}
        </PlainContainer>,
      ],
    },
    {
      title: '운동강도',
      isRequired: true,
      content: [
        <PlainContainer>
          {exerciseIntensityTypeEnumList?.map((exerciseIntensityTypeEnum) => (
            <RadioButton
              required
              key={exerciseIntensityTypeEnum.code}
              id={exerciseIntensityTypeEnum.code}
              name={formNames.EXERCISE_INTENSITY_TYPE}
              item={exerciseIntensityTypeEnum}
            />
          ))}
        </PlainContainer>,
      ],
    },
    {
      title: '사용기구',
      isRequired: true,
      content: [
        <VerticalContainer>
          <WrapContainer>
            {equipmentTypeEnumList?.map((equipmentTypeEnum, index) => (
              <ExtendedCheckbox
                key={equipmentTypeEnum.code}
                id={equipmentTypeEnum.code}
                name={`${formNames.EQUIPMENT_TYPE}.${index}`}
                item={equipmentTypeEnum}
                classes={{
                  Input: checkboxInputStyle,
                  Box: checkboxCustomUIStyle,
                  CheckboxT: checkboxT,
                }}
              />
            ))}
          </WrapContainer>
          {equipmentTypeWatch?.includes('CRET014') ? (
            // NOTE[CS] 기타를 입력받는 params가 현재 API에 존재하지 않음
            <Input name={formNames.EQUIPMENT_TYPE_ETC} placeholder='기타를 입력하세요.' maxLength={20} />
          ) : null}
        </VerticalContainer>,
      ],
    },
    {
      title: '포지션',
      isRequired: true,
      content: [
        <VerticalContainer>
          <WrapContainer>
            {positionTypeEnumList?.map((positionTypeEnum, index) => (
              <ExtendedCheckbox
                key={positionTypeEnum.code}
                id={positionTypeEnum.code}
                name={`${formNames.POSITION_TYPE}.${index}`}
                item={positionTypeEnum}
                classes={{
                  Input: checkboxInputStyle,
                  Box: checkboxCustomUIStyle,
                  CheckboxT: checkboxT,
                }}
              />
            ))}
          </WrapContainer>
          {positionTypeWatch?.includes('CRPT004') ? (
            // NOTE[CS] 기타를 입력받는 params가 현재 API에 존재하지 않음
            <Input name={formNames.POSITION_TYPE_ETC} placeholder='기타를 입력하세요.' maxLength={20} />
          ) : null}
        </VerticalContainer>,
      ],
    },
    {
      title: '신체 부위',
      isRequired: true,
      content: [
        <VerticalContainer>
          <WrapContainer>
            {bodyPartTypeEnumList?.map((bodyPartTypeEnum, index) => (
              <ExtendedCheckbox
                key={bodyPartTypeEnum.code}
                id={bodyPartTypeEnum.code}
                name={`${formNames.BODY_PART_TYPE}.${index}`}
                item={bodyPartTypeEnum}
                classes={{
                  Input: checkboxInputStyle,
                  Box: checkboxCustomUIStyle,
                  CheckboxT: checkboxT,
                }}
              />
            ))}
          </WrapContainer>
          {bodyPartTypeWatch?.includes('CRBPT015') ? (
            // NOTE[CS] 기타를 입력받는 params가 현재 API에 존재하지 않음
            <Input name={formNames.BODY_PART_TYPE_ETC} placeholder='기타를 입력하세요.' maxLength={20} />
          ) : null}
        </VerticalContainer>,
      ],
    },
    {
      title: '근육명',
      isRequired: true,
      content: [
        <Input required name={formNames.MUSCLE} type='text' placeholder='근육명을 입력해주세요.' maxLength='20' />,
      ],
    },
    {
      title: '예외 대상 1(신체부위)',
      isRequired: true,
      content: [
        <>
          <GuideMessage>해당 운동을 유의해야하는 고객군을 문제 신체부위 기준으로 선택해주세요.</GuideMessage>
          <VerticalContainer>
            <WrapContainer>
              {exceptionBodyPartTypeEnumList?.map((exceptionBodyPartTypeEnum, index) => (
                <ExtendedCheckbox
                  key={exceptionBodyPartTypeEnum.code}
                  id={exceptionBodyPartTypeEnum.code}
                  name={`${formNames.EXCEPTION_BODYPART_TYPE}.${index}`}
                  item={exceptionBodyPartTypeEnum}
                  classes={{
                    Input: checkboxInputStyle,
                    Box: checkboxCustomUIStyle,
                    CheckboxT: checkboxT,
                  }}
                />
              ))}
            </WrapContainer>
            {exceptionBodyPartTypeWatch?.includes('EBPT015') ? (
              // NOTE[CS] 기타를 입력받는 params가 현재 API에 존재하지 않음
              <Input name={formNames.EXCEPTION_BODYPART_TYPE_ETC} placeholder='기타를 입력하세요.' maxLength={20} />
            ) : null}
          </VerticalContainer>
        </>,
      ],
    },
    {
      title: '예외 대상 2(질환명)',
      isRequired: true,
      content: [
        <>
          <GuideMessage>해당 운동을 유의해야하는 고객군을 질환 기준으로 선택해주세요.</GuideMessage>
          <VerticalContainer>
            <WrapContainer>
              {exceptionDiseaseTypeEnumList?.map((exceptionDiseaseTypeEnum, index) => (
                <ExtendedCheckbox
                  key={exceptionDiseaseTypeEnum.code}
                  id={exceptionDiseaseTypeEnum.code}
                  name={`${formNames.EXCEPTION_DISEASE_TYPE}.${index}`}
                  item={exceptionDiseaseTypeEnum}
                  classes={{
                    Input: checkboxInputStyle,
                    Box: checkboxCustomUIStyle,
                    CheckboxT: checkboxT,
                  }}
                />
              ))}
            </WrapContainer>
            {exceptionDiseaseTypeWatch?.includes('EDT005') ? (
              // NOTE[CS] 기타를 입력받는 params가 현재 API에 존재하지 않음
              <Input name={formNames.EXCEPTION_DISEASE_TYPE_ETC} placeholder='기타를 입력하세요.' maxLength={20} />
            ) : null}
          </VerticalContainer>
        </>,
      ],
    },
    {
      title: '운동효과',
      isRequired: true,
      content: [
        <Input
          required
          name={formNames.EXERCISE_EFFECT}
          type='text'
          placeholder='운동효과를 입력해주세요.'
          maxLength='100'
        />,
      ],
    },
    {
      title: '동작설명',
      isRequired: true,
      content: [
        <Input
          required
          name={formNames.ACTION_DESCRIPTION}
          type='text'
          placeholder='동작설명을 입력해주세요.'
          maxLength='1000'
        />,
      ],
    },
    {
      title: '운동 목적',
      isRequired: true,
      content: [
        <VerticalContainer>
          <WrapContainer>
            {exercisePurposeTypeEnumList?.map((exercisePurposeTypeEnum, index) => (
              <ExtendedCheckbox
                key={exercisePurposeTypeEnum.code}
                id={exercisePurposeTypeEnum.code}
                name={`${formNames.EXERCISE_PURPOSE_TYPE}.${index}`}
                item={exercisePurposeTypeEnum}
                classes={{
                  Input: checkboxInputStyle,
                  Box: checkboxCustomUIStyle,
                  CheckboxT: checkboxT,
                }}
              />
            ))}
          </WrapContainer>
          {exercisePurposeTypeWatch?.includes('EPT007') ? (
            // NOTE[CS] 기타를 입력받는 params가 현재 API에 존재하지 않음
            <Input name={formNames.BODY_PART_TYPE_ETC} placeholder='기타를 입력하세요.' maxLength={20} />
          ) : null}
        </VerticalContainer>,
      ],
    },
    {
      title: '영상 썸네일 이미지',
      isRequired: true,
      content: [<ImageUpload name={formNames.CONTENTS_IMAGE_FILE} />],
    },
    {
      title: '영상 파일',
      isRequired: true,
      content: [<VideoUpload name={formNames.CONTENTS_ROUTINE_FILE} />],
    },
  ];

  return (
    <>
      <TitleWithDivider title='루틴/추천 운동 정보' />
      <GridLayout data={INFO_INPUT} />
    </>
  );
};

ContentsRoutineForm.propTypes = {
  formNames: PropTypes.objectOf(PropTypes.string),
};

export default ContentsRoutineForm;
