import { useState, useCallback } from 'react';
import { Button, Div, Icon } from '@components/index';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { CloseDarkIcon } from '@modules/FTA/assets';
import GridSubSessionLayout from '../GridLayout/GridSubSessionLayout';

const ButtonContainer = styled(Div)`
  display: flex;
  align-items: center;

  button + button {
    ::before {
      content: '';
      width: 1px;
      height: 16px;
      background-color: rgba(229, 229, 229, 1);
      margin-right: 10px;
      margin-left: -10px;
    }
  }
`;

const addButtonCss = css`
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 10px;
  background-color: black;
  color: white;
  padding: 8px;
  width: 100px;
`;

const deleteButtonCss = css`
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 10px;
  border: 1px solid black;
  background-color: white;
  color: balck;
  padding: 8px;
  width: 100px;
`;

const subButtonCss = css`
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 4px;
`;

const LIMIT_SESSION = 3;

const DefaultSection = { title: '', addBtn: '질문 추가', addSubBtn: '세션 추가' };

const DynamicSessionSubManagement = ({
  initSession,
  initSubSession,
  section = DefaultSection,
  edit,
  initData,
  initSubDataName,
}) => {
  /* edit이면 api 받아온 세션순서 아니면 [1] */

  const { unregister } = useFormContext();
  // const isChanged = useWatch({ name });
  const [questionOrder, setQuestionOrder] = useState(() =>
    edit === true ? initData?.map((_, index) => index + 1) : [1]
  );

  const [questionSubOrder, setQuestionSubOrder] = useState(
    edit === true ? initData?.map((init) => init?.[initSubDataName]?.map((s, sIndex) => sIndex)) : [1]
  );

  const [questions, setQuestions] = useState(() => {
    if (edit) {
      return initData?.map((el, index) => {
        const session = initSession(index + 1, el);
        const subSessions = el[initSubDataName].map((subEl, subIndex) =>
          initSubSession(index + 1, subIndex + 1, subEl)
        );
        session.subList = subSessions;
        return session;
      });
      // eslint-disable-next-line no-else-return
    } else {
      const session = initSession();
      // session.subList = [initSubSession(0)];
      return [session];
    }
  });

  const handleAddQuestion = useCallback(() => {
    if (questions.length >= LIMIT_SESSION) {
      return;
    }
    setQuestionOrder((prev) => {
      const newId = Math.max(...prev) + 1;
      setQuestions((prevQuestions) => [...prevQuestions, initSession(newId)]);
      return [...prev, newId];
    });
  }, [questions, initSession, questionOrder]);

  const handleDeleteQuestion = useCallback(
    (index) => {
      setQuestions((prev) => prev.filter((_, idx) => idx !== index));
      setQuestionOrder((prev) => prev.filter((_, idx) => idx !== index));
      unregister(`questions.${index + 1}`);
    },
    [unregister]
  );

  const handleAddSubQuestion = useCallback(
    (parentIndex) => {
      setQuestionSubOrder((prev) => {
        const newId = Math.max(...prev) + 1;
        setQuestions((prevQuestions) => {
          const newSubQuestion = initSubSession(parentIndex + 1, newId);
          const updatedQuestions = [...prevQuestions];
          updatedQuestions[parentIndex].subList.push(newSubQuestion);
          return updatedQuestions;
        });
        return [...prev, newId];
      });
    },
    [questionSubOrder, questions, initSubSession]
  );

  const handleDeleteSubQuestion = useCallback(
    (parentIndex, subIndex) => {
      setQuestions((prev) => {
        const updatedQuestions = [...prev];
        updatedQuestions[parentIndex].subList = updatedQuestions[parentIndex].subList.filter(
          (_, idx) => idx !== subIndex
        );
        return updatedQuestions;
      });

      //   setQuestionSubOrder((prev) => {
      //     const updatedQuestions = [...prev];
      //     updatedQuestions[parentIndex].subList = updatedQuestions[parentIndex].subList.filter(
      //       (_, idx) => idx !== subIndex
      //     );
      //   });

      unregister(`questions.${parentIndex + 1}.subQuestions.${subIndex + 1}`);
    },
    [unregister]
  );

  const buttonGroup = (index) => {
    if (!questions) {
      return null;
    }

    const canDeleteItem = questions.length > 1 && questions.length !== index;
    const canAddSession = questions.length === index + 1 && questions.length < LIMIT_SESSION;

    return (
      <ButtonContainer>
        {canAddSession && (
          <Button classes={{ Button: addButtonCss }} onClick={handleAddQuestion}>
            {section.addBtn}
          </Button>
        )}
        {canDeleteItem && (
          <Button classes={{ Button: deleteButtonCss }} onClick={() => handleDeleteQuestion(index)}>
            삭제
          </Button>
        )}
      </ButtonContainer>
    );
  };

  const buttonSubAdd = (parentIndex) => {
    if (!questions) {
      return null;
    }

    // const canAddSession = questions[parentIndex]?.subList?.length < LIMIT_SESSION;

    return (
      <ButtonContainer>
        <Button classes={{ Button: subButtonCss }} onClick={() => handleAddSubQuestion(parentIndex)}>
          {section.addSubBtn}
        </Button>
      </ButtonContainer>
    );
  };

  const buttonSubDelete = (parentIndex, subIndex) => {
    if (!questions) {
      return null;
    }

    const canDeleteItem =
      questions[parentIndex]?.subList?.length > 0 && questions[parentIndex].subList.length !== subIndex;

    return (
      <ButtonContainer>
        {canDeleteItem && (
          <Icon
            icon={CloseDarkIcon}
            iconWidth={20}
            iconHeight={20}
            onClick={() => handleDeleteSubQuestion(parentIndex, subIndex)}
          />
        )}
      </ButtonContainer>
    );
  };

  return (
    <GridSubSessionLayout
      data={questions}
      buttonGroup={buttonGroup}
      buttonSubAdd={buttonSubAdd}
      buttonSubDelete={buttonSubDelete}
      section={section}
    />
  );
};

DynamicSessionSubManagement.propTypes = {
  /* 세션 만들어주는 함수 */
  initSession: PropTypes.func.isRequired,
  initSubSession: PropTypes.func.isRequired,
  section: PropTypes.any,
  edit: PropTypes.bool,
  initData: PropTypes.any,
  initSubDataName: PropTypes.string,
};

export default DynamicSessionSubManagement;
