/** 기타 관리 > 공지사항 > 공지사항 클릭(상세 화면) > 수정 */

import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import styled, { css } from 'styled-components';

import { Button, Div, Input, Modal, ModalContext, SelectBox } from '@components/index';
import PageTitle from '@FTA/components/PageTitle';

import PATHS from '@router/paths';
import { useDispatch, useSelector } from 'react-redux';
import { getNoticeDetailAPI, NoticeRegisterAPI } from '@redux/action/etcAction';
import TitleWithDivider from '@modules/FTA/components/GridLayout/TitleWithDivider';
import GridLayout from '@modules/FTA/components/GridLayout/GridLayout';
import TextEditor from '@modules/FTA/components/TextEditor';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonCss = css`
  width: 75px;
  height: 55px;
  padding: 15px 18px 14px 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.72px;
  text-align: center;
  margin: 5px;
`;

// TODO: 유효성관련 메세지 파일 만들기
// const ERROR_MESSAGE = {
//   inputText: '내용을 입력해주세요.',
//   pwdLength: '비밀번호는 8자 이상 10자 이상이어야 합니다.',
// };

const FORM_NAMES = Object.freeze({
  TITLE: 'title',
  COUNTRY: 'country',
  CATEGORY: 'category',
  CONTENT: 'content',
});

const NoticeEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serarchParams] = useSearchParams();
  const noticeInfoId = serarchParams.get('noticeInfoId');

  /* eslint-disable no-unneeded-ternary */
  const isEdit = noticeInfoId ? true : false;
  const noticeDetail = useSelector((state) => state.etc.noticeDetail);
  const methods = useForm(
    isEdit && {
      defaultValues: {
        [FORM_NAMES.TITLE]: noticeDetail.title,
        [FORM_NAMES.COUNTRY]: noticeDetail.country,
        [FORM_NAMES.CATEGORY]: noticeDetail.category,
        [FORM_NAMES.CONTENT]: noticeDetail.content,
      },
    }
  );

  const { handleSubmit, reset } = methods;

  const { showModal, closeModal } = useContext(ModalContext);

  const testSelectBox = {
    guide: '',
    list: [
      { id: '1', title: 'SelectBox' },
      { id: '2', title: 'SelectBox item' },
    ],
  };

  const NOTICE_DATA = useMemo(() => {
    const formInfo = [
      { title: '번호', content: [noticeDetail?.noticeInfoId] },
      {
        title: '제목',
        isRequired: true,
        content: [<Input name={FORM_NAMES.TITLE} type='text' placeholder='제목을 입력해주세요.' maxLength='100' />],
      },
      {
        title: '국가 선택',
        content: [
          // FIXME : API 연결 후 기존 값으로 변경해야함
          <SelectBox name={FORM_NAMES.COUNTRY} options={testSelectBox} />,
        ],
      },
      {
        title: '카테고리 선택',
        content: [
          // FIXME : API 연결 후 기존 값으로 변경해야함
          <SelectBox name={FORM_NAMES.CATEGORY} options={testSelectBox} />,
        ],
      },
      {
        title: '내용',
        isRequired: true,
        content: [<TextEditor name={FORM_NAMES.CONTENT} isEdit={isEdit} />],
      },
    ];
    if (!isEdit) formInfo.splice(0, 1);
    return formInfo;
  }, [noticeDetail, isEdit]);

  useEffect(() => {
    getNoticeDetailAPI(dispatch, noticeInfoId);
  }, [noticeInfoId]);

  useEffect(() => {
    if (Object.values(noticeDetail).length > 0 && isEdit) {
      reset({
        [FORM_NAMES.TITLE]: noticeDetail.title,
        [FORM_NAMES.COUNTRY]: noticeDetail.country,
        [FORM_NAMES.CATEGORY]: noticeDetail.category,
        [FORM_NAMES.CONTENT]: noticeDetail.content,
      });
    } else {
      reset();
    }
  }, [noticeDetail, isEdit]);

  const handleNavigate = useCallback(
    (path) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  const handleSave = useCallback(
    (values) => {
      console.log('###values', values);
      showModal(
        <Modal
          body='입력한 정보를 저장하시겠습니까?'
          btns={
            <ButtonSection>
              <Button
                onClick={() => {
                  if (isEdit) {
                    values.category = 'FIXME: 어떤 카테고리인지 정의 필요';
                    values.country = 'FIXME: 어떤 국가인지 정의 필요';

                    NoticeRegisterAPI(dispatch, { ...values, ...{ noticeInfoId: Number(noticeDetail.noticeInfoId) } });
                    handleNavigate(`/${PATHS.NOTICE}`);
                  } else {
                    NoticeRegisterAPI(dispatch, values);
                    handleNavigate(`/${PATHS.NOTICE}`);
                  }
                  closeModal();
                }}
                $layout='square'
                $variantColor='primary'
                classes={{ Button: ButtonCss }}
              >
                확인
              </Button>
              <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
                취소
              </Button>
            </ButtonSection>
          }
        />
      );
    },
    [NOTICE_DATA]
  );

  const handleCancel = useCallback(() => {
    showModal(
      <Modal
        body='정보 저장을 취소하시겠습니까?'
        btns={
          <ButtonSection>
            <Button
              onClick={() => {
                handleNavigate(`/${PATHS.NOTICE}`);
                closeModal();
              }}
              $layout='square'
              $variantColor='primary'
              classes={{ Button: ButtonCss }}
            >
              확인
            </Button>
            <Button onClick={closeModal} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
              취소
            </Button>
          </ButtonSection>
        }
      />
    );
  }, []);

  return (
    <Container>
      <PageTitle title='공지사항' />
      <FormProvider {...methods}>
        <TitleWithDivider title='기본 정보' />
        <GridLayout data={NOTICE_DATA} />
        <ButtonSection>
          <Button
            onClick={handleSubmit(handleSave)}
            $layout='square'
            $variantColor='primary'
            classes={{ Button: ButtonCss }}
          >
            저장
          </Button>
          <Button onClick={handleCancel} $layout='square' $variantColor='line' classes={{ Button: ButtonCss }}>
            취소
          </Button>
        </ButtonSection>
      </FormProvider>
    </Container>
  );
};

NoticeEdit.propTypes = {};

export default NoticeEdit;
